import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setListaCodigosIAF, setListaEntes, setListaEstadosSolicitud, setListaMotivosIntegracion, setListaMotivosModificadores, setListaNormaMotivo, setListaNormaMotivoAlimentos, setListaNormaMotivoProducto, setListaNormas, setListaServicios, setListaUsuarios } from "redux/slices/globalSlice";
import { 
    getListaEntes, 
    getListaEstados,
    getListaMotivosIntegracion, 
    getListaMotivosModificacion, 
    getListaNormaMotivo, 
    getListaNormas, 
    getListaServicios, 
    getListaUsuarios 
} from "services/Listas";
import { getListCodigoIAF } from "services/Solicitud";

export const useGlobalData = (props) => {

    const dispatch = useDispatch();

    const getNormaMotivos = async () =>{
        getListaNormaMotivo()
        .then(response =>{
            dispatch(setListaNormaMotivo(response))
        })
        .catch(e => {
            console.error("ERROR GET LISTA NORMA MOTIVO", e)
        })
    } 

    const getNormaMotivosSegunTipo = async (tipo) =>{
        getListaNormaMotivo(tipo)
        .then(response =>{
            if(tipo === TIPO_SOLICITUD_PRODUCTO){
                dispatch(setListaNormaMotivoProducto(response))
            }

            if(tipo === TIPO_SOLICITUD_ALIMENTOS){
                dispatch(setListaNormaMotivoAlimentos(response))
            }

            if(tipo === TIPO_SOLICITUD_GESTION){
                dispatch(setListaNormaMotivo(response))
            }
        })
        .catch(e => {
            console.error("ERROR GET LISTA NORMA MOTIVO" + tipo, e)
        })
    }

    const getMotivosModificadores = async () =>{
        getListaMotivosModificacion()
        .then(response => {
            dispatch(setListaMotivosModificadores(response))
        })
        .catch(e => {
            console.error("ERROR GET MOTIVOS MODIFICACION", e)
        })
    }
    const getNormasCodigo = async () => {
        
        getListaNormas()
        .then(response => {
            dispatch(setListaNormas(response))
        })
        .catch(err => {
            //setError(true)
            console.error("ERROR GET NORMAS", err);
        });
    }


    const getServicios = async () => {
        
        getListaServicios()
        .then(response => {

            if(Array.isArray(response) && response.length > 0){
                // ordenar por nombre alfabeticamente
                response = response.sort((a,b) => { 
                    if(a.nombre > b.nombre){
                        return 1;
                    }
                    if(a.nombre < b.nombre){
                        return -1;
                    }
                    return 0;
                });
            }

            dispatch(setListaServicios(response))
        })
        .catch(err => {
            //setError(true)
            console.error("ERROR GET NORMAS", err);
        });
    }


    const getMotivosIntegracion = async () => {
        getListaMotivosIntegracion()
        .then(response => {
            dispatch(setListaMotivosIntegracion(response))
        })
        .catch(error => {
            //setError(true)
            console.error("ERROR GET MOTIVOS INTEGRACION", error)
        })
    }
    const getEntes = async() =>{
        getListaEntes()
        .then(response => {
            dispatch(setListaEntes(response))
        })
        .catch(error => {
            console.error("ERROR GET LISTA ENTES", error)
        })
    }
    const getCodigosIAF = async () => {

        getListCodigoIAF()
        .then(response => {
            dispatch(setListaCodigosIAF(response));
        })
        .catch(e => {
            console.error("ERROR GET CODIGOS IAF", e)
        })

        // const response = iafMockup;
        // dispatch(setListaCodigosIAF(response))
    }

    const getListaEstadosSolicitud = async () =>{
        getListaEstados()
        .then(response =>{
            dispatch(setListaEstadosSolicitud(response))
        })
        .catch(error => {console.error('ERROR GET LISTA ESTADOS',error)})
    }

    const getListaUsuariosBuscador = async () => {
        getListaUsuarios()
        .then(response => {
            dispatch(setListaUsuarios(response))
        })
        .catch(error => {console.error("ERROR GET LISTA USUARIOS", error)})
    }
    useEffect(() => {
        getEntes();
        getNormasCodigo();
        getNormaMotivos();
        getNormaMotivosSegunTipo(TIPO_SOLICITUD_PRODUCTO);
        getNormaMotivosSegunTipo(TIPO_SOLICITUD_ALIMENTOS);
        getMotivosModificadores();
        getMotivosIntegracion();
        getCodigosIAF();
        getListaEstadosSolicitud();
        getListaUsuariosBuscador();
        getServicios();
    }, [])
}