import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'

function TituloCustom({title, variant }) {
  return (
    <Box display="flex" flexDirection={"column"} alignSelf="center">
        <Typography variant={variant} fontWeight="fontWeightBold" >{title}</Typography>
    </Box>
  )
}

export default TituloCustom