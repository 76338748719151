import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { _getCursos } from 'services/Propuesta';

const initialState = {
    cursos: [],
    loading: false,
    error: null
};

export const fetchCursos = createAsyncThunk(
    'cursos/fetchCursos',
    async (_, thunkAPI) => {
        try {
            return await _getCursos();
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const cursosSlice = createSlice({
    name: 'cursos',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCursos.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCursos.fulfilled, (state, action) => {
                state.loading = false;
                state.cursos = action.payload;
            })
            .addCase(fetchCursos.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export default cursosSlice.reducer;