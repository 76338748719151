import { Box, Typography } from '@mui/material'
import React from 'react'

function PropuestaExterna() {
  return (
    <Box display="flex" justifyContent="center" sx={{border:'1px solid red'}}>
        <Typography>Propuesta Enviada por fuera del sistema</Typography>
    </Box>
  )
}

export default PropuestaExterna