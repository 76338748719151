import { useContext, useEffect, useState } from 'react';
import { Box, Button, Fab, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';

import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
 

import { setNuevaNorma, updateCotizacion, updateCotizacionAlimentos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import InputNormaMotivo from 'components/inputNormasMotivos';
import 'scss/components/paper-input-normas.scss';
import { classes } from './styles'
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import { usePermitirEdicion } from 'hooks/usePermitirEdicion';
import { useSelector } from 'react-redux';

const AlimentosNormas = ({ guardarCotizacion }) => {
   const globalSlice = useSelector(state => state.global);
   const { state, dispatch } = useContext(CotizacionContext);
   
   const [limiteNormas, setLimiteNormas] = useState(false)
   const [integracion, setIntegracion] = useState(true)
   const [habilitadoGuardar, setHabilitadoGuardar] = useState(false)
   const { listaNormas } = globalSlice;
   const { inputNormasMotivos, inputIntegracion, inputDatosCotizacion, json,estadoCotizacion, turnos, estadoCarga } = state;
   const [noEliminar, setNoEliminar] = useState(true);

   const [modoEdicion, setModoEdicion] = useState(false)

   const permitirEdicion = usePermitirEdicion(modoEdicion)

   const handleAgregarNorma = () => {
      const nuevaNorma = {
         id: 0, norma: '', motivo: '', nombre: '', integrada: false,
         vencimiento: new Date().toISOString(),
         cambioVersion: false,
         transferencia: false,
         ampliacion: false,
         reduccion: false,
         numero: '',
         ente: 1,
         cluster: 0,
         consumoEnergiaAnual: 0,
         fuentesDeEnergia: 0,
         usosDeEnergia: 0,
         nuevaNorma: true
      }

      dispatch(setNuevaNorma(nuevaNorma))
      setNoEliminar(false)
   }
   
   const handleEditar = (e) => {
      if (modoEdicion) {
         guardarCotizacion(e)
         setModoEdicion(false)
      }
      else {
         setModoEdicion(true)
      }

   }

   useEffect(() => {
      if (inputNormasMotivos.length > 1) {
         setNoEliminar(false)
      } else {
         setNoEliminar(true)
      }

      if (inputNormasMotivos.length < listaNormas.length) {
         setLimiteNormas(false)
      } else {
         setLimiteNormas(true)
      }

      if (inputNormasMotivos.filter(i => i.integrada === true && i.norma !== '').length > 1) {
         setIntegracion(false)
      } else {
         setIntegracion(true)
      }

      if (inputNormasMotivos.every(i => i.integrada === false)) {
         setHabilitadoGuardar(false)
      } else {
         if (inputIntegracion.motivosOrganizacion.length > 0) {
            setHabilitadoGuardar(false)
         } else {
            setHabilitadoGuardar(true)
         }
      }
   }, [inputNormasMotivos, inputIntegracion, listaNormas, inputDatosCotizacion.nombre])

   return (
      <Paper className="my_paper" elevation={5} sx={{position: "relative" }}  >
         {inputNormasMotivos.map((itemNormaMotivo, index) =>
            <InputNormaMotivo
               key={"inputnormamotivo" + index}
               index={index}
               noEliminar={noEliminar}
               setLimiteNormas={setLimiteNormas}
               modoEdicion={modoEdicion}
               tipo={"ALIMENTOS"}
            />
         )
         }
         {
            !permitirEdicion &&
         <Box sx={classes.add}>
            <Button
               variant="contained"
               startIcon={<AddIcon />}
               color="primary"
               onClick={handleAgregarNorma}
            >
               Agregar NORMA
            </Button>
         </Box>
         }
         {(estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
               <Fab
                  color="primary"
                  aria-label="add"
                  sx={{ position: "absolute", right: "-70px", top: "22%" }}
                  onClick={handleEditar}
                  disabled={estadoCarga.guardando}
               >
                  {modoEdicion ?
                     <SaveIcon />
                     :
                     <EditIcon />
                  }
               </Fab>
            </Restricted>
         }
      </Paper>
   )
}

export default AlimentosNormas;
