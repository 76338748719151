import React, { useContext, useState, useEffect} from 'react'
import LabelCustom from 'components/labelCustom';
import AddIcon from '@mui/icons-material/Add';
import { Accordion, AccordionDetails, AccordionSummary, Button, Drawer, FormControl, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import NormaChip from 'components/NormaChip';
import { agregarIntegracion, duplicarSitio, eliminarSitio, setEstadoCotizacion, updateSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import AccordionCalculoDiasAuditor from 'components/accordionCalculoDiasAuditor';
import ModalNorma from 'components/ModalAgregarNormaChip';
import CustomDialog from 'components/customDialog';
import LabelIntegracionStyled from 'components/IntegracionSitio';
import AddLinkIcon from '@mui/icons-material/AddLink';
import ChatIcon from '@mui/icons-material/Chat';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

//STYLE
import 'scss/components/accordionSitio.scss'
import { makeStyles } from '@mui/styles';
import TituloCustom from 'components/TituloCustom';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import { capitalizeFirstLetter } from 'utlis/stringUtils';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    Accordion: {
      padding: 0,
      boxShadow: `0px 0px 0px 0px`,
      backgroundColor:`#e4e4e4`
    
    },
    AccordionExpanded:{
        padding: 0,
        boxShadow: `0px 0px 0px 0px`,
    },
    ButtonAdd:{
        backgroundColor: `#e4e4e4`
    }
  }));

function AccordionSitio({sitio, grupo, setChildExpanded}) {

    const dispatchRedux = useDispatch()
    const {state, dispatch} = useContext(CotizacionContext)
    const {json, estadoCarga, listaRangoPersona, estadoCotizacion} = state

    const [expanded, setIsExpanded] = useState(false)

    const handleChange = () => (event, isExpanded) => {
        setIsExpanded(isExpanded)
      };

    const [sitioLocal, setSitioLocal] = useState({...sitio})
    const [errorNombre, setErrorNombre] = useState(false)
    const [errorCantidadSitios, setErrorCantidadSitios] = useState(false)
    const [errorPersonal, setErrorPersonal] = useState(false)
    
    const [idNormaEdicion, setIdNormaEdicion] = useState(null);


    const handleNombre = (e) => {
        setSitioLocal({...sitioLocal, nombre:e.target.value})
        setErrorNombre(grupo.sitios.some(s => s.nombre.toUpperCase() === e.target.value.toUpperCase()))
    }

    const actualizarSitio = () => {
        if(!errorNombre && !errorPersonal && !errorCantidadSitios)
        {
            const data = {
                idCotizacion: json.id,
                idGrupo: grupo.id,
                sitio: sitioLocal
            }
            if(sitioLocal.nombre !== sitio.nombre || 
               sitioLocal.cantidadDeSitios !== sitio.cantidadDeSitios ||
               sitioLocal.personal !== sitio.personal ||
               sitioLocal.rangoDePersonal !== sitio.rangoDePersonal){
                updateSitios(dispatch, data)
            }
        }
        else{
        }

    }
    
    const handleRangoPersonas = (e) => {
        const rango = listaRangoPersona.find(l => e.target.value === l.desde)
        setSitioLocal({...sitioLocal, rangoDePersonal: rango})
    }
    const handlePersonas = (e) => {
        setSitioLocal({...sitioLocal, personal: e.target.value})
        const numeroPersonal = e.target.value
        if(numeroPersonal){
            if(parseInt(numeroPersonal) === 0 || parseInt(numeroPersonal)>10700){
                setErrorPersonal(true)    
            }
            else{
                setErrorPersonal(false)
                setSitioLocal({...sitioLocal, personal: parseInt(numeroPersonal)})
            }
        }
        else{
            setErrorPersonal(true)
        }
    }
    const handleCantidad = (e) => {

        const cantidad = parseInt(e.target.value)
        let personalAux = null
        let personalRangoAux = null

        if(cantidad){
            if(cantidad===1){
                if(sitioLocal.personal){
                    setSitioLocal({...sitioLocal, cantidadDeSitios:cantidad, rangoDePersonal:null})   
                }
                else{
                    personalAux = sitioLocal.rangoDePersonal.desde
                    setSitioLocal({...sitioLocal, cantidadDeSitios:cantidad, rangoDePersonal:null, personal:personalAux})
                }
            }
            else{
                if(sitioLocal.personal){
                    personalRangoAux = listaRangoPersona.find(l => sitioLocal.personal >= l.desde && sitioLocal.personal <= l.hasta)
                    setSitioLocal({...sitioLocal, cantidadDeSitios:cantidad, rangoDePersonal:personalRangoAux, personal:null})
                }
                else{
                    personalAux = sitioLocal.rangoDePersonal.desde
                    setSitioLocal({...sitioLocal, cantidadDeSitios:cantidad, personal:personalAux})
                }
            }
            setErrorCantidadSitios(false)
        }
        else{
            if(cantidad === 0){
                setSitioLocal({...sitioLocal, cantidadDeSitios:0})
                setErrorCantidadSitios(true)
            }
            else{
                setSitioLocal({...sitioLocal, cantidadDeSitios:0})
                setErrorCantidadSitios(true)
            }
        }
    }
   

    const renderPersonal = () => {
        if(sitioLocal.cantidadDeSitios > 1){
            return (
                <FormControl fullWidth>
                <InputLabel id="label-personas-rango">Personas</InputLabel>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <Select
                    label="Personas"
                    labelId='label-personas-rango'
                    required
                    disabled={inputEditableEnviada()}
                    value={sitioLocal.rangoDePersonal.desde}
                    onChange={handleRangoPersonas}
                    >
                {
                    listaRangoPersona.map((item, index) => 
                    <MenuItem
                        key={'rangopersona'+index} 
                        value={item.desde}
                    >
                    {item.descripcion}
                    </MenuItem>)
                }
                </Select>
                </Restricted>
                </FormControl>
            )
        }else{
            return(
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField
                label="Personas"
                type="number"
                required
                fullWidth
                disabled={inputEditableEnviada()}
                error={errorPersonal}
                helperText={errorPersonal?"El numero debe estar entre el rango de 1 y 10700":""}
                onChange={handlePersonas}
                value={sitioLocal.personal}
            /></Restricted>
            )
        }
    }

    const handleAgregarIntegracion = () => {
        const data = {
            idCotizacion: json.id,
            idGrupo: grupo.id,
            idSitio: sitio.id
        }
        agregarIntegracion(dispatch, data)
    }

    const handleEliminarSitio = () => {
        const data = {
            idCotizacion: json.id,
            idGrupo: grupo.id,
            idSitio: sitio.id
        }
        eliminarSitio(dispatch, data)
        handleCloseDialog()
    }

    const onClickOpenCalculator = (norma) => {
        setIdNormaEdicion(norma.id);
        setOpenDrawer(true); 
    };

    const renderDias = (label, dias) => {
        //TODO
        if(dias){
            return( <Box className="sitio-resumen-certificacion">
                    <LabelCustom label={label} value={dias}></LabelCustom>
                    </Box>)
        }else{
            if(dias === 0){
                return( <Box className="sitio-resumen-certificacion">
                            <LabelCustom label={label} value={dias}></LabelCustom>
                        </Box>)
            }
        }
    }

    const totalDiasAuditor = () => {
        if(sitio.diasCertificacion){
            return(
                <>
                <Box sx={{width:"140px"}}>
                    <Typography sx={{width:"520px"}} variant="h6">{sitio.diasCertificacion} </Typography>
                    <Typography variant='body2' color="GrayText">(E1: {sitio.instancias[0].diasAuditorEtapa1} E2: {sitio.instancias[0].diasAuditorEtapa2})</Typography>
                </Box>
                <Box display="flex" gap={1} sx={{width:"140px"}}>
                    <Typography variant="h6">{sitio.diasMantenimiento} </Typography>
                    <Typography sx={{paddingTop:"9px"}} variant='body2' color="GrayText">x{sitio.instancias.length-1}</Typography>
                </Box>
                </>
            )
        }else{
            if(sitio.diasRecertificacion){
                return(
                    <>
                        <Box sx={{width:"140px"}}><Typography sx={{width:"520px"}} variant="h6">{sitio.diasRecertificacion}</Typography></Box>
                        <Box display="flex" gap={1} sx={{width:"140px"}}>
                            <Typography variant="h6">{sitio.diasMantenimiento} </Typography>
                            <Typography sx={{paddingTop:"9px"}} variant='body2' color="GrayText">x{sitio.instancias.length-1}</Typography>
                        </Box>
                    </>
                )
            }else{
                return(
                    <>
                    <Box display="flex" gap={1} sx={{width:"140px"}}>
                        <Typography variant="h6">{sitio.diasMantenimiento} </Typography>
                        <Typography sx={{paddingTop:"9px"}} variant='body2' color="GrayText">x{sitio.instancias.length}</Typography>
                    </Box>
                    </>
                )
            }
        }
    }

    const ToolTipNormas = () => {
        return(
            <>
            {
                sitio.normas.map((norma, index) =>
                <div key={'tooltip'+index}>
                    <span>{norma.cotizacionNorma.norma.nombre}</span><br></br>
                </div>
                )
            }
            </>
        )
    }

    const inputEditableEnviada = (permiteEnviada) => {
        if(permiteEnviada){
            if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA){
                return estadoCarga.guardando
            }else{
                return true
            }
        }else{
            if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR ){
                return estadoCarga.guardando
            }else{
                return true
            }
        }
    }
    const handleDuplicarSitio = () => {
        var data = {
            idCotizacion: json.id,
            idGrupo: grupo.id,
            idSitio: sitio.id,
        }
        duplicarSitio(dispatch, data)
    }

    //Flag para agregar integracion si existe general
    const isAddIntegracion = (json.infoIntegracion !== null && sitio.infoIntegracion === null)

    //Dialog
    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    //Drawer
    const [openDrawer, setOpenDrawer] = useState(false)
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    //Modal
    const [modalState, setModalState] = useState({opened: false});
    const handleModal = () => setModalState({opened: !modalState.opened})

    const commonStyles = {
        borderColor: 'text.primary',
      };

    const classes = useStyles(expanded);

    useEffect(() => {
        const timeout = setTimeout(
            actualizarSitio,
            1500)
        return () => {
            clearTimeout(timeout)
        }
    }, [sitioLocal.nombre, sitioLocal.cantidadDeSitios, sitioLocal.personal, sitioLocal.rangoDePersonal]);
    
    useEffect(() => {
      setChildExpanded(expanded)
    }, [expanded])
    
    return (
        <Box className='sitio-accordion' 
        sx={{ ...commonStyles, border: 1, borderRadius:"5px" }}
        >
        <Accordion 
            className={expanded ? classes.AccordionExpanded: classes.Accordion} 
            onChange={handleChange()}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Box className="sitio-resumen-nombre" gap={1}>
                    <TituloCustom 
                    title={capitalizeFirstLetter(sitio.nombre)}
                    variant={!expanded ? "":"h6"}
                    />
                    {
                        !expanded &&
                        <Box alignSelf="center" style={{color:"GrayText"}}> ({sitio.cantidadDeSitios})</Box> 
                    }
                </Box>
            {
                !expanded ?
                <Box className="sitio-resumen-normas-icon">
                <Tooltip title={ToolTipNormas()} placement='right'>
                        <ChatIcon/>
                </Tooltip>
                </Box>:<Box className="sitio-resumen-normas-icon"></Box>
            }
            <Box className="sitio-resumen-labels">
            {
                !expanded ? 
                <Box className="sitio-resumen-personal" >
                <LabelCustom  label="Personas" value={sitio.cantidadDeSitios===1? sitio.personal : sitio.rangoDePersonal.descripcion}></LabelCustom>
                </Box>:<Box className="sitio-resumen-personal" ></Box>
            }
            {
                renderDias("Días Certificación", sitio.diasCertificacion)
            }
            {
                renderDias("Días Recertificación", sitio.diasRecertificacion)
            }
            {
                renderDias("Días Mantenimiento", sitio.diasMantenimiento)
            }
            </Box>
            
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" gap={2}>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        label="Sitio" 
                        disabled={inputEditableEnviada(true)}
                        error={errorNombre}
                        helperText={errorNombre?"Ya existe un sitio con el mismo nombre en el grupo.":""}
                        value={sitioLocal.nombre} 
                        onChange={handleNombre}></TextField></Restricted>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField 
                        fullWidth 
                        variant="outlined" 
                        label="Cantidad" 
                        disabled={inputEditableEnviada()}
                        error={errorCantidadSitios}
                        helperText={errorCantidadSitios? "La cantidad debe ser 1 o mayor":""}
                        value={sitioLocal.cantidadDeSitios}
                        onChange={handleCantidad}
                        /></Restricted>
                    {
                        renderPersonal()
                    }
                </Box>
                
                <Box display="flex" sx={{paddingTop:"40px", paddingBottom:"10px"}} gap={2} flexWrap="wrap">
                    {
                        sitio.normas &&
                        <>
                            {
                                sitio.normas.map((norma, index) => 
                                <NormaChip 
                                    key={'normachip'+ index} 
                                    norma={norma} 
                                    idGrupo={grupo.id}
                                    idSitio={sitio.id}
                                    noEliminarNormaChip={sitio.normas.length === 1}
                                    setOpenDrawer={() => onClickOpenCalculator(norma)}/>)
                            }
                            {
                                sitio.normas.length < json.normas.length && estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                                <Box display="flex">
                                    <Tooltip title="Agregar Norma">
                                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                                        <Button 
                                            className={classes.ButtonAdd} 
                                            onClick={handleModal}
                                            variant='outlined' 
                                            color="secondary" 
                                            sx={{width:"200px"}}>
                                            <AddIcon/>
                                        </Button>
                                        </Restricted>
                                    </Tooltip>
                                </Box>
                            }
                        </>
                    }
                </Box>
                <div display="flex" style={{paddingTop:"20px"}}>
                    {   sitio.infoIntegracion &&
                        <LabelIntegracionStyled 
                        integracion={sitio.infoIntegracion} 
                        sitio={{...sitio, idGrupo: grupo.id}} 
                        isEdit={true} 
                        isSitio={true}/>
                    }
                </div>
                {
                 isAddIntegracion &&
                <div display="flex">
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                    <Button variant="outlined" color="integracion" onClick={handleAgregarIntegracion} startIcon={<AddLinkIcon/>}>Agregar Integracion</Button>
                    </Restricted>
                </div>
                }
                {
                    estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                    <Box display={"flex"} justifyContent="flex-end" gap={2}>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                        <Button variant="outlined" onClick={handleDuplicarSitio} startIcon={<ContentCopyIcon/>}>DUPLICAR SITIO</Button>
                        <Button variant="outlined" color="error"  onClick={() => setOpenDialog(true)}>ELIMINAR SITIO</Button>
                        </Restricted>
                    </Box>
                }
                
            </AccordionDetails>
        </Accordion>
        <React.Fragment >
            <Drawer
            anchor='right'
            open={openDrawer}
            onClose={closeDrawer}
            >
            <div style={{width:"800px", paddingTop:"100px"}}>
                <Box display="flex" flexDirection="row" sx={{backgroundColor:"#f5f5f5", border:"1px solid black", borderRadius:"5px"}}>
                    <Typography sx={{width:"520px", marginLeft:"5px"}} variant="h6">Totales</Typography>
                        {totalDiasAuditor()}
                </Box>
                {
                    sitio.normas.map((norma, index) =>
                    <div style={{paddingTop:"20px"}} key={"tabdiasauditorsitio"+ index} >
                        <AccordionCalculoDiasAuditor
                        className="accordion" 
                        idCotizacion={json.id}
                        idGrupo={grupo.id}
                        idSitio={sitio.id}
                        norma={{...norma, grupoTipo: grupo.tipo}}
                        expanded={norma.id === idNormaEdicion}
                        />
                    </div>
                    )
                }
            </div>
            </Drawer>
        </React.Fragment>
        <ModalNorma
            onClose={() => handleModal()}
            isOpened={modalState.opened}
            sitio={sitio}
            idGrupo={grupo.id}
        />
         <CustomDialog
            openDialog={openDialog} 
            handleCloseDialog={handleCloseDialog} 
            title={"¿Confirma que desea eliminar el sitio "+sitio.nombre+" ?"}
            content={"Se eliminarán los datos y modificaciones que haya hecho."}
            handleAgree={() => handleEliminarSitio(sitio.id)}
            onlyAgree={true}
                />
        </Box>
    )
}

export default AccordionSitio