import { deleteDataAsync, getDataAsync, postDataAsync, putDataAsync} from 'services/httpRequest';


export const postCotizaciones = async(data) => {
    const response = await postDataAsync('Cotizaciones', data);
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const putCotizacionesSitio = async (data, params) => {
    
    const response = await putDataAsync('Cotizaciones/0/sitios/0/normas', data, {Headers:{"Content-Type": "application/json"}});
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const updateCotizacionesSitio = async (idCotizacion, idGrupo, idSitio, data) => {
    
    //const response = await putDataAsync('Cotizaciones/0/sitios/0/normas', data, {Headers:{"Content-Type": "application/json"}});
    const response = await putDataAsync('Cotizaciones/'+idCotizacion+'/GruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/normas', data, {Headers:{"Content-Type": "application/json"}});
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const getCotizacionById = async(id) => {
    const response = await getDataAsync('Cotizaciones/' + id);
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const getPorcentajeIntegracion = async(coeficienteIRAM, coeficienteOrg) =>{
    const response = await getDataAsync('Utils/porcentajeReduccionIntegracion?coeficienteIRAM='+ coeficienteIRAM +'&coeficienteOrganizacion='+coeficienteOrg)
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const updateDatosCotizacion = async(data) =>{
    const response = await putDataAsync('Cotizaciones', data, {Headers:{"Content-Type": "application/json"}})
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const updateEstadoCotizacion = async(data) =>{
    const response = await putDataAsync('Cotizaciones/'+data.idCotizacion+'/estado', data)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const _duplicarCotizacion = async(id) => {
    const response = await postDataAsync('Cotizaciones/'+id+'/duplicar')
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

//ENDPOINTS SITIOS
export const postGrupoSitio = async (id, grupo) => {
    const response = await postDataAsync('Cotizaciones/'+id+'/gruposdesitios', grupo)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const postSitio = async (idCotizacion, idGrupoSitios, sitio) => {
    const response = await postDataAsync('Cotizaciones/'+ idCotizacion +'/gruposdesitios/'+ idGrupoSitios +'/sitios', sitio)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const _duplicarSitio = async (idCotizacion, idGrupoSitios, idSitio) => {
    const response = await postDataAsync('Cotizaciones/'+idCotizacion+ '/gruposdesitios/' + idGrupoSitios + '/sitios/' + idSitio)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const getPersonalRango = async (idCotizacion) => {
    const response = await getDataAsync('Normas/rangosDePersonal?idCotizacion='+idCotizacion)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const updateDatosGrupoSitios = async (idCotizacion, grupo) => {
    const response = await putDataAsync('Cotizaciones/'+idCotizacion+'/gruposdesitios',grupo)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const updateDatosSitio = async (idCotizacion, idGrupo, sitio) => {
    const response = await putDataAsync('Cotizaciones/'+idCotizacion+'/gruposdesitios/'+idGrupo+'/sitios', sitio)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const deleteGrupoSitio = async (idCotizacion, idGrupo) => {
    const response = await deleteDataAsync('Cotizaciones/'+idCotizacion+'/gruposdesitios/'+idGrupo)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const deleteSitio = async (idCotizacion, idGrupo, idSitio) => {
    const response = await deleteDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const deleteDatosNormaSitio = async (idCotizacion, idGrupo, idSitio, idNorma) => {
    const response = await deleteDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/normas/'+idNorma)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const addDatosNormaSitio = async (idCotizacion, idGrupo, idSitio, idNorma) => {
    const response = await postDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/normas/',{id:idNorma})
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

//agregar integracion eliminada
export const addDatosIntegracion = async (idCotizacion, idGrupo, idSitio) => {
    const response = await postDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/integracion')
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

//GET OBSERVACINES
export const getObservaciones = async (idCotizacion) => {
    const response = await getDataAsync('Cotizaciones/'+idCotizacion+'/observaciones/')
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}
//update observaciones
export const updateObservaciones = async (idCotizacion,texto) => {
    const response = await putDataAsync('Cotizaciones/'+idCotizacion+'/observaciones/', {cuerpo: texto})
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

//SITIO CONCRETOS
export const _agregarSitioConcreto = async (idCotizacion, idGrupo, idSitio, nombre) => {
    const response = await postDataAsync('Cotizaciones/'+ idCotizacion +'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/sitiosConcretos', {nombre: nombre})
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const _actualizarSitioConcreto = async (idCotizacion, idGrupo, idSitio, sitioConcreto) => {
    const response = await putDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/sitiosConcretos', sitioConcreto)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}

export const _eliminarSitioConcreto = async (idCotizacion, idGrupo, idSitio, idSitioConcreto) => {
    const response = await deleteDataAsync('Cotizaciones/'+idCotizacion+'/gruposDeSitios/'+idGrupo+'/sitios/'+idSitio+'/sitiosConcretos/'+ idSitioConcreto)
    if(response.status === 200 || response.status === 201 || response.status === 204){
        return response.data
    }
    else{
        throw response
    }
}
