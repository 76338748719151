import LoaderIram from 'components/LoaderIram';
import { authUsuario as authNew } from 'redux/slices/globalSlice';
 
import React, { useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';



const AuthHandler = ({children}) => {

    const dispatch = useDispatch();
    const globalSlice = useSelector(state => state.global);

    useEffect(() => {
        dispatch(authNew());
    }, []);


    return (
        globalSlice.loadingUsuario ?
        <div className="auth-loader-wrapper">
            <LoaderIram />
        </div>
        :
        <>
            {children}
        </>
    )
}

export default AuthHandler;