import { useContext, useEffect, useState } from 'react';
import { Box, Button, Fab, Paper } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
 
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setNACEs, setNuevoNace } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import AlimentosNacesInput from './input';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { usePermitirEdicion } from 'hooks/usePermitirEdicion';
import { useSelector } from 'react-redux';

const classes = {
   add: {
      display: 'flex',
      justifyContent: 'center',
      pt: 1,
      pb: 4,
   }
}

const AlimentosNaces = ({guardarCotizacion})  => {
   const globalSlice = useSelector(state => state.global);
   const {state, dispatch} = useContext(CotizacionContext);
   let { inputCodigosNACESUBNACES , json, estadoCotizacion, estadoCarga} = state;
   const [naces, setNaces] = useState([]);
   const [noEliminar, setNoEliminar] = useState(true);

   const [modoEdicion, setModoEdicion] = useState(false);
   const permiteEdicion = usePermitirEdicion(modoEdicion);
   
   const getNaces = () => {
      let iaf = globalSlice.listaCodigosIAF;
      let naces = iaf.filter(iaf => iaf.codigo === 3)[0].nacEs;
      setNaces(naces);
   }

   const getNace = (id) => {
      return naces.filter(n => n.id === id)[0];
   }

   const getSubnace = (id, nace) => {
      return naces.filter(n => n.id === Number(nace))[0].subNaces.filter(s => s.id === id)[0];
   }

   const onChangeNace = (index, id) => {
      let nace = getNace(id);

      let codigos = structuredClone(inputCodigosNACESUBNACES);

      codigos[index].id = id;
      codigos[index].codigo = nace.codigo;
      codigos[index].descripcion = nace.descripcion;

      dispatch(setNACEs(codigos));
   }

   const onChangeSubnace = (index, id, nace) => {
      let subnace = getSubnace(id, nace);

      let naceIndex = inputCodigosNACESUBNACES.findIndex((obj => obj.id === nace));
      let codigos = structuredClone(inputCodigosNACESUBNACES);

      console.log(subnace);
      codigos[naceIndex].subnaces[index] = {
         ...codigos[naceIndex].subnaces[index],
         id: id,
         codigo: subnace.codigo,
         descripcion: subnace.descripcion
      }

      dispatch(setNACEs(codigos));
   }

   const onAdd = () =>{
      dispatch(setNuevoNace());
      setNoEliminar(false)
   }

   const handleEditar = (e) => {
      if (modoEdicion) {
         guardarCotizacion(e)
         setModoEdicion(false)
      }
      else {
         setModoEdicion(true)
      }
   }
   
   useEffect(() => {
      getNaces();
   }, []);
  
   return (
      <Paper className="my_paper" elevation={5} sx={{position:"relative"}}>
         { inputCodigosNACESUBNACES.map((nace, index) =>
               <AlimentosNacesInput
                  key={"nace"+index}
                  data={nace}
                  index={index}
                  many={inputCodigosNACESUBNACES.length > 1}                  
                  naces={naces}
                  onChangeNace={(index, nace) => onChangeNace(index, nace)}
                  onChangeSubnace={(index, subnace, nace) => onChangeSubnace(index, subnace, nace)}
                  noEliminar={noEliminar}
                  permiteEdicion={permiteEdicion}
               />
            )
         }
         {
            !permiteEdicion &&
            <Box sx={classes.add}>
            <Button 
               variant="contained" 
               startIcon={<AddIcon/>} 
               color="primary"  
               onClick={onAdd}
            >
               Agregar NACE
            </Button>
         </Box>
         }
         {(estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
               <Fab
                  color="primary"
                  aria-label="add"
                  sx={{ position: "absolute", right: "-70px", top: "22%" }}
                  onClick={handleEditar}
                  disabled={estadoCarga.guardando}
               >
                  {modoEdicion ?
                     <SaveIcon />
                     :
                     <EditIcon />
                  }
               </Fab>
            </Restricted>
         }
         
      </Paper>  
   )
}

export default AlimentosNaces;
