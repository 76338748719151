
import { parseISO } from 'date-fns'
import { getResumenPropuestasByIdSolicitud, getSolicitudesCep, getSolicitudesFiltros } from 'services/Solicitud'
import { RESET_FILTROS, RESET_STATE, SET_ANIO, SET_CARGANDO_PAGINAS, SET_CEP, SET_CEPS, SET_DIRECCION, SET_ESTADO_SOLICITUD, SET_FILTROS, SET_ID_SOLICITUD, SET_LISTA_COTIZACIONES, SET_LISTA_SOLICITUDES, SET_NUMERO, SET_ORGANIZACION, SET_PAGINAS, SET_PERSONAL, SET_SELECTED_CEP, SET_SOLICITUD, SET_HACCP, SET_TURNOS, SET_CANTIDAD_ESTUDIOS_HACCP, SET_RESUMEN_PROPUESTAS, RESET_RESUMEN_PROPUESTAS} from 'context/solicitud/typesSolicitud/TypesSolicitud'
import { setError } from 'redux/slices/globalSlice'
import { store } from 'redux/store'


export const resetSolicitud = () => ({
    type: RESET_STATE,
    payload: null
})

export const setIdSolicitud = (id) => ({
    type: SET_ID_SOLICITUD,
    payload: id
})

export const setListaCotizaciones = (lista) => ({
    type: SET_LISTA_COTIZACIONES,
    payload: lista
})

export const setSolicitud = (solicitud) => ({
    type: SET_SOLICITUD,
    payload: solicitud
})

export const setPersonalEfectivo = (personal) => ({
    type: SET_PERSONAL,
    payload: personal
})

export const setDirecionEmpresa = (direccion) => ({
    type: SET_DIRECCION,
    payload: direccion
})

export const setCEP = (cep) => ({
    type: SET_CEP,
    payload: cep
})

export const setOrganizacion = (org) => ({
    type: SET_ORGANIZACION,
    payload: org
})

export const setCantidadEstudiosHaccp = (cantidadEstudiosHaccp) => ({
    type: SET_CANTIDAD_ESTUDIOS_HACCP,
    payload: cantidadEstudiosHaccp
})

export const setTurnos = (turnos) => ({
    type: SET_TURNOS,
    payload: turnos
})

export const setNumero = (numero) => ({
    type: SET_NUMERO,
    payload: numero
})

export const setAnio = (anio) => ({
    type: SET_ANIO,
    payload: anio
})

export const setListaSolicitudes = (lista) => ({
    type: SET_LISTA_SOLICITUDES,
    payload: lista
})

export const setFiltros = (filtros) => ({
    type: SET_FILTROS,
    payload: filtros
})

export const setPaginas = (paginas) => ({//espera el array de paginas
    type: SET_PAGINAS,
    payload:paginas
})

export const setLoading = (estado) => ({
    type: SET_CARGANDO_PAGINAS,
    payload: estado
})
export const resetFiltros = () => ({
    type:RESET_FILTROS,
    payload:null
})

export const setEstadoSolicitud = (estado) =>({
    type:SET_ESTADO_SOLICITUD,
    payload: estado
})

export const setCepsDisponibles = (ceps) =>({
    type:SET_CEPS,
    payload: ceps
})

export const setResumenPropuestas = (propuestas) =>({
    type: SET_RESUMEN_PROPUESTAS,
    payload: propuestas
})

export const resetResumenPropuestas = () =>({
    type: RESET_RESUMEN_PROPUESTAS,
    payload: null
})

export const setSelectedCep = (cep) =>({
    type:SET_SELECTED_CEP,
    payload: cep
})

const fechasIguales = (d1, d2) =>{
    if(d1.getFullYear() === d2.getFullYear()){
        if(d1.getMonth() === d2.getMonth()){
            if(d1.getDate() === d2.getDate()){
                return true
            }
            else{
                return false
            }
        }else{return false}
    }
    else{return false}
}
const parseFiltros = (filtros) =>{
    let filtrosAux = {...filtros}

    if(filtrosAux.numeroSolicitud === ''){
        filtrosAux.numeroSolicitud = null
    }
    if(filtrosAux.anio === ''){
        filtrosAux.anio = null
    }
    if(filtrosAux.idUsuario === ''){
        filtrosAux.idUsuario = null
    }
    if(filtrosAux.idOrganizacion === ''){
        filtrosAux.idOrganizacion = null
    }else{
        filtrosAux.idOrganizacion = filtrosAux.idOrganizacion.id
    }
    if(filtrosAux.idNorma === ''){
        filtrosAux.idNorma = null
    }
    if(filtrosAux.idIAF === ''){
        filtrosAux.idIAF = null
    }
    if(filtrosAux.idNACE === ''){
        filtrosAux.idNACE = null
    }
    if(filtrosAux.estado === ''){
        filtrosAux.estado = null
    }
    if(filtros.creacionDesde === ""){
        filtrosAux.creacionDesde = null
    }
    if(filtros.creacionHasta === ""){
        filtrosAux.creacionHasta = null
    }
    else{
        const fechaCreacionDesde = parseISO(filtros.creacionDesde)
        const fechaCreacionHasta = parseISO(filtros.creacionHasta)
        if(fechasIguales(fechaCreacionDesde, new Date()) &&
        fechasIguales(fechaCreacionHasta, new Date())){
            filtrosAux.creacionDesde = null
            filtrosAux.creacionHasta = null
        }else{
        }
    }
    

    return filtrosAux
}
export const buscarPagina = (dispatchSolicitud, filtros) => {
    
    const filtrosParse = parseFiltros(filtros)
    
    dispatchSolicitud(setLoading(true))
    getSolicitudesFiltros(filtrosParse)
    .then((response) => {
        dispatchSolicitud(setPaginas(response))
        dispatchSolicitud(setLoading(false))
    })
    .catch(error => {
        console.error(error)
        dispatchSolicitud(setLoading(false))
    })
}


export const fetchCepsDisponibles = async (dispatchSolicitud) => {
    try {
        const ceps = await getSolicitudesCep();
        dispatchSolicitud(setCepsDisponibles(ceps));
    } catch (error) {
        store.dispatch(setError("Ocurrio un error obteniendo las solicitudes CEP"));
    }
}

export const fetchPropuestasBySolicitud = async (dispatchSolicitud, idSolicitud, soloAceptadas) => {

    try {
        const propuestas = await getResumenPropuestasByIdSolicitud(idSolicitud, soloAceptadas);
        dispatchSolicitud(setResumenPropuestas(propuestas));
    } catch (error) {
        store.dispatch(setError("Ocurrio un error obteniendo las propuestas de la solicitud"));
    }
}