import { Button, Grid, Modal, Paper, Typography } from '@mui/material'
import React from 'react'

function ModalMensaje({isOpened, onClose, mensaje}) {

    const handleClose = () => {
        onClose()
    }
  return (
    <Modal open={isOpened} 
       sx={{display:"flex", direction:"column", alignItems: 'center',
            justifyContent: 'center',}} >
           <Paper>
           <Grid container
             direction="column"
             justifyContent="center"
             alignItems="center" 
             spacing={5}>
                    
                    <Grid item xs>
                        <Typography>{mensaje}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Button onClick={handleClose} variant="outlined">ACEPTAR</Button>
                    </Grid>
                </Grid>
           </Paper>
       </Modal>
  )
}

export default ModalMensaje