import { useContext } from "react";
import Tooltip from '@mui/material/Tooltip';
import { format, parseISO } from "date-fns";
import { Box, Typography } from "@mui/material";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CotizacionContext } from "context/cotizacion/cotizacionContext";

export default function UltimaModificacion({sx}) {

    
   const { state } = useContext(CotizacionContext);
   const { json: cotizacion } = state;
   let fechaModificacion;

   if(cotizacion && cotizacion.modificadoPor){
       fechaModificacion = format(parseISO(cotizacion.fechaModificacion),"dd/MM/yyyy");
   }

   return (
        <Tooltip title="Última modificación">
            <Box className="defaultShadow" display={"flex"} flexDirection={"row"} sx={{...sx,backgroundColor: "white", padding: "8px", paddingLeft:"20px", borderRadius:"30px"}}>
                {/* edit icon */}
                <ModeEditIcon color="primary"/>
                <Typography sx={{marginRight:"10px", marginLeft: "10px"}}>{cotizacion.modificadoPor}{" "}{fechaModificacion}</Typography>
            </Box>
        </Tooltip>
   );
}