import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TIPO_GRUPO_SITIO } from 'constants/constants';
import React, { useState } from 'react'

//style
import "scss/components/accordionGrupoVisita.scss";
import VisitaResumenBox from 'components/VisitaResumenBox';
import AccordionVisitaSitio from 'components/AccordionSitioVisita';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import SitioUnico from 'components/SitioUnico';
import { capitalizeFirstLetter } from 'utlis/stringUtils';

const useStyles = makeStyles(theme => ({
  Accordion: {
    padding: 0,
    boxShadow: `0px 0px 0px 0px`,
    backgroundColor:`#e4e4e4`,
  },
  AccordionExpanded:{
      padding: 0,
      boxShadow: `0px 0px 0px 0px`,
  },
}));


function AccordionGrupoVisita({grupo}) {
  const esSitioUnico = () => {
    if(grupo.sitios.length === 1 ){
      if(grupo.sitios[0].cantidadDeSitios === 1){
        return true
      }
      else{
        return false
      }
    }  
  }

  const renderTipo = () => {
  if(grupo.tipo !== TIPO_GRUPO_SITIO.CASA_CENTRAL){
      return( <Box className="grupo-visita-tipo">
              <Typography variant='h7' color={"GrayText"}>{grupo.tipo}</Typography>
              </Box>)
  }else{
      return (<Box className="grupo-visita-tipo"></Box>)
  }
}

//EXPANDED ACCORDION
const [expanded, setIsExpanded] = useState(false)
const handleChange = () => (event, isExpanded) => {
  setIsExpanded(isExpanded)
};

//style
const classes = useStyles();

return (
  <ScrollSync>
  <div className='grupo-visita-accordion'>
    {!esSitioUnico() ?
    <Accordion  onChange={handleChange()}  className={expanded ? classes.AccordionExpanded: classes.Accordion} >
    <AccordionSummary expandIcon={<ExpandMoreIcon/>} >
      <Box className="grupo-visita-nombre">
        <Typography variant="h6" fontWeight={"fontWeightBold"} >{capitalizeFirstLetter(grupo.nombre)}</Typography>
      </Box>
      {
        renderTipo()
      }
      <ScrollSyncPane>
      <Box className="grupo-visita-resumen-boxes" gap={1}>
        
        {
          grupo.grupoInstancias.map((instancia, index) => 
            <VisitaResumenBox key={"box"+index} instancia={instancia}/>
          )
        }
      </Box>
      </ScrollSyncPane>
    </AccordionSummary>
    <AccordionDetails>
        {
          grupo.sitios &&
          <Box sx={{paddingTop:"10px", paddingBottom:"10px"}}>
          {
              grupo.sitios.map(  (sitio, index) =>
                <AccordionVisitaSitio 
                key={'indexsitiovisita'+index} 
                sitio={sitio} 
                grupo={grupo}
                />
              )
          }
          </Box>
        }
    </AccordionDetails>
  </Accordion>
  :
  <SitioUnico grupo={grupo}/>
    }
  
  </div>
  </ScrollSync>
)
}

export default AccordionGrupoVisita