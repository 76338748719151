export function capitalizeFirstLetter(str, all) {
  if(all){
    return str.toUpperCase()
  }
  else{
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
    
  }

export function dateToString(date) {
  var fecha = new Date(date)
 
  return [
    padTo2Digits(fecha.getDate()),
    padTo2Digits(fecha.getMonth() + 1),
    fecha.getFullYear(),
  ].join('/');;
}

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}