import React, { useContext, useEffect } from 'react'
import PaperListaCotizacion from 'components/papercotizacionesList';
import Page from 'layouts/Page';
import DetailSolicitud from 'components/DetailSolicitud';
import { RUTAS } from 'constants/routes';
import { BrowserRouter as Router, Route, Switch, useParams } from 'react-router-dom';
import CotizacionGestion from './CotizacionGestion';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import { getSolicitudById } from 'services/Solicitud';
import { setCEP, setSolicitud } from 'context/solicitud/actionsSolicitud/ActionsSolicitud';
import NuevaCotizacionBasePage from './common/NuevaCotizacionBasePage';
import Documento from './Documento';
import CotizacionBasePage from './common/CotizacionBasePage';


const Solicitud = () => {

    const { idSolicitud } = useParams();
    const { stateSolicitud, dispatchSolicitud} = useContext(SolicitudContext);

    useEffect(() => {
        if (idSolicitud && stateSolicitud.idSolicitud !== idSolicitud) {
            getSolicitudById(idSolicitud)
                .then(response => {
                    dispatchSolicitud(setSolicitud({ ...response, estado: response.estado.estado }));
                    // dispatchSolicitud(setCEP(response.cep))
                })
                .catch(error => {
                    console.error("ERROR GET SOLICITUD BY ID", error)
                    // globalDispatch(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
                })
        }
    }, [idSolicitud]);

    return (
        <>
            <Page className='home'>
                <DetailSolicitud />
                    <Switch>
                    <Route exact path={RUTAS.SOLICITUD} component={PaperListaCotizacion}/>
                    <Route path={RUTAS.NUEVACOTIZACION} component={NuevaCotizacionBasePage}/>
                    <Route exact path={RUTAS.DOCUMENTO} component={Documento}/>
                    <Route path={RUTAS.PROPUESTAECONOMICA} component={CotizacionBasePage}/>
                    <Route path={[RUTAS.COTIZACION]} component={CotizacionBasePage}/>
                    </Switch> 
            </Page>
        </>
    )
}

export default Solicitud;
