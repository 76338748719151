import { Box, FormControl, MenuItem, OutlinedInput, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material'
import { TIPO_GRUPO_SITIO } from 'constants/constants'
import React from 'react'

function ResumenSolicitud({solicitud, cotizacion}) {

  return (
    <Box display="flex" flexDirection="column" sx={{paddingTop:"30px", paddingBottom:"30px"}}>
      <Box display="flex" sx={{width:"100%"}} gap={2} justifyContent="space-between" className="titleSolicitud">
        <Typography>Solicitud de Cotización</Typography>
        <TextField label="Número" value={solicitud.numero}></TextField>
        <TextField label="Año" value={solicitud.anio}></TextField>
        <TextField label="Elaborado" value={solicitud.elaborado}></TextField>
        <FormControl sx={{width:"30%"}}>
          <Select
              labelId='label-estado'
              label='Estado'
              value={1}
              disabled
              input={
                  <OutlinedInput
                      variant="status"
                  />
              }>
                <MenuItem disabled value={1}>{solicitud.estado}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box display="flex" sx={{width:"100%", paddingTop:"30px"}} justifyContent="center">
        <TableContainer>
          <Table>
            <TableHead></TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography sx={{fontWeight:"bold"}}>Organización:</Typography>
                  {solicitud.organizaciones.map((o => o.nombre)).join(',')}
                </TableCell>
                <TableCell><Typography sx={{fontWeight:"bold"}}>Personal efectivo:</Typography>{solicitud.personalEfectivo}</TableCell>
              </TableRow>
              <TableRow>
                { cotizacion.gruposDeSitios &&
                  <>
                    <TableCell><Typography sx={{fontWeight:"bold"}}>Sitios Propios:</Typography>{cotizacion.gruposDeSitios.filter(g => g.tipo === TIPO_GRUPO_SITIO.PROPIOS || g.tipo === TIPO_GRUPO_SITIO.CASA_CENTRAL).length}</TableCell>
                    <TableCell><Typography sx={{fontWeight:"bold"}}>Sitios Temporales:</Typography>{cotizacion.gruposDeSitios.filter(g => g.tipo === TIPO_GRUPO_SITIO.TEMPORALES).length}</TableCell>
                  </>
                }
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography sx={{fontWeight:"bold"}}>IAF:</Typography>
                  {
                    cotizacion.nacEs && cotizacion.nacEs.map((item, index) =>
                    <Typography sx={{paddingBottom:"10px"}}>{item.iaf.codigo + " - " + item.iaf.descripcion}</Typography>
                    )
                  }
                </TableCell>
                <TableCell>
                  <Typography sx={{fontWeight:"bold"}}>NACE:</Typography>
                  {
                    cotizacion.nacEs && cotizacion.nacEs.map((item, index) =>
                    <Typography sx={{paddingBottom:"10px"}}>{item.codigo + " - " + item.descripcion}</Typography>
                    )
                  }
                </TableCell>      
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
  </Box>
  )
}

export default ResumenSolicitud