import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import AddIcon from '@mui/icons-material/Add';
import LabelCustom from 'components/labelCustom';
import AccordionGrupoSitios from 'components/AccordionGrupoSitios';
import ModalGrupoSitio from 'components/modalGrupoSitio';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setListaRangoPersonal } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { getPersonalRango } from 'services/Cotizacion';
import { PERMISOS, TIPO_ESTADO, TIPO_GRUPO_SITIO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';

const TabDiasAuditorSitios = () => {
    //contexto cotizacion
    const {state, dispatch} = useContext(CotizacionContext);
    const {json, estadoCotizacion} = state
    const {tipo} = json

    const gruposDeSitios = json.gruposDeSitios.filter(g => g.tipo !== TIPO_GRUPO_SITIO.GENERAL);

    useEffect(() => {
        getPersonalRango(json.id)
        .then(response => {
            dispatch(setListaRangoPersonal(response))
        })
        .catch(error => console.error('error', error))
    },[])
    
    
    //Modal
    const [modalState, setModalState] = useState({opened: false});
    const handleModal = (param) => setModalState({opened: !modalState.opened, withSitio:param})

    return (
        <>
            {
                gruposDeSitios &&
                <>
                    {
                        gruposDeSitios.map((grupo, index) => <AccordionGrupoSitios key={'index'+index} grupo={grupo}/>)
                    }
                </>
            }
            {
                estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                <Box display="flex" justifyContent="space-between" sx={{paddingTop:"20px"}}>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                        {
                            tipo !== "ALIMENTOS"  &&
                            <Button startIcon={<AddIcon/>} variant='contained' onClick={() => handleModal(false)}>GRUPO DE SITIOS</Button>
                        }
                    <Button startIcon={<AddIcon/>} variant='outlined' onClick={() => handleModal(true)}>SITIO</Button>
                    </Restricted>
                </Box>
            }
        <ModalGrupoSitio 
            onClose={() => handleModal()}
            isOpened={modalState.opened}
            withSitio={modalState.withSitio}
            />
        </>
    )
}

export default TabDiasAuditorSitios