import { Box } from "@mui/system";
import { useContext } from "react";
import { TIPO_ESTADO } from "constants/constants";
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { setNombreCotizacion } from "context/cotizacion/actionsCotizacion/ActionsCotizacion";

function DatosCotizacionFormacion() {

    const { state, dispatch } = useContext(CotizacionContext);
    const { json: cotizacion } = state;
    const { estadoCotizacion } = state;

    const getColorEstadoCotizacion = (estado) => {
        switch (estado) {
            case TIPO_ESTADO.ACEPTADA: {
                return 'success';
            }
            case TIPO_ESTADO.ENVIADA: {
                return 'warning';
            }
            case TIPO_ESTADO.DESCARTADA: {
                return 'error';
            }
            case TIPO_ESTADO.RECHAZADA: {
                return 'error';
            }
            default: {
                return;
            }
        }
    }

    function onChangeNombre(event){
        dispatch(setNombreCotizacion(event.target.value));
    }

    return (
        <Paper className="my_paper" elevation={5} sx={{ position: "relative", padding: "2vw" }}>
            <Typography variant="h6">Datos Cotización</Typography>
            <Box
                sx={{marginTop: "2vh"}}
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent={"space-between"}>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <TextField
                        style={{ marginRight: "25px", width: "25vw" }}
                        label="Cotización"
                        type="text"
                        onChange={onChangeNombre}
                        value={cotizacion.nombre}
                        required />
                    <TextField
                        label="Código"
                        disabled
                        inputProps={{ readOnly: true }}
                        value={cotizacion?.codigo}
                    />
                    {cotizacion?.tipoCurso?.nombre && 
                    <FormControl sx={{ marginLeft: "2%" }}>

                        <InputLabel id='label-tipo'>Tipo</InputLabel>
                        <Select
                            value={cotizacion?.tipoCurso?.nombre}
                            labelId='label-tipo-curso'
                            label="Tipo"
                            name="tipo"
                            style={{ width: "10vw" }}
                        >
                            <MenuItem value={"INCOMPANY"}>Incompany</MenuItem>
                            <MenuItem value={"ABIERTO"}>Abierto</MenuItem>
                        </Select>

                    </FormControl>}
                </Box>
                <Button
                    variant="outlined"
                    color={getColorEstadoCotizacion(estadoCotizacion.estado)}
                    startIcon={<FiberManualRecordIcon />}>
                    {estadoCotizacion.estado}
                </Button>
            </Box>
        </Paper>
    );
}

export default DatosCotizacionFormacion;