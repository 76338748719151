export const classes = {
    delete: {
        position: 'absolute',
        top: '18px',
        right: 0
    },
    item: {
        flex: '0 0 auto',
        width: '50%',
        maxWidth: '100%',
        pr: '10px',
        pl: '10px',
        pt: '20px',
        '&:first-child': {
            pt: '0'
        },
        '&:nth-child(2)': {
            pt: '0'
        }
    },
    subnaces: {
        display: 'flex',
        flexWrap: 'wrap',
        mr: '-10px',
        ml: '-10px',
    },
    subnacesDelete: {
        position: 'relative',
        pr: '60px'
    }
}