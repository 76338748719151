export const parseNormas = (normasResponse) =>{

    const normas = normasResponse.map(
        function(norma){
            
            let fechaVencimiento 
            let idEnte = 0
            let idAux = 0
            let clusterAux = null
            if(norma.fechaVencimientoCertificado){
                
                const fechaSplit = norma.fechaVencimientoCertificado.split('T')[0]
                fechaVencimiento = fechaSplit
            }
            else{
                fechaVencimiento = ''
            }

            
            if(norma.ente)
            {
                idEnte = norma.ente.id
            }

            
            if(norma.id){
                idAux = norma.id
            }
            if(norma.cluster){
                clusterAux = norma.cluster
            }
            
            return {
                id:idAux,
                norma: norma.norma,
                motivo: norma.motivo,
                integrada: norma.integrada,
                numero: norma.numeroMantenimiento,
                cantidadMantenimientos: norma.cantidadMantenimientos,
                ampliacion: norma.esAmpliacionDeAlcance,
                cambioVersion: norma.esCambioDeVersion,
                reduccion: norma.esReduccionDeAlcance,
                transferencia: norma.esTransferencia,
                vencimiento: fechaVencimiento,
                ente: idEnte,
                cluster: clusterAux,
                consumoEnergiaAnual: norma.consumoEnergiaAnual,
                fuentesDeEnergia:norma.fuentesDeEnergia,
                usosDeEnergia: norma.usosDeEnergia
            }
        } 
    )
    return normas
}

