import React from 'react';
import { Box } from '@mui/system';
import {
    FormControl, 
    InputAdornment, 
    MenuItem, 
    Select, 
    TextField
} from '@mui/material';
import 'scss/components/tablaPropuestaEconomica.scss'



const InputProducto = ({productos, productoSelected, onChange}) => {


    const onChangeProducto = (event) => {
        const nuevoProducto = productos.find(p => p.id === event.target.value);
        onChange(nuevoProducto);
    }

    const onChangeValorCustom = (event) => {
        const nuevoValor = event.target.value;
        onChange({...productoSelected, valor: nuevoValor});
    }

    const esValorCustom = (producto) => {
        return !productos.some(p => p.valor === producto.valor);
    }


    return (

        <>
        <Box className="producto-valor">
            <TextField
                disabled={!productoSelected?.editable}
                value={productoSelected?.valor}
                required
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                onChange={onChangeValorCustom} />
        </Box>
        <Box className="producto-periodo">
            <FormControl sx={{ width: "100%" }}>
                <Select
                    value={esValorCustom(productoSelected) ? null: productoSelected?.id}
                    onChange={onChangeProducto}
                    renderValue={() => ""}
                >
                    {
                        productos.map(p => (<MenuItem key={'i'} value={p.id}>{p.periodo.toUpperCase()}</MenuItem>))
                    }
                </Select>
            </FormControl>
        </Box>
        </>
    )
}

export default InputProducto;