import React from 'react'
import { Button, Grid, Modal, Paper, Typography } from '@mui/material'
import ErrorIcon from '@mui/icons-material/Error';
import { useHistory } from 'react-router';
import { RUTAS } from 'constants/routes';
import { useDispatch, useSelector } from 'react-redux';
import { resetError } from 'redux/slices/globalSlice';


const ModalError = ({isOpened}) => {

    const dispatchRedux = useDispatch()
    const globalSlice = useSelector(state => state.global)
    const {error} = globalSlice
    
    const history = useHistory()
    const handleClickVolver = () =>{
        history.push(RUTAS.HOME)
        dispatchRedux(resetError())
    }
    return (
       <Modal open={isOpened} 
       sx={{display:"flex", direction:"column", alignItems: 'center',
            justifyContent: 'center',}} >
           <Paper>
           <Grid container
             direction="column"
             justifyContent="center"
             alignItems="center" 
             spacing={5}>
                    <Grid item xs>
                        <ErrorIcon color="error"></ErrorIcon>
                    </Grid>
                    <Grid item xs>
                        <Typography>Ocurrio un error</Typography>
                    </Grid>
                    <Grid item xs>
                        <Typography>Detalle: {error.descripcion}</Typography>
                    </Grid>
                    <Grid item xs>
                        <Button onClick={handleClickVolver} variant="outlined">VOLVER AL INICIO</Button>
                    </Grid>
                </Grid>
           </Paper>
       </Modal>
    )
}

export default ModalError
