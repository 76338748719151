import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Page from 'layouts/Page';
import { Button, Grid, Box } from '@mui/material';
import { Paper, TextField } from '@mui/material';
import { postSolicitud } from 'services/Solicitud';
import InputSolicitud from 'components/inputSolicitud';
import { RUTAS } from 'constants/routes';
import { TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';

//Context
import { SolicitudContext } from 'context/solicitud/solicitudContext'
import { resetSolicitud, setAnio, setEstadoSolicitud, setIdSolicitud, setListaCotizaciones, setNumero, setOrganizacion, setPersonalEfectivo, setCantidadEstudiosHaccp, setSelectedCep } from 'context/solicitud/actionsSolicitud/ActionsSolicitud';

import InputOrganizaciones from 'components/InputOrganizaciones/solicitud';
import { setError } from 'redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';
import { ModalEditarOrganizacion } from 'components/ModalEditarOrganizacion/ModalEditarOrganizacion';
import { ModalNuevaOrganizacion } from 'components/ModalNuevaOrganizacion/ModalNuevaOrganizacion';
import { OrganizacionCard } from 'components/OrganizacionCard/OrganizacionCard';

const classes = {
   button: {
      pb: 3
   }
}

const NuevaSolicitudAlimentos = () => {

   const [organizacionesSelected, setOrganizacionesSelected] = useState([])
   
   //global context
   const dispatchRedux = useDispatch()
   const globalSlice = useSelector(state => state.global)
   const { usuario } = globalSlice
   const nombreUsuario = usuario.user_claims.find(u => u.typ === 'name').val

   //Navegacion
   const history = useHistory();
   const navigateCotizaciones = (idSolicitud) => {
      history.push(RUTAS.SOLICITUD.replace(":idSolicitud", idSolicitud));
   }


   //Context 
   const { stateSolicitud, dispatchSolicitud } = useContext(SolicitudContext);
   const { personalEfectivo, organizaciones, cantidadEstudiosHaccp, turnos } = stateSolicitud;

   const [saving, setSaving] = useState(false)

   const [errorPersonal, setErrorPerosnal] = useState(false)

   const [showModalNuevaOrg, setShowModalNuevaOrg] = useState(false);
   const [showModalEditarOrg, setShowModalEditarOrg] = useState(false);
   const [idEmpresaEdit, setIdEmpresaEdit] = useState("");


   useEffect(() => {
      dispatchSolicitud(resetSolicitud())
   }, [])

   useEffect(() => {
      if (personalEfectivo > 10700) {
         setErrorPerosnal(true)
      }
      else {
         setErrorPerosnal(false)
      }

   }, [personalEfectivo])

   const handleSetPersonal = (e) => {
      dispatchSolicitud(setPersonalEfectivo(e.target.value))
   }

   const handleSetHaccp = (e) => {
      dispatchSolicitud(setCantidadEstudiosHaccp(e.target.value))
   }

   const handleSetOrganizacion = (organizacionesValue) => {
      dispatchSolicitud(setOrganizacion(organizacionesValue))
   }

   useEffect(() => {
      if (stateSolicitud.selectedCep !== null) {
         dispatchSolicitud(setPersonalEfectivo(stateSolicitud.selectedCep.cantidadPersonal))
         dispatchSolicitud(setOrganizacion(stateSolicitud.selectedCep.organizaciones))
      }
   }, [stateSolicitud.selectedCep]);

   //SUBMIT
   const handleSubmit = (e) => {
      e.preventDefault();
      setSaving(true)
      const data = {
         usuario: nombreUsuario,
         organizaciones: organizaciones,
         personalEfectivo: parseInt(personalEfectivo),
         cantidadEstudiosHaccp: parseInt(cantidadEstudiosHaccp),
         turnos,
         tipo: TIPO_SOLICITUD_ALIMENTOS,
         idCep: stateSolicitud.selectedCep?.id
      };

      postSolicitud(data)
         .then(response => {
            setSaving(false)
            dispatchSolicitud(setIdSolicitud(response.id))

            dispatchSolicitud(setNumero(response.numero))

            dispatchSolicitud(setAnio(response.anio))

            dispatchSolicitud(setEstadoSolicitud(response.estado.estado))

            dispatchSolicitud(setListaCotizaciones(response.cotizaciones))

            dispatchSolicitud(setSelectedCep(null));

            navigateCotizaciones(response.id);
         })
         .catch(error => {
            dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
            console.error("ERROR POST SOLICITUD, body: " + data + "ERROR:" + error)
         })
   }

   const onClickEditarOrg = (id) => {
      setIdEmpresaEdit(id)
      setShowModalEditarOrg(true)
   }

   const onNewEmpresa = (empresa) => {
      empresa.nombre = empresa.razonSocialAFIP;
      setOrganizacionesSelected([...organizacionesSelected, empresa])
      dispatchSolicitud(setOrganizacion([...organizacionesSelected, empresa]))
   }

   return (
      <Page className="home">
         <Paper className="my_paper" elevation={10}>
            <form onSubmit={handleSubmit} >
               <Grid container direction="row" alignItems="center" spacing={3}>
                  <InputSolicitud />
                  <Grid item xs={6}>
                     <InputOrganizaciones
                        required={true}
                        multiple={true}
                        onOrganizacionesChange={setOrganizacionesSelected}
                        handleOrganizacion={handleSetOrganizacion} />
                  </Grid>
                  <Grid item xs={3}>
                     <TextField
                        variant="outlined"
                        fullWidth
                        type="number"
                        label="Personal Efectivo"
                        error={errorPersonal}
                        helperText={errorPersonal ? "El personal no puede superar los 10700" : null}
                        value={personalEfectivo}
                        required
                        onChange={handleSetPersonal}>
                     </TextField>
                  </Grid>
                  {
                     organizacionesSelected &&
                     organizacionesSelected.length > 0 &&
                     <Grid container spacing={2}>
                        {organizacionesSelected.map((org, index) => (
                           <Grid item xs={12} sm={6} md={4} key={index}>
                              <OrganizacionCard id={org.id} nombre={org.nombre} cuit={org.cuit} onClickEdit={onClickEditarOrg} />
                           </Grid>
                        ))}
                     </Grid>
                  }
                  {/* { cantidadEstudiosHaccp &&  */}
                  {/* <Grid item xs={3}>
                        <TextField 
                           variant="outlined" 
                           fullWidth 
                           type="number" 
                           label="Cantidad de Estudios HACCP" 
                           value={cantidadEstudiosHaccp} 
                           required
                           onChange={handleSetHaccp}>
                        </TextField>
                     </Grid> */}
                  {/* } */}
               </Grid>
               <Box display="flex" justifyContent="space-between" sx={{ paddingBottom: "20px" }}>
                  <Button variant="contained" color="primary" onClick={() => setShowModalNuevaOrg(true)} >Nueva Organización</Button>
                  <Button variant="contained" color="primary" type="submit" disabled={saving}>SIGUIENTE</Button>
               </Box>
            </form>
         </Paper>
         <ModalNuevaOrganizacion onNewEmpresa={onNewEmpresa} isOpened={showModalNuevaOrg} onClose={() => setShowModalNuevaOrg(false)} />
         <ModalEditarOrganizacion id={idEmpresaEdit} isOpened={showModalEditarOrg} onClose={() => setShowModalEditarOrg(false)} />

      </Page>
   )
}

export default NuevaSolicitudAlimentos;
