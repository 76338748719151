import { useContext, useEffect, useState } from 'react';
import { Button, Fab, FormControlLabel, FormGroup, Grid, Paper, TextField, Switch, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SaveIcon from '@mui/icons-material/Save';

import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
 
import { SolicitudContext } from 'context/solicitud/solicitudContext';

import { setDatosCotizacion, setMultisitios, updateCotizacion, updateCotizacionAlimentos } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';

import ModalFechaEnviada from '../../ModalFechaEnviada';
import ModalFechaAceptada from '../../ModalFechaAceptada';
import ModalMotivoRechazo from 'components/ModalMotivoRechazo/ModalMotivoRechazo';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';

import { usePermitirEdicion } from 'hooks/usePermitirEdicion';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import { classes, getColorEstadoCotizacion } from './styles'
import { useDispatch, useSelector } from 'react-redux';

const AlimentosDatosCotizacion = ({guardarCotizacion}) => {
   const dispatchRedux = useDispatch()
   const globalSlice = useSelector(state => state.global)
   const { stateSolicitud } = useContext(SolicitudContext);
   const { state, dispatch } = useContext(CotizacionContext);

   const {listaNormas} = globalSlice

   const { idSolicitud, listaCotizaciones } = stateSolicitud
   const { inputCodigosIAFNACE, inputDatosCotizacion, json, multisitios, inputIntegracion, inputNormasMotivos, estadoCotizacion, estadoCarga, cantidadEstudiosHaccp } = state

   const [modoEdicion, setModoEdicion] = useState(false)
   const [modalState, setModalState] = useState({ opened: false });
   const [modalFechaEnviada, setModalFechaEnviada] = useState({ opened: false });
   const [modalFechaAceptada, setModalFechaAceptada] = useState({ opened: false });
   const permitirEdicion = usePermitirEdicion(modoEdicion)

   const nombreRepetido = listaCotizaciones.some(c => c.nombre.toUpperCase() === inputDatosCotizacion.nombre.toUpperCase()) && json.id === ''
   
   const textIAF = "3 - Alimentos, Bebidas y Tabaco"
   
   const mantenimientoEditable = () => {
      if (permitirEdicion) {
         return true
      } else {
         if (estadoCotizacion.estado === TIPO_ESTADO.BORRADOR) {
            return false
         } else {
            return true
         }
      }
   }

   const handleNombreCotizacion = (e) => {
      dispatch(setDatosCotizacion({ ...inputDatosCotizacion, nombre: e.target.value }))
   }

   const handleSetHaccp = (e) => {
      dispatch(setDatosCotizacion({ ...inputDatosCotizacion, cantidadEstudiosHaccp: e.target.value }))
   }

   const handleEditar = (e) => {
      if (modoEdicion) {
         guardarCotizacion(e)
         // guardarCambios()
         setModoEdicion(false)
      }
      else {
         setModoEdicion(true)
      }
   }

   const guardarCambios = () => {
      
      const listaNormas = inputNormasMotivos.map(
         function (val) {
            let idAux = 0
            let fechaVencimientoAux = null
            if (val.motivo === "RECERTIFICACION") {
               fechaVencimientoAux = null
            }

            if (val.id) {
               idAux = val.id
            }
            return {
               id: idAux,
               integrada: val.integrada,
               norma: val.norma,
               motivo: val.motivo,
               numeroMantenimiento: val.numero,
               esReduccionDeAlcance: val.reduccion,
               esAmpliacionDeAlcance: val.ampliacion,
               esCambioDeVersion: val.cambioVersion,
               esTransferencia: val.transferencia,
               fechaVencimientoCertificado: fechaVencimientoAux,
               ente: { id: val.ente }
            }
         }
      )

      const data = {
         id: json.id,
         idSolicitud: idSolicitud,
         nombre: inputDatosCotizacion.nombre,
         codigo: inputDatosCotizacion.codigo,
         cantidadMantenimientos: inputDatosCotizacion.seguimientos,
         cantidadEstudiosHaccp: inputDatosCotizacion.cantidadEstudiosHaccp,
         normas: listaNormas,
         NACEs: inputCodigosIAFNACE.map(item => ({ id: item.CodigoNace.id })),
         infoIntegracion: null,
         estado: { estado: 'BORRADOR' },
         usaMultisitios: multisitios
      }
      updateCotizacionAlimentos(dispatch, data);
   }
   
   const handleMultisitio = (e) => {
      dispatch(setMultisitios(e.target.checked))
   }

   const renderInputCantidadEstudios = () => {
      const ids = inputNormasMotivos.map(i => i.norma)
      let normasSelecionadas = listaNormas.filter(l => ids.includes(l.id))
      // if(normasSelecionadas.some(n => n.jerarquiaAlimentos !== 3)){
         return (
            <Grid item xs={4}>
               <TextField 
                  variant="outlined" 
                  fullWidth 
                  type="number" 
                  label="Cantidad de Estudios HACCP"
                  onWheel={event => event.target.blur()}
                  value={inputDatosCotizacion.cantidadEstudiosHaccp??""} 
                  required
                  disabled={permitirEdicion}
                  onChange={handleSetHaccp}>
               </TextField>
            </Grid>
         )
      // }
      // else{
      //    return null
      // }
   }

   const handleModal = () => setModalState({ opened: !modalState.opened })

   const handleModalFechaEnviada = () => setModalFechaEnviada({ opened: !modalFechaEnviada.opened })

   const handleModalFechaAceptada = () => setModalFechaAceptada({ opened: !modalFechaAceptada.opened })

   return (
      <Paper className="my_paper" elevation={5} sx={classes.root}>
         <Grid container
            alignItems="center"
            spacing={1}
            rowSpacing={2}
         >
            <Grid item xs={8}>
               <Typography variant="h6">Datos Cotización</Typography>
            </Grid>
            <Grid item xs={2} sx={classes.multisite}>
               <FormGroup>
                  <FormControlLabel checked={multisitios} disabled={mantenimientoEditable()} control={<Switch />} onChange={handleMultisitio} label="Multisitio" />
               </FormGroup>
            </Grid>
            <Grid item xs={2} sx={classes.state}>
               <Button variant="outlined" color={getColorEstadoCotizacion(estadoCotizacion.estado)} startIcon={<FiberManualRecordIcon />}>
                  {estadoCotizacion.estado}
               </Button>
            </Grid>
         </Grid>
         <Grid container
            alignItems="center"
            spacing={1}
            rowSpacing={2}
         >
            <Grid item xs={3}>
               <TextField
                  label="Cotización"
                  type="text"
                  onChange={handleNombreCotizacion}
                  fullWidth
                  required
                  error={nombreRepetido}
                  helperText={nombreRepetido && "Ya existe una cotizacion con el mismo nombre"}
                  value={inputDatosCotizacion.nombre}
                  disabled={permitirEdicion}
               />
            </Grid>
            <Grid item xs={3}>
               <TextField
                  label="Código"
                  fullWidth
                  disabled
                  inputProps={{ readOnly: true }}
                  value={inputDatosCotizacion.codigo}
               />
            </Grid>
            <Grid item xs={3}>
               <TextField
                  label="IAF"
                  fullWidth
                  disabled
                  inputProps={{ readOnly: true }} 
                  value={textIAF}
                  //value={`${inputDatosCotizacion.iaf?inputDatosCotizacion.iaf.codigo:""} - ${inputDatosCotizacion.iaf?inputDatosCotizacion.iaf.descripcion:""}`}
               />
            </Grid>
         </Grid>
            {/* MOSTRAR CANTIDAD DE ESTUDIOS SOLO SI TENGO UNA NORMA SELECCIONADA QUE NECESITE EL DATO */}
         {
            renderInputCantidadEstudios()
         }
         

         {(estadoCotizacion.permiteEdicion || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) && json.id !== '' &&
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
               <Fab
                  color="primary"
                  aria-label="add"
                  sx={{ position: "absolute", right: "-70px", top: "22%" }}
                  onClick={handleEditar}
                  disabled={estadoCarga.guardando}
               >
                  {modoEdicion ?
                     <SaveIcon />
                     :
                     <EditIcon />
                  }
               </Fab>
            </Restricted>
         }
         <ModalMotivoRechazo
            onClose={() => handleModal()}
            isOpened={modalState.opened}
            estado={estadoCotizacion}
         />
         <ModalFechaEnviada
            onClose={() => handleModalFechaEnviada()}
            isOpened={modalFechaEnviada.opened}
         />
         <ModalFechaAceptada
            onClose={() => handleModalFechaAceptada()}
            isOpened={modalFechaAceptada.opened}
         />
      </Paper>
   )
}

export default AlimentosDatosCotizacion;
