import React, { useContext} from 'react'
import { Typography, Box } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { IconButton } from '@mui/material';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { updateNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { useDispatch } from 'react-redux';

const ModificadoresCalculoDiasAuditor = ({norma, idGrupo, idSitio, onClickEdit, modificador}) => {

    //contextoGlobal
    const dispatchRedux = useDispatch()

    const {state, dispatch} = useContext(CotizacionContext);
    const {estadoCotizacion, json} = state
    
    const handleEditModificador = () => {
      onClickEdit(modificador.id)
    }
    
    function positivo (valor){
        if(valor>0){
            
            return ' + ' + valor * 100+ '%'
        }
        else{
            return'' + valor * 100 + '%'
        }
    }
    const handleDeleteModificador = () => {
          norma.modificadores = norma.modificadores.filter(m => m.id !== modificador.id)
          updateNorma(dispatch, json.id, idGrupo, idSitio, norma)
    }
    
    return (
        <>
            <Box className="integration-detail">
                <Typography>
                       {positivo(modificador.porcentaje) + ' | ' + modificador.motivos.map(m => m.nombre).join(", ")}
                </Typography>
                {
                    estadoCotizacion.permiteEdicion &&
                    <Box className="actions">
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                        <IconButton variant="edit" aria-label="Editar" onClick={handleEditModificador}>
                        <EditIcon />
                        </IconButton>
                        </Restricted>
                        {
                            estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <IconButton  variant="delete" onClick={handleDeleteModificador} aria-label="Eliminar" >
                            <RemoveCircleIcon  />
                            </IconButton>
                            </Restricted>
                        }
                    </Box>
                }
            </Box>
        </>
    )
}

export default ModificadoresCalculoDiasAuditor
