import { useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const AlimentosNacesSubnace = (props) => {
   const { data, index, subnaces, onChange, estado } = props;
   const { id } = data;
   const [ idSelected, setIdSelected] = useState(id);
  
   const handleChange = (event) => {
      const subnace = event.target.value;
      setIdSelected(subnace)
      onChange(index, subnace)
   }

   return (
      <FormControl fullWidth>
         <InputLabel id='label-subnace'>Sub NACE</InputLabel>
         <Select
            labelId="label-subnace"
            label="Sub NACE"
            fullWidth
            disabled={estado}
            name='CodigoSubnace'
            onChange={(event) => handleChange(event)}
            value={idSelected}
         >
            {subnaces.map((subnace, index) => (
               <MenuItem key={'subnace'+index} value={subnace.id}>
                  {` ${subnace.descripcion}`}
               </MenuItem>
            ))}
         </Select>
      </FormControl>
   )
}

export default AlimentosNacesSubnace;
