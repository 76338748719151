export const classes = {
    delete: {
        position: 'absolute',
        top: '18px',
        right: 0
    },
    error: {
        color: 'error.main',
        pl: 7
    },
    inputLabel: {
        backgroundColor: 'background.paper',
        px: 1
    },
    select: {
        display: 'flex'
    },
    turnosDelete: {
        position: 'relative',
        pr: '60px'
    }
}