import { TIPO_SOLICITUD_GESTION } from "constants/constants";
import { getDataAsync } from "services/httpRequest";

export const getListaMotivosModificacion = async () => {
    const response = await getDataAsync('MotivosDeModificacion');
    if (response.status === 200 || response.status === 201) { 
       return response.data;
    } else {
       throw response
    }
 }
export const getListaNormas = async() => {
    const response = await getDataAsync('Normas')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const getListaServicios = async() => {
    const response = await getDataAsync('Servicios')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const getListaNormaMotivo = async(tipo) => {
    if(tipo === undefined || tipo === null) tipo = TIPO_SOLICITUD_GESTION;
    const response = await getDataAsync('Normas/motivos?tipo=' + tipo);
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const getListaMotivosIntegracion = async() => {
    const response = await getDataAsync('MotivosDeIntegracion')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}
export const getListaEntes = async() =>{
    const response = await getDataAsync('Entes')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const getListaEstados = async() => {
    const response = await getDataAsync('Estados/estadosSolicitud')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const getListaUsuarios = async () => {
    const response = await getDataAsync('Usuarios')
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}