import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button} from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import AlimentosFormCotizacion from 'components/alimentos/formCotizacion';
import { useCallback } from 'react';

const NuevaCotizacionAlimentos = () => {
   //context global
   const history = useHistory();

   const { stateSolicitud } = useContext(SolicitudContext);
   
   const navigateToSolicitud = useCallback(() => {
      history.push(`/solicitud/${stateSolicitud.idSolicitud}`);
   },[stateSolicitud, history]);
  
   return (
     <>
         <Box marginTop={"4vh"} sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }} alignItems={"flex-end"} >
                <Button
                    variant='outlined'
                    onClick={navigateToSolicitud}
                    startIcon={<ArrowBackIosNewIcon />} >Volver a cotizaciones</Button>
         </Box>
         <AlimentosFormCotizacion/>
     </>
   )
}

export default NuevaCotizacionAlimentos;
