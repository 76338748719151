import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import ModalPortal from 'components/modalPortal';
import { agregarNormaSitio } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React, {useContext, useState} from 'react';

function ModalNorma({onClose, isOpened, sitio, idGrupo}) {
  const {state, dispatch} = useContext(CotizacionContext)
  const {json} = state

  const [norma, setNorma] = useState('')
  
  const handleNorma = (e) => {
    setNorma(e.target.value)
  }
  const onSave = () => {
    const data = {
      idCotizacion: json.id,
      idGrupo: idGrupo,
      idSitio: sitio.id,
      idNorma: norma
    }
    agregarNormaSitio(dispatch, data)
    onClose()
  }
  const onCloseButton = () => {
    onClose()
  }
  return (
  <ModalPortal
    onCancel={onCloseButton}
    onSave={onSave}
    title="Agregar Norma"
    isOpened={isOpened}
    >
      <Box display="flex" sx={{width:"500px"}} justifyContent="center">
      <FormControl fullWidth>
        <InputLabel id="norma-label-modal">Norma</InputLabel>
        <Select
          label="Norma"
          required
          labelId='norma-label-modal'
          value={norma}
          onChange={handleNorma}
          >
          { json.normas.map((norma, index ) =>
          <MenuItem 
            key={'normachip-modal'+index} 
            value={norma.id}
            disabled={sitio.normas.some(n => n.cotizacionNorma.id === norma.id)}
            >{norma.norma.nombre}</MenuItem>  
            )
          }
          
        </Select>
      </FormControl>
      </Box>
  </ModalPortal>);
}

export default ModalNorma;
