import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { TIPO_GRUPO_SITIO } from 'constants/constants';
import { getUsuario } from 'services/Auth';

// Define tu estado inicial
const initialState = {
    usuario: null,
    error: { estado: false, tipo: '', descripcion: '', statusCode: null },
    loadingUsuario: true,
    //LISTAS
    listaEntes: [{ id: '', nombre: '' }],
    listaNormas: [{ id: '', usaComplejidad: false, nombre: '' }],
    listaNormasMotivos: [],
    listaNormasMotivosProducto: [],
    listaNormasMotivosAlimentos: [],
    listaMotivosModificadores: [],
    listaComplejidad: [{ id: 0, detalle: 'ALTA' }],
    listaMotivosIntegracion: [],
    listaCodigosIAF: [],
    listaCodigosNace: [],
    listaEstadosSolicitud: [],
    listaUsuarios: [],
    listaServicios: [],
    
    listaReduccion: [{ id: 0, value: 0.0, detalle: '0%' },
    { id: 1, value: 0.05, detalle: '5%' },
    { id: 2, value: 0.10, detalle: '10%' },
    { id: 3, value: 0.15, detalle: '15%' },],
    coeficientesIntegracion: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    listaPorcentajeModificadores: [-50, -45, -40, -35, -30, -25, -20, -15, -10, -5, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50],
    listaPorcentajeModificadoresTemporales: [-95, -90, -85, -80, -75, -70, -65, -60, -55, -50, -45, -40, -35, -30, -25, -20, -15, -10, -5, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95],
    rangosPorcentajesFactores: {
        [TIPO_GRUPO_SITIO.PROPIOS]: {
            maximoDescuento: -50,
            maximoAditivo: 50,
            maximoTotalDescuento: -80,
        },
        [TIPO_GRUPO_SITIO.CASA_CENTRAL]: {
            maximoDescuento: -30,
            maximoAditivo: 50,
            maximoTotalDescuento: -50
        },
        [TIPO_GRUPO_SITIO.TEMPORALES]: {
            maximoDescuento: -95,
            maximoAditivo: 95,
            maximoTotalDescuento: -95,
        },
        [TIPO_GRUPO_SITIO.GENERAL]: {
            maximoDescuento: -30,
            maximoAditivo: 95,
            maximoTotalDescuento: -50
        }
    }
};

export const authUsuario = createAsyncThunk('auth/authUsuario', async (_, { dispatch }) => {
    dispatch(setLoadingUsuario(true));
    try {
        const res = await getUsuario();
        if (res.data.length === 0) {
            dispatch(setErrorUsuario({ statusCode: 401, estado: true }));
        } else {
            const usuario = res.data[0];
            dispatch(setUsuario(usuario));
        }
    } catch (err) {
        dispatch(setErrorUsuario({ statusCode: 401, estado: true }));
    }
});


export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setError: (state, action) => {
            state.error = action.payload;
        },
        resetError: (state) => {
            state.error = { estado: false, tipo: '', descripcion: '' };
        },
        setErrorUsuario: (state, action) => {
            state.usuario = null;
            state.error = action.payload;
            state.loadingUsuario = false;
        },
        setUsuario: (state, action) => {
            state.usuario = action.payload;
            state.loadingUsuario = false;
        },
        setLoadingUsuario: (state, action) => {
            state.loadingUsuario = action.payload;
        },
        // Agregar más reducers según sea necesario
        setListaEntes: (state, action) => {
            state.listaEntes = action.payload;
        },
        setListaNormas: (state, action) => {
            state.listaNormas = action.payload;
        },
        setListaNormaMotivo: (state, action) => {
            state.listaNormasMotivos = action.payload;
        },
        setListaNormaMotivoProducto: (state, action) => {
            state.listaNormasMotivosProducto = action.payload;
        },
        setListaNormaMotivoAlimentos: (state, action) => {
            state.listaNormasMotivosAlimentos = action.payload;
        },
        setListaMotivosModificadores: (state, action) => {
            state.listaMotivosModificadores = action.payload;
        },
        setListaComplejidad: (state, action) => {
            state.listaComplejidad = action.payload;
        },
        setListaMotivosIntegracion: (state, action) => {
            state.listaMotivosIntegracion = action.payload;
        },
        setListaCodigosIAF: (state, action) => {
            state.listaCodigosIAF = action.payload;
        },
        setListaCodigosNace: (state, action) => {
            state.listaCodigosNace = action.payload;
        },
        setListaEstadosSolicitud: (state, action) => {
            state.listaEstadosSolicitud = action.payload;
        },
        setListaUsuarios: (state, action) => {
            state.listaUsuarios = action.payload;
        },
        setListaServicios: (state, action) => {
            state.listaServicios = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(authUsuario.pending, (state) => {
                state.loadingUsuario = true;
            })
            .addCase(authUsuario.fulfilled, (state, action) => {
                state.loadingUsuario = false;
                // Puedes actualizar el estado con los datos recibidos si es necesario
                // state.usuario = action.payload;
            })
            .addCase(authUsuario.rejected, (state) => {
                state.loadingUsuario = false;
                state.error = { statusCode: 401, estado: true };
            });
    },
});

// Exportar acciones generadas automáticamente por createSlice
export const {
    setError,
    resetError,
    setErrorUsuario,
    setUsuario,
    setLoadingUsuario,
    setListaEntes,
    setListaNormas,
    setListaNormaMotivo,
    setListaNormaMotivoProducto,
    setListaNormaMotivoAlimentos,
    setListaMotivosModificadores,
    setListaComplejidad,
    setListaMotivosIntegracion,
    setListaCodigosIAF,
    setListaCodigosNace,
    setListaEstadosSolicitud,
    setListaUsuarios,
    setListaServicios,
} = authSlice.actions;

export default authSlice.reducer;