import React, { useContext } from 'react'
import AccordionCalculoDiasAuditor from 'components/accordionCalculoDiasAuditor'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import "scss/components/accordion.scss"
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@mui/system'

const TabDiasAuditor = () => {
 
    const {state} = useContext(CotizacionContext)
    const {json} = state
    let normas = []
    const grupoGeneral = json.gruposDeSitios.find(g => g.tipo === "GENERAL")
    const sitioGeneral = grupoGeneral.sitios[0]

    
    try{
      normas = sitioGeneral.normas
    }
    catch(e){
      console.error('error',e)
    }
    
    //instancias = instancias.sort((a, b) => (a.instancia.numero > b.instancia.numero) ? 1 : -1)

    return (
        <>
        <>
        {
          normas &&
          <>
          {
            normas.map((norma, indexAccordion) =>
                <AccordionCalculoDiasAuditor 
                key={"tabdiasauditor"+ indexAccordion} 
                idCotizacion={json.id}
                idGrupo={grupoGeneral.id}
                idSitio={sitioGeneral.id}
                norma={norma}
                
                />
            )
        }
          </>
        }
        </>
        
        <Box display="flex" gap={2} sx={{marginTop:"15px"}}>
          <Box display="flex" alignItems="center">
            <FiberManualRecordIcon color="reduccion"/>
            Red. planificación y documentación
          </Box>
          <Box display="flex" alignItems="center">
            Otros factores:
            <FiberManualRecordIcon color="factoresAditivo"/>
            Aditivos
            <FiberManualRecordIcon color="factoresSustrativo"/>
            Sustractivos
          </Box>
          <Box display="flex" alignItems="center">
            <FiberManualRecordIcon color="integracion"/>
            Reducción por Integración
          </Box>
        </Box>
        </>
    )
}

export default TabDiasAuditor
