import React, {useState, useContext, useEffect} from 'react'
import {Accordion, AccordionSummary, AccordionDetails, Grid, Box, Button, Chip, Divider, Typography} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ModificadoresCalculoDiasAuditor from 'components/modificadoresCalculoDiasAuditor'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import LabelCustom from 'components/labelCustom'
import ModalFactor from 'components/ModalFactor';
import { setAllAccordionClose } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import AddIcon from '@mui/icons-material/Add';

import "scss/components/accordion.scss"

import DiasAuditorUsuario from 'components/diasAuditorUsuario';
import ModificadoresNorma from 'components/ModificadoresNorma';
// import ModalFactorTest from 'components/test';
import { makeStyles } from '@mui/styles';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
 
const useStyles = makeStyles(theme => ({
    Accordion: {
        padding: 0,
        boxShadow: `0px 0px 0px 0px`,
        border: `1px solid black`,
        borderRadius: `5px`
      },
  }));

const AccordionCalculoDiasAuditor = ({norma, idGrupo, idSitio, expanded: exd}) => {
    
    //Context cotizacion
    const {state, dispatch} = useContext(CotizacionContext);

    const {json, allAccordionClose, estadoCotizacion, estadoCarga} = state;
    
    //Handle accordion
    const [expanded, setExpanded] = useState(exd ? exd : false);
    const handleChange = (panel) => (event, isExpanded) => {
      setExpanded(isExpanded);
      dispatch(setAllAccordionClose(!isExpanded))
    };

    //Handle modal
    const [modalState, setModalState] = useState({opened: false, selected: null});
    const handleModal = (id) => {
        setModalState({opened: !modalState.opened, selected: id})
    }

    
    //efecto minimizar maximizar paneles
    useEffect(() => {
        if(!exd && allAccordionClose){
            setExpanded(false)
        }
        
    }, [allAccordionClose])
    
    const obtenerNombre = () =>{
        if(norma.diasCertificacionUsuario){
            return "Certificación"
        }
        else{
            return "Recertificación"
        }
    }
    const renderPorcentajeReduccion = () =>{
        let labelReduccion = "0 %"
        let labelFactores = "0 %"
        let labelIntegracion = "0 %"
        let colorFactores = "default"
        if(norma.reduccionPlanificacionDocumentacion){
            labelReduccion = "-"+norma.reduccionPlanificacionDocumentacion*100+" %"
        }
        if(norma.porcentajeFactores){
            if(norma.porcentajeFactores > 0)
            {
                colorFactores="factoresAditivo"
                labelFactores = "+" + (norma.porcentajeFactores * 100).toFixed(0) + " %"
            }
            else{
                colorFactores="factoresSustrativo"
                labelFactores =  (norma.porcentajeFactores * 100).toFixed(0) + " %"
            }
        }
        if(json.infoIntegracion){
            labelIntegracion = "-"+json.infoIntegracion.porcentajeReduccion * 100 + " %"
        }
        return(
            <>
            {
                (norma.reduccionPlanificacionDocumentacion || norma.reduccionPlanificacionDocumentacion === 0) &&
                <Chip  className="reduccion_chip" 
                        color="reduccion"
                       label={labelReduccion} 
                />
            }
            {
                norma.porcentajeFactores &&
                <Chip  color={colorFactores}
                label={labelFactores} />
            }
            
            {
                (json.infoIntegracion && norma.integrada) &&
                <Chip color="integracion" 
                label={labelIntegracion} />
            }
        </>)
    }
    const renderResumen = () =>{
        return (
            <Box display="flex" sx={{width:'100%'}} justifyContent="space-between">

                <Box display="flex" className='titulo norma'  gap={2}>
                    <Typography variant="h7" fontWeight="fontWeightBold" alignSelf="center">
                        {norma.cotizacionNorma.norma.nombre}
                    </Typography>
                    <Box display="flex" className="porcentajesReduccion" gap={1} alignSelf="center">
                    {
                        renderPorcentajeReduccion()
                    }
                    </Box>
                </Box>
                
                <Box display="flex" gap={2}>
                    <Box display="flex" className='label-cer-recer' alignSelf="center">
                        {
                            (norma.diasCertificacionUsuario || norma.diasRecertificacionUsuario) &&
                            <LabelCustom label={obtenerNombre()} value={norma.diasCertificacionUsuario?.toFixed(2) || norma.diasRecertificacionUsuario?.toFixed(2)}></LabelCustom>
                        }
                    </Box>
                    <Box display="flex" className="mantenimientoxs" alignSelf="center">
                        {
                            norma.diasMantenimientoUsuario &&
                            <LabelCustom label={"Mantenimientos"} value={norma.diasMantenimientoUsuario?.toFixed(2) + " x" + norma.cantidadMantenimientos}></LabelCustom>
                        }
                    </Box>
                </Box>
                
            </Box>
        )
    }

    const classes = useStyles();

    return (
        <Accordion 
        className={classes.Accordion}
        expanded={expanded} 
        sx={{marginTop:"10px"}}
        onChange={handleChange()}
        >        
        
            <AccordionSummary 
                aria-controls="panel1d-content" 
                id="panel1d-header"
                expandIcon={<ExpandMoreIcon />} 
                >
                {
                    renderResumen()
                }
                
            </AccordionSummary>
            <AccordionDetails>
                <Grid container spacing={3}>
                    <ModificadoresNorma norma={norma} idGrupo={idGrupo} idSitio={idSitio}/>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {norma.modificadores.length > 0 &&
                        <><Grid item xs={12}>
                            {
                                norma.modificadores.map((item, index) =>
                                <ModificadoresCalculoDiasAuditor  
                                    onClickEdit={handleModal} 
                                    key={'mod'+index} 
                                    modificador={item} 
                                    norma={norma}
                                    idGrupo={idGrupo} 
                                    idSitio={idSitio}
                                    />
                                )
                            }    
                        </Grid>
                        {
                            estadoCotizacion.estado !== TIPO_ESTADO.BORRADOR &&
                            <Grid item xs={12}>
                            <Divider/>
                            </Grid>
                        }
                    </>
                    }
                    {
                        estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                        <>
                        <Grid item xs={12} container justifyContent="center">
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                        <Button 
                            variant="contained" 
                            color="primary" 
                            onClick={() => handleModal(-1)}
                            disabled={estadoCarga.guardando}>
                            <AddIcon/>AGREGAR FACTOR
                        </Button>  
                        </Restricted>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider/>
                        </Grid>
                        </>
                    }
                    
                    <DiasAuditorUsuario norma={norma} idGrupo={idGrupo} idSitio={idSitio}/>
                    
                    <ModalFactor 
                    onClose={() => handleModal()} 
                    norma={{...norma, idGrupo:idGrupo, idSitio:idSitio}}
                    idModificador={modalState.selected} 
                    isOpened={modalState.opened} 
                    />
                </Grid>
            </AccordionDetails>
        </Accordion>  
    )
}

export default AccordionCalculoDiasAuditor
