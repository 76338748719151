import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

const classes = {
   root: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: 56,
      borderWidth: 1,
      borderColor: 'primary.main',
      borderStyle: 'dashed',
      borderRadius: 1,
      cursor: 'pointer'
   }
}

const AlimentosNacesAddSubnace = ({ onAdd }) => {

   return (
      <Box sx={classes.root} onClick={onAdd}>
         <AddIcon color="primary" />
      </Box>
   )
}

export default AlimentosNacesAddSubnace;
