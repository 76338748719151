import React from 'react'
import {Dialog, DialogTitle, DialogContent, Button, Typography} from '@mui/material'
import { Box } from '@mui/system'
import InfoIcon from '@mui/icons-material/Info';

function CustomDialog({openDialog, handleCloseDialog, title, content, handleAgree, onlyAgree}) {
    return (
        <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            >
            <DialogTitle id="alert-dialog-title" display="flex" justifyContent="center">
            {
                title?
                <>{title}</>
                :
                <>
                    <InfoIcon color="warning" fontSize='large'/>
                </>
            }    
            </DialogTitle>
            <DialogContent>
                    <Typography textAlign="center">
                        {content}
                    </Typography>
            </DialogContent>
                <Box display="flex" flexDirection="row" justifyContent="center" gap="10px" sx={{padding:"10px"}}>
                    {
                        onlyAgree &&
                        <Button onClick={handleCloseDialog} color="secondary" variant="outlined">Cancelar</Button>
                    }
                    <Button onClick={handleAgree} color="primary" variant="contained" autoFocus>Aceptar</Button>
                </Box>
        </Dialog>
    )
}

export default CustomDialog
