import axios from 'axios';
import 'config/config.js';
import { BASEURL } from 'config/config.js';

const includeCredentials = BASEURL.includes("localhost") || BASEURL.includes("127.0.0.1") ? false : true;

const client = axios.create({
   baseURL: BASEURL,
   headers: {
      "Content-Type": "application/json"
   },
   withCredentials:  includeCredentials
});

export async function getDataAsync(url, config) {
   try {
      return await client.get("api/" + url , config);
   } catch (err) {
      return err.response;
   }
}

export async function postDataAsync(url, data, config) {
   try {
     return await client.post("api/" + url, data, config);
   } catch (err) {
     return err.response;
   }
}

export async function putDataAsync(url, data, config){
   try{
      return  await client.put("api/" + url, data, config);
   }catch(err){
      return err.response;
   }
}

export async function deleteDataAsync(url, config){
   try{
      return await client.delete("api/"+url, config)
   }
   catch(err){
      return err.response
   }
}

export default client;
