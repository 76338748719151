import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import React,{useContext} from 'react'
import { Box } from '@mui/system'

const LabelIntegracion = ({normasIntegradas}) => {
    const {state} = useContext(CotizacionContext)
    const {inputIntegracion} = state
    return (
        <Box className="integration-detail">
            <Box className="norms">
                { normasIntegradas.map(n => n.norma.numero).join(' - ') }
            </Box>
            <Box className="percentage">
                { inputIntegracion.porcentajeReduccion*100 }%
            </Box>
            <Box className="motive">
                { inputIntegracion.motivosOrganizacion.map(m => m.nombre).join(', ') }
            </Box>
        </Box>
    )
}

export default LabelIntegracion
