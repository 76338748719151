const {
    REACT_APP_BASEURL: BASEURL,
    REACT_APP_LOGINURL: LOGINURL,
    REACT_APP_LOGOUTURL: LOGOUTURL,
    REACT_APP_ENV: ENV
} = process.env;


const isProduction = () => ENV?.toUpperCase() === 'PROD';

const isDev = () => ENV?.toUpperCase() === 'DEV';

export {
    BASEURL,
    LOGINURL,
    LOGOUTURL,
    ENV,
    isProduction,
    isDev
}