import { CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import { styled } from '@mui/material/styles';
import {  useContext, useState, useEffect } from "react";
import { capitalizeFirstLetter } from "utlis/stringUtils";
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.primary,
}));

const PaperTotalVisitas = () => {

    const { state } = useContext(CotizacionContext)
    const { json } = state;



    let grupos = json.gruposDeSitios.filter(g => g.tipo != "GENERAL");

    let instancias = json.gruposDeSitios[0].sitios[0].instancias;
    instancias = instancias.sort((a, b) => (a.instancia.numero > b.instancia.numero) ? 1 : -1)
    const [loading, setLoading] = useState(true)
    
    useEffect(() => {
        if(json.id){
            setLoading(false)
        }
        else{
            setLoading(true)
        }
    }, [json.id])

    const getTotalEtapa1 = (instancia) => {
        let total = 0;
        grupos.forEach(g => {
            g.grupoInstancias.filter(gi => gi.numero === instancia.instancia.numero).forEach(gi => {
                total += gi.diasEtapa1
            })
        })
        return total;
    }

    const getTotalEtapa2 = (instancia) => {
        let total = 0;
        grupos.forEach(g => {
            g.grupoInstancias.filter(gi => gi.numero === instancia.instancia.numero).forEach(gi => {
                total += gi.diasEtapa2
            })
        })
        return total;
    }

    const getTotalInstancia = (instancia) => {
        let total = 0;
        grupos.forEach(g => {
            g.grupoInstancias.filter(gi => gi.numero === instancia.instancia.numero).forEach(gi => {
                total += gi.diasAuditor
            })
        })
        return total;
    }
    
    return (
        <Paper className={"my_paper"} elevation={5}>
            {
                loading ?
                <Box display="flex" justifyContent="center">
                    <CircularProgress/>
                </Box>
                :
                <Grid container padding="1%" overflow="scroll" style={{ overflowY: "hidden" }}>
                    <Box display="flex" sx={{paddingBottom:"10px"}}>
                        <Typography variant="h6" fontWeight="fontWeightBold" >Resumen Sitios a Visitar</Typography>
                    </Box>
                    <Box display="flex" flexDirection="row">
                        {
                            instancias.map((ins, index) =>(
                                <Box key={'key'+index} sx={{border: 1, marginRight: "20px", width: "380px", padding: "10px"}}>
                                <h3>{ins.instancia.nombre}</h3>
                                {
                                    renderTable(ins)
                                }
                                
                                <div>
                                {
                                    grupos.map((g, index) => <GrupoInstancia key={'keygrupo2'+index} grupo={g} instancia={ins}  />)
                                }
                                </div>
                               
                                    {
                                        ins.diasAuditorEtapa1 ?
                                    <Box display="flex" sx={{paddingTop:"10px"}}>
                                        <Box display="flex" flexDirection="row" sx={{backgroundColor:"", width:"160px"}}>
                                            <Typography sx={{fontWeight:"bold", marginLeft:"10px"}}>Total</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end"  alignSelf="center" sx={{backgroundColor:"", width:"50px"}}>
                                            <Typography sx={{fontWeight:"bold"}}>{getTotalEtapa1(ins)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" alignSelf="center" sx={{backgroundColor:"", width:"50px"}}>
                                            <Typography sx={{fontWeight:"bold"}}>{getTotalEtapa2(ins)}</Typography>
                                        </Box>
                                        <Box display="flex" justifyContent="flex-end" sx={{backgroundColor:"", width:"98px"}}>
                                            <Typography alignSelf="center" sx={{paddingRight:"28px"}}>{getTotalInstancia(ins)}</Typography>
                                        </Box>
                                    </Box>
                                        :
                                    <Box display="flex" justifyContent="space-between" sx={{marginTop:"10px"}}>
                                        <Typography sx={{marginLeft:"10px", fontWeight:"bold"}}>Total</Typography>
                                        <Typography alignSelf="center" sx={{paddingRight:"27px", fontWeight:"bold"}}>{getTotalInstancia(ins)}</Typography>
                                    </Box>
                                    }
                                </Box>
                               
                            ))
                        }
                    </Box>
                </Grid>
            }
        </Paper>
    )
}

const GrupoInstancia = ({grupo, instancia}) => {
    
    const grupoInstancia = grupo.grupoInstancias.filter(gi => gi.numero === instancia.instancia.numero)[0];

    const sitios = grupo.sitios;
    if(sitios === null){
        return (<>cargando</>)
    }
    
    if(sitios.length === 0){
        return (
            <Box display="flex" justifyContent="space-between" sx={{border: "1px solid #E5E5E5", padding: "8px", backgroundColor:""}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", width:"151px", backgroundColor:""}}>{capitalizeFirstLetter(grupo.nombre)}</Typography>
                <Box display="flex">
                <Typography variant="body1" alignSelf="center" sx={{fontWeight:"bold", paddingRight:"18px"}}>-</Typography>
                </Box>
            </Box>
            )
    }
    const sitiosEtapas = sitios[0].instancias[grupoInstancia.numero-1]
    if(esSitioUnico(grupo)){
        return(
            <Box display="flex" justifyContent="space-between" sx={{border: "1px solid #E5E5E5", padding: "8px", backgroundColor:""}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold", width:"151px", backgroundColor:""}}>{capitalizeFirstLetter(grupo.nombre)}</Typography>
                <Box display="flex">
                    {
                        renderEtapasGrupos(sitiosEtapas, grupoInstancia)
                    }
                </Box>
            </Box>
        )
    }else{
        return(
            <Box sx={{border: "1px solid #E5E5E5", padding: "8px", backgroundColor:"", width:"358px"}}>
                <Typography variant="subtitle1" sx={{fontWeight: "bold"}} color={grupoInstancia.sitiosPlanificados >= grupoInstancia.minimoSitios ?"":"red"}>{capitalizeFirstLetter(grupo.nombre)}</Typography>
                <Box sx={{padding: "10px"}}>
                    {
                    sitios &&
                    <>
                    {
                        sitios.map((si, index) =>
                        si.sitiosConcretos
                        .filter(sc => sc.instancias.find(sci => sci.instancia.numero === instancia.instancia.numero).requiereVisita)
                        .map(sc =>
                                renderSitioEtapas(sc.nombre,
                                                  sc.instancias.find(sci => sci.instancia.numero === instancia.instancia.numero),
                                                  index,
                                                  sc)
                            ))
                    }
                    </>
                    }

                    {
                        (grupoInstancia.diasEtapa1 || grupoInstancia.diasEtapa2) ?
                        <Box display="flex" sx={{backgroundColor:""}}>
                            <Box sx={{width:"140px", backgroundColor:""}}></Box>
                            <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                                <Typography sx={{fontWeight:"bold"}}>{grupoInstancia.diasEtapa1}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                                <Typography sx={{fontWeight:"bold"}}>{grupoInstancia.diasEtapa2}</Typography>
                            </Box>
                            <Box display="flex" justifyContent="flex-end" sx={{backgroundColor:"", width:"80px"}}>
                                <Typography sx={{paddingRight:"8px"}} color={grupoInstancia.diasAuditor === 0 && "red"}> {grupoInstancia.diasAuditor}</Typography>
                            </Box>
                        </Box>
                        :
                        <Box display="flex" justifyContent="flex-end">
                            <Typography sx={{fontWeight: "bold", paddingRight:"8px"}} color={grupoInstancia.diasAuditor === 0 && "red"}>{grupoInstancia.diasAuditor}</Typography>
                        </Box>

                    }
                </Box>
            </Box>
        );
    }
}

const esSitioUnico = (grupo) => {
    if(grupo.sitios.length === 1 ){
      return grupo.sitios[0].cantidadDeSitios === 1
    }
}

const renderEtapasGrupos = (instanciaSitioUnico, grupoInstancia) => {
    if(instanciaSitioUnico.diasAuditorEtapa1 || instanciaSitioUnico.diasAuditorEtapa2){
        return(
            <>
            <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                <Typography sx={{fontWeight:"bold"}} alignSelf="center">{instanciaSitioUnico.diasAuditorEtapa1}</Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                <Typography sx={{fontWeight:"bold"}} alignSelf="center">{instanciaSitioUnico.diasAuditorEtapa2}</Typography>
            </Box>
            <Box display="flex"justifyContent="flex-end" sx={{width:"90px", backgroundColor:""}}>
                <Typography variant="body1" alignSelf="center" color="GrayText" sx={{paddingRight:"19px"}}>{grupoInstancia.diasAuditor}</Typography>
            </Box>
            </>)
    }else{
        if(instanciaSitioUnico.diasAuditorEtapa1 === 0 || instanciaSitioUnico.diasAuditorEtapa2 === 0){
        }else{
            return (<Typography variant="body1" alignSelf="center" sx={{fontWeight:"bold", paddingRight:"18px"}}>{grupoInstancia.diasAuditor}</Typography>)
        }
    }
}

const renderSitioEtapas = (nombre, instancia, index, sc) => {
    
    if(instancia.diasEtapa1 || instancia.diasEtapa2){
        return(
            <Item key={'sitios'+index} sx ={{ marginBottom: "5px", display: "flex", justifyContent: "space-between"}}>
            <Typography >{capitalizeFirstLetter(nombre)}</Typography>

            <Box display="flex" >
                <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                    <Typography variant="body1" sx={{fontWeight:"bold"}} alignSelf="center">{instancia.diasEtapa1}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                    <Typography variant="body1" sx={{fontWeight:"bold"}} alignSelf="center">{instancia.diasEtapa2}</Typography>
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{width:"70px", backgroundColor:""}}>
                    <Typography  variant="body1" color={instancia.diasAuditor === 0 && "red"}>{instancia.diasAuditor}</Typography>
                </Box>
            </Box>
        </Item>)
    }else{
        if(instancia.diasEtapa1 === 0 || instancia.diasEtapa2 === 0){
            return(
                <Item key={'sitios'+index} sx ={{marginBottom: "5px", display: "flex", justifyContent: "space-between"}}>
                    <Typography variant="body1">{capitalizeFirstLetter(nombre)}</Typography>
                    <Typography variant="body1">{instancia.diasAuditor}</Typography>
                    </Item>
                )
        }
        else{
            return ('')
        }
    }
}

const renderTable = (instanciaSitioUnico) => {
    if(instanciaSitioUnico.diasAuditorEtapa1 || instanciaSitioUnico.diasAuditorEtapa2){
        return(
            <Box display="flex" sx={{width: "358px", backgroundColor:"", height:"24px"}}>
                <Box sx={{width:"160px", backgroundColor:""}}></Box>
                <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                    E1
                </Box>
                <Box display="flex" justifyContent="flex-end" sx={{width:"50px", backgroundColor:""}}>
                    E2
                </Box>
                <Box sx={{width:"98px", backgroundColor:""}}>

                </Box>
            </Box>
        )
    }else{
        if(instanciaSitioUnico.diasAuditorEtapa1 === 0 || instanciaSitioUnico.diasAuditorEtapa2 === 0){
        }else{
            return(<Box display="flex" sx={{width: "358px", height:"24px"}}></Box>)
        }
    }
}

export default PaperTotalVisitas;