const COTIZACION_VACIA = {
    id: '',
    idSolicitud: '',
    nombre: '',
    codigo: '',
    cantidadMantenimientos: 0,
    listaIAF_NACE: [{
        CodigoIaf: {
            codigo: 0,
            descripcion: ""
        },
        CodigoNace: {
            codigo: 0,
            descripcion: ""
        }
    }],
    servicios: [{}],
    normas: [
        {
            id: 0,
            integrada: false,
            norma: {
                id: 0,
                nombre: '',
                usaComplejidad: true
            },
            motivo: '',
            numeroMantenimiento: 0,
            esReduccionDeAlcance: true,
            esAmpliacionDeAlcance: true,
            esCambioDeVersion: true,
            esTransferencia: true,
            fechaVencimientoCertificado: new Date(),
            idEnteTransferencia: 0,
            ente: {
                id: 0,
                nombre: ''
            }
        }
    ],
    infoIntegracion: {
        id: 0,
        coeficienteIRAM: 0,
        coeficienteOrganizacion: 0,
        motivosOrganizacion: [
            {
                id: 0,
                nombre: '',
                descripcion: ''
            },
        ],
        porcentajeReduccion: 0
    },
    estado: '',
    gruposDeSitios: [
        {
            id: 0,
            nombre: '',
            tipo: '',
            sitiosMinimosCertificacion: 0,
            sitiosMinimosRecertificacion: 0,
            sitiosMinimosMantenimiento: 0,
            coeficienteMuestreoCertificacion: 0,
            coeficienteMuestreoRecertificacion: 0,
            coeficienteMuestreoMantenimiento: 0,
            sitios: [
                {
                    id: 0,
                    nombre: '',
                    personal: 0,
                    rangoDePersonasDesde: 0,
                    rangoDePersonasHasta: 0,
                    cantidadDeSitios: 0,
                    infoIntegracion: {
                        desvinculada: false
                    },
                    instancias: [
                        {
                            id: 0,
                            diasAuditor: 0,
                            diasAuditorEtapa1: 0,
                            diasAuditorEtapa2: 0,
                            tieneEtapas: false,
                            sitiosAVisitar: 0,
                            normas: [
                                {
                                    id: 0,
                                    nombre: '',
                                    diasAuditor: 0,
                                    diasAuditorEtapa1: 0,
                                    diasAuditorEtapa2: 0,
                                    sitioInstancia: '',
                                    sitioNorma: {
                                        id: '',
                                        complejidad: "",
                                        complejidadesPosibles: [],
                                        integrada: false,
                                        reduccionPlanificacionDocumentacion: 0,
                                        diasCertificacionEtapa1: 0,
                                        diasCertificacionEtapa2: 0,
                                        diasCertificacionTabla: 0,
                                        diasCertificacionAjustado: 0,
                                        diasCertificacionUsuario: 0,
                                        diasRecertificacionTabla: 0,
                                        diasRecertificacionAjustado: 0,
                                        diasRecertificacionUsuario: 0,
                                        diasMantenimientoTabla: 0,
                                        diasMantenimientoAjustado: 0,
                                        diasMantenimientoUsuario: 0,
                                        cantidadMantenimientos: 0,
                                        justificacion: "",
                                        editado: false,
                                        cotizacionNorma: {
                                            id: '',
                                            integrada: false,
                                            norma: {
                                                id: '',
                                                nombre: '',
                                                usaComplejidad: false,
                                            },
                                            motivo: '',
                                            numeroMantenimiento: 0,
                                            esReduccionDeAlcance: false,
                                            esAmpliacionDeAlcance: false,
                                            esCambioDeVersion: false,
                                            esTransferencia: false,
                                            fechaVencimientoCertificado: new Date(),
                                            ente: 0
                                        },
                                        instancias: [],
                                        modificadores: []
                                    }
                                }
                            ],
                            instancia: {
                                id: 0,
                                nombre: '',
                                mes: '',
                                numero: 0,
                                tieneEtapas: false,
                                diasAuditor: 0,
                                diasAuditorEtapa1: 0,
                                diasAuditorEtapa2: 0
                            }
                        }
                    ],
                    normas: [
                        {
                            id: 0,
                            complejidad: '',
                            complejidadesPosibles: [],
                            integrada: false,
                            reduccionPlanificacionDocumentacion: 0,
                            diasCertificacionEtapa1: 0,
                            diasCertificacionEtapa2: 0,
                            diasCertificacionTabla: 0,
                            diasCertificacionAjustado: 0,
                            diasCertificacionUsuario: 0,
                            diasRecertificacionTabla: 0,
                            diasRecertificacionAjustado: 0,
                            diasRecertificacionUsuario: 0,
                            diasMantenimientoTabla: 0,
                            diasMantenimientoAjustado: 0,
                            diasMantenimientoUsuario: 0,
                            cantidadMantenimientos: 0,
                            justificacion: '',
                            editado: false,
                            cotizacionNorma: {
                                id: 0,
                                integrada: false,
                                norma: {
                                    id: 0,
                                    nombre: '',
                                    usaComplejidad: false
                                },
                                motivo: '',
                                numeroMantenimiento: 0,
                                esReduccionDeAlcance: true,
                                esAmpliacionDeAlcance: true,
                                esCambioDeVersion: true,
                                esTransferencia: true,
                                fechaVencimientoCertificado: new Date(),
                                idEnteTransferencia: 0,
                                ente: {
                                    id: 0,
                                    nombre: ''
                                }
                            },
                            instancias: [
                                {
                                    id: 0,
                                    nombre: '',
                                    diasAuditor: 0,
                                    diasAuditorEtapa1: 0,
                                    diasAuditorEtapa2: 0,
                                    sitioInstancia: '',
                                    sitioNorma: '',
                                    sitioInstanciaaux: {
                                        id: 0,
                                        diasAuditor: 0,
                                        sitiosAVisitar: 0,
                                        instancia: {
                                            id: 0,
                                            nombre: '',
                                            mes: '',
                                            numero: 0
                                        }
                                    }
                                }

                            ],
                            modificadores: [
                                {
                                    id: 0,
                                    porcentaje: 0,
                                    motivos: [
                                        {
                                            id: 0,
                                            nombre: '',
                                            descripcion: '',
                                            tipo: ''
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            ]
        }
    ]
};
export default COTIZACION_VACIA;