export const SET_ESTADO_CARGA = "setEstadoCarga"

export const SET_SAVING = "setSaving";
export const SET_JSON = "setJson";
export const SET_NOMBRE_COTIZACION = "setNombreCotizacion";
export const RESET_NORMAS = "resetNormas"
export const SET_NORMAS_MOTIVO = "setNormasMotivos"
export const SET_NUEVA_NORMA = "setNuevaNorma"
export const DELETE_NORMA = "deleteNorma"
export const SET_SAVED = "setSaved"
export const SET_INPUT_INTEGRACION = "setInputIntegracion"
export const RESET_INTEGRACION = "resetIntegracion"
export const RESET_ESTADO_COTIZACION = "resetEstadoCotizacion"
export const SET_INPUT_CODIGOS = "setInputFieldsCodigos"
export const SET_INPUT_REDUCCION = "setInputReduccion"
export const ADD_INPUT_MODIFICADORES = "addInputModificadores"
export const RESET_COTIZACION = "resetCotizacion"
export const RESET_TURNOS = "resetTurnos"
export const RESET_IAF_NACE = "resetIAFNACE"
export const SET_INFO_COTIZACION = "setInfoCotizacion"
export const SET_NUEVO_TURNO = "setNuevoTurno"
export const SET_TURNOS = "setTurnos"
export const DELETE_TURNO = "deleteTurno"
export const SET_NUEVO_NACE = "setNuevoNace"
export const SET_NACE = "setNaces"
export const SET_NUEVO_SUBNACE = "setNuevoSubnace"
export const DELETE_NACE = "deleteNace"
export const SET_ALL_ACCORDION_CLOSE = "allAccordionClose"
export const SET_ESTADO_COTIZACION = "setEstadoCotizacion"
export const SET_LISTA_RANGO_PERSONAL = "setListaRangoPersonal"
export const SET_OBSERVACIONES = "setObersvaciones"

export const SET_INPUT_IAF_NACE = "setinputCodigosIAFNACECodigos"
export const ADD_INPUT_IAF_NACE = "addInputIafNace";
export const DELETE_CODIGO_IAF_NACE = "deleteCodigoIafNace"

export const SET_TAB = "setTab"
export const SET_MULTISITIOS = "setMultisitios"
export const UPDATE_TIPO_CALCULO = "updateTipoCalculoPropuesta"

//PROPUESTA
export const SET_PROPUESTA = "setPropuesta"
export const SET_PRODUCTOS = "setProductos"

//Documento
export const SET_SECCIONESDOCUMENTO = "setSeccionesDocumento"
export const SET_SECCIONESEDIT = "setSeccionesEdit"
export const SET_DOCUMENTO_TEMP = "setDocumentoTemp"

//Servicios Producto
export const AGREGAR_SERVICIO = "nuevoServicio"
export const ACTUALIZAR_SERVICIO = "actualizarServicio"
export const ACTUALIZAR_SERVICIOS = "actualizarServicios"
export const BORRAR_SERVICIO = "borrarServicio"
