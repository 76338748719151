import { configureStore } from '@reduxjs/toolkit';
import globalSlice from './slices/globalSlice';
import cursosSlice from './slices/cursosSlice';


export const store = configureStore({
  reducer: {
    global: globalSlice,
    cursos: cursosSlice
  },
});