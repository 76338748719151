import React, { useContext, useEffect, useState } from 'react'
import ModalPortal from 'components/modalPortal';
import { Box } from '@mui/system'
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { _postConcepto } from 'services/Propuesta';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { getProductosProducto, setPropuesta } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import SelectProductos from 'components/SelectProductos';
import { fetchCursos } from 'redux/slices/cursosSlice';
import { useDispatch, useSelector } from 'react-redux';

function ModalConcepto({onClose, isOpened, propuesta}) {

    const dispatch = useDispatch();

    const {state, dispatch: dispatchCotizacion} = useContext(CotizacionContext)

    const [conceptoLocal, setConceptoLocal] = useState("")
    const [comentario, setComentario] = useState("")
    const [producto, setProducto] = useState(null)

    

    useEffect(() => {
        if(!isOpened) return;
        getProductosProducto(dispatchCotizacion, state.json.id);
        dispatch(fetchCursos());
    },[isOpened])

    const onCloseButton = () => {
        setConceptoLocal("")
        setComentario("")
        onClose()

    }
    const onSave = (e) => {
        e.preventDefault();
        const data = {
            concepto:{
                nombre:conceptoLocal,
                comentario:comentario,
                bonificacion:0,
                recargo:0,
                producto: producto.tipo === 'producto' ? producto : null,
                curso: producto.tipo === 'curso' ? producto : null
            },
            idPropuesta: propuesta.id
        }
        _postConcepto(data)
        .then(response => {
            let propuestaAux = {...state.propuesta}
            propuestaAux.conceptos.push(response)

            dispatchCotizacion(setPropuesta(propuestaAux))
        })
        .catch(error => {
            console.error('error', error)
        })

     
        setConceptoLocal("")
        setComentario("")
        onClose()
    }

    const handleConcepto = (e) => {
        setConceptoLocal(e.target.value)
    }

    const handleComentario = (e) => {
        setComentario(e.target.value)
    }



return (
    <ModalPortal
    onCancel={onCloseButton}
    onSave={onSave}
    title="Agregar Concepto"
    isOpened={isOpened}
    >
        <Box display='flex' sx={{width:"500px"}} flexDirection="column" gap={3}>
            <SelectProductos onChange={setProducto} />
            <TextField
            label="Comentario"
            value={comentario}
            onChange={handleComentario}
            multiline
            rows={4}
            fullWidth
            />

        </Box>
    </ModalPortal>
)
}

export default ModalConcepto