import { Box, Chip, Typography }  from '@mui/material';
import { useHistory } from 'react-router';
import { RUTAS } from 'constants/routes'
import logoiram from 'assets/logo-iram-mobile.svg'
import { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { resetSolicitud } from 'context/solicitud/actionsSolicitud/ActionsSolicitud';
import { ENV, isProduction } from 'config/config';

const classes = {
   env: {
      ml: 4
   },
   root: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
   }
}

const Brand = () => {
   const {dispatch} = useContext(CotizacionContext)
   const history = useHistory();
   const handleLogo = () => {
      dispatch(resetSolicitud())
      history.push(RUTAS.HOME)
   }

   return (
      <>
         <Box onClick={handleLogo} sx={classes.root}>
            <Box className="logo">
               <img src={logoiram} alt="IRAM"/>
            </Box>
            <Typography component="div" variant="h6">
               Cotizador
            </Typography>
         </Box>
         <div></div>
         { !isProduction() && <Chip label={ENV.toUpperCase()} color="warning" sx={classes.env} /> }
      </>
   )
}

export default Brand;
