import React, {useContext, useEffect, useState} from 'react'
import TabDiasAuditor from 'components/tabDiasAuditor'
import TabPanel from 'components/tabPanel'
import {Paper, AppBar, Tabs, Tab,Fab, CircularProgress, Drawer, Tooltip} from '@mui/material'
import { makeStyles } from '@mui/styles';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setAllAccordionClose, setTab } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import { Box } from '@mui/system';
import TabDiasAuditorSitios from 'components/TabDiasAuditorSitio';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import TabVisitas from 'components/TabVisitas';
import 'react-quill/dist/quill.snow.css'; // ES6
import ObersvacionesTextEditor from 'components/ObservacionesTextEditor';
import { TIPO_GRUPO_SITIO } from 'constants/constants';
import ModalMensaje from 'components/ModalMensaje';
import TabPropuestaEconomica from '../TabPropuestaEconomica';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PropuestaExterna from 'components/PropuestaExterna';

const PaperCalculo = () => {

    const {state,dispatch} = useContext(CotizacionContext)
    const {allAccordionClose, json, tabSeleccionada} = state
    
     //Modal
     const [modalState, setModalState] = useState({opened: false});
     const handleModal = (msj) => setModalState({opened: !modalState.opened, mensaje: msj})


    const handleChangeTab = (event, newValue) => {
        if( (json.usaMultisitios && newValue === 1) || (!json.usaMultisitios && newValue === 2) ){
            const listaGrupos = json.gruposDeSitios.filter(g => g.tipo !== TIPO_GRUPO_SITIO.CASA_CENTRAL)
            var grupoSinSitios = listaGrupos.find( g => g.sitios === null || g.sitios.length === 0)
            if(grupoSinSitios){
                handleModal("No se puede acceder a la planificacion de visitas porque el grupo '" + grupoSinSitios.nombre + "' no tiene sitios")
            }
            else{
                // setValue(newValue)
                dispatch(setTab(newValue))
            }
        }else{
            dispatch(setTab(newValue))
            // setValue(newValue);
        }
    };

    //CSS
    const useStyles = makeStyles((theme) => ({
        paper: {
            padding: theme.spacing(3),
            margin: theme.spacing(3),
        },
        fixedButton:{
            margin:0,
            top:"auto",
            left:50,
            bottom:20,
            right:"auto",
            position:"fixed"
        }
       
    }));
    const classes = useStyles();

    const handleMinimizar = () =>{
        dispatch(setAllAccordionClose(true))
    }

    const handleObservaciones = () =>{
        setOpenDrawer(true)
    }

    const [loading, setLoading] = useState(true)
    useEffect(() => {
        if(json.id){
            setLoading(false)
        }
        else{
            setLoading(true)
        }
    }, [json.id])

    //Drawer
    const [openDrawer, setOpenDrawer] = useState(false)
    const closeDrawer = () => {
        setOpenDrawer(false)
    }

    
    return (
        <Paper className="my_paper" elevation={5} sx={{position:"relative"}}>
            {
                loading ?
                    <Box display="flex" justifyContent="center" sx={{padding:'10px'}}>
                        <CircularProgress/>
                    </Box>
                    :
                    <div>
                        <AppBar className={classes.appbar} position='static' color='default'>
                            <Tabs value={tabSeleccionada} onChange={handleChangeTab}>
                                {
                                    !json.usaMultisitios &&
                                    <Tab  label="CALCULO GENERAL DIAS AUDITOR"/>
                                }
                                {
                                    json.usaMultisitios &&
                                    <Tab key={"t1"} label='CALCULO POR SITIOS'/>
                                }
                                {
                                    json.usaMultisitios &&
                                    <Tab key={"t2"} label='MUESTREO DE SITIOS'/>
                                }
                                
                                <Tab key={"t3"} icon={<LocalAtmIcon/>}  iconPosition="start" label='PROPUESTA ECONOMICA'/> 
                            </Tabs>
                        </AppBar>
                       
                        {
                        !json.usaMultisitios &&
                        <TabPanel key={"tab0"} value={tabSeleccionada} index={0}>
                            <TabDiasAuditor/>
                        </TabPanel>
                        }
                        {
                            json.usaMultisitios &&
                            <>
                            <TabPanel  key={"tab1"} value={tabSeleccionada} index={0}>
                                <TabDiasAuditorSitios/>
                            </TabPanel>
                            <TabPanel key={"tab2"} value={tabSeleccionada} index={1}>
                                <TabVisitas/>
                            </TabPanel>   
                           
                            </>
                        }
                        <TabPanel key={"tab3"} value={tabSeleccionada} index={json.usaMultisitios?2:1}>
                                {
                                    json.propuestas && json.propuestas.length > 0 ?
                                    <TabPropuestaEconomica/>
                                    :
                                    json.estado.estado === "Aceptada" ?
                                    <PropuestaExterna/> 
                                    :
                                    <TabPropuestaEconomica />
                                }
                        </TabPanel>
                        <Fab 
                            color="primary" 
                            className={classes.fixedButton}
                            aria-label="add" 
                            onClick={handleObservaciones}
                            >
                                <Tooltip title="Observaciones">
                                <NoteAltIcon  />
                                </Tooltip>
                                
                        </Fab>
                        {
                            !allAccordionClose &&
                            <Fab 
                            color="primary" 
                            aria-label="add" 
                            sx={{position:"absolute", right:"-70px", top:"50%"}}
                            onClick={handleMinimizar}
                            >
                                <UnfoldLessIcon  />
                            </Fab>
                        }
                        <Drawer
                            anchor='left'
                            open={openDrawer}
                            onClose={closeDrawer}
                            >
                               <ObersvacionesTextEditor/>
                        </Drawer>
                    </div>
                    
            }
            <ModalMensaje
             onClose={() => handleModal()} 
             mensaje={modalState.mensaje}
             isOpened={modalState.opened} >
            </ModalMensaje>
        </Paper>
    )
}

export default PaperCalculo
