import React, { useContext } from 'react'
import { Box, Checkbox, FormControl, InputLabel, Select, MenuItem, Grid, ListSubheader, Chip, Autocomplete, TextField } from '@mui/material'

import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
 

import { setNormaMotivo } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { usePermitirEdicion } from 'hooks/usePermitirEdicion';
import DetalleMotivoNorma from 'components/detalleMotivoNorma';
import { TIPO_ESTADO, TIPO_SOLICITUD, TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import DetalleMotivoNormaAlimentos from 'components/DetalleMotivoNormaAlimentos';
import { useSelector } from 'react-redux';

function InputNormaMotivo({ index, noEliminar, setLimiteNormas, modoEdicion, tipo }) {
    const globalSlice = useSelector(state => state.global)
    const { state, dispatch } = useContext(CotizacionContext)

    const { inputNormasMotivos, estadoCotizacion} = state
    const { listaNormas } = globalSlice
    const permitirEdicion = usePermitirEdicion(modoEdicion)

    const handleChangeNormaMotivo = (event, index) => {
  
        const normaMotivoAux = [...inputNormasMotivos];
        normaMotivoAux[index][event.name] = event.value;
        dispatch(setNormaMotivo(normaMotivoAux));
    }

    const handleChangeIntegrada = (event, index) => {
        const normaMotivoAux = [...inputNormasMotivos];
        normaMotivoAux[index].integrada = event.value;
        dispatch(setNormaMotivo(normaMotivoAux));
    }

    const handleChangeNorma = (event, index) => {
       
        const normaMotivoAux = [...inputNormasMotivos];
        normaMotivoAux[index].norma = event.value;
        dispatch(setNormaMotivo(normaMotivoAux));
    }

    const handleResetIntegracionNormas = () => {
        const newNormaMotivo = inputNormasMotivos.map(norma => {
            return { ...norma, integrada: false }
        })
        dispatch(setNormaMotivo(newNormaMotivo))
    }

    const normaEditable = () => {
        if (permitirEdicion) {
            return !inputNormasMotivos[index].nuevaNorma
        } else {
            if (estadoCotizacion.estado === TIPO_ESTADO.BORRADOR) {
                return false
            } else {
                return true
            }
        }
    }

    const getTipoNorma = (norma) => {
        switch(norma.jerarquiaAlimentos){
            case 2:
                return "HACCP";
            case 3:
                return "BPM";
            default:""
                break;
        }
    }

    const renderItemNorma = (props, option) => {

        var tipoNorma = getTipoNorma(option)
        if(tipo === TIPO_SOLICITUD_ALIMENTOS){
            return (<MenuItem {...props} key={'norma' + option.id}
            disabled={inputNormasMotivos.some(i => i.norma === option.id)}
            sx={{height:"56px !important"}}
            value={option.id}>
            {option.nombre}
            {
                tipoNorma &&
                <Chip label={tipoNorma} size='small' sx={{marginLeft:"5px"}}></Chip>
                // <Box display="flex" sx={{marginLeft:"5px",border: "1px solid black", borderRadius:"5px"}}>{tipoNorma}</Box>
            }
            </MenuItem>)
        }else{
            return (<MenuItem {...props} key={'norma' + option.id}
            disabled={inputNormasMotivos.some(i => i.norma === option.id)}
            sx={{height:"56px !important"}}
            value={option.id}>
            {option.nombre}
            </MenuItem>)    
        }
        
    }
    
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start">
                <Grid item xs={4}>
                    <Box display="flex">
                        {
                            tipo !== "ALIMENTOS" &&
                                <Checkbox
                                    name="integrada"
                                    color="integracion"
                                    checked={inputNormasMotivos[index].integrada}
                                    disabled={normaEditable()}
                                    onChange={(event) => handleChangeIntegrada({ name: event.target.name, value: event.target.checked }, index)} />
                        }
                        <FormControl fullWidth>
                        <AutocompleteNorma
                            tipo={tipo}
                            normaEditable={normaEditable}
                            handleChangeNormaMotivo={(event, index) => handleChangeNormaMotivo({...event, name: "norma"}, index)}
                            norma={inputNormasMotivos[index]?.norma}
                            index={index} // El índice correspondiente
                            listaNormas={listaNormas}
                            renderItemNorma={renderItemNorma}
                        />
                        </FormControl>
                    </Box>
                </Grid>
                <Grid item xs={8}>
                    {
                        tipo === "ALIMENTOS"?
                        <DetalleMotivoNormaAlimentos
                            value={inputNormasMotivos[index].motivo}
                            numeroNorma={listaNormas.find(l => l.id === inputNormasMotivos[index].norma.id)?.numero}
                            index={index}
                            onChangeNormaMotivo={handleChangeNormaMotivo}
                            setLimiteNormas={setLimiteNormas}
                            noEliminar={noEliminar}
                            resetNormasIntegracion={handleResetIntegracionNormas}
                            modoEdicion={modoEdicion}
                            allInputs={true}
                        />
                        :
                        <DetalleMotivoNorma
                            value={inputNormasMotivos[index].motivo}
                            numeroNorma={listaNormas.find(l => l.id === inputNormasMotivos[index].norma.id)?.numero}
                            index={index}
                            onChangeNormaMotivo={handleChangeNormaMotivo}
                            setLimiteNormas={setLimiteNormas}
                            noEliminar={noEliminar}
                            resetNormasIntegracion={handleResetIntegracionNormas}
                            modoEdicion={modoEdicion}
                            allInputs={true}
                        ></DetalleMotivoNorma>
                    }
                    
                </Grid>
            </Grid>
        </>
    )
}

const AutocompleteNorma = ({ tipo, normaEditable, handleChangeNormaMotivo, norma, index, listaNormas, renderItemNorma }) => {
    if(typeof norma === "number"){
        norma = listaNormas.find(l => l.id === norma)
    }

    return (
        <Autocomplete
            disableClearable
            disabled={normaEditable()}
            options={listaNormas.filter(n => n.tipo === tipo).sort((n1, n2) => n1.jerarquiaAlimentos - n2.jerarquiaAlimentos)}
            getOptionLabel={(option) => option.nombre}  // Asumiendo que la norma tiene un atributo 'nombre'
            onChange={(event, newValue) => handleChangeNormaMotivo({...event.target, value: newValue}, index)}
            value={norma === "" ? null: norma}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Norma"
                    required
                />
            )}
            renderOption={(props, option) => renderItemNorma(props, option)}
        />
    );
}

export default InputNormaMotivo
