import React, {useContext, useState, useEffect} from 'react'
import {SolicitudContext} from 'context/solicitud/solicitudContext'
import { MenuItem, Grid, InputLabel, Select, FormControl, IconButton} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete';
 
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { deleteCodigoIafNace, setInputIafNace } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { useSelector } from 'react-redux';


const InputCodigos = ({valueIndex, disabled, guardado}) => {

    //context lists
    const globalSlice = useSelector(state => state.global)
    const {listaCodigosIAF} = globalSlice

    //Context
    const {state, dispatch} = useContext(CotizacionContext);
    const { inputCodigosIAFNACE} = state;

    const [sePuedeEliminar, setPuedeEliminar] = useState(false)

    const [codigosNaceLocal, setNaceLocal] = useState([]);

    const [naceHabilitado, setNaceHabilitado] = useState(false)
    

    useEffect(() => {
        if(disabled){
            setPuedeEliminar(false)
        }else{
            if(inputCodigosIAFNACE.length>1){
                setPuedeEliminar(true)
            }else{
                setPuedeEliminar(false)
            }
            
        }
        if(inputCodigosIAFNACE[valueIndex].CodigoIaf.id){
            const listaNaceByIAF = listaCodigosIAF.find(c => c.codigo === inputCodigosIAFNACE[valueIndex].CodigoIaf.id).nacEs;
            setNaceLocal(listaNaceByIAF);
        }
    }, [inputCodigosIAFNACE, valueIndex, disabled, guardado])
    
    useEffect(() => {
      if(disabled){
          if(guardado){
            setNaceHabilitado(true)
          }else{
            setNaceHabilitado(false)
          }
        
      }else{
        if(codigosNaceLocal.length === 0){
            setNaceHabilitado(true)
        }else{
            setNaceHabilitado(false)
        }
        
      }
    }, [disabled, codigosNaceLocal])
    

    const handleChangeInput = (index, event, child) => 
    {
        const values = [...inputCodigosIAFNACE];
        values[index][event.target.name].id = event.target.value;
        values[index][event.target.name].codigo = child.props.children.split('-')[0];
        values[index][event.target.name].descripcion = child.props.children.split('-')[1];
        
        
        dispatch(setInputIafNace(values));
    }

    const handleDeleteInput = () =>{
        dispatch(deleteCodigoIafNace(valueIndex))
        
    }

    const renderNace = () => {
        return (
            codigosNaceLocal.map((item, index) =>
                <MenuItem key={'nace'+index} value={item.id} disabled={inputCodigosIAFNACE.some(i => i.CodigoNace.id === item.id)} >{item.codigo + ' - ' + item.descripcion}</MenuItem>
            ))
    }
    return (
        <>
            <Grid item xs={6}>
                <FormControl fullWidth>
                    <InputLabel id='label-iaf'>IAF</InputLabel>
                        <Select
                        labelId="label-iaf"
                        label="IAF"
                        fullWidth
                        name='CodigoIaf'
                        required
                        displayEmpty
                        disabled={disabled}
                        onChange={(event,child) => handleChangeInput(valueIndex, event, child)}
                        value={inputCodigosIAFNACE[valueIndex]['CodigoIaf'].id}>
                            {listaCodigosIAF.map((item, index) =>
                            
                                <MenuItem key={'iaf'+index} value={item.id}>{item.codigo + ' - ' + item.descripcion}</MenuItem>
                            )}
                    </Select> 
                </FormControl>
            </Grid>
            <Grid item xs={sePuedeEliminar? 5:6}>
                <FormControl fullWidth>
                    <InputLabel id='label-nace'>NACE</InputLabel>
                    <Select
                    labelId="label-nace"
                    label="NACE"
                    fullWidth
                    name='CodigoNace'
                    required
                    disabled={naceHabilitado}
                    onChange={(event, child) => handleChangeInput(valueIndex, event, child)}
                    value={inputCodigosIAFNACE[valueIndex]['CodigoNace'].id}>
                        {
                            renderNace()
                        }
                    </Select>
                    
                </FormControl>
               
            </Grid>
            {
                sePuedeEliminar ? 
                <>
                    <Grid item xs={1}>
                        <IconButton edge="end" aria-label="delete" onClick={handleDeleteInput}>
                            <DeleteIcon />
                        </IconButton>
                    </Grid>
                </>
                :null
            }
           
            
        </>
    )
}

export default InputCodigos
