import { Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select } from '@mui/material'
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system'
import ModalPortal from 'components/modalPortal'
import { TIPO_ESTADO, TIPO_GRUPO_SITIO } from 'constants/constants';
import { updateNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
 
import React, { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120
    },
    selectEmpty: {
        marginTop: theme.spacing(2)
    },
    menuPaper: {
        maxHeight: 250
    }
}));

function ModalFactor({ idModificador, onClose, norma, isOpened }) {

    const classes = useStyles();
    //contexto global
    const dispatchRedux = useDispatch()
    const globalSlice = useSelector(state => state.global)

    //contexto listas
    const { rangosPorcentajesFactores, listaMotivosModificadores } = globalSlice

    //contexto cotizacion
    const { state, dispatch } = useContext(CotizacionContext);
    const { json, estadoCotizacion } = state;

    const [factor, setFactor] = useState({ porcentaje: '', motivos: [] })


    const [listaMotivos, setListaMotivos] = useState([])
    const [listaMotivosAux, setListaMotivosAux] = useState([])


    function range(start, end) {
        const arr = []
        for (let i = start; i <= end; i+=5) {
            arr.push(i)
        }
        return arr;
    }

    const getPorcentajeAcumulado = (indexGrupo) => {
        let porcentajeAcumulado = 0;
        for(let sitio of json.gruposDeSitios[indexGrupo].sitios.filter(s => s.tipo === TIPO_GRUPO_SITIO.SITIO)){
            for(let normaSitio of sitio.normas){
                if(normaSitio.id === norma.id){
                    for(let modificador of normaSitio.modificadores){
                        porcentajeAcumulado += modificador.porcentaje * 100;
                    }
                }
            }
        }
        return porcentajeAcumulado;
    }

    const getPorcentajesDisponibles = () => {

        const indexGrupo = json.gruposDeSitios.findIndex(g => g.id === norma.idGrupo);
        if (indexGrupo > -1) {
            const porcentajeAcumulado = getPorcentajeAcumulado(indexGrupo);
            const tipoGrupo = json.gruposDeSitios[indexGrupo].tipo;
            const porcentajes = rangosPorcentajesFactores[tipoGrupo];
            const rangos = range(porcentajes.maximoDescuento, porcentajes.maximoAditivo);
            return rangos.filter(r => {
                if(porcentajeAcumulado < 0){
                   
                    if((idModificador === null || idModificador === undefined)){
                        return r >= porcentajes.maximoTotalDescuento - porcentajeAcumulado;
                    }else{
                        return r >= porcentajes.maximoTotalDescuento - porcentajeAcumulado + factor.porcentaje;
                    }
                    
                }
                return true;
            }).filter(r => r !== 0);
        }
        return [];
    }

    const handlePorcentaje = (e) => {
        const porcentajeAux = parseInt(e.target.value)
        if (porcentajeAux > 0) {
            if (factor.porcentaje < 0) {
                setFactor({ ...factor, porcentaje: porcentajeAux, motivos: [] })
            } else {
                setFactor({ ...factor, porcentaje: porcentajeAux })
            }
        } else {
            if (factor.porcentaje > 0) {
                setFactor({ ...factor, porcentaje: porcentajeAux, motivos: [] })
            } else {
                setFactor({ ...factor, porcentaje: porcentajeAux })
            }
        }
    }

    const handleMotivos = (e) => {
        const motivosFiltered = listaMotivosModificadores.filter(l => e.target.value.some(v => v === l.id))
        setFactor({ ...factor, motivos: motivosFiltered })
    }

    //EFECTS
    useEffect(() => {
        let normaModificadoresSelected = norma.modificadores.map(m => m.motivos).flat()
        let motivosAux = listaMotivosModificadores.map(
            function (motivo) {
                if (normaModificadoresSelected.some(s => s.id === motivo.id)) {
                    if (idModificador && idModificador != -1) {
                        const factorSelected = norma.modificadores.find(m => m.id === idModificador)
                        if (factorSelected.motivos.some(m => m.id === motivo.id)) {
                            return { ...motivo, selected: false }
                        }
                        else {
                            return { ...motivo, selected: true }
                        }
                    } else {
                        return { ...motivo, selected: true }
                    }
                } else {
                    return { ...motivo, selected: false }
                }
            })
        setListaMotivosAux(motivosAux)

        //open modificador loading data
        if (idModificador && idModificador != -1) {
            const factorSelected = norma.modificadores.find(m => m.id === idModificador)
            setFactor({ porcentaje: factorSelected.porcentaje * 100, motivos: factorSelected.motivos })
        }
    }, [idModificador])

    useEffect(() => {
        if (parseInt(factor.porcentaje) > 0) {
            const motivosPositivos = listaMotivosAux.filter(l => l.tipo === 'Aditivo');

            setListaMotivos(motivosPositivos)
        } else {
            const motivosNegativos = listaMotivosAux.filter(l => l.tipo === 'Sustractivo');
            setListaMotivos(motivosNegativos)
        }
    }, [factor.porcentaje])

    const handleRender = (e) => {

        const motivosFiltered = listaMotivosModificadores.filter(l => e.some(v => v === l.id))
        return (<div>
            {
                motivosFiltered.map(m => m.nombre).join(', ')
            }
        </div>)
    }

    const onSave = () => {
        let factoresAux = []
        if (idModificador && idModificador != -1) {
            let factorSelected = norma.modificadores.find(m => m.id === idModificador)
            factorSelected.porcentaje = factor.porcentaje / 100
            factorSelected.motivos = factor.motivos
            factoresAux = norma.modificadores
        }
        else {
            factoresAux = norma.modificadores.concat([{ id: 0, porcentaje: factor.porcentaje / 100, motivos: factor.motivos }])
        }
        const data = { ...norma, modificadores: factoresAux }

        updateNorma(dispatch, json.id, norma.idGrupo, norma.idSitio, data)
        setFactor({ porcentaje: '', motivos: [] })
        setListaMotivos([])
        onClose()
    }

    const onCloseButton = () => {
        setFactor({ porcentaje: '', motivos: [] })
        setListaMotivos([])
        onClose()
    }

    return (
        <ModalPortal
            onCancel={onCloseButton}
            onSave={onSave}
            title="Factores"
            isOpened={isOpened}
        >
            <Box display="flex" gap={3} flexDirection="column" sx={{ width: '650px', height:"320px", paddingBottom: "30px" }}>
                <FormControl fullWidth>
                    <InputLabel id="label-pocentaje">Porcentaje</InputLabel>
                    <Select
                        labelId="label-porcentaje"
                        label="Porcentaje"
                        required
                        disabled={estadoCotizacion.estado === TIPO_ESTADO.ENVIADA}
                        onChange={handlePorcentaje}
                        value={factor.porcentaje}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                        {
                            getPorcentajesDisponibles().map((item, index) =>
                                <MenuItem key={'porcentaje' + index} value={item}>
                                    <ListItemText primary={item} />
                                </MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                <FormControl fullWidth>
                    <InputLabel id='labelMotivoModificadores'>Motivos</InputLabel>
                    <Select
                        labelId="demo-mutiple-checkbox-label"
                        label="Motivos"
                        id="demo-mutiple-checkbox"
                        multiple
                        required
                        disabled={listaMotivos.length === 0}
                        onChange={handleMotivos}
                        renderValue={handleRender}
                        value={factor.motivos.map(m => m.id)}
                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                        {
                            listaMotivos.map((item, index) =>
                                <MenuItem
                                    key={"mot" + item.nombre}
                                    value={item.id}
                                    disabled={item.selected}
                                    sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Checkbox checked={factor.motivos.some(m => m.id === item.id)} />
                                    <ListItemText primary={item.nombre}></ListItemText>
                                </MenuItem>)
                        }
                    </Select>
                </FormControl>
                <Box display="flex" flexDirection={"column"}>
                    {
                        factor.motivos.map((motivo, index) =>
                            <li key={"motivos-factor"+index}>{motivo.nombre}</li>
                        )
                    }
                </Box>
            </Box>
        </ModalPortal>
    )
}

export default ModalFactor
