import { TextField } from '@mui/material'
import ModalPortal from 'components/modalPortal'
import { TIPO_ESTADO } from 'constants/constants'
import { updatePropuestaEstado } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
// import { setEstadoCotizacion } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
 
import React, { useContext, useState,useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import { updateEstadoCotizacion } from 'services/Cotizacion'

const ModalMotivoRechazo = ({onClose, isOpened, propuesta}) => {

    const dispatchRedux = useDispatch()
    const {dispatch} = useContext(CotizacionContext)
    // const {json} = state
    const [motivo, setMotivo] = useState('')
    const [guardar, setGuardar] = useState(false)

    useEffect(() => {
        // if(propuesta.motivoRechazo){
        //     setMotivo(json.motivoRechazo)
        //     setGuardar(true)
        // }
    }, [])

    const onSave = () =>{
        var data = {
            idPropuesta: propuesta.id,
            estado: TIPO_ESTADO.RECHAZADA,
            posiblesTransiciones:null,
            motivoRechazada:motivo
            // fechaEnviada:parseISO(fecha)
        }
        updatePropuestaEstado(dispatch, data, dispatchRedux)
        // const data = {
        //     idCotizacion: json.id,
        //     estado: 'Rechazada',
        //     posiblesTransiciones: null,
        //     motivoRechazada:motivo
        // }
        // updateEstadoCotizacion(data)
        //     .then((response) => {
        //         let estadoAux = {...response.estado}
        //         estadoAux.motivoRechazo = response.motivoRechazo
        //         estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
        //         dispatch(setEstadoCotizacion(estadoAux))
        //         setGuardar(true)
        //     })
        //     .catch((error) => {
        //         console.error('error',error)
        //     })
        onClose()
    }
    const onCloseButton = () => {
        onClose()
    }
    const handleMotivo = (e) =>{
        setMotivo(e.target.value)
    }

    return (
        <ModalPortal
            onCancel={onCloseButton}
            onSave={onSave}
            title="Motivo Rechazo Cotizacion"
            isOpened={isOpened}
            onlyShow={guardar}
        >
            <div sx={{width:"500px"}}>
                <TextField
                    multiline
                    fullWidth
                    rows={5}
                    required
                    disabled={guardar}
                    onChange={handleMotivo}
                    value={motivo}
                    label="Motivo"
                />
            </div>
        </ModalPortal>
    )
}

export default ModalMotivoRechazo
