import React, { useContext, useState, useEffect } from 'react'
import { Select, MenuItem, TextField, InputLabel, FormControl, IconButton } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns'
import { Box } from '@mui/system'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import CustomDialog from 'components/customDialog'
import { deleteNorma, resetIntegracion } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import DeleteIcon from '@mui/icons-material/Delete'
import { usePermitirEdicion } from 'hooks/usePermitirEdicion'
import { TIPO_ESTADO } from 'constants/constants'
import { useSelector } from 'react-redux'

const DetalleMotivoNormaAlimentos = (
    {
        value,
        numeroNorma,
        index,
        onChangeNormaMotivo,
        setLimiteNormas,
        noEliminar,
        resetNormasIntegracion,
        modoEdicion,
        allInputs,
    }) => {


    //Listas
    const globalSlice = useSelector(state => state.global)
    const { listaNormasMotivosAlimentos, listaCodigosIAF, listaNormas } = globalSlice
    const { state, dispatch } = useContext(CotizacionContext)
    const { estadoCotizacion, inputNormasMotivos, inputCodigosIAFNACE } = state
    const listaIAFs = inputCodigosIAFNACE.map(i => i.CodigoIaf.id).flat()
    const listaClusterAux = listaCodigosIAF.filter(n => listaIAFs.includes(n.id))
    const listaCluster = listaClusterAux.map(n => n.clusters).flat()

    const [errorFecha, setErrorFecha] = useState(false);

    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const cantidadMantenimientos = (norma) => {

        let normaSeleccionada = listaNormas.find((n => n.id ===  inputNormasMotivos[index].norma)) 
       switch(normaSeleccionada?.jerarquiaAlimentos)
       {
            case 0:
                return [2]
            case 1:
                return [2];
            case 2:
                return [2,5]
            case 3:
                return [2, 5, 8]
            default:
                return [2,5,8];
       }
    }

    //HOOK
    const permitirEdicion = usePermitirEdicion(modoEdicion)

    useEffect(() => {
        let isoDate = parseISO(inputNormasMotivos[index].vencimiento);
        if (differenceInDays(new Date(), isoDate) > 0) {
            setErrorFecha(true);
        } else {
            setErrorFecha(false);
        }
    }, [inputNormasMotivos[index].vencimiento]);


    const handleVencimiento = (event) => {
        let isoDate = parseISO(event.target.value);
        if (differenceInDays(new Date(), isoDate) > 0) {
            setErrorFecha(true);
        } else {
            setErrorFecha(false);
        }
        onChangeNormaMotivo({ name: event.target.name, value: event.target.value }, index)
    }

    const handleCheck = (e, index) => {
        onChangeNormaMotivo({ name: e.name, value: e.checked }, index)
    }

    const handleNumero = (e) => {
        let valor = e.target.value

        onChangeNormaMotivo({ name: 'numero', value: valor }, index)
    }

    const handleCantidadMantenimiento = (e) => {
        let valor = e.target.value
        onChangeNormaMotivo({ name: 'cantidadMantenimientos', value: valor }, index)
    }

    const handleQuitarNorma = (index) => {
        if (inputNormasMotivos[index].integrada === true) {
            if (inputNormasMotivos.filter(n => n.integrada === true).length === 2) {
                dispatch(resetIntegracion())
                resetNormasIntegracion()
            }
        }
        dispatch(deleteNorma(index))
        setLimiteNormas(false)

        setOpenDialog(false)
    }

    const handleButtonQuitarNorma = (index) => {
        setOpenDialog(true)
    }

    const onClusterChange = (event) => {
        onChangeNormaMotivo({ name: 'cluster', value: listaCluster.find(c => c.id == event.target.value) }, index);
    }

    const inputEditable = () => {
        if (permitirEdicion) {
            return true
        } else {
            if (estadoCotizacion.estado === TIPO_ESTADO.BORRADOR || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) {
                return false
            } else {
                return true
            }
        }
    }
    return (
        <>
            <Box display="flex" gap={1} >
                <Box display="flex" flexDirection="column">
                    <FormControl sx={{width:"100%", minWidth:"250px"}}>
                        <InputLabel id="demo-simple-select-label">Mótivo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Motivo"
                            name='motivo'
                            required
                            variant="outlined"
                            disabled={inputEditable()}
                            value={value}
                            onChange={(even) => onChangeNormaMotivo(even.target, index)}
                        >
                            {listaNormasMotivosAlimentos.map((motivo, indexMotivo) =>{
                                    return(<MenuItem key={'motivo' + indexMotivo} value={motivo}>{motivo}</MenuItem>)
                                }
                            )}
                        </Select>
                    </FormControl>
                </Box>
                <Box display="flex" flexDirection="row" gap={1} sx={{width:"100%"}}>
                {
                    value === "MANTENIMIENTO"&&
                    <FormControl sx={{minWidth:"200px"}}>
                        <TextField label="Número Mantenimiento" required  disabled={inputEditable()} onChange={handleNumero} value={inputNormasMotivos[index].numero}></TextField>
                    </FormControl>
                }
                <FormControl sx={{width:"100%"}}>
                    <InputLabel id="label-numero-mantenimiento">Cantidad Mantenimiento</InputLabel>
                    <Select
                        label="Cantidad Mantenimiento"
                        labelId='label-numero-mantenimiento'
                        required
                        name="numero"
                        value={inputNormasMotivos[index].cantidadMantenimientos}
                        onChange={handleCantidadMantenimiento}
                        disabled={inputEditable()}
                    >
                        {
                            cantidadMantenimientos().map((item, index) =>
                                <MenuItem key={'mant' + index} value={item}>{item}</MenuItem>
                            )
                        }
                    </Select>
                </FormControl>
                </Box>
                {
                    !permitirEdicion && estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                    <Box className="ButtonEliminar" display="flex" flexDirection="column" justifyContent="center">
                        <IconButton aria-label="delete" onClick={() => handleButtonQuitarNorma(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            {
                numeroNorma === 50001 &&
                <Box display="flex" gap={1} sx={{ paddingTop: "15px" }}>
                    <FormControl sx={{ width: "50%" }}>
                        <InputLabel id="label-area">Área Técnica / Cluster</InputLabel>
                        <Select
                            label="Área Técnica / Cluster"
                            labelId="label-area"
                            required
                            name="cluster"
                            disabled={permitirEdicion}
                            onChange={onClusterChange}
                            value={inputNormasMotivos[index].cluster ? inputNormasMotivos[index].cluster.id : ''}
                        >
                            {
                                listaCluster.map((item, index) =>
                                    <MenuItem key={'clusters' + index} value={item.id}>{item.numero + " - " + item.nombre}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        name="consumoEnergiaAnual"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].consumoEnergiaAnual}
                        onChange={(event) => onChangeNormaMotivo({ name: 'consumoEnergiaAnual', value: parseInt(event.target.value) }, index)}
                        label="Consumo Anual Energía" />
                    <TextField
                        required
                        name="fuentesDeEnergia"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].fuentesDeEnergia}
                        onChange={(event) => onChangeNormaMotivo({ name: 'fuentesDeEnergia', value: parseInt(event.target.value) }, index)}
                        label="Nº Fuentes Energía" />
                    <TextField
                        required
                        name="usosDeEnergia"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].usosDeEnergia}
                        onChange={(event) => onChangeNormaMotivo({ name: 'usosDeEnergia', value: parseInt(event.target.value) }, index)}
                        label="Nº Usos Energ. Signific." />
                </Box>
            }
            <CustomDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                title={noEliminar ? null : "¿Confirma que desea eliminar la norma?"}
                content={noEliminar ? "No se pueden borrar todas las normas, primero agregue otra." :
                    "Se eliminarán los factores y modificaciones que haya hecho. Si quedara una sola norma en la cotización, se eliminará la integración."}
                handleAgree={noEliminar ? handleCloseDialog : () => handleQuitarNorma(index)}
                onlyAgree={!noEliminar}
            />
        </>
    )
}
export default DetalleMotivoNormaAlimentos
