import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Chip, IconButton } from '@mui/material';
import { getEstadoCliente } from 'services/Organizacion/organizacionService';

export function OrganizacionCard({id, nombre, cuit, onClickEdit}) {

    const [estadoCliente, setEstadoCliente] = useState(null);

    const handleEdit = () => {
        onClickEdit(id);
    }

    async function validarEstadoCliente(cuit){
        try {
            const estado = await getEstadoCliente(cuit);
            setEstadoCliente(estado);
        } catch (error) {
            setEstadoCliente(null);
        }
    }

    useEffect(() => {

        if(cuit){
            validarEstadoCliente(cuit)
        }

    }, [cuit]);

    return (
            <Card style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CardContent>
                    <Typography sx={{fontSize: "0.75em"}} component="h2">
                        {nombre}
                    </Typography>
                    <Box sx={{display: "flex", flexDirection: "row", alignItems: "center"}}>
                        <Typography sx={{width: "120px"}} color="textSecondary">
                            {cuit}
                        </Typography>
                        {!cuit || cuit.trim() == "" && <Chip size='small' label={"NO TIENE CUIT"} sx={{marginLeft: "10px",color: "white", backgroundColor: "black"}} />}
                        {estadoCliente && <Chip size='small' label={estadoCliente.descripcion.toUpperCase()} sx={{marginLeft: "10px",color: "white", backgroundColor: estadoCliente.color}} />}
                    </Box>
                </CardContent>
            <CardActions>
                
            <IconButton aria-label="Ver" onClick={handleEdit}>
                    <VisibilityIcon />
                </IconButton>
            </CardActions>
            </Card>
    )
}