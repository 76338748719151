import { Checkbox, Divider, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import Restricted, { RESTRICT_METHOD } from 'components/Restricted'
import { PERMISOS } from 'constants/constants'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import React, { useContext, useEffect } from 'react'
import { actualizarSitioConcreto } from '../../context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { useDispatch } from 'react-redux'

function InstanciaSitioConcreto({key, sitio, index, sitioConcretoLocal, setSitioConcreto, idInstancia, sitioConcreto}) {

    const {state, dispatch} = useContext(CotizacionContext)
    const dispatchRedux = useDispatch()
    const {estadoCarga, estadoCotizacion} = state
    const instancia = sitioConcretoLocal.instancias.find(i => i.id === idInstancia)
    const tieneEtapas = instancia.instancia.tieneEtapas;
    const maximo = instancia.diasAuditor;

    const handleEtapa1 = (e) => {
        const instanciasAux = [...sitioConcretoLocal.instancias]
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            let etapa1Aux = parseInt(e.target.value)
            if(etapa1Aux){
                if(etapa1Aux + instancia.diasEtapa2 === maximo){
                    instanciasAux[index] = {...instancia, diasEtapa1:etapa1Aux}
                    setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                    // setInstancia({...instanciaLocal, diasEtapa1: etapa1Aux})
                }else{
                    const aux = maximo - etapa1Aux
                    if(aux < 0){
                        instanciasAux[index] = {...instancia, diasEtapa1:maximo, diasEtapa2:0}
                        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                        // setInstancia({...instanciaLocal, diasEtapa1: maximo, diasEtapa2: 0})
                    }else{
                        instanciasAux[index] = {...instancia, diasEtapa1:etapa1Aux, diasEtapa2:aux}
                        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                        // setInstancia({...instanciaLocal, diasEtapa1: etapa1Aux, diasEtapa2: aux})
                    }
                }
            }else{
                instanciasAux[index] = {...instancia, diasEtapa1:0, diasEtapa2:maximo}
                setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                // setInstancia({...instanciaLocal, diasEtapa1: 0, diasEtapa2:maximo})
            }
        }
    }

    const handleEtapa2 = (e) => {
        const instanciasAux = [...sitioConcretoLocal.instancias]
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            let etapa2Aux = parseInt(e.target.value)
            if(etapa2Aux){
                if(etapa2Aux + instancia.diasEtapa1 === maximo){
                    instanciasAux[index] = {...instancia, diasEtapa2:etapa2Aux, diasEtapa1:0}
                    setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                    // setInstancia({...instanciaLocal, diasEtapa2: etapa2Aux, diasEtapa1:0})
                }else{
                    const resto = maximo - etapa2Aux
                    if(resto < 0){
                        instanciasAux[index] = {...instancia, diasEtapa2:maximo, diasEtapa1:0}
                        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                        // setInstancia({...instanciaLocal, diasEtapa2: maximo, diasEtapa1: 0})
                    }else{
                        instanciasAux[index] = {...instancia, diasEtapa2:etapa2Aux, diasEtapa1:resto}
                        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                        // setInstancia({...instanciaLocal, diasEtapa2: etapa2Aux, diasEtapa1: resto})
                    }
                }
            }else{
                instanciasAux[index] = {...instancia, diasEtapa2:0, diasEtapa1:maximo}
                setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
                // setInstancia({...instanciaLocal, diasEtapa2: 0, diasEtapa1:maximo})
            }
        }
    }

    const handleCheck = (e) => {
        const instanciasAux = [...sitioConcretoLocal.instancias]
        if(e.target.checked){
            instanciasAux[index] = {...instancia, requiereVisita:e.target.checked, diasEtapa1: sitio.instancias.find(i => i.numero == instancia.numero).diasAuditorEtapa1, diasEtapa2: sitio.instancias.find(i => i.numero == instancia.numero).diasAuditorEtapa2}
            setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
        }else{
            instanciasAux[index] = {...instancia, requiereVisita:e.target.checked, diasEtapa1: 0, diasEtapa2:0}
            setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
        }
        updateSitioConcreto(instanciasAux)
    }

    const handleCheckSinEtapas = (e) => {
        const instanciasAux = [...sitioConcretoLocal.instancias]
        instanciasAux[index] = {...instancia, requiereVisita: e.target.checked}
        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasAux})
        updateSitioConcreto(instanciasAux)
    }

    const updateSitioConcreto = (instancias) => {
        const data = {
            id:sitioConcretoLocal.id,
            idCotizacion: sitio.idCotizacion,
            idGrupo: sitio.idGrupo,
            idSitio: sitio.id,
            nombre: sitioConcretoLocal.nombre,
            instancias: instancias
        }
        actualizarSitioConcreto(dispatch, data)
    }

    
    const deshabilitarInput = () => {
        if(estadoCotizacion.permiteEdicion){
           return !instancia.requiereVisita
        }else{
            return true;
        }
    }

    const deshabilitarCheck = () => {
        if(estadoCotizacion.permiteEdicion){
            if(estadoCarga.guardando){
                return true;
            }else{
                return false;
            }
        }else{
            return true;
        }
    }

    const esUnicoSitioDelGrupo = () => {
        if(sitio.cantidadDeSitios === 1){
            return true
        }else{
            return false
        }
    }

    useEffect(() => {
    const timeout = setTimeout(
        // updateSitioConcreto(),
        actualizacionEtapas,
        1500)
    return () => {clearTimeout(timeout)}
    }, [instancia.diasEtapa1, instancia.diasEtapa2]);

    const actualizacionEtapas = () => {
        const data = {
            id:sitioConcretoLocal.id,
            idCotizacion: sitio.idCotizacion,
            idGrupo: sitio.idGrupo,
            idSitio: sitio.id,
            nombre: sitioConcretoLocal.nombre,
            instancias:sitioConcretoLocal.instancias
        }
        if(
            (instancia.diasEtapa1 !==0 || instancia.diasEtapa2 !== 0)
            &&
            (sitioConcreto.instancias[index].diasEtapa1 !== 0 || sitioConcreto.instancias[index].diasEtapa2 !== 0)
        ){
            
            if(instancia.diasEtapa1 === sitioConcreto.instancias[index].diasEtapa1 || 
                instancia.diasEtapa2 === sitioConcreto.instancias[index].diasEtapa2){
             }else{
                 actualizarSitioConcreto(dispatch, data)
             }
        }
    }

    
    const renderInputs = () => {
        if(tieneEtapas){
            return(
            <Box key={key} className="sitio-muestra-box">                   
                <Box className="sitio-muestra-etapas" gap={1}>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField 
                    label="E1" 
                    value={instancia.requiereVisita? instancia.diasEtapa1:"0"} 
                    disabled={deshabilitarInput()} 
                    inputProps={
                        { readOnly:esUnicoSitioDelGrupo() }
                    }
                    onChange={handleEtapa1}
                    /></Restricted>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField 
                    label="E2" 
                    value={instancia.requiereVisita? instancia.diasEtapa2:"0"} 
                    disabled={!instancia.requiereVisita} 
                    inputProps={
                        { readOnly:esUnicoSitioDelGrupo() }
                    }
                    onChange={handleEtapa2} 
                    ></TextField></Restricted>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Checkbox checked={instancia.requiereVisita} disabled={deshabilitarCheck()} onChange={handleCheck}/></Restricted>
                    
                </Box>
            </Box>)
        }else{
            return(
                <Box key={key} className="sitio-muestra-box">                   
                    <Box className="sitio-muestra-sin-etapas">
                    <Typography variant="body2">Dias</Typography>
                    <Box className="sitio-muestra-sin-etapas-value">
                        <Typography variant="h6">{instancia.requiereVisita?instancia.diasAuditor:"0"}</Typography>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <Checkbox checked={instancia.requiereVisita} disabled={deshabilitarCheck()} sx={{padding:"3px"}} onChange={handleCheckSinEtapas}></Checkbox>
                        </Restricted>
                    </Box>
                    <Divider />
                    </Box>
                </Box>)
        }
    }
  return (
    <>
    {
        renderInputs()
    }
    </>
  )
}

export default InstanciaSitioConcreto
