import { TableCell, TableRow, Typography } from '@mui/material'
import { Box } from '@mui/system';
import { TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React from 'react'
import { useContext } from 'react';
import { capitalizeFirstLetter, dateToString } from 'utlis/stringUtils';

function TablaConceptosDocumento({ conceptos }) {
    // var listaMotivos = [... new Set(item.nombreMotivo?.split(','))]

    const { state } = useContext(CotizacionContext)
    const { json } = state

    var indiceComentario = 1

    var conceptosFix = conceptos.map(function (concepto, index) {
        var aux = null;
        if (concepto.comentario) {
            aux = { ...concepto, indice: indiceComentario }
            indiceComentario++
        } else {
            aux = { ...concepto }
        }
        return aux;
    })

    const textPeriodo = (concepto) => {
        if (!concepto.habilitado) return "-"
        if (concepto.custom) {
            if(concepto.producto.periodo.includes("ARS") || concepto.producto.periodo.includes("USD")){
                return "-"
            }else{
                return concepto.producto.periodo
            }
        } else {
            if (concepto.arancel.moneda === "USD") {
                return "-"
            } else {
                return concepto.arancel.nombre
            }

        }
    }

    const calcularValor = (concepto) => {
        if (!concepto.habilitado) return "-"
        if (concepto.custom) {
            return concepto.producto.moneda + " " + concepto.producto.valor
        } else {
            return concepto.arancel.moneda + " " + concepto.arancel.valor
        }
    }

    const calcularBonificacion = (concepto) => {
        if (!concepto.habilitado || concepto.bonificacion == 0) return "-"
        if (concepto.esBonificacionPorcentual) {
            return concepto.bonificacion + " %"
        } else {
            return "$ " + concepto.bonificacion
        }
    }

    const calcularRecargo = (concepto) => {
        if (!concepto.habilitado || concepto.recargo == 0) return "-"
        if (concepto.esRecargoPorcentual) {
            return concepto.recargo + " %"
        } else {
            return "$ " + concepto.recargo
        }
    }

    const calcularTotal = (concepto) => {
        if (!concepto.habilitado) return "-"
        if (!concepto.custom) {
            const valorUnitario = concepto.arancel !== null ? concepto.arancel.valor : 0;
            const totalPorDiaAuditor = (Number(concepto.diasOrganizacion)) * Number(valorUnitario);

            const bonificado = concepto.esBonificacionPorcentual ? totalPorDiaAuditor * concepto.bonificacion / 100 : concepto.bonificacion;

            const recargado = concepto.esRecargoPorcentual ? totalPorDiaAuditor * concepto.recargo / 100 : concepto.recargo;

            return totalPorDiaAuditor - bonificado + recargado;
        } else {
            const totalPorProducto = Number(concepto.producto.valor) * Number(concepto.producto.cantidad);

            const bonificado = concepto.esBonificacionPorcentual ? totalPorProducto * concepto.bonificacion / 100 : concepto.bonificacion;

            const recargado = concepto.esRecargoPorcentual ? totalPorProducto * concepto.recargo / 100 : concepto.recargo;

            return totalPorProducto - bonificado + recargado;
        }

    }

    const renderConceptoTabla = (concepto) => {
        const { state } = useContext(CotizacionContext)
        const { json } = state

        if (concepto.realizada) {
            return (

                <TableCell colSpan={8} align="center" >REALIZADA</TableCell>

            )
        }
        if (concepto.aCotizar) {
            return (
                <TableCell colSpan={8} align="center">A COTIZAR</TableCell>
            )
        }
        if (!concepto.realizada && !concepto.aCotizar) {
            return (
                <>
                    {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell><Typography>{concepto.custom ? "-" : concepto.diasIram}</Typography></TableCell>}
                    {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <TableCell><Typography>{concepto.custom ? "-" : concepto.diasOrganizacion}</Typography></TableCell>}
                    <TableCell><Typography>{calcularValor(concepto)}</Typography></TableCell>
                    <TableCell><Typography>{textPeriodo(concepto)}</Typography></TableCell>
                    <TableCell><Typography>{calcularBonificacion(concepto)}</Typography></TableCell>
                    <TableCell><Typography>{calcularRecargo(concepto)}</Typography></TableCell>
                    <TableCell><Typography>{calcularTotal(concepto)}</Typography></TableCell>
                </>
            )
        }
    }

    const renderTituloConcepto = (concepto) => {
        var listaMotivos = [... new Set(concepto.nombreMotivo?.split(','))]
        return (
            <>
                <TableCell>
                    <Box display="flex" flexDirection="row">
                        <Box display="flex" flexDirection="column" sx={{ width: "150px" }}>
                            <Typography>{concepto.nombre}</Typography>
                            {
                                listaMotivos.map((motivo, index) =>
                                    <Typography key={'c' + index} variant='caption'>{"- " + capitalizeFirstLetter(motivo.toLowerCase())}</Typography>
                                )
                            }
                            <Typography>{ }</Typography>
                        </Box>
                        <Box display="flex" alignSelf="center">
                            {concepto.comentario &&
                                <Typography sx={{ paddingLeft: "5px" }} variant='caption'>({concepto.indice})</Typography>
                            }
                        </Box>
                    </Box>

                </TableCell>
            </>
        )
    }

    return (
        <>
            {
                conceptosFix.map((concepto, index) =>
                    <TableRow key={"concepto" + index}>
                        {
                            renderTituloConcepto(concepto)
                        }

                        {
                            renderConceptoTabla(concepto)
                        }

                    </TableRow>
                )
            }

        </>
    )
}

export default TablaConceptosDocumento