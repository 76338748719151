import { getDataAsync, putDataAsync, postDataAsync, deleteDataAsync } from "services/httpRequest";

export const _getPropuestaById = async(id) => {
    const response = await getDataAsync('propuesta/'+ id);
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _postPropuesta = async(data) => {
    const response = await postDataAsync('propuesta', data);
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _postDuplicarPropuesta = async(data) => {
    const response = await postDataAsync("propuesta/"+ data.idPropuesta +"/duplicar");
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _postConcepto = async(data) => {
    const response = await postDataAsync('propuesta/' + data.idPropuesta + '/concepto', data.concepto)
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _putConcepto = async(data) => {
    const response = await putDataAsync('propuesta/' + data.idPropuesta + '/concepto/' + data.concepto.id, data.concepto)
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _deleteConcepto = async(data) => {
    const response = await deleteDataAsync('propuesta/'+ data.idPropuesta+'/concepto/'+data.conceptoId)
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _ordenarConceptos = async(idPropuesta, ordenConceptos) => {
    const response = await postDataAsync(`propuesta/${idPropuesta}/concepto/orden`, ordenConceptos); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _getCursos = async() => {
    const response = await getDataAsync(`cursos`); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _getProductos = async() => {
    const response = await getDataAsync(`propuesta/producto`); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _getProductosProducto = async(idCot) => {
    const response = await getDataAsync(`propuesta/producto?cotizacionId=${idCot}`); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _getArancelesPrecioUnitario = async() => {
    const response = await getDataAsync(`propuesta/producto`); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _getDocumento = async(idPropuesta) => {
    const response = await postDataAsync(`propuesta/${idPropuesta}/documento`); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _putPropuestaEstado = async(data) => {
    const response = await putDataAsync('propuesta/'+ data.idPropuesta + '/estado',data); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _putPropuestaEstadoEnAprobacion = async(data, idPropuesta) => {
    const response = await putDataAsync('propuesta/'+ idPropuesta + '/estadoEnAprobacion',data); 
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}

export const _putPropuesta = async(idPropuesta, actualizarPropuesta) => {
    const response = await putDataAsync('propuesta/'+ idPropuesta, 
            actualizarPropuesta
        );
    if(response.status === 200 || response.status === 201){
        return response.data
    }
    else{
        throw response
    }
}
