import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, FormControl, InputLabel, LinearProgress, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSitio from 'components/AccordionSitio';
import { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import ModalSitio from 'components/ModalSitio';
import { eliminarGrupo, setEstadoCarga, updateGrupoSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import CustomDialog from 'components/customDialog';
import { PERMISOS, TIPO_ESTADO, TIPO_GRUPO_SITIO, TIPO_SOLICITUD, TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import AddIcon from '@mui/icons-material/Add';
//style
import 'scss/components/paper-input-normas.scss';
import 'scss/components/accordionGrupoSitio.scss';
import LabelCustom from 'components/labelCustom';
import { makeStyles } from '@mui/styles';
import { capitalizeFirstLetter } from 'utlis/stringUtils';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import NormaChip from 'components/NormaChip';
import AccordionNormaAlimentos from 'components/AccordionNormaAlimentos';
import { id } from 'date-fns/locale';
import { useDispatch } from 'react-redux';


const useStyles = makeStyles(theme => ({
    Accordion: {
      padding: 0,
      boxShadow: `0px 0px 0px 0px`,
      backgroundColor:`#e4e4e4`,
    },
    AccordionExpanded:{
        padding: 0,
        boxShadow: `0px 0px 0px 0px`,
    }
  }));

function AccordionGrupoSitios({grupo}) {

    const dispatchRedux = useDispatch()

    const {state, dispatch } = useContext(CotizacionContext)
    const {json, estadoCotizacion, estadoCarga} = state
    const {tipo} = json

    const [expanded, setIsExpanded] = useState(false)

    const handleChange = () => (event, isExpanded) => {
        setIsExpanded(isExpanded)
      };
    
    const renderSitiosMinimos = (label, sitiosMinimos) => {
        if( tipo === TIPO_SOLICITUD_ALIMENTOS){

        }else{
            if(sitiosMinimos){
                return( <Box className="grupo-sitio-resumen-minimos">
                            <LabelCustom label={label} value={sitiosMinimos}></LabelCustom>
                        </Box>)
                }
            else{
                if(sitiosMinimos === 0){
                    return(
                        <Box className="grupo-sitio-resumen-minimos">
                            <LabelCustom label={label} value={sitiosMinimos}></LabelCustom>
                        </Box>
                    )
                }
            }
        }
    }
  
    const renderCer = grupo.sitiosMinimosCertificacion !== null;
    const renderReCer = grupo.sitiosMinimosRecertificacion !== null;
    const renderMan = grupo.sitiosMinimosMantenimiento !== null;

    //Modal
    const [modalState, setModalState] = useState({opened: false});
    const handleModal = () => setModalState({opened: !modalState.opened})

    //INICIO HANDLE NOMBRE Y UPDATE
    const [nombreLocal, setNombreLocal] = useState(grupo.nombre)
    const [errorNombre, setErrorNombre] = useState(false)
    const handleNombre = (e) => {
        setNombreLocal(e.target.value)
        setErrorNombre(json.gruposDeSitios.some(g => g.nombre.toUpperCase() === e.target.value.toUpperCase()))
    }

    useEffect(() => {
        const timeout = setTimeout(
            actualizarNombre,
            2000)
            return () => {
                clearTimeout(timeout)
            }
    }, [nombreLocal])

    const actualizarNombre = () => {
        if(!errorNombre){
            const data = {
                idCotizacion:json.id,
                grupo:{...grupo, nombre: nombreLocal}
            }
            if(nombreLocal === grupo.nombre){
            }
            else{
                updateGrupoSitios(dispatch, data)
            }
        }//todo
    }
    //FIN HANDLE NOMBRE Y UPDATE

    const handleCoeficienteCertificacion = (e) => {
        const data = {
            idCotizacion: json.id,
            grupo: {...grupo, coeficienteMuestreoCertificacion:e.target.value}
        }
        updateGrupoSitios(dispatch, data)
    }
    const handleCoeficienteRecertificacion = (e) => {
        const data = {
            idCotizacion: json.id,
            grupo: {...grupo, coeficienteMuestreoRecertificacion:e.target.value}
        }
        updateGrupoSitios(dispatch, data)
    }
    const handleCoeficienteMantenimiento = (e) => {
        const data = {
            idCotizacion: json.id,
            grupo: {...grupo, coeficienteMuestreoMantenimiento:e.target.value}
        }
        updateGrupoSitios(dispatch, data)
    }
    const handleTipo = (e) => {
        const data = {
            idCotizacion: json.id,
            grupo:{...grupo, tipo:e.target.value}
        }
        updateGrupoSitios(dispatch, data)
    }

    //funcion de habilitacion coeficientes y saving
    const inHabilitado = () => {
        if(grupo.tipo === TIPO_GRUPO_SITIO.PROPIOS){
            return true;
        }
        else{
            return estadoCarga.guardando;
        }
    }

    const handleEliminarGrupo = () => {
        eliminarGrupo(dispatch,{idCotizacion:json.id, idGrupo:grupo.id})
        handleCloseDialog()
    }
    //Dialog
    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const renderTipo = () => {
        if(tipo !== TIPO_SOLICITUD_ALIMENTOS){
            if(grupo.tipo !== TIPO_GRUPO_SITIO.CASA_CENTRAL){
                if(expanded){
                    return (<Box className="grupo-sitio-resumen-tipo"></Box>)
                }else{
                    return( <Box className="grupo-sitio-resumen-tipo">
                            <Typography variant='body2'>{grupo.tipo}</Typography>
                            </Box>)
                }
            }else{
                return (<Box className="grupo-sitio-resumen-tipo"></Box>)
            }
        }
    }

    const [childExpanded, setChildExpanded] = useState(false)
    const commonStyles = {
        border: 1,
      };

    const inputEditableEnviada = (permiteEnviada) => {
        if(permiteEnviada){
            if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA){
                return estadoCarga.guardando
            }else{
                return true
            }
        }else{
            if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR ){
                return estadoCarga.guardando
            }else{
                return true
            }
        }
    }

    const handleDiasAuditor = (e) => {
        setDiasAuditor(e.target.value)
    }
    
    //INICIO HANDLE DIAS AUDITOR ALIMENTOS
    const obtenerDiasAuditor = (sitios) => {
        let sitio;
        if(sitios === null || sitios.length === 0){
            return 0;
        }else{
            sitio = sitios[0]
            if(sitio){
                if(sitio.diasCertificacion){
                    return sitio.diasCertificacion/2
                }
                else if(sitio.diasRecertificacion){
                    return sitio.diasRecertificacion
                }
                else{
                    return sitio.diasMantenimiento
                }
            }
        }
    }

    
    const [diasAuditor, setDiasAuditor] = useState(obtenerDiasAuditor(grupo.sitios))
    
    useEffect(() => {
        const timeout = setTimeout(
            actualizarDias,
            2000)
            return () => {
                clearTimeout(timeout)
            }
    }, [diasAuditor])

    const actualizarDias = () => {
        if(diasAuditor !== "0"){
            const data = {
                idCotizacion:json.id,
                grupo:{...grupo, nombre: nombreLocal}
            }
            if(nombreLocal === grupo.nombre){
            }
            else{
                // updateGrupoSitios(dispatch, data)
            }
        }
    }
    
    const renderDias = (sitio) => {
        const tieneEtapas = sitio.instancias.some(i => i.tieneEtapas)
        if(sitio.diasCertificacion){
            if(tieneEtapas){
                return(
                <>
                <TextField
                label="Etapa 1"
                value={sitio.diasCertificacion/2}
                disabled
                variant='filled'
                />
                <TextField
                label="Etapa 2"
                value={sitio.diasCertificacion/2}
                disabled
                variant='filled'
                />
                <TextField
                label="Mantenimientos"
                value={sitio.diasMantenimiento}
                disabled
                variant='filled'
                />
                </>
                )
            }else{
                return(<>
                <TextField
                label="Certificacion"
                value={sitio.diasCertificacion/2}
                disabled
                variant='filled'
                /><TextField
                label="Mantenimientos"
                value={sitio.diasMantenimiento}
                disabled
                variant='filled'
                />
                </>)
                
            }
        }
        else if(sitio.diasRecertificacion){
            return(<><TextField
                label="Recertificacion"
                value={sitio.diasRecertificacion}
                disabled
                variant='filled'
                />
                <TextField
                label="Mantenimientos"
                value={sitio.diasMantenimiento}
                disabled
                variant='filled'
                />
                </>)
        }
        else{
           return( <TextField
            label="Mantenimientos"
            value={sitio.diasMantenimiento}
            disabled
            variant='filled'
            />)
        }
    }
    const renderDetalleAlimentos = () => {
        const sitio = grupo.sitios[0]
        if(grupo.tipo === TIPO_GRUPO_SITIO.CASA_CENTRAL){
            
            return(
                <>
                {
                        sitio.normas.sort((a, b) => a.cotizacionNorma.norma.jerarquiaAlimentos - b.cotizacionNorma.norma.jerarquiaAlimentos).map((norma, index) =>
                        <AccordionNormaAlimentos norma={norma} index={index} idGrupo={grupo.id} idSitio={sitio.id}></AccordionNormaAlimentos>
                        )
                }
                </>
            )
            
        }else{

            return(
                <>
                <Box 
                display="flex" 
                flexDirection="row" 
                gap={3}
                sx={{paddingBottom:"20px"}}>
                     <TextField 
                        label="Nombre" 
                        error={errorNombre}
                        value={nombreLocal}  
                        onChange={handleNombre}
                        disabled={inputEditableEnviada(true)}
                        />
                        <TextField
                        label="Dias Auditor por Sitio"
                        name="certificacion"
                        type='number'
                        value={diasAuditor}
                        onChange={handleDiasAuditor}
                        />
                        
                        
                        
                 
                </Box>
                <Box display="flex" gap={2} sx={{paddingBottom:"10px"}}>
                    {
                        renderDias(sitio)
                    }
                        
                </Box>
                <Button variant='contained' color="error" onClick={() => setOpenDialog(true)}>ELIMINAR SITIO</Button>
                </>
                
            )
        }
    }
    //FIN DIAS AUDITOR ALIMENTOS

    const classes = useStyles();
    const getTitulo = (nombre) => {
        if(tipo === TIPO_SOLICITUD_ALIMENTOS){
                return "¿Confirma que desea eliminar el sitio "+nombre+" ?"
        }else{
            return "¿Confirma que desea eliminar el grupo "+nombre+" ?"
        }
        
    }
    return (
    <Box className='grupo-sitio-accordion' sx={{ ...commonStyles, borderRadius: '4px', borderColor:"#3a3a3a" }} >
        <Accordion 
            onChange={handleChange()} 
            className={(expanded && !childExpanded) ? classes.AccordionExpanded: classes.Accordion} 
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>} className={classes.AccordionSummary}>
                <Box className="grupo-sitio-resumen-nombre">
                    <Typography variant={!expanded?"":"h6"}  fontWeight="fontWeightBold">{capitalizeFirstLetter(grupo.nombre)}</Typography>
                </Box>
                {
                    renderTipo()
                }
                <Box className="grupo-sitio-resumen-labels">
                    {tipo !== TIPO_SOLICITUD_ALIMENTOS &&
                    <Box className="grupo-sitio-resumen-totales">
                        <LabelCustom label={"Sitios Totales"} value={grupo.sitiosTotales}></LabelCustom>
                    </Box>
                    }
                    {
                    renderSitiosMinimos("Mínimo Certificación",grupo.sitiosMinimosCertificacion)
                    }
                    {
                    renderSitiosMinimos("Mínimo Recertificación", grupo.sitiosMinimosRecertificacion)
                    }
                    {
                    renderSitiosMinimos("Mínimo Mantenimiento", grupo.sitiosMinimosMantenimiento)
                    }    
                </Box>
            </AccordionSummary>
            <AccordionDetails>
                {
                    tipo === TIPO_SOLICITUD_ALIMENTOS ?
                    <>
                    {renderDetalleAlimentos()}
                    </>
                    :
                    <>
                    <Box 
                    display="flex" 
                    flexDirection="row" 
                    sx={{paddingBottom:"20px"}}>
                        <div style={{width:"300px"}}>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                        <TextField 
                        label="Nombre" 
                        error={errorNombre}
                        value={nombreLocal}  
                        onChange={handleNombre}
                        disabled={inputEditableEnviada(true)}
                        />
                        </Restricted>
                        </div>
                    
                    <div className='grupo-sitio-detail-tipo'>
                    {
                    grupo.tipo !== TIPO_GRUPO_SITIO.CASA_CENTRAL &&
                    <FormControl>
                    <InputLabel id='label-tipo'>Tipo</InputLabel>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <Select 
                                labelId='label-tipo'
                                label="Tipo"
                                name="tipo"
                                disabled={inputEditableEnviada()}
                                value={grupo.tipo}
                                onChange={handleTipo}
                                >
                                    <MenuItem value={"TEMPORALES"}>Temporal</MenuItem>
                                    <MenuItem value={"PROPIOS"}>Propio</MenuItem>
                            </Select>
                        </Restricted>
                    </FormControl>
                    }
                     </div>
                        {
                            renderCer &&
                            <div className='grupo-sitio-detail-minimos'>
                            <FormControl fullWidth>
                                <InputLabel id='label-coeficiente-cer'>Coeficiente Certificacion</InputLabel>
                                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                                <Select 
                                    labelId='label-coeficiente-cer'
                                    label="Coeficiente Certificacion"
                                    name="coecertificacion"
                                    disabled={inHabilitado() || inputEditableEnviada()}
                                    value={grupo.coeficienteMuestreoCertificacion}
                                    onChange={handleCoeficienteCertificacion}
                                    >
                                        <MenuItem value={0.3}>0.3</MenuItem>
                                        <MenuItem value={1}>1</MenuItem>
                                </Select>
                                </Restricted>
                            </FormControl>
                            </div>
                            
                        }
                        {
                            renderReCer &&
                            <div className='grupo-sitio-detail-minimos'>
                            <FormControl fullWidth disabled={estadoCarga.guardando}>
                                <InputLabel id='label-coeficiente-cer'>Coeficiente Recertificacion</InputLabel>
                                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                                <Select 
                                    labelId='label-coeficiente-cer'
                                    label="Coeficiente Certificacion"
                                    name="coecertificacion"
                                    disabled={inHabilitado() || inputEditableEnviada()}
                                    value={grupo.coeficienteMuestreoRecertificacion}
                                    onChange={handleCoeficienteRecertificacion}
                                    >
                                        <MenuItem value={0.3}>0.3</MenuItem>
                                        <MenuItem value={0.8}>0.8</MenuItem>
                                </Select>
                                </Restricted>
                            </FormControl>
                            </div>
                        }
                        {
                            renderMan &&
                            <div className='grupo-sitio-detail-minimos'>
                            <FormControl fullWidth disabled={estadoCarga.guardando}>
                                <InputLabel id='label-coeficiente-man'>Coeficiente Mantenimiento</InputLabel>
                                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                                <Select 
                                    labelId='label-coeficiente-man'
                                    label="Coeficiente Mantenimiento"
                                    name="coemantenimiento"
                                    disabled={inHabilitado() || inputEditableEnviada()}
                                    value={grupo.coeficienteMuestreoMantenimiento}
                                    onChange={handleCoeficienteMantenimiento}
                                    >
                                        <MenuItem value={0.6}>0.6</MenuItem>
                                        <MenuItem value={0.3}>0.3</MenuItem>
                                </Select>
                                </Restricted>
                            </FormControl>
                            </div>
                        }
                    </Box>
                    {
                        grupo.sitios &&
                        <Box sx={{paddingTop:"10px", paddingBottom:"10px"}}>
                        {
                            grupo.sitios.map((sitio, index) =>
                            <AccordionSitio 
                                key={'indexsitio'+index} 
                                sitio={sitio} 
                                grupo={grupo}
                                setChildExpanded={setChildExpanded}
                                />
                            )
                        }
                        </Box>
                    }
                    {
                        estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                        <Box display="flex" justifyContent={"space-between"}>
                            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                            <Button variant='outlined'  onClick={handleModal}>Agregar sitio al grupo {grupo.nombre}</Button>
                            <Button variant='contained' color="error" onClick={() => setOpenDialog(true)}>ELIMINAR GRUPO</Button>
                            </Restricted>
                        </Box>                
                    }
                    </>
                }
                
            </AccordionDetails>
        </Accordion>

        <ModalSitio onClose={() => handleModal()} isOpened={modalState.opened} idGrupo={grupo.id}/>
        <CustomDialog
            openDialog={openDialog} 
            handleCloseDialog={handleCloseDialog} 
            title={getTitulo(grupo.nombre)}
            content={"Se eliminarán los datos y modificaciones que haya hecho."}
            handleAgree={() => handleEliminarGrupo()}
            onlyAgree={true}
                />
    </Box>
    )
}

export default AccordionGrupoSitios
