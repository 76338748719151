import { Autocomplete, Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useContext } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import { useSelector } from "react-redux";

function InputServicios({ servicios, serviciosSeleccionados, servicio, onChange, onDelete, modoEdicion }) {

    const globalSlice = useSelector(state => state.global)
    const { listaNormasMotivosProducto } = globalSlice

    const { state } = useContext(CotizacionContext);
    const { estadoCarga } = state;

    function onChangeServicio(_, s) {
        const idServicio = s.id;
        const servicioSeleccionado = servicios.find(s => s.id === idServicio);

        onChange({ ...servicioSeleccionado, motivos: servicio.motivos });
    }

    function onChangeMotivos(event) {
        const motivos = event.target.value;
        onChange({ ...servicio, motivos });
    }

    return (
        <Box className="input-servicios-wrapper">
            <Box className="inputs-servicios">
            <Autocomplete className={["form-select-motivos", "input-servicios-motivos"]}
                    disablePortal
                    onChange={onChangeServicio}
                    id="inputServicios"
                    getOptionDisabled={(option) => serviciosSeleccionados.some(ss => ss.id === option.id)}
                    disabled={!modoEdicion}
                    value={servicio.nombre}
                    options={servicios.map(s => ({ id: s.id, label: s.nombre }))}
                    renderInput={(params) => <TextField {...params} label="Servicio" />}
                />
                <FormControl className={["form-select-motivos", "input-servicios-motivos"]}>
                    <InputLabel id="motivos-servicio-select-label">Motivos</InputLabel>
                    <Select
                        label="Motivos"
                        multiple={true}
                        labelId="motivos-servicio-select-label"
                        id="Motivos"
                        name='Motivos'
                        required
                        disabled={!modoEdicion}
                        onChange={onChangeMotivos}
                        value={servicio.motivos ? servicio.motivos : []}
                    >
                        {listaNormasMotivosProducto.map((motivo, indexMotivo) =>
                            <MenuItem key={'motivoServicio' + indexMotivo}
                                // disabled={serviciosSeleccionados.some(ss => ss.id === motivo.id)}
                                value={motivo}>
                                {motivo}
                            </MenuItem>
                        )}
                    </Select>
                </FormControl>
            </Box>
            {modoEdicion &&
                <IconButton
                    onClick={onDelete}
                    color="primary"
                    disabled={estadoCarga.guardando}
                    aria-label="quitar servicio">
                    <DeleteIcon />
                </IconButton>
            }
        </Box>
    );
}

export default InputServicios;