export const RUTAS = Object.freeze({
    AZURE_REDIRECT:                    "/auth/redirect",
    HOME :                              "/",
    PROPUESTAECONOMICA:                 "/solicitud/:idSolicitud/cotizacion/:idCotizacion/propuesta/:idPropuesta",     
    COTIZACION:                         "/solicitud/:idSolicitud/cotizacion/:idCotizacion",   
    INDEX_SOLICITUD:                    "/solicitud",
    SOLICITUD:                          "/solicitud/:idSolicitud",
    NUEVASOLICITUD:                     "/nuevasolicitud",
    NUEVACOTIZACION:                    "/solicitud/:idSolicitud/nuevaCotizacion",
    DOCUMENTO:                          "/solicitud/:idSolicitud/cotizacion/:idCotizacion/propuesta/:idPropuesta/documento",
    ENVIODOCUMENTO:                     "/envioDocumento",
    ALIMENTOS_SOLICITUD:                "/alimentos/solicitud/:idSolicitud",
    ALIMENTOS_SOLICITUD_NUEVA:          "/alimentos/solicitud/nueva",
    ALIMENTOS_COTIZACION:               "/alimentos/solicitud/:idSolicitud/cotizacion/:idCotizacion",
    ALIMENTOS_COTIZACION_NUEVA:         "/alimentos/solicitud/:idSolicitud/cotizacion/nueva",
    ALIMENTOS_PROPUESTA_ECONOMICA:      "/alimentos/solicitud/:idSolicitud/cotizacion/:idCotizacion/propuesta/:idPropuesta", 
});
