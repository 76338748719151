import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import usePermisos from 'hooks/usePermisos';
import React, { cloneElement, useContext } from 'react';

export const RESTRICT_METHOD = {
    HIDE: "HIDE",
    DISABLE: "DISABLE"
};

export default function Restricted({ to, children, method = RESTRICT_METHOD.HIDE }) {
    const permisos = usePermisos();
    const {state} = useContext(CotizacionContext)
    const {json: cotizacion} = state
    const tipo = cotizacion.tipo;

    let isRestricted = permisos.includes(to);
    if(tipo){
        if(tipo.includes("ALIMENTOS")){
            if(!permisos.includes("RDAEscritorAlimentos")) isRestricted = true;
        }
    
        if(tipo.includes("GESTION")){
            if(!permisos.includes("RDAEscritor")) isRestricted = true;
        }
    
        if(tipo.includes("PRODUCTO")){
            if(!permisos.includes("RDAEscritorProducto")) isRestricted = true;
        }
    }
    

    if(!isRestricted){
        return <>{children}</>;
    }

    switch(method){
        case RESTRICT_METHOD.HIDE:
            return null;
        case RESTRICT_METHOD.DISABLE:
            return cloneElement(children, { disabled: true });
        default:
            return null;
    }
}
