import { TIPO_ESTADO } from 'constants/constants';

export const classes = {
    multisite: {
        ml: 'auto',
        display: 'flex',
        justifyContent: 'flex-end'
    },
    root: {
        position: 'relative'
    },
    state: {
        display: 'flex',
        justifyContent: 'flex-end'
    }
}

export const getColorEstadoCotizacion = (estado) => {
    switch (estado) {
        case TIPO_ESTADO.ACEPTADA: {
            return 'success';
        }
        case TIPO_ESTADO.ENVIADA: {
            return 'warning';
        }
        case TIPO_ESTADO.DESCARTADA: {
            return 'error';
        }
        case TIPO_ESTADO.RECHAZADA: {
            return 'error';
        }
        default: {
            return;
        }
    }
}