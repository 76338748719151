import { AppBar, Toolbar }  from '@mui/material';
import Brand from './brand';
import MenuSolicitud from './menuSolicitud';
import Section from './section';
import User from './user';
import 'scss/components/header.scss';

const Header = () => {

   return (
      <AppBar position="fixed" color="inherit" className="header">
         <Toolbar className="toolbar">
            <Brand />
            <Section />
            <MenuSolicitud />
            <User />
         </Toolbar>
      </AppBar>
   );
};

export default Header;
