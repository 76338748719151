import { useContext, useEffect, useState } from 'react';
import { Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { deleteNace, setNuevoSubnace } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';

import AlimentosNacesAddSubnace from './addSubnace';
import AlimentosNacesCategoria from './categoria';
import AlimentosNacesSubnace from './subnace';
import CustomDialog from 'components/customDialog';
import { classes } from './styles'

const AlimentosNacesInput = (props) => {
   const { state, dispatch } = useContext(CotizacionContext);
   const {json } = state
   const { data, index, many, naces, onChangeNace, onChangeSubnace, noEliminar, permiteEdicion } = props;
   const { id, subnaces } = data;
   const [subnacesByNace, setSubnacesByNace] = useState([]);
   const [categoriaByNace, setCategoriaByNace] = useState();
   const [enableAdd, setEnableAdd] = useState(false);
   const [openDialog, setOpenDialog] = useState(false)
   const hasSubnaces = subnacesByNace.length > 0 ? true : false;

   useEffect(() => {
      if (!id) {
         setSubnacesByNace([]);
         setCategoriaByNace();
      }

      if (id && naces.length > 0) {
         getSubnacesByNace(id);
         getCategoriaByNace(id);
      }
   }, [naces, id]);

   const getSubnacesByNace = (value) => {
      const subnaces = naces.filter(nace => nace.id === value)[0].subNaces;
      setSubnacesByNace(subnaces);
   }

   const getCategoriaByNace = (value) => {
      
      const dataFiltered = naces.filter(nace => nace.id === value)[0];
      const categoriaFixed = {
         descripcion:dataFiltered.categoria +" - "+ dataFiltered.categoriaDescripcion,
         subcategorias:dataFiltered.subNaces
      }
      // const categoria = naces.filter(nace => nace.id === value)[0].categoria;
      setCategoriaByNace(categoriaFixed);
   }

   const handleChange = (event) => {
      const nace = event.target.value;
      getSubnacesByNace(nace);
      getCategoriaByNace(nace);
      onChangeNace(index, nace);
   }

   const handleChangeSubnace = (index, subnace) => {

      onChangeSubnace(index, subnace, id);

      if (subnacesByNace.length > subnaces.length) {
         setEnableAdd(true)
      }
   }

   const onAddSubnace = () => {
      dispatch(setNuevoSubnace(index));
      setEnableAdd(false);
   }

   const onDelete = () => {
      dispatch(deleteNace(index));
   }

   const handleOpenDialog = () => {
      setOpenDialog(true)
   }

   const handleCloseDialog = () => {
      setOpenDialog(false)
   }

   const getEstado = () => {
      return permiteEdicion
   }

   const renderNace = (value) => {
      if(value){
         const nace = naces.find((nace) => nace.id === value)
         if(!nace){
            return "sin opciones"
         }
         return (
            <Box display="flex" flexDirection="column">
               <div>{`${nace.codigo} - ${nace.descripcion}`}</div>
            </Box>
         )
      } else{
         return 'Seleccione una opción';
      }
   }


   return (
      <>
         <Grid container>
            <Grid item xs={6}>
               <FormControl fullWidth>
                  <InputLabel id='label-nace'>NACE</InputLabel>
                  <Select
                     labelId="label-nace"
                     label="NACE"
                     fullWidth
                     name='CodigoNace'
                     required
                     onChange={(event) => handleChange(event)}
                     disabled={getEstado()}
                     value={id}
                     renderValue={renderNace}
                  >
                     {naces.filter(n => n.tipo === "ALIMENTOS").map((nace, index) => (
                        <MenuItem key={'nace' + index} value={nace.id}>
                           <Box display="flex" flexDirection="column">
                              <div>{`${nace.codigo} - ${nace.descripcion}`}</div>
                              <Typography variant='caption' fontWeight="bold">categoria: {nace.categoria ? nace.categoria : "-"}</Typography>
                           </Box>
                        </MenuItem>
                     ))}
                  </Select>
               </FormControl>
            </Grid>

            <Grid item xs={6} sx={many ? classes.subnacesDelete : ''}>
               {hasSubnaces &&
                  <Box sx={classes.subnaces}>
                     {subnaces.map((subnace, index) =>
                        <Box key={"subnace" + index} sx={classes.item}>
                           <AlimentosNacesSubnace
                              data={subnace}
                              index={index}
                              estado={getEstado()}
                              subnaces={subnacesByNace}
                              onChange={(index, subnace) => handleChangeSubnace(index, subnace)}
                           />
                        </Box>
                     )
                     }
                     {enableAdd &&
                        <Box sx={classes.item}>
                           <AlimentosNacesAddSubnace
                              onAdd={onAddSubnace}
                           />
                        </Box>
                     }
                  </Box>
               }
               {many &&
                  <Box sx={classes.delete}>
                     <IconButton onClick={handleOpenDialog}>
                        <DeleteIcon />
                     </IconButton>
                  </Box>
               }
            </Grid>
         </Grid>
         {categoriaByNace &&
            <AlimentosNacesCategoria
               data={categoriaByNace}
            />
         }
         <CustomDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            title={noEliminar ? null : "¿Confirma que desea eliminar el nace?"}
            content={noEliminar ? "No se pueden borrar todos los naces" :
               "Se eliminarán los factores y modificaciones que haya hecho."}
            handleAgree={noEliminar ? handleCloseDialog : () => onDelete()}
            onlyAgree={!noEliminar}
         />
      </>
   )
}

export default AlimentosNacesInput;
