import { Box, Checkbox, FormControl, Grid, InputLabel, ListItemText, MenuItem, Select, Typography } from '@mui/material'
import ModalPortal from 'components/modalPortal'
import { setInputIntegracion, updateSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import React, { useContext, useState, useEffect} from 'react'
import { getPorcentajeIntegracion } from 'services/Cotizacion'
import InfoIcon from '@mui/icons-material/Info';
import { TIPO_ESTADO } from 'constants/constants'
import { setError } from 'redux/slices/globalSlice'
import { useDispatch, useSelector } from 'react-redux'

const ModalIntegracion = ({onClose, isOpened, integracion, isSitio, sitio, isGeneral}) => {
    
    //context global
    const dispatchRedux = useDispatch()
    const globalSlice = useSelector(state => state.global)
    const {coeficientesIntegracion,listaMotivosIntegracion} = globalSlice
    //context cotizacion
    const {state, dispatch} = useContext(CotizacionContext)
    const {json, estadoCotizacion} = state

    const sitioIntegracionDesvinculada = (element, index, array) => {
        if(element.sitios){
            const sitioIntegracion = element.sitios.filter(s => s.infoIntegracion !== null)
            return sitioIntegracion.some(s => s.infoIntegracion.desvinculada)
        }
        else{
            return false
        }
    }
    const existeIntegracionDesvinculada = json.gruposDeSitios.some(sitioIntegracionDesvinculada)

    //Local
    const [integracionLocal, setIntegracion] = useState(integracion)
    
    useEffect(() => {
        setIntegracion(integracion)
    }, [integracion]);

    const handleChangeCoeficiente = (event) => {
        setIntegracion({...integracionLocal, [event.target.name]:event.target.value })
    }

    const handleChangeMotivos = (event) => {
        const motivosFiltered = listaMotivosIntegracion.filter(l => event.target.value.some(v => v === l.id))
        setIntegracion({...integracionLocal, motivosOrganizacion: motivosFiltered})
    }

    const onSave = (e) => {
        e.preventDefault()
        getPorcentajeIntegracion(integracionLocal.coeficienteIRAM, integracionLocal.coeficienteOrganizacion)
        .then(response =>{
            if(isSitio){
                const data = {
                    idCotizacion: json.id,
                    idGrupo: sitio.idGrupo,
                    sitio: {...sitio, infoIntegracion: {...integracionLocal, porcentajeReduccion:response}}
                }
                
                updateSitios(dispatch, data)
            }
            else{
                dispatch(setInputIntegracion({...integracionLocal, porcentajeReduccion: response}))
            }
        })
        .catch(error =>{
            dispatchRedux(setError({estado:true, tipo:error.status, descripcion: error.data.details?error.data.details:"sin detalles"}))
            console.error("ERROR GET PORCENTAJE INTEGRACION", error)
        })
        onClose()
    }

    const onCloseButton = () => {
        setIntegracion(integracion)
        onClose()
    }

    const idsMotivos = (e) => {
        const motivosFiltered = listaMotivosIntegracion.filter(l =>e.some(v => v === l.id))
        return(<div>
            {
                motivosFiltered.map(m => m.nombre).join(', ')
            }
            </div>)
    }

    return (
        <>
            <ModalPortal    
                onCancel={onCloseButton} 
                onSave={onSave}
                title="Integración"
                isOpened={isOpened}
            >
                <Box sx={{width:"650px"}}>
                    <Grid container
                        direction="row"
                        justifyContent="space-around"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="label_coeficiente_organizacion">Coeficiente Organizacion</InputLabel>
                                <Select
                                labelId="label_coeficiente_organizacion"
                                label="Coeficiente Organizacion"
                                onChange={handleChangeCoeficiente}
                                fullWidth
                                disabled={estadoCotizacion.estado === TIPO_ESTADO.ENVIADA}
                                value={integracionLocal.coeficienteOrganizacion}
                                name="coeficienteOrganizacion"
                                >
                                    {   coeficientesIntegracion?.map((coeficienteIram, index) =>
                                        <MenuItem key={'coeIram'+index} value={coeficienteIram}>{coeficienteIram}</MenuItem>
                                    )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="label_coeficiente_iram">Coeficiente capacidad IRAM</InputLabel>
                                <Select
                                labelId="label_coeficiente_iram"
                                label="Coeficiente capacidad IRAM"
                                name="coeficienteIRAM"
                                fullWidth
                                disabled={estadoCotizacion.estado === TIPO_ESTADO.ENVIADA}
                                onChange={handleChangeCoeficiente}
                                value={integracionLocal.coeficienteIRAM}
                                >
                                    {   coeficientesIntegracion.map((coeficienteOrg, index) =>
                                        <MenuItem key={'coeOrg'+index} value={coeficienteOrg}>{coeficienteOrg}</MenuItem>
                                    )
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth>
                                <InputLabel id="label-motivo">Motivo</InputLabel>
                                <Select
                                labelId="label-motivo"
                                label="Motivo"
                                fullWidth
                                multiple
                                required
                                autoWidth
                                sx={{textOverflow:"ellipsis"}}
                                onChange={handleChangeMotivos}
                                value={integracionLocal.motivosOrganizacion.map(m => m.id)}
                                renderValue={idsMotivos}
                                >
                                    {
                                        listaMotivosIntegracion.map((motivos, index)=>
                                        <MenuItem key={'motInt'+index} value={motivos.id} sx={{paddingLeft:"0px", paddingRight:"0px"}}>
                                            <Checkbox checked={integracionLocal.motivosOrganizacion.indexOf(motivos)> -1}/>
                                            <ListItemText primary={motivos.nombre} />
                                        </MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl> 
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Competencia del equipo auditor en más de una norma</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {
                                 integracionLocal.motivosOrganizacion &&
                                 <Box display="flex" flexDirection={"column"}>
                                     {
                                         integracionLocal.motivosOrganizacion.map((mot, index) =>
                                         <li key={'listmotaux'+index}>{mot.nombre}</li>     
                                         )
                                     }
                                 </Box>
                            }
                        </Grid>
                        <Grid item xs={6}></Grid>
                        {
                            (isGeneral && existeIntegracionDesvinculada) &&
                            <Grid item xs={12}>
                                <Box display="flex" gap={1}>
                                <Box alignSelf={'center'}><InfoIcon color='warning'/></Box>
                                <Box>
                                <Typography variant="body2">
                                    Existen sitios con la integración de normas modificada. Los cambios que realice sobre la integración general no impactarán en los mismos.
                                </Typography>
                                </Box>
                                </Box>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </ModalPortal>
        </>
    )
}

export default ModalIntegracion