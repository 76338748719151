import { Box, Button, FormControl, Modal, Paper, TextField, Typography } from '@mui/material'
import ModalPortal from 'components/modalPortal'
import { updateConcepto } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
 
import React, { useContext, useState } from 'react'
import { makeStyles } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import { useDispatch } from 'react-redux'

const useStyles = makeStyles((theme) => ({
  modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  },
  paper: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3)
  },
  title: {
      marginBottom: theme.spacing(3)
  }
  
}));

function ModalComentarioConcepto({onClose, isOpened, concepto, propuesta, onlyShow}) {

  const classes = useStyles();
  const dispatchRedux = useDispatch()
  const {dispatch} = useContext(CotizacionContext)

  const [comentario, setComentario] = useState(concepto.comentario)

  const handleComentario =(e) => {
      setComentario(e.target.value)
  }
  const handleGuardarComentario = (e) => {
    e.preventDefault();
    const data = {
      concepto: {...concepto, comentario: comentario},
      idPropuesta: propuesta.id,
      propuesta:propuesta
  }
    updateConcepto(dispatch, data, dispatchRedux)
      onClose()
  }

  return (
    <Modal
    className={classes.modal}
    open={isOpened}
    onClose={onClose}
    >
      <Paper className={classes.paper}>
        <Box className={classes.title}>
            <Typography component="h3" variant="h6">Comentario</Typography>
        </Box>
        <TextField 
          fullWidth
          value={comentario} 
          onChange={handleComentario}
          multiline
          rows={5}
        />
        <Box className="toolbar-actions" display="flex" sx={{paddingTop:"10px"}}>
       
        <Button variant="outlined" color="secondary" onClick={onClose}>Cancelar</Button>
        {!onlyShow &&
        <Button 
        variant="outlined" 
        color="primary" 
        onClick={handleGuardarComentario}
        startIcon={<SaveIcon/>} 
        >Guardar</Button>
        }
        </Box>
      </Paper>
    </Modal>
  )
}

export default ModalComentarioConcepto