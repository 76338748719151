import {
    ADD_INPUT_IAF_NACE,
    ADD_INPUT_MODIFICADORES,
    DELETE_CODIGO_IAF_NACE,
    DELETE_NORMA,
    RESET_COTIZACION,
    RESET_IAF_NACE,
    RESET_INTEGRACION,
    RESET_NORMAS,
    RESET_ESTADO_COTIZACION,
    SET_ALL_ACCORDION_CLOSE,
    SET_ESTADO_COTIZACION,
    SET_INFO_COTIZACION,
    SET_INPUT_IAF_NACE,
    SET_INPUT_INTEGRACION,
    SET_INPUT_REDUCCION,
    SET_JSON,
    SET_LISTA_RANGO_PERSONAL,
    SET_NORMAS_MOTIVO,
    SET_NUEVA_NORMA,
    SET_OBSERVACIONES,
    SET_SAVED,
    SET_SAVING,
    SET_ESTADO_CARGA,
    SET_TAB,
    SET_PROPUESTA,
    SET_PRODUCTOS,
    SET_MULTISITIOS,
    SET_SECCIONESDOCUMENTO,
    SET_SECCIONESEDIT,
    UPDATE_TIPO_CALCULO,
    SET_DOCUMENTO_TEMP,
    SET_NUEVO_TURNO,
    SET_TURNOS,
    DELETE_TURNO,
    SET_NUEVO_NACE,
    SET_NUEVO_SUBNACE,
    DELETE_NACE,
    AGREGAR_SERVICIO,
    ACTUALIZAR_SERVICIO,
    BORRAR_SERVICIO,
    SET_NOMBRE_COTIZACION,
    SET_NACE,
    ACTUALIZAR_SERVICIOS,
    RESET_TURNOS
} from "context/cotizacion/typesCotizacion/TypesCotizacion"
import COTIZACION_VACIA from "context/cotizacion/reducerCotizacion/cotizacionVacia"



export const reducer = (state, action) => {
    switch (action.type) {
        case SET_NORMAS_MOTIVO: {
            return { ...state, inputNormasMotivos: action.payload }
        }
        case SET_INPUT_IAF_NACE: {
            return { ...state, inputCodigosIAFNACE: action.payload }
        }
        case ADD_INPUT_IAF_NACE: {
            return { ...state, inputCodigosIAFNACE: state.inputCodigosIAFNACE.concat({ CodigoIaf: { id: '', descripcion: '' }, CodigoNace: { id: '', descripcion: '' } }) }
        }
        case DELETE_CODIGO_IAF_NACE: {
            return { ...state, inputCodigosIAFNACE: state.inputCodigosIAFNACE.filter((input, index) => index !== action.payload) }
        }
        case SET_INPUT_REDUCCION: {
            return { ...state, inputReduccion: action.payload }
        }
        case ADD_INPUT_MODIFICADORES: {
            return { ...state, inputMotivosModificadores: state.inputMotivosModificadores.concat(action.payload) }
        }
        case SET_TAB: {
            return { ...state, tabSeleccionada: action.payload }
        }
        case SET_MULTISITIOS: {
            return { ...state, multisitios: action.payload, tabSeleccionada: action.payload ? state.tabSeleccionada : 0 }
        }
        case SET_JSON: {
            return { ...state, json: action.payload }
        }
        case SET_ESTADO_CARGA: {
            return { ...state, estadoCarga: action.payload }
        }
        case SET_SAVING: {
            return { ...state, saving: action.payload }
        }
        case SET_SAVED: {
            return { ...state, saved: action.payload }
        }
        case RESET_IAF_NACE: {
            return {
                ...state, inputCodigosIAFNACE: [{
                    CodigoIaf: { id: '', codigo: '', descripcion: '' },
                    CodigoNace: { id: '', codigo: '', descripcion: '' }
                }]
            }
        }
        case RESET_NORMAS: {
            return {
                ...state, inputNormasMotivos: [{
                    id: 0, norma: '', motivo: '', nombre: '', integrada: false,
                    vencimiento: '',
                    cambioVersion: false,
                    transferencia: false,
                    ampliacion: false,
                    reduccion: false,
                    numero: '',
                    cantidadMantenimientos:0,
                    ente: 0,
                    cluster: {
                        id: 0,
                    },
                    consumoEnergiaAnual: 0,
                    fuentesDeEnergia: 0,
                    usosDeEnergia: 0
                }]
            }
        }
        case SET_NUEVA_NORMA: {
            return { ...state, inputNormasMotivos: state.inputNormasMotivos.concat(action.payload) }
        }
        case DELETE_NORMA: {
            return { ...state, inputNormasMotivos: state.inputNormasMotivos.filter((input, index) => index !== action.payload) }
        }
        case SET_INPUT_INTEGRACION: {
            return { ...state, inputIntegracion: action.payload }
        }
        case RESET_INTEGRACION: {
            return {
                ...state, inputIntegracion: {
                    coeficienteOrganizacion: 0,
                    coeficienteIRAM: 0,
                    motivosOrganizacion: [],
                    porcentajeReduccion: -1
                }
            }
        }
        case RESET_ESTADO_COTIZACION: {
            return {
                ...state, estadoCotizacion: {
                    estado: "Borrador",
                    permiteEdicion: true,
                    posiblesTransiciones: [],
                    motivoRechazo: ''
                }
            }
        }
        case RESET_COTIZACION: {
            return {
                ...state,
                json: COTIZACION_VACIA,
                inputDatosCotizacion: {
                    nombre: "",
                    seguimientos: 2,
                    codigo: '',
                    revision: 'D',
                    iaf: {
                        id: null,
                        codigo: null,
                        descripcion: '',
                    }
                }
            }
        }
        case RESET_TURNOS:{
            return {...state, turnos:[{
                descripcion: '',
                horarioFin: '',
                horarioInicio: '',
                personal: null,
                selected: false
            }]}
        }
        case SET_INFO_COTIZACION: {
            return { ...state, inputDatosCotizacion: action.payload }
        }
        case SET_NUEVO_TURNO: {
            const inputs = [...state.turnos];

            inputs.push({
                descripcion: '',
                horarioInicio: '',
                horarioFin: '',
                personal: null
            });

            return {
                ...state,
                turnos: inputs
            }
        }
        case SET_TURNOS: {
            return { ...state, turnos: action.payload }
        }
        case DELETE_TURNO: {
            const inputs = state.turnos.filter((input, index) => index !== action.payload);

            return {
                ...state,
                turnos: inputs
            }
        }
        case SET_NUEVO_NACE: {
            const inputs = [...state.inputCodigosNACESUBNACES];

            inputs.push({
                id: '',
                codigo: '',
                descripcion: '',
                subnaces: [
                    {
                        id: '',
                        codigo: '',
                        descripcion: ''
                    }
                ]
            });

            return {
                ...state,
                inputCodigosNACESUBNACES: inputs
            }
        }

        case SET_NACE:{
            return {...state, inputCodigosNACESUBNACES: action.payload}
        }
        case SET_NUEVO_SUBNACE: {
            const inputs = [...state.inputCodigosNACESUBNACES];
            const index = action.payload;

            inputs[index].subnaces.push({
                id: '',
                codigo: '',
                descripcion: ''
            });

            return {
                ...state,
                inputCodigosNACESUBNACES: inputs
            }
        }
        case DELETE_NACE: {
            const inputs = state.inputCodigosNACESUBNACES.filter((input, index) => index !== action.payload);

            return {
                ...state,
                inputCodigosNACESUBNACES: inputs
            }
        }
        case SET_ALL_ACCORDION_CLOSE: {
            return { ...state, allAccordionClose: action.payload }
        }
        case SET_ESTADO_COTIZACION: {
            return { ...state, estadoCotizacion: action.payload }
        }
        case SET_LISTA_RANGO_PERSONAL: {
            return { ...state, listaRangoPersona: action.payload }
        }
        case SET_OBSERVACIONES: {
            return { ...state, observaciones: action.payload }
        }
        //PROPUESTA--------------------------------------------------
        case SET_PROPUESTA: {
            return { ...state, propuesta: action.payload }
        }

        case SET_PRODUCTOS: {
            return { ...state, productos: action.payload }
        }
        case UPDATE_TIPO_CALCULO: {
            return { ...state, propuesta: { ...state.propuesta, tipoCalculo: action.payload }, tabSeleccionada: 0 }
        }

        //Documento -------------------------------------------------
        case SET_SECCIONESDOCUMENTO: {
            return { ...state, secciones: action.payload }
        }

        case SET_SECCIONESEDIT: {
            return { ...state, seccionesEdit: action.payload }
        }
        case SET_DOCUMENTO_TEMP: {
            return { ...state, documentoTemp: action.payload }
        }
        case AGREGAR_SERVICIO: {
            return { ...state, json: { ...state.json, servicios: [...state.json.servicios, {}] } }
        }
        case ACTUALIZAR_SERVICIO: {
            let servicios = [...state.json.servicios]
            servicios[action.payload.index] = action.payload.servicio
            return { ...state, json: { ...state.json, servicios } }
        }
        case ACTUALIZAR_SERVICIOS: {
            return { ...state, json: { ...state.json, servicios: action.payload.servicios } }
        }
        case BORRAR_SERVICIO: {
            let servicios = [...state.json.servicios]
            servicios.splice(action.payload.index, 1)
            return { ...state, json: { ...state.json, servicios } }
        }
        case SET_NOMBRE_COTIZACION: {
            return { ...state, json: { ...state.json, nombre: action.payload.nombre } }
        }

        default:
            return state;
    }
}
