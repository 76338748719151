import { FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material'
import { Box } from '@mui/system'
import ModalPortal from 'components/modalPortal'
import { AddSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
 
import React, { useContext, useState } from 'react'
import Switch from '@mui/material/Switch'
import HelpIcon from '@mui/icons-material/Help';
import { useDispatch } from 'react-redux'

function ModalSitio({onClose, isOpened, idGrupo}) {

    const dispatchRedux = useDispatch()

    const {state, dispatch} = useContext(CotizacionContext)
    const {json, listaRangoPersona} = state

    const [nuevoSitio, setNuevoSitio] = useState({nombre:'',cantidad:1,personas:'',personasRango:''})
    const [esSitioRepresentativo, setEsSitioRepresentativo] = useState(false);

    const handleNombre = (e) => {
        setNuevoSitio({...nuevoSitio, nombre:e.target.value})
    }

    const handleCantidad = (e) => {
        setNuevoSitio({...nuevoSitio, cantidad: parseInt(e.target.value)})
    }

    const handlePersonas = (e) => {
        setNuevoSitio({...nuevoSitio, personas: e.target.value})
    }

    const handleRangoPersonas = (e) => {
        setNuevoSitio({...nuevoSitio, personasRango: e.target.value})
    }
    
    const onSave = () => {
        let rangoDePersonalAux = null
        let personalAux = null
        if(esSitioRepresentativo){
            if(nuevoSitio.cantidad > 1){
                personalAux = null;
                rangoDePersonalAux = {desde:parseInt(nuevoSitio.personasRango.split('|')[0]),hasta:parseInt(nuevoSitio.personasRango.split('|')[1])}
            }else{
                personalAux = parseInt(nuevoSitio.personas);
                rangoDePersonalAux = null;
            }
        }else{
            personalAux = parseInt(nuevoSitio.personas);
            rangoDePersonalAux = null;
        }
        
        const data = {
            idCotizacion: json.id,
            idGrupoSitios: idGrupo,
            sitio : {
                nombre: nuevoSitio.nombre,
                cantidadDeSitios: esSitioRepresentativo ? nuevoSitio.cantidad : 1,
                personal: personalAux,
                rangoDePersonal:rangoDePersonalAux
            }
        }
        
        AddSitios(dispatch, data)
        setNuevoSitio({nombre:'',cantidad:1,personas:'',personasRango:''})
        onClose()
    }

    const onCloseButton = () => {
        setNuevoSitio({nombre:'',cantidad:1,personas:'',personasRango:''})
        onClose()
    }

    const renderInputPersonal = () => {
        if(nuevoSitio.cantidad > 1){
            return (
                <FormControl fullWidth>
                <InputLabel id="label-personas-rango">Personas</InputLabel>
                <Select
                    label="Personas"
                    labelId='label-personas-rango'
                    required
                    value={nuevoSitio.personasRango}
                    onChange={handleRangoPersonas}>
                {
                    listaRangoPersona.map((item, index) => 
                    <MenuItem 
                        key={'rango'+index} 
                        value={item.desde+'|'+item.hasta}
                    >
                    {item.descripcion}
                    </MenuItem>)
                }
                </Select>
                </FormControl>
            )
        }else{
            return(
                <TextField
                label="Personas"
                type="number"
                required
                fullWidth
                onChange={handlePersonas}
                value={nuevoSitio.personas}
            />
            )
        }
    }

    return (
        <ModalPortal
            onCancel={onCloseButton}
            onSave={onSave}
            title="Nuevo Sitio"
            isOpened={isOpened}
            >
                <Box display="flex" flexDirection="row" alignItems="center" sx={{marginBottom: "25px"}}>
                    <Typography>Sitio representativo</Typography>
                    
                    <Switch checked={esSitioRepresentativo} onChange={(value) => setEsSitioRepresentativo(state => !state)} style={{position: "absolute", top: "0px"}} />
                    <Tooltip title={"Un sitio representativo puede estar formado por sitios concretos, los cuales deberan ser especificados mas adelante"}>
                            <HelpIcon />
                    </Tooltip>
                </Box>
                <Box display="flex" sx={{width:"600px"}} flexDirection="column" gap={3}>
                    <TextField 
                        label="Nombre" 
                        type="text" 
                        required 
                        fullWidth 
                        onChange={handleNombre}
                        value={nuevoSitio.nombre}
                    />
                    {esSitioRepresentativo && <TextField
                        label="Cantidad sitios concretos"
                        type="number"
                        required
                        fullWidth
                        onChange={handleCantidad}
                        value={nuevoSitio.cantidad}
                    />}
                    {
                        renderInputPersonal()
                    }
                </Box>
        </ModalPortal>
    )
}

export default ModalSitio
