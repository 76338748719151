import { useEffect, useState } from 'react';
import { Chip } from '@mui/material';
import { useLocation } from 'react-router';
import { useContext } from 'react';
import { SolicitudContext } from 'context/solicitud/solicitudContext';

const classes = {
   root: {
      ml: 4,
      px: 2,
      fontWeight: 'fontWeightMedium',
      textTransform: 'uppercase'
   }
}

const Section = () => {
   const location = useLocation();
   const [section, setSection] = useState(null);
   const { stateSolicitud } = useContext(SolicitudContext)
   const getSection = () => {
      
      setSection(stateSolicitud.tipo);
   }

   useEffect(() => {
      getSection();
   }, [stateSolicitud]);
   if(section)
      return <Chip label={section} color="primary" variant="outlined" sx={classes.root} />;
   else
      return null;
}

export default Section;