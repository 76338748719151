import { useSelector } from 'react-redux';

const getPermisos = (usuario) => {
    if (usuario) {
        return usuario.user_claims
            .filter(claim => claim.typ === 'roles')
            .map(claim => claim.val);
    }
    return [];
}

export default function usePermisos() {
    const globalSlice = useSelector(state => state.global);
    const permisos = getPermisos(globalSlice.usuario);

    return permisos;
}