import React, { useContext } from 'react'
import ModalPortal from 'components/modalPortal';
import { Box } from '@mui/system'
import { _postConcepto } from 'services/Propuesta';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { useDispatch, useSelector } from 'react-redux';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import CommentIcon from '@mui/icons-material/Comment';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { TextField, Typography } from '@mui/material';
import InputOrganizaciones from 'components/InputOrganizaciones/solicitud';

function ModalEditDetalleSolicitud({ onClose, isOpened }) {

    const dispatch = useDispatch();

    const { stateSolicitud } = useContext(SolicitudContext)

    console.log(stateSolicitud);
    const organizaciones = stateSolicitud.organizaciones;
    console.log(organizaciones);

    const onCloseButton = () => {
        onClose()
    }

    return (
        <ModalPortal
            onCancel={onCloseButton}
            title="Editar Detalle Solicitud"
            isOpened={isOpened}
        >
            <Box display='flex' sx={{ width: "90vw" }} flexDirection="column" gap={3}>
                <Typography variant='subtitle1'>Organizaciones</Typography>
                <InputOrganizaciones
                        required={true}
                        multiple={true} />
                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                    {organizaciones.map((value) => (
                        <ListItem
                            key={value.nombre}
                            disableGutters
                            secondaryAction={
                                <>
                                    <IconButton aria-label="edit">
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton aria-label="delete">
                                        <DeleteIcon />
                                    </IconButton>
                                </>
                            }
                        >
                            <ListItemText primary={`${value.nombre}`} />
                        </ListItem>
                    ))}
                </List>
                {/* Editar Grupo Economico
                 */}
                <TextField id="outlined-basic" label="Grupo Economico" variant="outlined" />
            </Box>
        </ModalPortal>
    )
}

export default ModalEditDetalleSolicitud