import { IconButton, LinearProgress, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { ScrollSyncPane } from 'react-scroll-sync'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

//style
import 'scss/components/sitioMuestra.scss'
import { agregarSitioConcreto, eliminarSitioConcreto, actualizarSitioConcreto as updateSitioConcreto } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import InstanciaSitioConcreto from 'components/InstanciaSitioConcreto';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS } from 'constants/constants';
import { useDispatch } from 'react-redux';

function getRandomNumber(){
    return Math.floor(Math.random() * 100000)
}

function SitioConcreto({sitio, sitioConcreto, key}) {
    //context
    const {state, dispatch} = useContext(CotizacionContext)
    
    const {estadoCarga} = state

    const dispatchRedux = useDispatch()

    const [sitioConcretoLocal, setSitioConcreto] = useState({...sitioConcreto})

    const [nombreSitioConcreto, setNombre] = useState(sitioConcretoLocal.nombre)

    useEffect(() => {
      setSitioConcreto({...sitioConcreto})
    }, [sitioConcreto])
    

    const handleEliminarSitioMuestra = () => {

        const data = {
            idCotizacion: sitio.idCotizacion,
            idGrupo: sitio.idGrupo,
            idSitio: sitio.id,
            idSitioConcreto: sitioConcretoLocal.id
        }

        eliminarSitioConcreto(dispatch, data)
    }

    const handleNombre = (e) => {
        setNombre(e.target.value)
    }

    const handleUpdateInstancia = (instancia) => {
        let instanciasNuevas = [];

        for (const i of sitioConcretoLocal.instancias) {
            if(i.id === instancia.id){
                instanciasNuevas.push(instancia);
            }else{
                instanciasNuevas.push(i);
            }
        }
        setSitioConcreto({...sitioConcretoLocal, instancias: instanciasNuevas});
    }

    const actualizarSitioConcreto = () => {
        const data = {
            id: sitioConcretoLocal.id ? sitioConcretoLocal.id : null,
            idCotizacion: sitio.idCotizacion,
            idGrupo: sitio.idGrupo,
            idSitio: sitio.id,
            nombre: nombreSitioConcreto,
            instancias: sitioConcretoLocal.instancias
        }
        if(nombreSitioConcreto){
            if(sitioConcretoLocal.id){
                updateSitioConcreto(dispatch, data, dispatchRedux)
            }else if(nombreSitioConcreto != null || nombreSitioConcreto != ""){
                agregarSitioConcreto(dispatch, data)
            }
        }
    }

    const cambioInstanciaSitioConcreto = (instancia, instanciaNueva) => {
        if(instancia.diasEtapa1 !== instanciaNueva.diasEtapa1) return true;
        if(instancia.diasEtapa2 !== instanciaNueva.diasEtapa2) return true;
        if(instancia.requiereVisita !== instanciaNueva.requiereVisita) return true;

        return false;
    }

    const cambioSitioConcreto = () => {
        if(nombreSitioConcreto !== sitioConcretoLocal.nombre) return true;

        if(sitioConcreto.instancias != null){
            for (const instancia of sitioConcreto.instancias) {
                if(sitioConcretoLocal.instancias
                    .some(instanciaNueva => instanciaNueva.id == instancia.id && cambioInstanciaSitioConcreto(instancia, instanciaNueva))){
                        return true;
                    }
            }
        }
        return false;
    }

    useEffect(() => {
        if(cambioSitioConcreto()){
            const timeout = setTimeout(
                actualizarSitioConcreto,
                2500)
            return () => {
                clearTimeout(timeout)
            } 
        }
    }, [nombreSitioConcreto]);

return (
<Box key={key} className="sitio-muestra">
    {
        sitio.cantidadDeSitios > 1 ?
        <Box className="sitio-muestra-titulo">
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
            <TextField label="Sitio" value={nombreSitioConcreto} onChange={handleNombre}></TextField>
            </Restricted>
        </Box>:<Box className="sitio-muestra-titulo"></Box>
    }
    <ScrollSyncPane>
    <Box className="sitio-muestra-etapas-scroll" gap={1}>
    {
        sitioConcretoLocal.instancias ?
        <>
        {
        sitioConcretoLocal.instancias.map((instancia, index) => 
           <InstanciaSitioConcreto 
           key={"instanciasitioconcreto" + getRandomNumber()} 
           onChangeInstancia = {handleUpdateInstancia}
           sitio={sitio}
           index={index} 
           idInstancia={instancia.id}
           setSitioConcreto={setSitioConcreto}
           sitioConcretoLocal={sitioConcretoLocal}
           sitioConcreto={sitioConcreto}
            />
        )
        }
        </>
        :
        <>
        {
            estadoCarga.guardando &&
            <Box sx={{width:"680px", paddingLeft:"13px", paddingTop:"25px"}}>
                <LinearProgress />
            </Box>
        }
        </>
    }
    </Box>
    </ScrollSyncPane>
    {
        (sitio.cantidadDeSitios > 1) && (sitioConcretoLocal.instancias) &&
        <Box className="sitio-muestra-icon-delete">
        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
        <IconButton sx={{color:"black"}} size="large" onClick={handleEliminarSitioMuestra}>
            <RemoveCircleIcon/>
        </IconButton>
        </Restricted>
        </Box>
    }
    
    
</Box>
)
}
export default SitioConcreto