import EditIcon from '@mui/icons-material/Edit';
import { Box, IconButton, Tooltip } from '@mui/material';
import React, {useContext, useState} from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalIntegracion from 'components/modalIntegracion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { updateSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
 
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import InfoIcon from '@mui/icons-material/Info';

function LabelIntegracionStyled({integracion, sitio, isEdit, isSitio, normas}) {

    //context cotizacion
    const {state, dispatch} = useContext(CotizacionContext)
    const {json} = state
    
    const handleDeleteIntegracion = () => {
        const data = {
            idCotizacion: json.id,
            idGrupo: sitio.idGrupo,
            sitio: {...sitio, infoIntegracion: null}
        }
        updateSitios(dispatch, data)
    }
    //Modal Integracion 
    const [modalStateIntegracion, setModalStateIntegracion] = useState({opened: false});
    const handleModalIntegracion = () => setModalStateIntegracion({opened: !modalStateIntegracion.opened})
 
  
    return (
    <Box className="integration-detail">
        {
            integracion.desvinculada &&
            <Box display="flex" sx={{paddingRight:"5px"}}>
                <Tooltip title="Integracion desvinculada">
                    <InfoIcon color="warning"/>
                </Tooltip>
            </Box>
            
        }
        <Box className="norms">
            {sitio.normas.map(n => n.cotizacionNorma.norma.numero).join(' - ')}
        </Box>

        <Box className="percentage">
        {' - '+integracion.porcentajeReduccion*100+'%'}
        </Box>
        
        <Box className="motive">
            {integracion.motivosOrganizacion.map(m => m.nombre).join(', ')}
        </Box>
        {
            isEdit &&
            <Box className="actions">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <IconButton variant="edit" onClick={handleModalIntegracion}>
                    <EditIcon/>
                </IconButton>
                </Restricted>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <IconButton variant="delete" onClick={handleDeleteIntegracion}>
                    <DeleteIcon/>
                </IconButton>
                </Restricted>
            </Box>                      
        }
        <ModalIntegracion 
        onClose={()=>handleModalIntegracion()} 
        integracion={integracion}
        isOpened={modalStateIntegracion.opened}
        sitio={sitio}
        isSitio={isSitio} />
    </Box>
    );
}

export default LabelIntegracionStyled;
