import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import ReactQuill from 'react-quill'

import { actualizarObservaciones, setEstadoCarga, setSaving } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { getObservaciones, updateObservaciones } from 'services/Cotizacion';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS } from 'constants/constants';
import usePermisos from 'hooks/usePermisos';
import { useDispatch } from 'react-redux';
import { setError } from 'redux/slices/globalSlice';

function ObersvacionesTextEditor() {
  const dispatchRedux = useDispatch()
  const { state, dispatch } = useContext(CotizacionContext)

  const { observaciones, json: cotizacion } = state
  const [texto, setTexto] = useState('')
  const permisosActuales = usePermisos();

  const [saving, setSavingLocal] = useState({ value: false, error: '', isError: false });

  useEffect(() => {
    getObservaciones(cotizacion.id)
      .then(response => {
        setTexto(response.cuerpo)
      })
      .catch(error => {
        console.error("ERROR OBTENIENDO OBSERVACION", error)
        dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
      })
  }, [])


  const handleChangeTexto = (e) => {
    setTexto(e)
  }

  useEffect(() => {
    if (!permisosActuales.includes(PERMISOS.LECTURA)) {
      const timeout = setTimeout(
        _actualizarObservaciones,
        1500)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [texto]);

  const _actualizarObservaciones = () => {
    if (texto !== "") {
      setSavingLocal(s => ({ ...s, value: true }));
      dispatch(setEstadoCarga({
        guardando: true,
        guardado: false,
      }))
      updateObservaciones(cotizacion.id, texto)
        .then(response => {
          dispatch(setEstadoCarga({
            guardando: false,
            guardado: true,
          }))
          setSavingLocal(s => ({ ...s, value: false }));
        })
        .catch(error => {
          dispatch(setEstadoCarga({
            guardando: false,
            guardado: false,
          }))
          setSavingLocal(s => ({ ...s, value: false, isError: true, error: error }));
          console.error("ERROR ACTUALIZANDO OBSERVACION", error)
          dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data.details ? error.data.details : "sin detalles" }))
        })
    }
  }

  const permisos = usePermisos();

  return (
    <>
      <Box className="observaciones-container">
        <Typography className="observaciones-titulo" variant='h5' fontWeight="fontWeightBold" >Observaciones</Typography>
        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}></Restricted>

        <ReactQuill value={texto}
          onChange={handleChangeTexto}
          readOnly={permisos.includes(PERMISOS.LECTURA)}
          className="observaciones-editor"
        ></ReactQuill>
      </Box>
    </>
  )
}

export default ObersvacionesTextEditor