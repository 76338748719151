import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React from 'react'

function ResumenCotizacion({cotizacion}) {
  return (
  <Box display="flex" flexDirection="column" gap={2} sx={{paddingTop:"20px", paddingBottom:"20px"}}>
    <TableContainer>
      <Table>
        <TableHead></TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <Typography sx={{fontWeight:"bold"}}>Cotización:</Typography>
              {cotizacion.nombre}
            </TableCell>
            <TableCell>
              <Typography sx={{fontWeight:"bold"}}>Codigó:</Typography>
              {cotizacion.codigo}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <Typography sx={{fontWeight:"bold"}}>Revisión:</Typography>
              -
            </TableCell>
            <TableCell>
              <Typography sx={{fontWeight:"bold"}}>Estado:</Typography>
              {cotizacion.estado.estado}
            </TableCell>
          </TableRow>
          {
            cotizacion.normas &&
            <TableRow>
            <TableCell>
                <Typography sx={{fontWeight:"bold"}}>Normas:</Typography>
                {
                  cotizacion.normas && cotizacion.normas.map((norma, index) =>
                  <Typography sx={{paddingBottom:"10px"}}>{norma.norma.nombre}</Typography>
                  )
                }
              </TableCell>
              <TableCell>
                <Typography sx={{fontWeight:"bold"}}>Proceso:</Typography>
                {
                   cotizacion.normas && cotizacion.normas.map((norma, index) =>
                  <Typography sx={{paddingBottom:"10px"}}>{norma.motivo}</Typography>
                  )
                }
              </TableCell>
          </TableRow>
          }
          {
            cotizacion.servicios &&
            <TableRow>
            <TableCell>
                <Typography sx={{fontWeight:"bold"}}>Servicios:</Typography>
                {
                  cotizacion.servicios && cotizacion.servicios.map((servicio, index) =>
                  <Typography sx={{paddingBottom:"10px"}}>{servicio.nombre}</Typography>
                  )
                }
              </TableCell>
              <TableCell>
                <Typography sx={{fontWeight:"bold"}}>Proceso:</Typography>
                {
                   cotizacion.servicios && cotizacion.servicios.map((servicio, index) =>
                  <Typography sx={{paddingBottom:"10px"}}>{servicio.motivos.join(",")}</Typography>
                  )
                }
              </TableCell>
          </TableRow>
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
  )
}

export default ResumenCotizacion