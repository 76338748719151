import React, {createContext, useReducer} from 'react'
import { reducer } from 'context/cotizacion/reducerCotizacion'
import { INITIAL_STATE } from 'context/cotizacion/reducerCotizacion/initialState';

export const CotizacionContext = createContext();

export const CotizacionProvider = (props) => {

    const [state, dispatch] = useReducer(reducer, INITIAL_STATE);

    return (
    <CotizacionContext.Provider value={{state, dispatch}}>
        {props.children}
    </CotizacionContext.Provider>
    )
}

