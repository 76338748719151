import { Badge, Box, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'
import OpenWithIcon from '@mui/icons-material/OpenWith';
import MessageIcon from '@mui/icons-material/Message';
import 'scss/components/tablaPropuestaEconomica.scss'
import { capitalizeFirstLetter } from 'utlis/stringUtils';
import { TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import { useContext } from 'react';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';

function TituloConcepto({item, comentario, handleComentario}) {

    const {state: stateCot} = useContext(CotizacionContext)
    const {json} = stateCot;

    var listaMotivos = [... new Set(item.nombreMotivo?.split(','))]

    const getDisabled = () => {
        if(item.custom){
            return false
        }else{

            return !(item.habilitado || item.realizada || item.aCotizar)
        }
    }

    const classNameTitulo = json && json.tipo === TIPO_SOLICITUD_PRODUCTO ? 'titulo-concepto-producto' : 'titulo-concepto-unified';

    return (
    <Box display="flex" className={classNameTitulo} gap={1} flexDirection="row">
        <Box alignSelf="center" className='titulo-concepto-icon'>
            <OpenWithIcon/>
        </Box>
        <Box alignSelf="center"  display="flex" flexDirection="column" >
            <Typography variant="caption" sx={{font:"bold"}} >{item.nombre}</Typography>
            {
                !item.custom &&
                <>
                {
                    listaMotivos.map((motivo, index) =>
                    <Typography key={'c'+index} variant='caption'>{"- " + capitalizeFirstLetter(motivo.toLowerCase())}</Typography>        
                    )
                }
                </>
            }
            
        </Box>
        <Box display="flex" alignSelf="center" sx={{marginLeft:"auto", }}>
            <Tooltip title={comentario?comentario:""}>
                <IconButton onClick={handleComentario} disabled={getDisabled()}>
                    {
                        comentario?
                        <Badge variant="dot" color="primary">
                            <MessageIcon/>
                        </Badge>
                        :
                        <MessageIcon/>
                    }
                        
                </IconButton>
            </Tooltip>
        </Box>
    </Box>
  )
}

export default TituloConcepto