import { Box, Step, StepIcon, StepLabel, Stepper } from '@mui/material'
import ModalPortal from 'components/modalPortal'
import React from 'react'
import { dateToString } from 'utlis/stringUtils'
import DateRangeIcon from '@mui/icons-material/DateRange';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

function ModalHistorial({onClose, isOpened, propuesta}) {

    const onSave = () => {
        onClose()
    }
    const onCloseButton = () => {
        onClose()
    }

    const getActiveStep = () => {
      return 1;
    }
  return (
    <ModalPortal
    onCancel={onCloseButton}
    onSave={onSave}
    title="Historial"
    isOpened={isOpened}
    onlyShow={true}
    >
        <Box display='flex' sx={{width:"500px"}} flexDirection="column">
          <Stepper activeStep={getActiveStep()} alternativeLabel>
           
            <Step>
              <StepLabel StepIconComponent={DateRangeIcon}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <div>Fecha Creación</div>
                  <div>{dateToString(propuesta.fechaCreacion)}</div>
                </Box>
              </StepLabel>
            </Step>
            {
              propuesta.fechaEnviada &&
              <Step>
              <StepLabel icon={<DateRangeIcon color="warning"/>}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <div>Fecha Enviada</div>
                  <div>{dateToString(propuesta.fechaEnviada)}</div>
                </Box>
              </StepLabel>
              </Step>
            }
            {
              propuesta.fechaAceptada &&
              <Step>
              <StepLabel icon={<DateRangeIcon color="success" />}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <div>Fecha Aceptada</div>
                  <div>{dateToString(propuesta.fechaAceptada)}</div>
                </Box>
              </StepLabel>
              </Step>
            }
            {
              propuesta.fechaDescartada &&
              <Step>
              <StepLabel icon={<RemoveCircleOutlineIcon color="success" />}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <div>Fecha Descartada</div>
                  <div>{dateToString(propuesta.fechaDescartada)}</div>
                </Box>
              </StepLabel>
              </Step>
            }
            {
              propuesta.fechaRechazada &&
              <Step >
              <StepLabel icon={<HighlightOffIcon color="error" />}>
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <div>Fecha Rechazada</div>
                  <div>{dateToString(propuesta.fechaRechazada)}</div>
                </Box>
              </StepLabel>
              </Step>
            }
        </Stepper>
        </Box>
    </ModalPortal>
  )
}

export default ModalHistorial