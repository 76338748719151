import React, { useState, useContext } from 'react'
import ModalPortal from 'components/modalPortal'
import { Box } from '@mui/system'
import { parseISO } from 'date-fns'
import { TextField } from '@mui/material'
import { TIPO_ESTADO } from 'constants/constants';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { updatePropuestaEstado } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { useDispatch } from 'react-redux'

function ModalFechaAceptada({onClose, isOpened, propuesta}) {
    const dispatchRedux = useDispatch()
    const {dispatch} = useContext(CotizacionContext)

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-');
    }
    const [fecha, setFecha] = useState(formatDate(new Date()))

    const onSave = () => {
        var data = {
            idPropuesta: propuesta.id,
            estado: TIPO_ESTADO.ACEPTADA,
            posiblesTransiciones:null,
            FechaAceptacion:parseISO(fecha)
        }
        updatePropuestaEstado(dispatch, data, dispatchRedux)
        // const data ={
        //     idCotizacion: json.id,
        //     estado:TIPO_ESTADO.ACEPTADA,
        //     posiblesTransiciones:null,
        //     fechaEnviada:parseISO(fecha)
        // }
        // updateEstadoCotizacion(data)
        // .then((response) => {
        //     let estadoAux = {...response.estado}
        //     estadoAux.motivoRechazo = response.motivoRechazo
        //     estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
        //     dispatch(setEstadoCotizacion(estadoAux))
        // })
        // .catch((error) => {
        //     console.error('error',error)
        // })
        onClose()
    }

    const onCloseButton = () => {
        onClose()
    }

    const handleFecha = (e) => {
        setFecha(e.target.value)
    }

    const getFechaMinima = () => {
        if(propuesta){
            return propuesta.fechaEnviada?.split('T')[0]
        }
        else{
            return "2022-01-01"
        }
    }

    return (
        <ModalPortal
        onCancel={onCloseButton}
        onSave={onSave}
        title="Fecha cotizacion aceptada"
        isOpened={isOpened}

        >
            <Box display="flex">
            <TextField  
                label="Fecha"
                type={"date"}
                fullWidth
                value={fecha} 
                InputLabelProps={{ shrink: true }}  
                InputProps={{inputProps: {min:getFechaMinima(), max:"2900-05-04"}}}
                required
                onChange={handleFecha}/>
            </Box>
        </ModalPortal>
    )
}

export default ModalFechaAceptada