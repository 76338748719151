import React, { useContext, useEffect, useState } from 'react'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Button, FormControl, MenuItem, FormControlLabel, InputLabel, OutlinedInput, Select, Switch, Typography, ToggleButtonGroup, ToggleButton, Tooltip, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import Concepto from "components/Concepto";
import ModalConcepto from "components/ModalConcepto"
import { useHistory } from "react-router-dom";
//styled
import 'scss/components/tablaPropuestaEconomica.scss'
import { generarPropuesta, getProductos, obtenerPropuestaById, ordenarConceptos, postConcepto, setPropuesta, updateMuestraTotales, updatePresupuestoCerrado, updatePropuestaAuditoriaDiagnostico, updatePropuestaEstado, updatePropuestaTipoCalculo } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS, TIPO_ESTADO, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import { useParams } from 'react-router-dom';
import FooterPropuesta from 'components/FooterPropuesta';
import ModalMotivoRechazo from 'components/ModalMotivoRechazo/ModalMotivoRechazo';
import ModalFechaEnviada from 'components/ModalFechaEnviada';
import ModalFechaAceptada from 'components/ModalFechaAceptada';
import AnnouncementIcon from '@mui/icons-material/Announcement';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { dateToString } from 'utlis/stringUtils';
import ModalHistorial from 'components/ModalHistorial';
import ModalFechaDescartada from 'components/ModalFechaDescatada';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { _postConcepto } from 'services/Propuesta';
import ModalAuditoriaExtraOrdinaria from 'components/ModalAuditoriaExtraOrdinaria';
import ModalAprobacionEstado from 'components/ModalAprobacionEstado';
import usePermisos from 'hooks/usePermisos';
import { useDispatch } from 'react-redux';

const TabPropuestaEconomica = () => {
  const history = useHistory();
  const dispatchRedux = useDispatch()
  const { state, dispatch } = useContext(CotizacionContext)
  const { json, propuesta, estadoCarga, estadoCotizacion } = state
  const propuestaId = json.propuestas && json.propuestas.length !== 0 ? json.propuestas[0].id : -1
  const { idPropuesta } = useParams()
  const roles = usePermisos();

  const DesHabilitar = () => {
    if (propuesta.estado.permiteEdicion) {
      return estadoCarga.guardando;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (idPropuesta) {

    } else {
      if (propuestaId != -1 && propuestaId != undefined) {
        obtenerPropuestaById(dispatch, { id: propuestaId });
      }
    }
    getProductos(dispatch);
  }, [])


  const handleAuditoria = (e) => {
    updatePropuestaAuditoriaDiagnostico(dispatch, propuesta.id, e.target.checked, dispatchRedux)
  }

  const handleTotales = (e) => {
    const mostrarTotales = e.target.checked;
    updateMuestraTotales(dispatch, propuesta.id, mostrarTotales, dispatchRedux);
  }

  const handlePresupuestoCerrado = (e) => {
    updatePresupuestoCerrado(dispatch, propuesta.id, e.target.checked, dispatchRedux);
  }

  const handleSitios = (e, nuevoTipoCalculo) => {
    if (nuevoTipoCalculo !== null) {
      updatePropuestaTipoCalculo(dispatch, propuesta.id, nuevoTipoCalculo, dispatchRedux);
    }
  }

  const handlePropuesta = (e) => {
    obtenerPropuestaById(dispatch, { id: e.target.value })
  }

  //Modal
  const [modalState, setModalState] = useState({ opened: false });
  const handleModal = () => setModalState({ opened: !modalState.opened })

  const compareConceptos = (c1, c2) => c1.orden < c2.orden ? -1 : 1;

  const handleAgregarConcepto = () => {
    handleModal()
  }
  const handleAgregarAuditoriaExtraOrdinario = () => {
    handleModalAuditoriaExtra()
  }

  //Modal Motivo Rechazo
  const [modalMotivoRechazo, setModalMotivoRechazo] = useState(false)
  const handleModalMotivoRechazo = () => setModalMotivoRechazo(!modalMotivoRechazo)

  //Modal Fecha Enviada
  const [modalEnviada, setModalEnviada] = useState(false)
  const handleModalEnviada = () => setModalEnviada(!modalEnviada)

  //Modal Fecha Aceptada
  const [modalAceptada, setModalAceptada] = useState(false)
  const handleModalAceptada = () => setModalAceptada(!modalAceptada)

  //Modal Descartada
  const [modalDescartada, setModalDescartada] = useState(false)
  const handleModalDescartada = () => setModalDescartada(!modalDescartada)

  //Modal Historial
  const [modalHistorial, setModalHistorial] = useState(false)
  const handleModalHistorial = () => setModalHistorial(!modalHistorial)

  //Modal Nombre Auditoria ExtraOrdinaria
  const [modalAuditoriaExtra, setModalAuditoriaExtra] = useState(false)
  const handleModalAuditoriaExtra = () => setModalAuditoriaExtra(!modalAuditoriaExtra)

  //Modal Aprobacion Estado
  const [modalAprobacionEstado, setModalAprobacionEstado] = useState(false)
  const handleModalAprobacionEstado = () => setModalAprobacionEstado(!modalAprobacionEstado)

  const esJunior = () => {
    if (json.tipo === "GESTION") {
      return roles.includes(r => r === "RDAJuniorGestion");
    }

    if (json.tipo === "PRODUCTO") {
      return roles.includes(r => r === "RDAJuniorProducto");
    }

    if (json.tipo === "ALIMENTOS") {
      return roles.includes(r => r === "RDAJuniorAlimentos");
    }
  }

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    let posInicial = 1;
    result.forEach(concepto => concepto.orden = posInicial++);
    return result;
  }

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index &&
      source.droppableId === destination.droppableId) return;

    const conceptosAux = reorder(propuesta.conceptos, source.index, destination.index)
    dispatch(setPropuesta({ ...propuesta, conceptos: conceptosAux }))

    const conceptosOrdenados = conceptosAux.map(concepto => ({
      id: concepto.id,
      orden: concepto.orden
    }));

    ordenarConceptos(dispatch, { idPropuesta: propuesta.id, conceptosOrdenados }, dispatchRedux);

  }

  const getColorEstadoPropuesta = (estado) => {
    switch (estado) {
      case TIPO_ESTADO.ACEPTADA: {
        return 'success';
      }
      case TIPO_ESTADO.ENVIADA: {
        return 'warning';
      }
      case TIPO_ESTADO.DESCARTADA: {
        return 'error';
      }
      case TIPO_ESTADO.RECHAZADA: {
        return 'error';
      }
      default: {
        return;
      }
    }
  }

  const handleChangeEstadoPropuesta = (e) => {
    const estado = e.target.value
    switch (estado) {
      case TIPO_ESTADO.ENVIADA: {
        handleModalEnviada()
        break;
      }
      case TIPO_ESTADO.ACEPTADA: {
        handleModalAceptada()
        break;
      }
      case TIPO_ESTADO.RECHAZADA: {
        handleModalMotivoRechazo()
        break;
      }
      case TIPO_ESTADO.DESCARTADA: {
        handleModalDescartada()
        break;
      }
      default: {
        var data = {
          idPropuesta: propuesta.id,
          estado: e.target.value,
          motivoRechazada: ""
        }
        updatePropuestaEstado(dispatch, data, dispatchRedux)
        break;
      }
    }
  }

  const handleNuevaPropuesta = () => {
    generarPropuesta(dispatch, { idCotizacion: json.id, json: json }, dispatchRedux)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    history.push(`/solicitud/${json.idSolicitud}/cotizacion/${json.id}/propuesta/${propuesta.id}/documento`);
  }

  const getCambiarEstadoOptions = () => {
    const opciones = propuesta.estado.posiblesTransiciones?.map((item, index) =>
      <MenuItem
        key={'estados' + index}
        value={item}
        disabled={item === propuesta.estado.estado}
      >
        {item}
      </MenuItem>
    );

    opciones.push(<MenuItem disabled key={"estadoActual"} value={propuesta.estado.estado}>{propuesta.estado.estado}</MenuItem>)
    return opciones;
  }

  const renderInfoEstado = () => {

    if (propuesta.estado.estado === TIPO_ESTADO.RECHAZADA) {
      return (
        <Box display="flex" sx={{ height: "56px" }}>
          <Tooltip title={"Motivo rechazo: " + propuesta.motivoRechazada} sx={{ cursor: "pointer" }} onClick={setModalHistorial}>
            <AnnouncementIcon color="error"></AnnouncementIcon>
          </Tooltip>
        </Box>
      )
    }
    if (propuesta.estado.estado === TIPO_ESTADO.ENVIADA) {
      return (
        <Box display="flex" sx={{ height: "56px", paddingTop: "15px" }} >
          <Tooltip title={dateToString(propuesta.fechaEnviada)} sx={{ cursor: "pointer" }} onClick={setModalHistorial}>
            <DateRangeIcon color={getColorEstadoPropuesta(propuesta.estado.estado)} />
          </Tooltip>
        </Box>
      )
    }
    if (propuesta.estado.estado === TIPO_ESTADO.ACEPTADA) {
      return (
        <Box display="flex" sx={{ height: "56px", paddingTop: "15px" }} >
          <Tooltip title={dateToString(propuesta.fechaAceptada)} sx={{ cursor: "pointer" }} onClick={setModalHistorial}>
            <DateRangeIcon color={getColorEstadoPropuesta(propuesta.estado.estado)} />
          </Tooltip>
        </Box>
      )
    }
    if (propuesta.estado.estado === TIPO_ESTADO.DESCARTADA) {
      return (
        <Box display="flex" sx={{ height: "56px", paddingTop: "15px" }} >
          <Tooltip title={dateToString(propuesta.fechaDescartada)} sx={{ cursor: "pointer" }} onClick={setModalHistorial}>
            <DateRangeIcon color={getColorEstadoPropuesta(propuesta.estado.estado)} />
          </Tooltip>
        </Box>
      )
    }

  }

  const classNameTitulo = json && json.tipo === TIPO_SOLICITUD_PRODUCTO ? 'titulo-concepto-producto-head' : 'titulo-concepto-unified-head';

  const estadoEnAprobacion = "Estado pendiente de revisión - " + propuesta?.estadoEnAprobacion?.estado;
  const renderPropuesta = () => {

    if (propuestaId === -1) {
      return (<>
        <Box display="flex" sx={{ paddingRight: "20px" }}>
          <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
            <Button sx={{ width: "100px", height: "40px" }} onClick={handleNuevaPropuesta} variant="contained">Nueva Propuesta</Button>
            {/* <Button sx={{width: "100px", height: "40px"}} onClick={handleDuplicarPropuesta} variant="contained">Duplicar Propuesta</Button> */}
          </Restricted>
        </Box>
      </>)
    }
    if (json.propuestas.length > 0) {
      return (
        <div>
          <form onSubmit={handleSubmit}>
            <Box display="flex" justifyContent="space-between" sx={{ paddingBottom: "20px", backgroundColor: "" }}>
              <Box display="flex" flexDirection="column" sx={{ width: "100%" }} >
                {/* PRIMERA FILA */}
                <Box display="flex" alignItems={"center"} flexDirection="row" sx={{ width: "100%" }} justifyContent="space-between">
                  <Box display="flex" alignItems={"center"} flexDirection={"row"}>
                    <Box display="flex">
                      <FormControl fullWidth>
                        <InputLabel id="label-propuesta">Propuesta</InputLabel>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE} >
                          <Select
                            labelId='label-propuesta'
                            label="Propuesta"
                            name="propuesta"
                            // autoFocus={json.id}
                            style={{ width: "100%", marginRight: "2vw" }}
                            onChange={handlePropuesta}
                            disabled={estadoCarga.guardando}
                            value={propuesta.id}>
                            {
                              json.propuestas.map((p, index) =>
                                <MenuItem value={p.id} key={'menuitem-propuestas' + index}>Número {p.version}</MenuItem>
                              )
                            }
                          </Select>
                        </Restricted>
                      </FormControl>
                    </Box>
                    <Box sx={{ marginLeft: "10px" }} display="flex" gap={1}>
                      <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                        <Button sx={{ width: "200px", height: "40px" }} disabled={estadoCarga.guardando} onClick={handleNuevaPropuesta} variant="contained" startIcon={<AddCircleIcon />} >Nueva version</Button>
                        {/* <Button sx={{width: "150px", height: "40px"}} disabled={estadoCarga.guardando} onClick={handleDuplicarPropuesta} variant="outlined" startIcon={<ContentCopyIcon/>} >Duplicar</Button> */}
                      </Restricted>
                    </Box>

                  </Box>
                  <Box display="flex" flexDirection="row" gap={1} alignItems="flex-end">
                    <Button
                      disabled={estadoCarga.guardando}
                      type="submit"
                      sx={{ alignSelf: "center" }}
                      variant="contained">
                      Generar Documento
                    </Button>
                    <FormControl sx={{ width: "400px" }}>
                      {propuesta.estadoEnAprobacion && !propuesta.nuevoEstadoAprobado ?
                        <Box display="flex" flexDirection={"column"}>
                          <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <Select
                              labelId='label-estado'
                              label='Estado en aprobación'
                              disabled={true}
                              value={estadoEnAprobacion}
                              input={
                                <OutlinedInput
                                  variant="status"
                                  color={getColorEstadoPropuesta(estadoEnAprobacion)}
                                />}
                            >

                              <MenuItem
                                value={estadoEnAprobacion}
                              >
                                {estadoEnAprobacion}
                              </MenuItem>

                            </Select>
                          </Restricted>
                          {!esJunior() &&
                            <Box sx={{ marginTop: "10px" }} display="flex" flexDirection={"row"} justifyContent={"flex-end"}>
                              <Button onClick={handleModalAprobacionEstado} sx={{ marginLeft: "10px" }} variant="contained" color='primary'>Gestionar cambio de estado</Button>
                            </Box>
                          }
                        </Box> :
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                          <Select
                            labelId='label-estado'
                            label='Estado'
                            disabled={estadoCarga.guardando}
                            value={propuesta?.estado?.estado || ""}
                            onChange={handleChangeEstadoPropuesta}
                            input={
                              <OutlinedInput
                                variant="status"
                                color={getColorEstadoPropuesta(propuesta.estado.estado)}
                                autoFocus={false}
                              />}
                          >
                            {
                              propuesta.estado.posiblesTransiciones?.length > 0 ?
                                getCambiarEstadoOptions()
                                :
                                <MenuItem key={propuesta.estado.estado} value={propuesta.estado.estado}>{propuesta.estado.estado}</MenuItem>
                            }
                          </Select>
                        </Restricted>
                      }
                    </FormControl>
                    {renderInfoEstado()}
                  </Box>
                </Box>
                {/* SEGUNDA FILA */}
                <Box marginTop={"40px"} display="flex" flexDireccion="row" gap={2} sx={{ paddingTop: "10px" }}>
                  <Box alignItems="center" justifyContent={"center"} display="flex" gap={1}>
                  {json.tipo !== TIPO_SOLICITUD_FORMACION && <><Typography style={{ width: "60px" }}>Tipo de calculo: </Typography>
                    
                      
                      <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                      <ToggleButtonGroup
                        sx={{ height: "30px" }}
                        value={propuesta.tipoCalculo}
                        exclusive
                        disabled={DesHabilitar()}
                        onChange={handleSitios}
                        color="primary"
                        aria-label="tipo de calculo"
                      >
                        <ToggleButton value="GENERAL">General</ToggleButton>
                        {
                          json.usaMultisitios &&
                          <ToggleButton value="MULTISITIOS" >Sitios</ToggleButton>
                        }

                      </ToggleButtonGroup>
                    </Restricted></>}
                  </Box>
                  



                  <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <FormControlLabel
                      onChange={handleTotales}
                      disabled={DesHabilitar()}
                      checked={propuesta?.mostrarTotales}
                      control={<Switch />} label="Fila Totales" />
                  </Restricted>

                  <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <FormControlLabel
                      onChange={handlePresupuestoCerrado}
                      disabled={DesHabilitar()}
                      checked={propuesta?.presupuestoCerrado}
                      control={<Switch />} label="Presupuesto cerrado" />
                  </Restricted>
                </Box>
              </Box>
            </Box>

            <DragDropContext
              onDragEnd={onDragEnd}
            >
              <Box display="flex" className="headerTable" sx={{ paddingBottom: "10px" }}>
                <Box className={classNameTitulo}>
                  <Typography>Conceptos</Typography>
                </Box>
                {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box className="titulo-dias-iram">
                  <Typography>Dias IRAM</Typography>
                </Box>}
                {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box className="titulo-dias-organizacion">
                  <Typography>Dias Org.</Typography>
                </Box>}
                <Box className="titulo-moneda">
                  <Typography>Moneda</Typography>
                </Box>
                <Box className="titulo-arancel-new">
                  <Typography>Arancel Unitario</Typography>
                </Box>
                <Box className="titulo-bonificacion">
                  <Typography >Bonificacion</Typography>
                </Box>
                <Box className="titulo-recargo">
                  <Typography>Recargo</Typography>
                </Box>
                <Box className="titulo-total">
                  <Typography>ARANCEL TOTAL</Typography>
                </Box>
              </Box>
              <Droppable droppableId='ROOT' >
                {
                  (droppableProvided) => (
                    <div
                      {...droppableProvided.droppableProps}
                      ref={droppableProvided.innerRef}
                    >
                      {
                        propuesta.conceptos.sort(compareConceptos).map((item, index) => (
                          <Concepto key={item.id} id={item.id} index={index} item={item} propuesta={propuesta} auditoria={propuesta.usaAuditoriaDiagnostico} deshabilitar={DesHabilitar()} />
                        ))}
                      {droppableProvided.placeholder}
                    </div>
                  )}
              </Droppable>
            </DragDropContext>
          </form>
          {
            propuesta?.mostrarTotales &&
            <FooterPropuesta total={propuesta.total} conceptos={propuesta.conceptos}></FooterPropuesta>
          }

          <Box sx={{marginTop: 2}} display="flex" justifyContent="space-between">
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE} >
              <Button variant="outlined" disabled={DesHabilitar()} onClick={handleAgregarConcepto}>+ AGREGAR CONCEPTO</Button>
            </Restricted>
            <Box>
              {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && json.tipo !== TIPO_SOLICITUD_FORMACION && <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                      <FormControlLabel
                        onChange={handleAuditoria}
                        disabled={DesHabilitar()}
                        checked={propuesta?.usaAuditoriaDiagnostico}
                        control={<Switch />} label="Auditoria Diagnostico" />
                    </Restricted>}

              {
                json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && json.tipo !== TIPO_SOLICITUD_FORMACION &&
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                  <Button sx={{ height: "40px" }} disabled={DesHabilitar()} variant='contained' onClick={handleAgregarAuditoriaExtraOrdinario}>+ Auditoria extraordinaria</Button>
                </Restricted>
              }

            </Box>

          </Box>

          <ModalConcepto
            onClose={() => handleModal()}
            isOpened={modalState.opened}
            propuesta={propuesta}
          />

          <ModalMotivoRechazo
            onClose={() => handleModalMotivoRechazo()}
            isOpened={modalMotivoRechazo}
            propuesta={propuesta}
          />
          <ModalFechaEnviada
            onClose={() => handleModalEnviada()}
            isOpened={modalEnviada}
            propuesta={propuesta}
          />
          <ModalFechaAceptada
            onClose={() => handleModalAceptada()}
            isOpened={modalAceptada}
            propuesta={propuesta}
          />
          <ModalFechaDescartada
            onClose={() => handleModalDescartada()}
            isOpened={modalDescartada}
            propuesta={propuesta}
          />

          <ModalHistorial
            onClose={() => handleModalHistorial()}
            isOpened={modalHistorial}
            propuesta={propuesta}
          />

          <ModalAuditoriaExtraOrdinaria
            onClose={() => handleModalAuditoriaExtra()}
            isOpened={modalAuditoriaExtra}
            propuesta={propuesta}
          />

          <ModalAprobacionEstado
            onClose={() => handleModalAprobacionEstado()}
            isOpened={modalAprobacionEstado}
            propuesta={propuesta}
          />

        </div>
      )


    }
    else {
      return (<><CircularProgress /></>)
    }
  }
  return (
    <>
      {
        renderPropuesta()
      }
    </>
  )
}

export default TabPropuestaEconomica