import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { makeStyles } from '@mui/styles';
import ModalPortal from 'components/modalPortal'
import { TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import { AddGrupoSitios } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React, {useContext, useState} from 'react'
import { useDispatch } from 'react-redux';


const useStyles = makeStyles((theme) => ({
    container: {
        width: 592
    },
}));

const ModalGrupoSitio = ({onClose, isOpened, withSitio}) => {

    const classes = useStyles();

    //context global
    const dispatchRedux = useDispatch()

    //context cotizacion
    const {state, dispatch} = useContext(CotizacionContext)
    const {json} = state
    const {tipo} = json
    
    //estado local
    const [nuevoGrupo, setNuevoGrupo] = useState({id:0, tipo:'', nombre:'', coeficienteCertificacion:'', coeficienteSeguimiento:'', sitios:[{nombre:'',cantidadDeSitios:1,personal:0, diasCertificacion:''}]})
    const [errorNombre, setErrorNombre] = useState(false)

    const handleTipo = (e) => {
        setNuevoGrupo({...nuevoGrupo, tipo:e.target.value})
    }

    const handleNombre = (e) => {
        setNuevoGrupo({...nuevoGrupo, nombre:e.target.value})
        setErrorNombre(json.gruposDeSitios.some(g => g.nombre.toUpperCase() === e.target.value.toUpperCase()))
    }

    const handleCantidad = (e) => {
        let sitiosAux = [...nuevoGrupo.sitios]
        sitiosAux[0].personal = e.target.value
        setNuevoGrupo({...nuevoGrupo, sitios: sitiosAux})
    }

    const handleDias = (e) => {
        let sitiosAux = [...nuevoGrupo.sitios]
        sitiosAux[0].diasCertificacion = e.target.value 
        setNuevoGrupo({...nuevoGrupo, sitios: sitiosAux})
    }

    const onSave = () =>{
        let newGrupoSitio = {
            nombre: nuevoGrupo.nombre,
            tipo: nuevoGrupo.tipo === '' ? "PROPIOS": nuevoGrupo.tipo,
            coeficienteMuestreoCertificacion:0,
            coeficienteMuestreoRecertificacion:0,
            coeficienteMuestreoMantenimiento:0,
        }
        let sitios = [{
            nombre:nuevoGrupo.nombre,
            cantidadDeSitios:1,
            personal:nuevoGrupo.sitios[0].personal,
            diasCertificacion: nuevoGrupo.sitios[0].diasCertificacion === "" ? null : nuevoGrupo.sitios[0].diasCertificacion
        }]

        if(withSitio){
            newGrupoSitio = {...newGrupoSitio, sitios: sitios}
        }
        if(errorNombre){
            alert("Ya existe un grupo con el mismo nombre")
            setNuevoGrupo({id:0, tipo:'', nombre:'', coeficienteCertificacion:'', coeficienteSeguimiento:'', sitios:[{nombre:'',cantidadDeSitios:1,personal:0}]})
            onClose()
        }else{
            
            AddGrupoSitios(dispatch, json.id, newGrupoSitio)
            setNuevoGrupo({id:0, tipo:'', nombre:'', coeficienteCertificacion:'', coeficienteSeguimiento:'', sitios:[{nombre:'',cantidadDeSitios:1,personal:0, diasCertificacion:''}]})
            onClose()
        }
    }

    const onCloseButton = () =>{
        setNuevoGrupo({id:0, tipo:'', nombre:'', coeficienteCertificacion:'', coeficienteSeguimiento:'', sitios:[{nombre:'',cantidadDeSitios:1,personal:0}]})
        onClose()
    }

    const getTitulo = () => {
        if(tipo === TIPO_SOLICITUD_ALIMENTOS){  
            return "Nuevo Sitio"
        }else{
            withSitio?"Nuevo Grupo + Sitio":"Nuevo Grupo"
        }
    }

    return (
        <ModalPortal
        onCancel={onCloseButton}
        onSave={onSave}
        title={getTitulo()}
        isOpened={isOpened}
        onlyShow={errorNombre}
        >
            <Box display="flex" className={classes.container}>
                <Grid container direction="row">
                    <Grid item xs={12}>
                        <FormControl fullWidth >
                            <TextField 
                                label="Nombre" 
                                type="text" 
                                required
                                helperText={errorNombre?"Ya existe un grupo con el mismo nombre.":""}
                                error={errorNombre}
                                onChange={handleNombre} value={nuevoGrupo.nombre}/>
                        </FormControl>
                    </Grid>
                    {
                        tipo !== TIPO_SOLICITUD_ALIMENTOS ?
                        <>
                        <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id='label-tipo'>Tipo</InputLabel>
                            <Select 
                                labelId='label-tipo'
                                label="Tipo"
                                name="tipo"
                                required
                                value={nuevoGrupo.tipo}
                                onChange={handleTipo}>
                                    <MenuItem value="PROPIOS">Propio</MenuItem>
                                    <MenuItem value="TEMPORALES">Temporal</MenuItem>
                            </Select>
                        </FormControl>
                        </Grid>
                        {
                            withSitio &&
                            <Grid item xs={12}>
                                <TextField
                                    label="Personal"
                                    type="number"
                                    required
                                    fullWidth
                                    onChange={handleCantidad}
                                    value={nuevoGrupo.sitios[0].cantidad}
                            />
                            </Grid>
                        }
                        </>
                        :
                        <>
                        <Grid item xs={12}>
                            <TextField
                                label="Dias Auditor"
                                type="number"
                                required
                                fullWidth
                                onChange={handleDias}
                                value={nuevoGrupo.sitios[0].diasCertificacion}
                        />
                        </Grid>
                        </>
                    }
                    
                </Grid>
            </Box>
        </ModalPortal>
    )
}

export default ModalGrupoSitio
