import React, { useState, useContext } from 'react'
import ModalPortal from 'components/modalPortal'
import { Box } from '@mui/system'
import { parseISO } from 'date-fns'
import { TextField } from '@mui/material'
import { TIPO_ESTADO } from 'constants/constants';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { updatePropuestaEstado } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { useDispatch } from 'react-redux'

function ModalFechaEnviada({onClose, isOpened, propuesta}) {
    const dispatchRedux = useDispatch()
    const { dispatch } = useContext(CotizacionContext)

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-');
    }

    const [fecha, setFecha] = useState(formatDate(new Date()))
    const [error, setError] = useState(false)



    const onSave = () => {
        var data = {
            idPropuesta: propuesta.id,
            estado: TIPO_ESTADO.ENVIADA,
            posiblesTransiciones:null,
            FechaEnvio:parseISO(fecha)
        }
        updatePropuestaEstado(dispatch, data, dispatchRedux)
        onClose()
    }

    const onCloseButton = () => {
        onClose()
    }

    const handleFecha = (e) => {
        var fecha = e.target.value
        setFecha(fecha)
    }

    const getFechaMinima = () => {
        if(propuesta){
            return propuesta.fechaCreacion?.split('T')[0]
        }
        else{
            return "2022-01-01"
        }
    }

    return (
        <ModalPortal
        onCancel={onCloseButton}
        onSave={onSave}
        title="Fecha cotizacion enviada"
        isOpened={isOpened}

        >
            <Box display="flex">
            <TextField  
                label="Fecha"
                type={"date"}
                fullWidth
                error={error}
                value={fecha} 
                InputLabelProps={{ shrink: true }}  
                InputProps={{inputProps: {min:getFechaMinima(), max:"2900-05-04"}}}
                required
                onChange={handleFecha}/>
            </Box>
        </ModalPortal>
    )
}

export default ModalFechaEnviada