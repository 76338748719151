import { CircularProgress, LinearProgress } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import logoiram from 'assets/logo-iram-mobile.svg'

export default function LoaderIram(){
    
    return  <div className="loader-iram" >
                <img src={logoiram} alt="IRAM"/>
                <LinearProgress />
            </div>
}