import { forwardRef, useContext, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, Grid, IconButton, InputLabel, TextField, OutlinedInput, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { IMaskInput } from 'react-imask';

import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { deleteTurno } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import CustomDialog from 'components/customDialog';
import { classes } from './styles'

const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
   const { onChange, ...other } = props;
   return (
      <IMaskInput
         {...other}
         mask='00:00'
         placeholder='HH:MM'
         inputRef={ref}
         onAccept={(value) => onChange({ target: { name: props.name, value } })}
         overwrite
      />
   );
});

const AlimentosTurnosInput = (props) => {
   const { state, dispatch } = useContext(CotizacionContext);

   const { data, index, many, onChange, noEliminar, permiteEdicion } = props;
   const { descripcion, horarioInicio, horarioFin, personal, selected } = data;
   const [error, setError] = useState({ error: false, message: '' });
   const [openDialog, setOpenDialog] = useState(false)

   const {json} = state;

   const validate = (turno) => {
      const { descripcion, horarioInicio, horarioFin, personal } = turno;

      if (!descripcion) {
         setError({
            error: true,
            message: 'Ingrese una descripción'
         });
         return false;
      }

      if (horarioInicio.length < 5) {
         setError({
            error: true,
            message: 'Ingrese un horario de inicio'
         });
         return false;
      }

      if (horarioFin.length < 5) {
         setError({
            error: true,
            message: 'Ingrese un horario de fin'
         });
         return false;
      }

      if (!personal) {
         setError({
            error: true,
            message: 'Ingrese personal'
         });
         return false;
      }

      setError({
         error: false,
         message: ''
      });

      return true;
   }

   const handleChange = (event) => {
      const { name, value } = event;

      validate({
         ...data,
         [name]: value
      });

      onChange({
         ...data,
         [name]: value
      }, index);
   }

   const onDelete = () => {
      dispatch(deleteTurno(index));
   }

   const handleOpenDialog = () => {
      setOpenDialog(true)
   }

   const handleCloseDialog = () => {
      setOpenDialog(false)
   }

   const estado = () => {
      return permiteEdicion
   }

   const handleSetFormato = (event) => {
      
      let time = event.target.value
      let formattedTime = '';

      if(time.length === 5 ){

      }
      else{
         if (time.length === 1) {
            formattedTime = `0${time}:00`;
          } else if (time.length === 2) {
            formattedTime = `${time}:00`;
          } else if (time.length === 4) {
            const hour = time.substr(0, 2);
            const minute = time.substr(3);
            formattedTime = `${hour}:${minute}0`;
          }
          handleChange({ name: event.target.name, value: formattedTime })
      }
      
   }

   return (
      <Grid container>
         <Grid item xs={3} sx={classes.select}>
            <TextField
               label="Descripción"
               name="descripcion"
               type="text"
               required
               fullWidth
               disabled={estado()}
               value={descripcion}
               onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
            />
         </Grid>
         <Grid item xs={3}>
            <FormControl fullWidth>
               <InputLabel htmlFor="horarioInicio" sx={classes.inputLabel}>Hora Inicio</InputLabel>
               <OutlinedInput
                  name="horarioInicio"
                  id="horarioInicio"
                  onBlur={handleSetFormato}
                  required
                  disabled={estado()}
                  inputComponent={TextMaskCustom}
                  value={horarioInicio}
                  onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
               />
            </FormControl>
         </Grid>
         <Grid item xs={3}>
            <FormControl fullWidth>
               <InputLabel htmlFor="horarioFin" sx={classes.inputLabel}>Hora Fin</InputLabel>
               <OutlinedInput
                  name="horarioFin"
                  id="horarioFin"
                  required
                  disabled={estado()}
                  onBlur={handleSetFormato}
                  inputComponent={TextMaskCustom}
                  value={horarioFin}
                  onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
               />
            </FormControl>
         </Grid>
         <Grid item xs={3} sx={many ? classes.turnosDelete : ''}>
            <TextField
               label="Personas"
               name="personal"
               type="text"
               required
               disabled={estado()}
               fullWidth
               value={personal??""}
               onChange={(event) => handleChange({ name: event.target.name, value: Number(event.target.value) })}
            />
            {(many) &&
            <>
            {
               json.id == 0 &&
               <Box sx={classes.delete}>
                  <IconButton onClick={handleOpenDialog}>
                     <DeleteIcon />
                  </IconButton>
               </Box>
            }
            </>
               
            }
         </Grid>
         {error.error &&
            <Typography variant="subtitle2" sx={classes.error}>
               {error.message}
            </Typography>
         }
         <CustomDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            title={noEliminar ? null : "¿Confirma que desea eliminar el turno?"}
            content={noEliminar ? "No se pueden borrar todos los turnos" :
               "Se eliminarán los factores y modificaciones que haya hecho."}
            handleAgree={noEliminar ? handleCloseDialog : () => onDelete()}
            onlyAgree={!noEliminar}
         />
      </Grid>
   )
}

export default AlimentosTurnosInput;
