import { IconButton, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useContext, useState } from 'react'

import DeleteIcon from '@mui/icons-material/Delete';
import CalculateIcon from '@mui/icons-material/Calculate';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { deleteNormaSitio } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import CustomDialog from 'components/customDialog';

// Styles
import 'scss/components/paper-input-normas.scss';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';


function NormaChip({norma, setOpenDrawer, idGrupo, idSitio, noEliminarNormaChip}) {


    const {state, dispatch} = useContext(CotizacionContext)
    const {json, estadoCotizacion} = state

    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () =>{
        setOpenDialog(false)
    }

    const handleDelete = (e) => {
        const data = {
            idCotizacion: json.id,
            idGrupo: idGrupo,
            idSitio: idSitio,
            idNorma: norma.id,
        }
        deleteNormaSitio(dispatch, data)
        setOpenDialog(false)
    }
    return (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1} flexWrap="nowrap">
            <TextField label="Norma" value={norma.cotizacionNorma.norma.nombre} variant="outlined"></TextField>
           
            <IconButton variant="edit" onClick={setOpenDrawer} >
                <CalculateIcon/>
            </IconButton>
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
            {
                estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                <IconButton disabled={noEliminarNormaChip} variant="delete" onClick={()=>setOpenDialog(true)}>
                    <DeleteIcon/>
                </IconButton>
            }</Restricted>

            <CustomDialog
            openDialog={openDialog} 
            handleCloseDialog={handleCloseDialog} 
            title={"Desea eliminar este elemento?"}
            content="Se eliminarán los modificadores y las ediciones que haya hecho"
            handleAgree={() => handleDelete()}
            onlyAgree={true}
            />
        </Box>
    )
}

export default NormaChip
