import { 
    RESET_FILTROS, 
    RESET_STATE, 
    SET_ANIO, SET_CANTIDAD_POR_PAGINAS, 
    SET_CARGANDO_PAGINAS, 
    SET_ELABORADO, SET_FILTROS, 
    SET_ID_SOLICITUD, 
    SET_ESTADO_SOLICITUD,
    SET_LISTA_COTIZACIONES, 
    SET_LISTA_SOLICITUDES, 
    SET_NUMERO, 
    SET_NUMERO_PAGINA, 
    SET_ORGANIZACION, 
    SET_PAGINAS, 
    SET_PERSONAL, 
    SET_SOLICITUD,
    SET_SELECTED_CEP,
    SET_CEPS,    
    SET_DIRECCION,
    SET_CEP,
    SET_CANTIDAD_ESTUDIOS_HACCP,
    SET_TURNOS,
    SET_RESUMEN_PROPUESTAS,
    RESET_RESUMEN_PROPUESTAS
} from "context/solicitud/typesSolicitud/TypesSolicitud"

export const INITIAL_STATE = {
    //input de creacion
   
    numero: '',
    anio: 0,
    // organizaciones: [{id:0, nombre:''}],
    organizaciones: [],
    direccion:"",
    personalEfectivo: '',
    elaborado: '',
    cantidadEstudiosHaccp: null,
    turnos: [],
    idSolicitud:'',
    estado:'BORRADOR',
    tipo: null,

    //lista solicitudes home
    listaSolicitudes: [],
    listaCotizaciones: [{
        id:0,
        nombre:'',
        normas:[
            {norma:{nombre:''}}
            ],
        estado:''
    }],

    cep:{},
    resumenPropuestas: null,
    //inputs filtros
    filtros:{
        numeroSolicitud: '',
        anio: '',
        tipo: null,
        idUsuario: '',//como se maneja el id de usuario?
        idOrganizacion: '',
        idNorma: '',
        idIAF: '',
        idNACE: '',
        idSUBNACE: '',
        idCategoria: '',
        idSubCategoria: '',
        miUsuario:true,
        creacionDesde: '',//"2021-12-01T13:42:25.707Z",
        creacionHasta: '',//"2021-12-01T13:42:25.707Z",
        estado: '',
        ordenarPor: null,
        ordenarDescendente: true,
        numeroDePagina: 1,
        filasPorPagina: 10
    },
    //fin filtros

    //estado paginas buscador 
    paginas:{},
    cargando:false,
    cepsDisponibles: null,
    selectedCep: null,
}
export const reducer = (state, action) => {
    switch(action.type) {
        //----------------------Listas desplegables---------------------------
        case SET_LISTA_SOLICITUDES:
            return {...state, listaSolicitudes: action.payload}
        case SET_LISTA_COTIZACIONES:
                return {...state, listaCotizaciones: action.payload}
        //----------------------Solicitud State----------------------------
        case RESET_STATE: {
            return {...state,   numero:'',
                                elaborado:'',
                                // organizaciones:[{id:0, nombre:''}],
                                organizaciones:[],
                                personalEfectivo:'',
                                direccion:"",
                                estado:'BORRADOR'}
        }
     
        case SET_NUMERO:{
            return {...state, numero: action.payload}
        }
        case SET_ANIO: {
            return {...state, anio: action.payload}
        }
        case SET_ORGANIZACION:{
            return {...state, organizaciones: action.payload}
        }
        case SET_DIRECCION:{
            return {...state, direccion: action.payload}
        }
        case SET_PERSONAL:{
            return {...state, personalEfectivo: action.payload}
        }
        case SET_ELABORADO:{
            return {...state, elaborado: action.payload}
        }
        case SET_CANTIDAD_ESTUDIOS_HACCP:{
            return {...state, cantidadEstudiosHaccp: action.payload}
        }
        case SET_TURNOS:{
            return {...state, turnos: action.payload}
        }
        case SET_ID_SOLICITUD:{
            return {...state, idSolicitud: action.payload}
        }
        case SET_SOLICITUD:{
            return {
                ...state,   
                listaCotizaciones: action.payload.cotizaciones,
                numero: action.payload.numero,
                organizaciones: action.payload.organizaciones,
                elaborado: action.payload.usuario,
                personalEfectivo: action.payload.cantidadPersonal,
                cantidadEstudiosHaccp: action.payload.cantidadEstudiosHaccp,
                turnos: action.payload.turnos,
                anio: action.payload.anio,
                idSolicitud: action.payload.id,
                estado:action.payload.estado,
                tipo: action.payload.tipo        
            }
        }
       
        case SET_FILTROS:
            return {...state, filtros: action.payload}
        case SET_NUMERO_PAGINA:
            return {...state, numeroPagina: action.payload}
        case SET_CANTIDAD_POR_PAGINAS:
            return {...state, cantidadPorPagina: action.payload}
        case SET_PAGINAS:
            return {...state, paginas: action.payload}
        case SET_CARGANDO_PAGINAS: 
            return {...state, cargando: action.payload}
        case RESET_FILTROS:
            return {...state, filtros: {      numeroSolicitud: '',
            anio: '',
            tipo: null,//por ahora no se usa este dato
            idUsuario: '',//como se maneja el id de usuario?
            idOrganizacion: '',
            idNorma: '',
            idIAF: '',
            idNACE: '',
            miUsuario:true,
            creacionDesde:'',//"2021-12-01T13:42:25.707Z",
            creacionHasta:'',//"2021-12-01T13:42:25.707Z",
            estado: '',
            ordenarPor: null,
            ordenarDescendente: true,
            numeroDePagina: 1,
            filasPorPagina: 10}}
        case SET_ESTADO_SOLICITUD:
            return {...state, estado: action.payload}
        case SET_CEPS:
            return {...state, cepsDisponibles: action.payload}
        case SET_SELECTED_CEP:
            return {...state, selectedCep: action.payload}

        case SET_CEP:
            return {...state, cep: action.payload}
        case SET_RESUMEN_PROPUESTAS:
            return {...state, resumenPropuestas: action.payload}
        case RESET_RESUMEN_PROPUESTAS:
            return {...state, resumenPropuestas: null}
        default:
            return state;
            
    }
}
