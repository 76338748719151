import { useContext } from 'react';
import { Avatar, Box, IconButton, Typography }  from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
 
import { LOGOUTURL } from 'config/config';
import { useSelector } from 'react-redux';

const classes = {
   button: {
      ml: 1
   },
   name: {
      pl: 2
   },
   root: {
      display: 'flex',
      alignItems: 'center',
      ml: 8
   }
}

const User = () => {
   const globalSlice = useSelector(state => state.global)
   const { usuario } = globalSlice

   const nombreUsuario = usuario.user_claims.find(u => u.typ === 'name').val
   
   const handleLogOut = () => {
      window.location.href = LOGOUTURL;
   }

   return (
      <Box sx={classes.root}>
         <Avatar>{nombreUsuario.charAt(0)}</Avatar>
         <Typography sx={classes.name} className='username' component="div" variant="h7">
            {nombreUsuario}
         </Typography>
         <IconButton onClick={handleLogOut} sx={classes.button}>
            <LogoutIcon></LogoutIcon>
         </IconButton>
      </Box>
   )
}

export default User;