import React, {useEffect, useState,useContext} from 'react'
import { TextField, Input, Grid, InputLabel, FormControl,  Button, FormHelperText, InputAdornment} from '@mui/material'
import CreateIcon from '@mui/icons-material/Create';
import { updateNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { Box } from '@mui/system';
 

//style
import 'scss/components/diasAuditorUsuario.scss'
import { PERMISOS, TIPO_ESTADO, TIPO_GRUPO_SITIO, TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { useDispatch } from 'react-redux';

const DiasAuditorUsuario = ({norma, idGrupo, idSitio}) => {
    //GlobalContext
    const dispatchRedux = useDispatch()

    //CONTEXT COTIZACION
    const {state, dispatch} = useContext(CotizacionContext); 
    const {estadoCotizacion} = state
    
    //valores tabla
    const {diasCertificacionTabla, diasMantenimientoTabla, diasRecertificacionTabla } = norma
    
    //valores modificado
    const {diasCertificacionAjustado, diasMantenimientoAjustado, diasRecertificacionAjustado} = norma

    //valores minimos
    const {diasCertificacionMinimo, diasMantenimientoMinimo, diasReCertificacionMinimo} = norma

    //valores integrados
    const {diasCertificacionIntegrado, diasMantenimientoIntegrado, diasRecertificacionIntegrado} = norma
    
    //valores justificacion y estado de carga
    const {cantidadMantenimientos} = norma
    const {json, estadoCarga} = state

    var grupoaux = json.gruposDeSitios.find(g => g.id === idGrupo)

    var sitioAux = grupoaux.sitios.find(s => s.id === idSitio)

    const {tipo} = json
    var jerarquia = norma.cotizacionNorma.norma.jerarquiaAlimentos
    
    //const grupoGeneral = json.grupoDeSitios.find(g => g)
    
    //STATE LOCAL
    const [etapa1, setEtapa1] = useState('')
    const [etapa2, setEtapa2] = useState('')
    const [localCertificacion, setCertificacion] = useState('')
    const [localMantenimiento, setMantenimiento] = useState('')
    const [localRecertificacion, setRecertificacion] = useState('')
    const [localJustificacion, setJustificacion] = useState('')

    const [errorEtapa1, setErrorEtapa1] = useState(false)
    const [errorCertificacion, setErrorCertificacion] = useState(false)
    const [errorMantenimiento, setErrorMantenimiento] = useState(false)
    const [errorRecertificacion, setErrorRecertificacion] = useState(false)

    const [redistribucion, setRedistribucion] = useState(false)
    const [cambioCertificacion, setCambioCertificacion] = useState(false)
    const [cambioMantenimiento, setCambioMantenimiento] = useState(false)
    const [cambioRecertificacion, setCambioRecertificacion] = useState(false)
    const [justificacionObligatoria, setJustificacionObligatoria] = useState(true)
    const [guardar, setGuardar] = useState(false)

    useEffect(() => {
        setEtapa1(norma.diasCertificacionEtapa1?.toFixed(2))
        setEtapa2(norma.diasCertificacionEtapa2?.toFixed(2))
        setCertificacion(norma.diasCertificacionUsuario?.toFixed(2))
        setMantenimiento(norma.diasMantenimientoUsuario?.toFixed(2))
        setRecertificacion(norma.diasRecertificacionUsuario?.toFixed(2))
        setJustificacion(norma.justificacion? norma.justificacion:"")
    }, [norma])

    //HANDLE INPUTS
    const handleEtapa1 = (e) =>{
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            setEtapa1(e.target.value)
        }
    }
    const handleEtapa2 = (e) =>{
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            setEtapa2(e.target.value)
        }
    }
    const handleRecertificacion = (e) =>{
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            setRecertificacion(e.target.value)
        }
    }
    const handleMantenimiento = (e) =>{
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            setMantenimiento(e.target.value)
        }
    }
    const handleJustificacion = (e) =>{
        setJustificacion(e.target.value)
    }

    const handleCertificacion = (e) => {
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            setCertificacion(e.target.value)
        }
    }

    //SUMA ETAPAS
    useEffect(() => {
        if(diasCertificacionTabla){
            let e1 = parseFloat(etapa1)
            let e2 = parseFloat(etapa2)
            if(e1 % 0.5 !== 0){
                
                setErrorEtapa1(true)
            }
            else{	
                let grupoCasaCentral = json.gruposDeSitios?.find(g => g.tipo === TIPO_GRUPO_SITIO.CASA_CENTRAL);	
                let sitioDentroDeCasaCentral = grupoCasaCentral?.sitios?.some(s => s.id === idSitio);	
                if(e1 === 0 && sitioDentroDeCasaCentral){	
                    setErrorEtapa1(true)	
                }else{	
                    setErrorEtapa1(false)	
                }	
            }
            const suma = parseFloat((e1+e2).toFixed(2))
            const minimoAux = parseFloat(diasCertificacionMinimo.toFixed(2))

            if(isNaN(suma)){
                setCertificacion(diasCertificacionTabla)
            }else{
                setCertificacion(suma.toFixed(2))
            }

            const diffEtapa1 = Math.abs(e1 - norma.diasCertificacionEtapa1);
            const diffEtapa2 = Math.abs(e2 - norma.diasCertificacionEtapa2);
            
            if(diffEtapa1 < 0.01 && diffEtapa2 < 0.01){//SIN CAMBIOS NI REDISTRIBUCION DE DIAS
                setErrorCertificacion(false)
                setCambioCertificacion(false)
                setRedistribucion(false)
            }
            else{
                setCambioCertificacion(true)
                if(suma >= minimoAux){
                    if(suma === minimoAux){//la suma es igual entonces se redistribulleron los dias en las etapas
                        setRedistribucion(true)
                    }
                    setErrorCertificacion(false)
                }
                else{
                    setRedistribucion(false)
                    setErrorCertificacion(true)
                }
            }
        }
        else{
            setErrorEtapa1(false)
            setErrorCertificacion(false)
        }
    }, [etapa1, etapa2])

   //EDICION CERTIFICACION -- ALIMENTOS
   useEffect(() => {
    if(json.tipo === TIPO_SOLICITUD_ALIMENTOS){
        if(diasCertificacionTabla){
            const certificacionAux = parseFloat(localCertificacion)
            
            const certificacionMinimo = parseFloat(diasCertificacionMinimo?.toFixed(2))
            if(certificacionAux === certificacionMinimo){
                setErrorCertificacion(false)
                setCambioCertificacion(false)
            }
            else{
                setCambioCertificacion(true)
                if(certificacionAux >= certificacionMinimo){
                    setErrorCertificacion(false)
                }
                else{
                    setErrorCertificacion(true)
                }
            }
        }
        else{
            setErrorCertificacion(false)
        }
    }
    else{

    }
    
}, [localCertificacion])
    

    //EDICION MANTENIMIENTO
    useEffect(() => {
        if(diasMantenimientoTabla){
            const mantenimientoAux = parseFloat(localMantenimiento)
            const mantenimientoMinimo = parseFloat(diasMantenimientoMinimo?.toFixed(2))
            if(mantenimientoAux === mantenimientoMinimo){
                setErrorMantenimiento(false)
                setCambioMantenimiento(false)
            }else{
                setCambioMantenimiento(true)
                if(mantenimientoAux >= mantenimientoMinimo){
                    setErrorMantenimiento(false)
                }else{
                    setErrorMantenimiento(true)
                }
            }
        }
    }, [localMantenimiento])

    //EDICION RECERTIFICACION
    useEffect(() => {
        if(diasRecertificacionTabla){
            const recertificacionAux = parseFloat(localRecertificacion)
            const recertificacionMinimo = parseFloat(diasReCertificacionMinimo?.toFixed(2))
            if(recertificacionAux === recertificacionMinimo){
                setErrorRecertificacion(false)
                setCambioRecertificacion(false)
            }
            else{
                setCambioRecertificacion(true)
                if(recertificacionAux >= recertificacionMinimo){
                    setErrorRecertificacion(false)
                }
                else{
                    setErrorRecertificacion(true)
                }
            }
        }
        else{
            setErrorRecertificacion(false)
        }
    }, [localRecertificacion])


    //VALIDACION DE DATOS
    useEffect(() => {
        if(errorEtapa1 || errorCertificacion || errorMantenimiento || errorRecertificacion){//se detecto al menos un error
            setGuardar(false)
        }
        else{
            if(cambioCertificacion || cambioMantenimiento || cambioRecertificacion){//se detecto al menos un cambio
                if(redistribucion){
                    if(cambioMantenimiento || cambioRecertificacion){
                        setJustificacionObligatoria(true)
                    }else{
                        setJustificacionObligatoria(false)
                    }
                }else{
                    setJustificacionObligatoria(true)
                }
                setGuardar(true)
            }
            else{
                setGuardar(false)
            }
        }
    }, [errorEtapa1, errorCertificacion, errorMantenimiento, errorRecertificacion, cambioCertificacion, cambioMantenimiento, cambioRecertificacion,redistribucion])

    //BUTTONS
    const handleSubmit = (e) =>{
        e.preventDefault()
        
        let normaAux = {...norma}

        if(diasCertificacionTabla){
            normaAux.diasCertificacionEtapa1 = parseFloat(etapa1)
            normaAux.diasCertificacionEtapa2 = parseFloat(etapa2)
            normaAux.diasCertificacionUsuario = parseFloat(localCertificacion)
        }
        if(diasRecertificacionTabla){
            normaAux.diasRecertificacionUsuario = parseFloat(localRecertificacion)
        }
        if(diasMantenimientoTabla){
            normaAux.diasMantenimientoUsuario = parseFloat(localMantenimiento)
        }
        normaAux.justificacion = localJustificacion
        
        updateNorma(dispatch, json.id, idGrupo, idSitio, normaAux)
    }
    const handleCancelar = () =>{
        setEtapa1(norma.diasCertificacionEtapa1?.toFixed(2))
        setEtapa2(norma.diasCertificacionEtapa2?.toFixed(2))
        setCertificacion(norma.diasCertificacionUsuario?.toFixed(2))
        setMantenimiento(norma.diasMantenimientoUsuario?.toFixed(2))
        setRecertificacion(norma.diasRecertificacionUsuario?.toFixed(2))
        setJustificacion(norma.justificacion? norma.justificacion:"")
    }

    //RENDER

    const renderCertificacion = () => {
        if(diasCertificacionTabla){
            if(jerarquia === 3){
                return(
                    <Grid>
                        <FormControl sx={{width:"250px"}}>
                            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <TextField 
                                label="Certificacion"
                                error={errorCertificacion}
                                value={localCertificacion}
                                onChange={handleCertificacion}
                                disabled={inputEditable()}
                                sx={{width:"130px", marginLeft:"0.5vw"}}
                                
                            />
                            </Restricted>
                            <FormHelperText id="my-helper-text" className='my-helper-text'>
                                <>
                                <span>{"Inicial Tabla " + diasCertificacionTabla.toFixed(2)}</span>
                                <span>{"Modificado " + diasCertificacionAjustado.toFixed(2)}</span>
                                {
                                    norma.integrada &&
                                    <span >{"Integrado " + diasCertificacionIntegrado.toFixed(2)}</span> 
                                }
                                </>
                            </FormHelperText>
                        </FormControl>
                    </Grid>
                )
            }else{
                return (
                    <Box sx={{width:"250px", paddingTop:"10px"}} alignSelf="flex-start">
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <TextField 
                            label="Etapa 1"
                            value={etapa1}
                            onChange={handleEtapa1}
                            disabled={inputEditable()}
                            error={errorEtapa1}
                            sx={{width:"120px"}}
                            InputProps={{
                                endAdornment:<InputAdornment position="end"><CreateIcon/></InputAdornment>,
                            }}/>
                        </Restricted>
                        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <TextField 
                            label="Etapa 2"
                            value={etapa2}
                            onChange={handleEtapa2}
                            disabled={inputEditable()}
                            sx={{width:"120px", marginLeft:"0.5vw"}}
                            InputProps={{
                                endAdornment:<InputAdornment position="end"><CreateIcon/></InputAdornment>
                            }}/>
                        </Restricted>
                        <FormControl sx={{width:"250px", marginTop:"20px"}}>
                            <InputLabel htmlFor="my-input">Certificacion</InputLabel>
                            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                            <Input  id="my-input" 
                                    aria-describedby="my-helper-text" 
                                    value={localCertificacion} 
                                    disabled={inputEditable()}
                                    error={errorCertificacion}/>
                            </Restricted>
                            <FormHelperText id="my-helper-text" className='my-helper-text'>
                                <>
                                <span>{"Inicial Tabla " + diasCertificacionTabla.toFixed(2)}</span>
                                <span>{"Modificado " + diasCertificacionAjustado.toFixed(2)}</span>
                                {
                                    norma.integrada &&
                                    <span >{"Integrado " + diasCertificacionIntegrado.toFixed(2)}</span> 
                                }
                                </>
                                </FormHelperText>
                        </FormControl>
                    </Box>
                    )
            }
            
        }
            
    }
    const renderRecertifacion = () => {
        let labelValores = "Inicial: " + diasRecertificacionTabla?.toFixed(2) +" Modificado: "+ diasRecertificacionAjustado?.toFixed(2)
        if(norma.diasRecertificacionIntegrado){
            labelValores = labelValores + " Integrado " + diasRecertificacionIntegrado.toFixed(2)
        }
        if(diasRecertificacionTabla){
            return(
                <Grid>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField 
                    label="Recertificacion"
                    error={errorRecertificacion}
                    value={localRecertificacion}
                    onChange={handleRecertificacion}
                    disabled={inputEditable()}
                    sx={{width:"140px", marginLeft:"0.5vw"}}
                    helperText={labelValores}
                    InputProps={{
                        endAdornment:<InputAdornment position="end"><CreateIcon/></InputAdornment>,
                    }}></TextField>
                    </Restricted>
                </Grid>   
            )
        }
    }
    const renderMantenimiento = () => {
        let labelValores="Inicial " + diasMantenimientoTabla?.toFixed(2) + " Modificado " + diasMantenimientoAjustado?.toFixed(2)
        if(diasMantenimientoIntegrado){
            labelValores = labelValores + " Integrado " + diasMantenimientoIntegrado.toFixed(2)
        }
        if(diasMantenimientoTabla){
            return(
            <Grid >
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
            <TextField 
                label="Mantenimiento"
                error={errorMantenimiento}
                value={localMantenimiento}
                onChange={handleMantenimiento}
                disabled={inputEditable()}
                sx={{width:"130px", marginLeft:"0.5vw"}}
                helperText={labelValores}
                InputProps={{
                    endAdornment:<InputAdornment position="end"><CreateIcon/>x{cantidadMantenimientos}</InputAdornment>,
                }}></TextField>
                </Restricted>
            </Grid>                
            )
        }
    }

    const inputEditable = () => {
        if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR){
            return estadoCarga.guardando
        }else{
            return true
        }
    }

    return (
        <Grid item container
            direction="row"
            justifyContent="space-between"
            alignItems="center">
        {
            renderCertificacion()
        }
        {
            renderRecertifacion()
        }
        {
            renderMantenimiento()
        }
        <form onSubmit={handleSubmit} style={{width:"350px"}}>
        <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
            <TextField
            label="Justificacion"
            multiline
            fullWidth
            rows={5}
            value={localJustificacion}
            onChange={handleJustificacion}
            disabled={estadoCarga.guardando}
            required={justificacionObligatoria}
            sx={{marginBottom:"20px"}}
            /></Restricted>
            {
                estadoCotizacion.permiteEdicion &&
                <>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <Button onClick={handleCancelar} 
                    variant="outlined" 
                    color="secondary" 
                    sx={{marginRight:"20px"}}
            >Cancelar</Button>
            <Button type="submit" variant="outlined" disabled={!guardar}>Guardar</Button>
            </Restricted>
                </>
            }
        </form>
        </Grid>
    )
}

export default DiasAuditorUsuario