import { Box, TextField } from '@mui/material'
import ModalPortal from 'components/modalPortal'
import { setPropuesta } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import React, { useContext, useState } from 'react'
import { _postConcepto } from 'services/Propuesta'

function ModalAuditoriaExtraOrdinaria({onClose, isOpened, propuesta}) {

    const {state, dispatch} = useContext(CotizacionContext)

    const [nombre, setNombre] = useState("")

    const handleNombre = (e) => {
        setNombre(e.target.value)
    }
    const onCloseButton = () => {
        onClose()
    }

    const onSave = () => {
        const data = {
            concepto:{
                nombre:nombre,
                comentario:"",
                bonificacion:0,
                recargo:0,
                tipo:"AUDITORIA_EXTRAORDINARIA"
            },
            idPropuesta: propuesta.id
          }
          _postConcepto(data)
          .then(response => {
              let propuestaAux = {...state.propuesta}
              propuestaAux.conceptos.push(response)
        
              dispatch(setPropuesta(propuestaAux))
          })
          .catch(error => {
              console.error('error', error)
          })  
        onClose()
    }

  return (
    <ModalPortal
    onCancel={onCloseButton}
    onSave={onSave}
    title="Auditoria Extraordinaria"
    isOpened={isOpened}

    >
        <Box display="flex">
        <TextField
            label="Nombre Auditoria Extraordinaria"
            fullWidth
            value={nombre} 
            InputLabelProps={{ shrink: true }}  
            required
            onChange={handleNombre}/>
        </Box>
    </ModalPortal>
  )
}

export default ModalAuditoriaExtraOrdinaria