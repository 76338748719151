import { Box } from '@mui/system';
import AccordionGrupoVisita from 'components/AccordionGrupoVisita';
import SitioUnico from 'components/SitioUnico';
import { TIPO_GRUPO_SITIO } from 'constants/constants';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React, { useContext } from 'react'

function TabVisitas() {

    //CONTEXT
    const {state} = useContext(CotizacionContext);
    const {json} = state

    const gruposDeSitios = json.gruposDeSitios.filter(g => g.tipo !== TIPO_GRUPO_SITIO.GENERAL);

  return (
    <>
    {
    gruposDeSitios &&
    <>
        {
            gruposDeSitios.map((grupo, index) =>                  
              <AccordionGrupoVisita key={'index'+index} grupo={{...grupo, idCotizacion: json.id}}/>
              )
        }
    </>
    }
    {/* <Box display="flex" justifyContent="space-between" sx={{paddingTop:"20px"}}>
        <Button startIcon={<AddIcon/>} variant='contained' onClick={() => handleModal(false)}>AGREGAR GRUPO DE SITIOS</Button>
        <Button startIcon={<AddIcon/>} variant='outlined' onClick={() => handleModal(true)}>AGREGAR GRUPO Y SITIO</Button>
    </Box> */}
    </>
  )
}

export default TabVisitas