import React, { useContext, useEffect, useState } from 'react'
import {Grid, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, IconButton, FormControlLabel, Checkbox} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search';
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import { buscarPagina, resetFiltros, setFiltros } from 'context/solicitud/actionsSolicitud/ActionsSolicitud';
import InputOrganizaciones from 'components/InputOrganizaciones';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
 
import { PERMISOS, TIPO_SOLICITUD, TIPO_SOLICITUD_ALIMENTOS } from 'constants/constants';
import { useSelector } from 'react-redux';

const classes = {
   me: {
      display: 'flex'
   }
}

const Filtros = () => {
   const globalSlice = useSelector(state => state.global)
   const {listaNormas, listaCodigosIAF, listaEstadosSolicitud, listaUsuarios, usuario} = globalSlice
   const {stateSolicitud, dispatchSolicitud} = useContext(SolicitudContext);
   const { filtros } = stateSolicitud;
   const [listaNace, setListaNace] = useState([]);
   const [listaSubNace, setListaSubNace] = useState([]);
   const isFood = filtros.tipo === TIPO_SOLICITUD_ALIMENTOS ? true : false;

   const handleCheck = (e) => {
      dispatchSolicitud(setFiltros({...filtros, miUsuario: e.target.checked, idUsuario:e.target.checked?'':''}))
   }
   const handleNumero = (e) => {
      dispatchSolicitud(setFiltros({...filtros, numeroSolicitud: parseInt(e.target.value)}))
   }
   const handleAnio = (e) => {
      dispatchSolicitud(setFiltros({...filtros, anio: parseInt(e.target.value)}))
   }

   const handleTipo = (e) => {
      if(e.target.value === TIPO_SOLICITUD_ALIMENTOS){
         return dispatchSolicitud(setFiltros({...filtros, idIAF: 3, tipo: e.target.value}))
      } 
      
      dispatchSolicitud(setFiltros({...filtros, tipo: e.target.value}))
   }

   const handleUsuario = (e) =>{
      dispatchSolicitud(setFiltros({...filtros, idUsuario: e.target.value, miUsuario:false}))
   }
   const handleOrganizacion = (idOrganizacion) => {
      dispatchSolicitud(setFiltros({...filtros, idOrganizacion: idOrganizacion}))
   }

   const handleNorma = (e) => {
      dispatchSolicitud(setFiltros({...filtros, idNorma: e.target.value}))
   }

   const handleIAF = (e) => {
      if(e.target.value !== 3){
         return dispatchSolicitud(setFiltros({...filtros, tipo: null, idIAF: e.target.value}))
      }
      
      dispatchSolicitud(setFiltros({...filtros, idIAF: e.target.value}))
   }

   const handleNace = (e) =>{
      dispatchSolicitud(setFiltros({...filtros, idNACE:e.target.value}))
   }

   const handleSubNace = (e) =>{
      dispatchSolicitud(setFiltros({...filtros, idSUBNACE:e.target.value}))
   }

   const handleEstado = (e) => {
      dispatchSolicitud(setFiltros({...filtros, estado: e.target.value}))
   }

   const handleDesde = (e) => {
      const fecha = e.target.value
      dispatchSolicitud(setFiltros({...filtros, creacionDesde: fecha}))
   }

   const handleHasta = (e) => {
      const fecha = e.target.value
      dispatchSolicitud(setFiltros({...filtros, creacionHasta: fecha}))
   }

   //DESELECT  
   const handleTipoDeselect = (e) => {
      const value = e.target.dataset.value
      if(value === filtros.tipo){
         dispatchSolicitud(setFiltros({...filtros, tipo: null}))
      }
   }

   const handleIAFDeselect = (e) => {
      const value = parseInt(e.target.dataset.value)
      if(value === filtros.idIAF){
         dispatchSolicitud(setFiltros({...filtros, idIAF: null}))
      }
   }

   const handleNaceDeselect = (e) => {
      const value = parseInt(e.target.dataset.value)
      if(value === filtros.idNACE){
         dispatchSolicitud(setFiltros({...filtros, idNACE: null, idSUBNACE: null}))
      }
   }

   const handleSubNaceDeselect = (e) => {
      const value = parseInt(e.target.dataset.value)
      if(value === filtros.idSUBNACE){
         dispatchSolicitud(setFiltros({...filtros, idSUBNACE: null}))
      }
   }

   const handleNormaDeselect = (e) => {
      const value = parseInt(e.target.dataset.value)
      if(value === filtros.idNorma){
         dispatchSolicitud(setFiltros({...filtros, idNorma: null}))
      }
   }

   const handleEstadoDeselect = (e) => {
      const value = e.target.dataset.value
      if(value === filtros.estado){
         dispatchSolicitud(setFiltros({...filtros, estado: null}))
      }
   }

   const handleUsuarioDeselect = (e) => {
      const value = parseInt(e.target.dataset.value)
      if(value === filtros.idUsuario){
         dispatchSolicitud(setFiltros({...filtros, idUsuario: null}))
      }
   }

   const handleBuscar = () =>{
      buscarPagina(dispatchSolicitud, filtros)
   }
    
   const handleClearFiltros = () =>{
      setListaNace([])
      dispatchSolicitud(resetFiltros())
   }

   useEffect(() => {
      if(filtros.idIAF){
            const listaNaceByIAF = listaCodigosIAF.find(c => c.codigo === filtros.idIAF).nacEs;
         setListaNace(listaNaceByIAF);
      }
   }, [filtros.idIAF, listaCodigosIAF])

   useEffect(() => {
      if(filtros.idNACE && isFood){
         const listaSubNaceByNace = listaNace.find(n => n.id === filtros.idNACE).subNaces;
         setListaSubNace(listaSubNaceByNace);   
      }
   }, [filtros.idNACE, listaNace, isFood])

   useEffect(() => {
      if(usuario.user_claims.find(u => u.typ === 'roles').val === PERMISOS.LECTURA){
         dispatchSolicitud(setFiltros({...filtros, miUsuario:false}))
      }
   }, [])

   return (
      <Grid container >
         <Grid item xs={12}>
            <Typography variant="h5" fontWeight="bold">Buscador Solicitudes</Typography>
         </Grid>
         <Grid item xs={3}>
            <TextField  label='Número'
               fullWidth
               type="number"
               value={filtros.numeroSolicitud}
               onChange={handleNumero}
            />
         </Grid>
         <Grid item xs={2}>
            <TextField  label='Año'
               fullWidth
               type="number"
               value={filtros.anio}
               onChange={handleAnio}
            />
         </Grid>
         <Grid item xs={3}>
            <FormControl fullWidth>
               <InputLabel id="tipo-label">Tipo</InputLabel>
               <Select
                  label="Tipo"
                  labelId='tipo-label'
                  value={filtros.tipo}
                  name="tipo"
                  onChange={handleTipo}
                  onClick={handleTipoDeselect}
               >
                  { TIPO_SOLICITUD.map((tipo, index) => 
                     <MenuItem key={'tipo'+index} 
                        value={tipo.key}>{tipo.value}
                     </MenuItem>)
                  }
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={3}>
            <FormControl fullWidth>
               <InputLabel id="usuario-label">Usuario</InputLabel>
               <Select
                  label="Usuario"
                  labelId='usuario-label'
                  value={filtros.idUsuario}
                  name="usuario"
                  onChange={handleUsuario}
                  onClick={handleUsuarioDeselect}
               >
                  { listaUsuarios.map((usuario, index) => 
                     <MenuItem key={'usuario'+index} 
                        value={usuario.id}>{usuario.nombre}
                     </MenuItem>)
                  }
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={1} sx={classes.me}>
            <FormControlLabel 
               control={
                  <Checkbox   
                     checked={filtros.miUsuario} 
                     onChange={handleCheck}
                  />
               } 
               label="Yo" 
            />
         </Grid>
         <Grid item xs={6}>
            <InputOrganizaciones 
               handleOrganizacion={handleOrganizacion} 
               multiple={false}
               required={false}
            />
         </Grid>
         <Grid item xs={6}>
            <FormControl fullWidth>
               <InputLabel id="label-norma-buscador">Norma</InputLabel>
               <Select
                  label="Norma"
                  labelId="label-norma-buscador"
                  name="norma"
                  value={filtros.idNorma}
                  onChange={handleNorma}
                  onClick={handleNormaDeselect}
               >
                  {
                     listaNormas.map((norma, index)=>
                        <MenuItem key={'norma-buscador-index'+index} value={norma.id}>{norma.nombre}</MenuItem>
                     )
                  }
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={6}>
            <FormControl fullWidth>
               <InputLabel id="label-iaf-buscador">IAF</InputLabel>
               <Select
                  label="IAF"
                  unselectable=''
                  labelId="label-iaf-buscador"
                  value={filtros.idIAF}
                  onChange={handleIAF}
                  onClick={handleIAFDeselect}
               >
                  {
                     listaCodigosIAF.map((item, index)=>
                        <MenuItem key={'iaf-buscador'+index} value={item.id}>
                           {item.codigo + ' - ' + item.descripcion}
                        </MenuItem>
                     )
                  }
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={6}>
            <FormControl fullWidth>
               <InputLabel id="label-nace-buscador">NACE</InputLabel>
               <Select
                  label="NACE"
                  labelId="label-nace-buscador"
                  value={filtros.idNACE}
                  disabled={listaNace.length === 0}
                  onChange={handleNace}
                  onClick={handleNaceDeselect}
               >
                  {
                     listaNace.map((item, index)=>
                        <MenuItem key={'nace-buscador'+index} value={item.id}>{item.codigo + ' - ' + item.descripcion}</MenuItem>
                     )
                  }
               </Select>
            </FormControl>
         </Grid>
         
         { isFood &&
            <>
               <Grid item xs={4}>
                  <FormControl fullWidth>
                     <InputLabel id="label-subnace-buscador">Sub NACE</InputLabel>
                     <Select
                        label="Sub NACE"
                        labelId="label-subnace-buscador"
                        value={filtros.idSUBNACE}
                        disabled={listaSubNace.length === 0}
                        onChange={handleSubNace}
                        onClick={handleSubNaceDeselect}
                     >
                        {
                           listaSubNace.map((item, index)=>
                              <MenuItem key={'subnace-buscador'+index} value={item.id}>{item.codigo + ' - ' + item.descripcion}</MenuItem>
                           )
                        }
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <FormControl fullWidth>
                     <InputLabel id="label-categoria-buscador">Categoría</InputLabel>
                     <Select
                        label="Categoría"
                        labelId="label-categoria-buscador"
                        value={filtros.idCategoria}
                        //disabled={listaNace.length === 0}
                        //onChange={handleNace}
                        //onClick={handleNaceDeselect}
                     >
                        {/* {
                           listaNace.map((item, index)=>
                           < MenuItem key={'nace-buscador'+index} value={item.id}>{item.codigo + ' - ' + item.descripcion}</MenuItem>
                           )
                        } */}
                     </Select>
                  </FormControl>
               </Grid>
               <Grid item xs={4}>
                  <FormControl fullWidth>
                     <InputLabel id="label-subcategoria-buscador">Sub Categoría</InputLabel>
                     <Select
                        label="Categoría"
                        labelId="label-subcategoria-buscador"
                        value={filtros.idSubCategoria}
                        //disabled={listaNace.length === 0}
                        //onChange={handleNace}
                        //onClick={handleNaceDeselect}
                     >
                        {/* {
                           listaNace.map((item, index)=>
                           < MenuItem key={'nace-buscador'+index} value={item.id}>{item.codigo + ' - ' + item.descripcion}</MenuItem>
                           )
                        } */}
                     </Select>
                  </FormControl>
               </Grid>
             </>
         }

         <Grid item xs={3}>
            <TextField  
               label="Desde" 
               type="date" 
               name="desde"
               fullWidth
               InputLabelProps={{ shrink: true }}  
               value={filtros.creacionDesde} 
               onChange={handleDesde}
            />
         </Grid>
         <Grid item xs={3}>
            <TextField  
               label="Hasta" 
               type="date" 
               name="hasta"
               fullWidth
               InputLabelProps={{ shrink: true }}  
               value={filtros.creacionHasta} 
               onChange={handleHasta}
            />
         </Grid>
         <Grid item xs={3}>
            <FormControl fullWidth>
               <InputLabel id="label-estado-buscador">Estado</InputLabel>
               <Select
                  label="Estado"
                  labelId="label-estado-buscador"
                  value={filtros.estado}
                  onChange={handleEstado}
                  onClick={handleEstadoDeselect}
               >
                  {
                     listaEstadosSolicitud &&
                     listaEstadosSolicitud.map((item, index) => 
                        <MenuItem key={'index'+ index} value={item}>{item}</MenuItem>
                     )
                  }
               </Select>
            </FormControl>
         </Grid>
         <Grid item xs={2} container alignItems="center">
            <Button 
               variant="outlined" 
               color="primary"
               fullWidth
               startIcon={<SearchIcon/>}
               onClick={handleBuscar}
            >
               BUSCAR
            </Button>
         </Grid>
         <Grid item xs={1} container alignItems="center">
            <IconButton onClick={handleClearFiltros}>
               <CleaningServicesIcon/>
            </IconButton>
         </Grid>
      </Grid>
   )
}

export default Filtros
