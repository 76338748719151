import { Button, Paper, TextField } from '@mui/material'
import React from 'react'

function Test() {
    
    const submit1 = (e) => {
        e.preventDefault()
        
    }

    const submit2 = (e) => {
        e.preventDefault()
    }

  return (
    <>
        <Paper gap={2}>
            <form onSubmit={submit1}>
                <TextField value={1} label="input 1"></TextField>
                <Button type="submit">Guardar 1</Button>

                <form onSubmit={submit2}>
                    <TextField value={2} label="input 2"></TextField>
                    <Button type="submit">Guardar 2</Button>
                </form>
            </form>
        </Paper>
    </>
  )
}

export default Test