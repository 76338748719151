import { Box, Button, Paper, Typography, Modal } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SaveIcon from '@mui/icons-material/Save';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    title: {
        marginBottom: theme.spacing(3)
    }
    
}));


export const ModalPortalWithoutActions = ({ onCancel, isOpened, children, title}) => {
    const classes = useStyles();

    return (
        <Modal className={classes.modal} open={isOpened} onClose={onCancel}>
            <Paper className={classes.paper} >
                    <Box className={classes.title}>
                        <Typography component="h3" variant="h6">{title}</Typography>
                    </Box>
                    {children}
            </Paper>
        </Modal>
    );
}

const ModalPortal = ({ onSave, onCancel, isOpened, children, title, onlyShow}) => {
    const classes = useStyles();

    

    return (
        <Modal className={classes.modal} open={isOpened} onClose={onCancel}>
            <Paper className={classes.paper} >
                <form id='modalForm' onSubmit={onSave}>
                    <Box className={classes.title}>
                        <Typography component="h3" variant="h6">{title}</Typography>
                    </Box>

                    {children}

                    <Box className="toolbar-actions" sx={{paddingTop:"10px"}}>
                        <Button variant="outlined" color="secondary" onClick={onCancel}>Cancelar</Button>
                        {!onlyShow &&
                        <Button 
                        variant="outlined" 
                        color="primary" 
                        type="submit" 
                        formTarget='modalForm'
                        startIcon={<SaveIcon/>} 
                        >Guardar</Button>
                        }
                        
                    </Box>
                </form>
            </Paper>
        </Modal>
    );
}

export function ModalPortalAprobaciones ({ onAccept, onReject, onClose, isOpened, children, title, onlyShow}) {
    const classes = useStyles();

    return (
        <Modal className={classes.modal} open={isOpened} onClose={onClose}>
            <Paper sx={{width: "500px"}} className={classes.paper} >
                    <Box className={classes.title}>
                        <Typography component="h3" variant="h6">{title}</Typography>
                    </Box>

                    {children}

                    <Box className="toolbar-actions" sx={{paddingTop:"10px"}}>
                        <Button variant="contained" color="error" onClick={onReject}>Rechazar</Button>
                        {!onlyShow &&
                        <Button 
                        variant="contained" 
                        color="success" 
                        onClick={onAccept}
                        formTarget='modalForm'
                        >Aprobar</Button>
                        }
                        {/* <Button variant="outlined" color="secondary" onClick={onCancel}>Cerrar</Button> */}
                        
                    </Box>
            </Paper>
        </Modal>
    );
}

export default ModalPortal;