import { Box, Typography } from '@mui/material'
import VisitaResumenBox from 'components/VisitaResumenBox'
import { TIPO_GRUPO_SITIO } from 'constants/constants'
import React from 'react'
import { ScrollSyncPane } from 'react-scroll-sync'

import 'scss/components/SitioUnico.scss'
import { capitalizeFirstLetter } from 'utlis/stringUtils'

function SitioUnico({grupo}) {

    const renderTipo = () => {
        if(grupo.tipo !== TIPO_GRUPO_SITIO.CASA_CENTRAL){
            return( <Box className="sitio-unico-tipo">
                    <Typography variant='h7' color={"GrayText"}>{grupo.tipo}</Typography>
                    </Box>)
        }else{
            return (<Box className='sitio-unico-sin-tipo'></Box>)
        }
    }

  return (
    <Box display="flex">
        <Box className="sitio-unico-nombre">
            <Typography variant="h6" fontWeight={"fontWeightBold"} >{capitalizeFirstLetter(grupo.nombre)}</Typography>
        </Box>
        {
            renderTipo()
        }
        <ScrollSyncPane>
        <Box className="sitio-unico-boxes" gap={1}>
        {
          grupo.grupoInstancias.map((instancia, index) => 
            <VisitaResumenBox key={"box"+index} instancia={instancia} sitioInstancia={grupo.sitios[0].instancias[index]} sitioUnico={true}/>
          )
        }
      </Box>
      </ScrollSyncPane>
    </Box>
  )
}

export default SitioUnico