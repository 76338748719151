import { Box, CircularProgress, Typography } from "@mui/material";

function LoadingCotizacion() {
    const customColor = '#001D96';

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: 200,
                gap: 2,
            }}
        >
            <CircularProgress
                size={40}
                sx={{
                    color: customColor,
                }}
            />
            <Typography
                variant="h6"
                sx={{
                    fontWeight: 'medium',
                }}
            >
                Cargando Cotización
            </Typography>
        </Box>
    );
}


export default LoadingCotizacion;