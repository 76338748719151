import React from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Paper, Typography } from '@mui/material';

// Layout
import Page from 'layouts/Page';

// Styles
import 'scss/pages/not-found.scss';

const useStyles = makeStyles(theme => ({
   paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
   },
}));

const Home = () => {
   const { pathname } = useLocation();
   const classes = useStyles();

   return (
      <Page className="not-found">
         <Paper className={classes.paper}>
            <Typography variant="subtitle1">
               La página <b>{ pathname }</b> no existe o no está disponible
            </Typography>
         </Paper>
      </Page>
   )
}

export default Home;
