import {Box} from '@mui/material'

const TabPanel = (props) => {
    const { children, value, index} = props;
  
    return (
      <div>
        {value === index && (
          <Box sx={{paddingTop:"30px", paddingBottom:"20px"}}>
            {children}
          </Box>
        )}
      </div>
    );
  }

export default TabPanel;