import { Snackbar } from '@mui/material';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React, { useContext, useState } from 'react';
import { useEffect } from 'react';
import MuiAlert from '@mui/material/Alert';

function AlertaGuardado() {
    const {state} = useContext(CotizacionContext)
    const {estadoCarga, json} = state

    const [openGuardado, setOpenGuardado] = useState(false); 
    const inCotizacion = json && json.id && json.id !== "";
    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
      });

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {return;}
        setOpenGuardado(false)
    }

    //efecto de guardado y error
    useEffect(() => {
      if(inCotizacion){
          if(estadoCarga.guardando){
            const a = json && json.id && json.id !== "";
            setOpenGuardado(true)
          }else{
              if(estadoCarga.guardado){
                  setTimeout(() => {
                    setOpenGuardado(false)
                  }, 5000);
              }else{
              }
          }
      }
    }, [estadoCarga])

    

    const renderAlert = () => {
        if(estadoCarga.guardado){
            return(
                <Snackbar open={openGuardado} autoHideDuration={1500} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Guardado con exito!
                </Alert>
                </Snackbar>
            )
        }else{
            if(estadoCarga.error){
                return(
                    <Snackbar open={openGuardado} autoHideDuration={1500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                        No se pudo guardar
                    </Alert>
                    </Snackbar>
                )
            }else{
                return (
                    <Snackbar open={openGuardado} autoHideDuration={1500} onClose={handleClose}>
                    <Alert onClose={handleClose} severity="info" sx={{ width: '100%' }}>
                        Guardando ...
                    </Alert>
                    </Snackbar>   
                )
            }
            
        }
    }
    return (
        <>
        {
            renderAlert()    
        }
        </>
    );
}



export default AlertaGuardado;
