import React, { useContext, useState, useEffect } from 'react'
import { Select, MenuItem, TextField, Checkbox, InputLabel, FormControl, IconButton, Typography } from '@mui/material'
import { differenceInDays, parseISO } from 'date-fns'
import { Box } from '@mui/system'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import CustomDialog from 'components/customDialog'
import { deleteNorma, resetIntegracion } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
import DeleteIcon from '@mui/icons-material/Delete'
import { usePermitirEdicion } from 'hooks/usePermitirEdicion'
import { TIPO_ESTADO } from 'constants/constants'
import { useSelector } from 'react-redux'


const DetalleMotivoNorma = (
    {
        value,
        numeroNorma,
        index,
        onChangeNormaMotivo,
        setLimiteNormas,
        noEliminar,
        resetNormasIntegracion,
        modoEdicion,
        allInputs,
    }) => {


    //Listas
    const globalSlice = useSelector(state => state.global)
    const { listaEntes, listaNormasMotivos, listaCodigosIAF } = globalSlice
    const { state, dispatch } = useContext(CotizacionContext)
    const { estadoCotizacion, inputNormasMotivos, inputDatosCotizacion, inputCodigosIAFNACE } = state
    const listaIAFs = inputCodigosIAFNACE.map(i => i.CodigoIaf.id).flat()
    const listaClusterAux = listaCodigosIAF.filter(n => listaIAFs.includes(n.id))
    const listaCluster = listaClusterAux.map(n => n.clusters).flat()

    const [errorFecha, setErrorFecha] = useState(false);

    const [openDialog, setOpenDialog] = useState(false)
    const handleCloseDialog = () => {
        setOpenDialog(false)
    }

    const numerosMantenimientos = [1, 2, 3, 4, 5]
    const auxMantenimientos = numerosMantenimientos.slice(0, inputDatosCotizacion.seguimientos)

    //HOOK
    const permitirEdicion = usePermitirEdicion(modoEdicion)

    useEffect(() => {
        let isoDate = parseISO(inputNormasMotivos[index].vencimiento);
        if (differenceInDays(new Date(), isoDate) > 0) {
            setErrorFecha(true);
        } else {
            setErrorFecha(false);
        }
    }, [inputNormasMotivos[index].vencimiento]);


    const handleVencimiento = (event) => {
        let isoDate = parseISO(event.target.value);
        if (differenceInDays(new Date(), isoDate) > 0) {
            setErrorFecha(true);
        } else {
            setErrorFecha(false);
        }
        onChangeNormaMotivo({ name: event.target.name, value: event.target.value }, index)
    }

    const handleCheck = (e, index) => {
        onChangeNormaMotivo({ name: e.name, value: e.checked }, index)
    }

    const handleNumero = (e) => {
        let valor = e.target.value

        onChangeNormaMotivo({ name: 'numero', value: valor }, index)
    }

    const handleQuitarNorma = (index) => {
        if (inputNormasMotivos[index].integrada === true) {
            if (inputNormasMotivos.filter(n => n.integrada === true).length === 2) {
                dispatch(resetIntegracion())
                resetNormasIntegracion()
            }
        }
        dispatch(deleteNorma(index))
        setLimiteNormas(false)

        setOpenDialog(false)
    }

    const handleButtonQuitarNorma = (index) => {
        setOpenDialog(true)
    }

    const onClusterChange = (event) => {
        onChangeNormaMotivo({ name: 'cluster', value: listaCluster.find(c => c.id == event.target.value) }, index);
    }

    const renderDetalle = (motivo) => {
        switch (motivo) {
            case "RECERTIFICACION":
                if (allInputs === false) {
                    return (
                        <Box className="Numero-ente" flexGrow={2} sx={{width: '50%'}}>
                            <FormControl fullWidth>
                                <InputLabel id="label-numero-mantenimiento">Numero Mantenimiento</InputLabel>
                                <Select
                                    label="Numero Mantenimiento"
                                    labelId='label-numero-mantenimiento'
                                    required
                                    name="numero"
                                    value={inputNormasMotivos[index].numero}
                                    onChange={handleNumero}
                                    disabled={inputEditable()}
                                >
                                    {
                                        numerosMantenimientos.map((item, index) =>
                                            <MenuItem key={'mant' + index} value={item}>{item}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                            {inputNormasMotivos[index].transferencia &&
                                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                                    <InputLabel labelId="enteLabel">Ente</InputLabel>
                                    <Select
                                        labelId="enteLabel"
                                        label="Ente"
                                        name="ente"
                                        required
                                        disabled={permitirEdicion}
                                        onChange={(even) => onChangeNormaMotivo(even.target, index)}
                                        value={inputNormasMotivos[index].ente}
                                    >
                                        {
                                            listaEntes.map((ente, indexEnte) =>
                                                <MenuItem key={"ente2" + indexEnte} value={ente.id}>{ente.nombre}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            }
                        </Box>
                    )
                }
                return (
                    <Box className="Numero-ente" flexGrow={2} sx={{width: '50%'}}>
                        <TextField
                            label="Vencimiento"
                            type={"date"}
                            name="vencimiento"
                            fullWidth
                            disabled={permitirEdicion}
                            value={inputNormasMotivos[index].vencimiento}
                            InputLabelProps={{ shrink: true }}
                            required
                            color={errorFecha ? "warning" : ""}
                            helperText={errorFecha ? <span style={{ color: "#ff9800" }}>La recertificación puede realizarse hasta 6 meses vencido el certificado</span> : ""}
                            onChange={handleVencimiento} />
                        {inputNormasMotivos[index].transferencia &&
                            <FormControl fullWidth sx={{ marginTop: "15px" }}>
                                <InputLabel labelId="enteLabel">Ente</InputLabel>
                                <Select
                                    labelId="enteLabel"
                                    label="Ente"
                                    name="ente"
                                    required
                                    disabled={permitirEdicion}
                                    onChange={(even) => onChangeNormaMotivo(even.target, index)}
                                    value={inputNormasMotivos[index].ente}
                                >
                                    {
                                        listaEntes.map((ente, indexEnte) =>
                                            <MenuItem key={"ente2" + indexEnte} value={ente.id}>{ente.nombre}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Box>
                )
            case "MANTENIMIENTO":
                return(<Box className="Numero-ente" flexGrow={2} sx={{width: '50%'}}>
                    <FormControl fullWidth>
                            <InputLabel id="label-numero-mantenimiento">Numero Mantenimiento</InputLabel>
                            <Select
                                label="Numero Mantenimiento"
                                labelId='label-numero-mantenimiento'
                                required
                                name="numero"
                                value={inputNormasMotivos[index].numero}
                                onChange={handleNumero}
                                disabled={inputEditable()}
                            >
                                {
                                    numerosMantenimientos.map((item, index) =>
                                        <MenuItem key={'mant' + index} value={item}>{item}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl>
                        {inputNormasMotivos[index].transferencia &&
                                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                                    <InputLabel labelId="enteLabel">Ente</InputLabel>
                                    <Select
                                        labelId="enteLabel"
                                        label="Ente"
                                        name="ente"
                                        required
                                        disabled={permitirEdicion}
                                        onChange={(even) => onChangeNormaMotivo(even.target, index)}
                                        value={inputNormasMotivos[index].ente}
                                    >
                                        {
                                            listaEntes.map((ente, indexEnte) =>
                                                <MenuItem key={"ente2" + indexEnte} value={ente.id}>{ente.nombre}</MenuItem>
                                            )
                                        }
                                    </Select>
                                </FormControl>
                            }
                </Box>)
                break;
            default:
                return (
                    <Box className="Numero-ente" flexGrow={2} sx={{width: '50%'}}>
                        {/* <FormControl fullWidth>
                            <InputLabel id="label-numero-mantenimiento">Numero Mantenimiento</InputLabel>
                            <Select
                                label="Numero Mantenimiento"
                                labelId='label-numero-mantenimiento'
                                required
                                name="numero"
                                value={inputNormasMotivos[index].numero}
                                onChange={handleNumero}
                                disabled={inputEditable()}
                            >
                                {
                                    numerosMantenimientos.map((item, index) =>
                                        <MenuItem key={'mant' + index} value={item}>{item}</MenuItem>
                                    )
                                }
                            </Select>
                        </FormControl> */}
                        {inputNormasMotivos[index].transferencia &&
                            <FormControl fullWidth sx={{ marginTop: "15px" }}>
                                <InputLabel labelId="enteLabel">Ente</InputLabel>
                                <Select
                                    labelId="enteLabel"
                                    label="Ente"
                                    name="ente"
                                    required
                                    disabled={permitirEdicion}
                                    onChange={(even) => onChangeNormaMotivo(even.target, index)}
                                    value={inputNormasMotivos[index].ente}
                                >
                                    {
                                        listaEntes.map((ente, indexEnte) =>
                                            <MenuItem key={"ente2" + indexEnte} value={ente.id}>{ente.nombre}</MenuItem>
                                        )
                                    }
                                </Select>
                            </FormControl>
                        }
                    </Box>
                )
        }
            }

    const inputEditable = () => {
        if (permitirEdicion) {
            return true
        } else {
            if (estadoCotizacion.estado === TIPO_ESTADO.BORRADOR || estadoCotizacion.estado === TIPO_ESTADO.ENVIADA) {
                return false
            } else {
                return true
            }
        }
    }

    return (
        <>
            <Box display="flex" gap={1} >
                <Box display="flex" flexDirection="column" flexGrow={3} sx={{width: '50%'}}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Mótivo</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Motivo"
                            name='motivo'
                            required
                            variant="outlined"
                            disabled={inputEditable()}
                            value={value}
                            onChange={(even) => onChangeNormaMotivo(even.target, index)}
                        >
                            {listaNormasMotivos.map((motivo, indexMotivo) =>{
                                    return(<MenuItem key={'motivo' + indexMotivo} value={motivo}>{motivo}</MenuItem>)
                                }
                            )}
                        </Select>
                    </FormControl>
                    {allInputs === false ?
                        null
                        :
                        (value !== "CERTIFICACION" && value !== "") &&
                        <Box display="flex" flexDirection="row" justifyContent="space-around" sx={{ paddingTop: "15px" }} flexWrap>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body2" display="block" gutterBottom>
                                    Reducción
                                </Typography>
                                <Checkbox disabled={permitirEdicion} checked={inputNormasMotivos[index].reduccion} name="reduccion" onChange={(event) => handleCheck(event.target, index)} />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body2" display="block" gutterBottom>
                                    Ampliación
                                </Typography>
                                <Checkbox disabled={permitirEdicion} checked={inputNormasMotivos[index].ampliacion} name="ampliacion" onChange={(event) => handleCheck(event.target, index)} />
                            </Box>
                            <Box display="flex" flexDirection="column">
                                <Typography variant="body2" display="block" gutterBottom>
                                    Transferencia
                                </Typography>
                                <Checkbox disabled={permitirEdicion} checked={inputNormasMotivos[index].transferencia} name="transferencia" onChange={(event) => handleCheck(event.target, index)} />
                            </Box>
                        </Box>
                    }
                </Box>
                {renderDetalle(value)}
                {
                    !permitirEdicion && estadoCotizacion.estado === TIPO_ESTADO.BORRADOR &&
                    <Box className="ButtonEliminar" display="flex" flexDirection="column" justifyContent="center">
                        <IconButton aria-label="delete" onClick={() => handleButtonQuitarNorma(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </Box>
                }
            </Box>
            {
                numeroNorma === 50001 &&
                <Box display="flex" gap={1} sx={{ paddingTop: "15px" }}>
                    <FormControl sx={{ width: "50%" }}>
                        <InputLabel id="label-area">Área Técnica / Cluster</InputLabel>
                        <Select
                            label="Área Técnica / Cluster"
                            labelId="label-area"
                            required
                            name="cluster"
                            disabled={permitirEdicion}
                            onChange={onClusterChange}
                            value={inputNormasMotivos[index].cluster ? inputNormasMotivos[index].cluster.id : ''}
                        >
                            {
                                listaCluster.map((item, index) =>
                                    <MenuItem key={'clusters' + index} value={item.id}>{item.numero + " - " + item.nombre}</MenuItem>
                                )
                            }
                        </Select>
                    </FormControl>
                    <TextField
                        required
                        name="consumoEnergiaAnual"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].consumoEnergiaAnual}
                        onChange={(event) => onChangeNormaMotivo({ name: 'consumoEnergiaAnual', value: parseInt(event.target.value) }, index)}
                        label="Consumo Anual Energía" />
                    <TextField
                        required
                        name="fuentesDeEnergia"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].fuentesDeEnergia}
                        onChange={(event) => onChangeNormaMotivo({ name: 'fuentesDeEnergia', value: parseInt(event.target.value) }, index)}
                        label="Nº Fuentes Energía" />
                    <TextField
                        required
                        name="usosDeEnergia"
                        type="number"
                        disabled={inputEditable()}
                        value={inputNormasMotivos[index].usosDeEnergia}
                        onChange={(event) => onChangeNormaMotivo({ name: 'usosDeEnergia', value: parseInt(event.target.value) }, index)}
                        label="Nº Usos Energ. Signific." />
                </Box>
            }
            <CustomDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                title={noEliminar ? null : "¿Confirma que desea eliminar la norma?"}
                content={noEliminar ? "No se pueden borrar todas las normas, primero agregue otra." :
                    "Se eliminarán los factores y modificaciones que haya hecho. Si quedara una sola norma en la cotización, se eliminará la integración."}
                handleAgree={noEliminar ? handleCloseDialog : () => handleQuitarNorma(index)}
                onlyAgree={!noEliminar}
            />
        </>
    )
}
export default DetalleMotivoNorma
