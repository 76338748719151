import { Box, Tooltip, Typography } from '@mui/material'
import React from 'react'

import 'scss/components/visitaResumenBox.scss'

function VisitaResumenBox({instancia, sitioUnico, sitioInstancia }) {
  
  const renderEtapasDiasAuditor = () => {
    
    if(sitioUnico){
      if(sitioInstancia.diasAuditorEtapa1 || sitioInstancia.diasAuditorEtapa2){
        return(
          <>
          <Box display="flex" gap={1}>
            <Typography variant="h7" >E1: </Typography>
            <Typography variant="h7" fontWeight={"fontWeightBold"} >{sitioInstancia.diasAuditorEtapa1}</Typography>
          </Box>
          <Box display="flex" gap={1}>
            <Typography variant="h7" >E2: </Typography>
            <Typography variant="h7" fontWeight={"fontWeightBold"} >{sitioInstancia.diasAuditorEtapa2}</Typography>
          </Box>
          <Typography variant="h7" color="GrayText">({instancia.diasAuditor})</Typography>
          </>
          
        )
      }else{
        if(sitioInstancia.diasAuditorEtapa1 === 0 || sitioInstancia.diasAuditorEtapa2 === 0){

        }else{
          return (<Typography variant="h7"  fontWeight={"fontWeightBold"}>{instancia.diasAuditor}</Typography>)
        }
      }
    }
    else{
      if(instancia.diasEtapa1 || instancia.diasEtapa2){
        return(
          <>
            <Box display="flex" gap={1}>
              <Typography variant="h7">E1:</Typography>
              <Typography variant="h7" fontWeight={"fontWeightBold"}>{instancia.diasEtapa1}</Typography>
           </Box>
           <Box display="flex" gap={1}>
              <Typography variant="h7">E2:</Typography>
              <Typography variant="h7" fontWeight={"fontWeightBold"}>{instancia.diasEtapa2}</Typography>
           </Box>
          
          {/* <Typography variant="h7" fontWeight={"fontWeightBold"}>E2: {instancia.diasEtapa2}</Typography> */}
          <Typography variant="h7" color="GrayText">({instancia.diasAuditor})</Typography>
          </>
        
        )
      }else{
        if(instancia.diasEtapa1 === 0 || instancia.diasEtapa2 === 0){
          return("0")
        }else{
          return (<Typography variant="h7"  fontWeight={"fontWeightBold"}>{instancia.diasAuditor}</Typography>)
        }
      }
    }
  }

  return (
    <Box className="visita-resumen-box">
      <Box className="visita-resumen-box-title">
        <Typography variant="body2"  sx={{marginBottom:"10px"}}>{instancia.nombre}</Typography>
      </Box>
    {
      !sitioUnico &&
      <Box className="visita-resumen-box-label-etapas">
      <div className='visita-resumen-box-certificacion-etapas'>
        <Typography variant="body2" fontWeight={"fontWeightBold"}>{"Mínimo"}</Typography>
        <Typography variant="h6">{instancia.minimoSitios}</Typography>
      </div>
      <div className='visita-resumen-box-certificacion-etapas'>
        <Typography variant="body2" fontWeight={"fontWeightBold"} color={instancia.sitiosPlanificados<instancia.minimoSitios && "red"}>{"Planificados"}</Typography>
        <Typography variant="h6" color={instancia.sitiosPlanificados<instancia.minimoSitios && "red"} >{instancia.sitiosPlanificados}</Typography>
      </div>
      </Box>
    }
    
    <Box className="visita-resumen-box-label">
      <Typography variant="body2" fontWeight={"fontWeightBold"}>{"Días Auditor"}</Typography>
      <Box display="flex" gap={1}>
          <Box display="flex" alignSelf={"center"} gap={2}>
            {
              renderEtapasDiasAuditor()
            }
          </Box>
          
      </Box>
    </Box>
  </Box>
  )
}

export default VisitaResumenBox