import { Box, padding } from '@mui/system'
import React, { useContext, useEffect, useState } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import PercentIcon from '@mui/icons-material/Percent';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { Button, FormControl, IconButton, Menu, MenuItem, Select, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import 'scss/components/concepto.scss'
import { _deleteConcepto, _putConcepto } from 'services/Propuesta';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setPropuesta, updateConcepto } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import DeleteIcon from '@mui/icons-material/Delete';
import ModalComentarioConcepto from 'components/ModalComentarioConcepto';
import TituloConcepto from 'components/TituloConcepto';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS, TIPO_AUDITORIA, TIPO_CONCEPTO, TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import InputProducto from './InputProducto';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import 'scss/components/tablaPropuestaEconomica.scss'
import { parseISO } from 'date-fns';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
    concepto: {
        backgroundColor:`#e4e4e4`,
        paddingBottom:"5px",
        paddingTop:"5px"
    },
    conceptoDragging:{
        boxShadow: `0px 0px 0px 0px`,
        paddingBottom:"5px",
        paddingTop:"5px"
    },
  }));

const Concepto = ({item, index, propuesta, auditoria, deshabilitar}) => {
    const {state: stateCot, dispatch} = useContext(CotizacionContext)
    const {json} = stateCot;
    const dispatchRedux = useDispatch()
    const [firstRender, setFirstRender] = useState(true);
    const [concepto, setConcepto] = useState(item);
    const [moneda, setMoneda] = useState("");
    //Handle modal
    const [modalState, setModalState] = useState({opened: false, selected: null});
    const handleModal = (id) => {
    setModalState({opened: !modalState.opened, selected: id})
    }

    //style
    const classes = useStyles();

    const esConceptoDiagnostico = item.nombre.includes("Diagnostico")
    const esConceptoAuditoriaExtraordinaria = item.tipo === TIPO_AUDITORIA.EXTRAORDINARIA

    const renderInputs = () =>{
        if(item.habilitado){
            return(allInputs())
        }
        if(item.realizada){
            return (
            <>
            <Box className="concepto-realizada" >
                <Typography>REALIZADA</Typography>
            </Box>
            <Box display='flex'  justifyContent="flex-end">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                    {
                        item.tipo === TIPO_AUDITORIA.EXTRAORDINARIA?
                        <>
                        <IconButton onClick={handleClickMenu} disabled={deshabilitar}>
                        <MoreHorizIcon/>
                        </IconButton>
                        <Menu
                        id="basic-menu-extra"
                        anchorEl={menu}
                        open={openMenuExtra}
                        
                        onClose={handleCloseMenu}
                        MenuListProps={{ 'aria-labelledby': 'basic-button',}}
                        >
                            <MenuItem onClick={() => handleMenuExtraOption("DESHABILITAR")}>Deshabilitar</MenuItem>
                            <MenuItem onClick={() => handleMenuExtraOption("ELIMINAR")}>Eliminar</MenuItem>
                        </Menu>
                        </>
                        :
                        <>
                        <IconButton onClick={handleDeshabilitar} disabled={deshabilitar}>
                            <RemoveCircleIcon/>
                        </IconButton>
                        </>
                    }
                
                </Restricted>
            </Box>
            </>
            )
        }
        if(item.aCotizar){
            return (
            <>
            <Box display="flex" className="dias-iram">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField required variant="outlined" value={concepto.diasIram} onChange={handleDiasIramChange}></TextField>
                </Restricted>
            </Box>
            <Box display="flex" className="dias-organizacion">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <TextField variant="outlined" value={obtenerValorDiasOrganizacion()} onChange={handleDiasOrganizacion}></TextField>
                </Restricted>
            </Box>
            <Box className="concepto-realizada" >
                <Typography>A COTIZAR</Typography>
            </Box>
            <Box display='flex'  justifyContent="flex-end">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                    {
                        item.tipo === TIPO_AUDITORIA.EXTRAORDINARIA?
                        <>
                        <IconButton onClick={handleClickMenu}>
                        <MoreHorizIcon/>
                        </IconButton>
                        <Menu
                        id="basic-menu-extra"
                        anchorEl={menu}
                        open={openMenuExtra}
                        onClose={handleCloseMenu}
                        MenuListProps={{ 'aria-labelledby': 'basic-button',}}
                        >
                            <MenuItem onClick={() => handleMenuExtraOption("DESHABILITAR")}>Deshabilitar</MenuItem>
                            <MenuItem onClick={() => handleMenuExtraOption("ELIMINAR")}>Eliminar</MenuItem>
                        </Menu>
                        </>
                        :
                        <>
                        <IconButton onClick={handleDeshabilitar}>
                            <RemoveCircleIcon/>
                        </IconButton>
                        </>
                    }
                
                </Restricted>
            </Box>
            </>
            )
        }
        if(!item.habilitado){
            return(inputsHabilitar())
        }
    }

    const handleDeshabilitar = () => {
        let conceptoAux = {...item}
        conceptoAux.habilitado = false
        conceptoAux.realizada = false
        conceptoAux.aCotizar = false
        const data = {
            concepto: conceptoAux,
            idPropuesta: propuesta.id,
            propuesta: propuesta
        }
        updateConcepto(dispatch, data, dispatchRedux)
    }

    const handleEliminarConcepto = (id) => {
        const data = {
            idPropuesta: propuesta.id,
            conceptoId: id
        }
        _deleteConcepto(data)
        .then(response => {
            let propuestaAux = {...propuesta}
            propuestaAux.conceptos = propuesta.conceptos.filter(c => c.id !== id)
            dispatch(setPropuesta(propuestaAux))
        })
        .catch(error => {
            console.error('error delete concepto', error)
        })
    }

    const calcularTotal = () => {
        
        if(!concepto.custom){
            
            const valorUnitario = concepto.arancel !== null ? concepto.arancel.valor : 0;
            const totalPorDiaAuditor = (Number(concepto.diasOrganizacion)) * Number(valorUnitario);

            const bonificado = concepto.esBonificacionPorcentual ? totalPorDiaAuditor * concepto.bonificacion / 100 : concepto.bonificacion;

            const recargado =  concepto.esRecargoPorcentual ? totalPorDiaAuditor * concepto.recargo / 100 : concepto.recargo;
            
            return  totalPorDiaAuditor - bonificado + recargado;
        }else{
            const totalPorProducto = Number(concepto.producto.valor) * Number(concepto.producto.cantidad);
            
            const bonificado = concepto.esBonificacionPorcentual ? totalPorProducto * concepto.bonificacion / 100 : concepto.bonificacion;

            const recargado =  concepto.esRecargoPorcentual ? totalPorProducto * concepto.recargo / 100 : concepto.recargo;

            return  totalPorProducto - bonificado + recargado;
        }
        
    }

    const getMonedas = () => {
        let monedas = null
        if(item.custom){
            monedas = item.productosDisponibles.concat([item.producto]).map(p => p.moneda) 
        } else{
           monedas = item.arancelesDisponibles.map(a => a.moneda);
        }
        
        let monedasUnicas = {};
        monedas.forEach(m => {
            if(!monedasUnicas[m]){
                monedasUnicas[m] = m;
            }
        });

        return Object.keys(monedasUnicas);
    }

    const handleRecargo = (e) => {
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
            const valor = e.target.value !== "" ? e.target.value : 0;
            setConcepto({...concepto, recargo: Number(valor)})
        }
    }

    const handleTipoRecargo = () => {
        const esPorcentual = !concepto.esRecargoPorcentual;

        if(esPorcentual && concepto.recargo > 100){
            concepto.recargo = 100;
        }

        setConcepto({...concepto, esRecargoPorcentual: !concepto.esRecargoPorcentual})
    }

    const onArancelChange = (event) => {

        let conceptoAux = {...item}
        conceptoAux.arancel = conceptoAux.arancelesDisponibles.find(a => a.id === event.target.value);
        setConcepto(conceptoAux);
    }

    const handleCambioArancel = arancel => {
        let conceptoAux = {...item}
        conceptoAux.arancel = arancel;
        setConcepto(conceptoAux);
    }

    const handleCambioProducto = producto => {
        let conceptoAux = {...item}
        conceptoAux.producto = producto;
        setConcepto(conceptoAux);
    }

    const handleBonificacion = (e) => {
        if(e.target.value.replace(/([0-9])+/g, "") === ""){
        const valor = e.target.value !== "" ? e.target.value : 0;
        setConcepto({...concepto, bonificacion: Number(valor)})
        }
    }

    const handleTipoBonificacion = () => {
        const esPorcentual = !concepto.esBonificacionPorcentual;

        if(esPorcentual && concepto.bonificacion > 100){
            concepto.bonificacion = 100;
        }

        setConcepto({...concepto, esBonificacionPorcentual: !concepto.esBonificacionPorcentual})
    }

    const handleDiasIramChange = (e) => {
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
        const valor = e.target.value !== "" ? e.target.value : 0;
        setConcepto({...concepto, diasIram: Number(valor)})
        }
    }

    const handleDiasOrganizacion = (e) => {
       
        if(esConceptoDiagnostico){
            if(e.target.value.replace(/([0-9.])+/g, "") === ""){
                const valor = e.target.value !== "" ? e.target.value : 0;
                setConcepto({...concepto, diasOrganizacion: Number(valor)})
                }
        }
        if(esConceptoAuditoriaExtraordinaria){
            if(e.target.value.replace(/([0-9.])+/g, "") === ""){
                const valor = e.target.value !== "" ? e.target.value : 0;
                setConcepto({...concepto, diasOrganizacion: Number(valor)})
                }
        }
    }

    const obtenerValorDiasOrganizacion = () => {
        if(esConceptoAuditoriaExtraordinaria || esConceptoDiagnostico){
            return concepto.diasOrganizacion
        }
        else{
            return item.diasOrganizacion
        }
    }

    const onValorProductoChange = (e) => {
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
        const valor = e.target.value !== "" ? e.target.value : 0;
        setConcepto({...concepto, producto: {...concepto.producto, valor: Number(valor)}})
        }
    }

    const onCantidadProductoChange = (e) => {
        if(e.target.value.replace(/([0-9.])+/g, "") === ""){
        const valor = e.target.value !== "" ? e.target.value : 0;
        setConcepto({...concepto, producto: {...concepto.producto, cantidad: Number(valor)}})
        }
    }

    const handleChangeMoneda = (e) => {
        setMoneda(e.target.value);
        if(item.custom){
            if(item.productosDisponibles && item.productosDisponibles.some(p => p.moneda === e.target.value)){
                handleCambioProducto(item.productosDisponibles.find(p => p.moneda === e.target.value));
            }
        }else{
            if(item.arancelesDisponibles && item.arancelesDisponibles.some(p => p.moneda === e.target.value)){
                handleCambioArancel(item.arancelesDisponibles.find(a => a.moneda === e.target.value))
            }
        }
    }

    const handleComentario = (e) => {
        handleModal()
    }

    const actualizarConcepto = () => {
        
        if(validarCambiosConcepto()){
            const data = {
                concepto: concepto,
                idPropuesta: propuesta.id,
                propuesta: propuesta
            }
            updateConcepto(dispatch, data, dispatchRedux)
        }
        else{
        }
        
    }

    const validarCambiosConcepto = () => {
       
        return JSON.stringify(item) !== JSON.stringify(concepto);
    }

    //MENU DEFINIR
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    }
    const handleMenuOption = (accion) => {
        
        let conceptoAux = {...item}
        let data = {
            concepto: conceptoAux,
            idPropuesta: propuesta.id,
            propuesta:propuesta
        }

        if(accion === TIPO_CONCEPTO.REALIZADA){
            data.concepto.realizada = true
        }
        if(accion === TIPO_CONCEPTO.HABILITAR){
            data.concepto.habilitado = true
        }
        if(accion === TIPO_CONCEPTO.A_COTIZAR){
            data.concepto.aCotizar = true
        }
        updateConcepto(dispatch, data, dispatchRedux)
        setAnchorEl(null);
    }

    //MENU Deshabilitar/Eliminar
    const [menu, setMenu] = useState(null)
    const openMenuExtra = Boolean(menu)
    const handleClickMenu = (event) => {
        setMenu(event.currentTarget)
    }
    const handleCloseMenu = () => {
        setMenu(null)
    }
    const handleMenuExtraOption = (action) => {
        if(action === "ELIMINAR"){
            handleEliminarConcepto(item.id)
            setMenu(null);
        }
        else{
            handleDeshabilitar(item.id)
            setMenu(null);
        }
    }
    const getArancelesDisponibles = () => {
        if(item.arancel === null)
            return item.arancelesDisponibles
        if(item.arancelesDisponibles.some(ad => ad.id === item.arancel.id)){
            return item.arancelesDisponibles
        }else{
            return [...item.arancelesDisponibles.concat(item.arancel)]
        }
    }

    //USEEFFECT
    useEffect(() => {
        if(!firstRender){
            const timeout = setTimeout(
                actualizarConcepto,
                2000)
                return () => {
                    clearTimeout(timeout)
                }
        }else{
            setFirstRender(false);
        }

        
    }, [concepto])

    useEffect(() => {
        if(!firstRender){
                if(concepto.nombre.toLowerCase().includes("diagnostico")){
                    setConcepto({...concepto, habilitado: auditoria});
                }
                
        }else{
            setFirstRender(false);
        }
    }, [auditoria]);

    useEffect(() => {
        if(item){
            if(!item.custom && item.arancel){
                setMoneda(item.arancel.moneda);
            }else if(item.producto){
                setMoneda(item.producto.moneda);
            }
        }
    }, [item]);

    //RENDER
    const allInputs = () => {
        return (
            <>
            {
                item.custom ?
                <>
                {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box display="flex" className="dias-iram"></Box>}
                {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box display="flex" className="dias-organizacion"></Box>}
                </>

                :
                <>
                <Box display="flex" className="dias-iram">
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                        <TextField required variant="outlined" disabled={deshabilitar} value={concepto.diasIram} onChange={handleDiasIramChange}></TextField>
                    </Restricted>
                </Box>
                <Box display="flex" className="dias-organizacion">
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                        <TextField variant="outlined" disabled={deshabilitar} value={obtenerValorDiasOrganizacion()} onChange={handleDiasOrganizacion}></TextField>
                    </Restricted>
                </Box>
                </>
                
            }
            
            {
                !item.custom?
                <> 
                <Box display="flex" className="moneda">
                <FormControl sx={{width:"100%"}}>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                    value={moneda}
                    required
                    disabled={deshabilitar}
                    onChange={handleChangeMoneda}
                    >
                      {
                          getMonedas().map(m => (<MenuItem value={m?.toUpperCase()}>{m?.toUpperCase()}</MenuItem>))
                      }  
                    </Select>
                    </Restricted>
                </FormControl>
            </Box>
            <Box display="flex" className="arancel">
                <FormControl sx={{width:"100%"}}>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                    required
                    disabled={deshabilitar}
                    value={concepto?.arancel?.id}
                    onChange={onArancelChange}

                    >
                    {
                        getArancelesDisponibles().filter(a => a.moneda === moneda)
                        .sort((a, b) => parseISO(a.fechaHasta) - parseISO(b.fechaHasta))
                        .map(a => (<MenuItem value={a.id}>{a.nombre.toUpperCase()}</MenuItem>))
                        // item.arancelesDisponibles.filter(a => a.moneda === moneda)
                        // .map(a => (<MenuItem value={a.id}>{a.nombre.toUpperCase()}</MenuItem>))
                    }
                    </Select>
                    </Restricted>
                </FormControl>
                <Box alignSelf="center">
                </Box>
            </Box>
            </>
            :
            <>
            <Box display="flex" className="moneda">
                <FormControl sx={{width:"100%"}}>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                    required
                    value={moneda}
                    disabled={deshabilitar}
                    onChange={handleChangeMoneda}
                    >
                      {
                          getMonedas()
                          .map(m => (<MenuItem value={m?.toUpperCase()}>{m?.toUpperCase()}</MenuItem>))
                      }  
                    </Select>
                    </Restricted>
                </FormControl>
            </Box>
           
                <InputProducto 
                productoSelected={concepto?.producto}
                onChange={handleCambioProducto}
                productos={concepto.productosDisponibles.filter(p => p.moneda === moneda)} />
               
            <Box className="producto-cantidad">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField label="Cant." value={concepto.producto.cantidad || 0} disabled={deshabilitar} onChange={onCantidadProductoChange}></TextField>
                </Restricted>
            </Box>
            
            </>
            }
            
            <Box display="flex" className="bonificacion">
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField value={concepto.bonificacion}
                onChange={handleBonificacion}
                disabled={deshabilitar}
                InputProps={{
                    endAdornment:   
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <IconButton onClick={handleTipoBonificacion} color="primary" disabled={deshabilitar}>
                                    {
                                        concepto.esBonificacionPorcentual?
                                        <PercentIcon/>
                                        :
                                        <AttachMoneyIcon/>
                                    }
                                    </IconButton>
                    </Restricted>
                }}
                ></TextField>
                </Restricted>
            </Box>
            <Box className="recargo">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField value={concepto.recargo}
                onChange={handleRecargo}
                disabled={deshabilitar}
                InputProps={{
                    endAdornment:   
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <IconButton onClick={handleTipoRecargo} color="primary" disabled={deshabilitar}>
                                    {
                                        concepto.esRecargoPorcentual?
                                        <PercentIcon/>
                                        :
                                        <AttachMoneyIcon/>
                                    }
                                    </IconButton>
                    </Restricted>
                }}
                ></TextField>
                </Restricted>
            </Box>
            <Box display="flex" className="total">
                <Typography  alignSelf="center" variant="h6" sx={{font:"bold"}}>{calcularTotal()}</Typography>
                {
                    !item.custom ?
                    <Box display='flex'  justifyContent="flex-end">
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                        {
                            item.tipo === TIPO_AUDITORIA.EXTRAORDINARIA?
                            <>
                            <IconButton onClick={handleClickMenu} disabled={deshabilitar}>
                                <MoreHorizIcon/>
                            </IconButton>
                            <Menu
                            id="basic-menu-extra"
                            anchorEl={menu}
                            open={openMenuExtra}
                            onClose={handleCloseMenu}
                            MenuListProps={{ 'aria-labelledby': 'basic-button',}}
                            >
                                <MenuItem onClick={() => handleMenuExtraOption("DESHABILITAR")}>Deshabilitar</MenuItem>
                                <MenuItem onClick={() => handleMenuExtraOption("ELIMINAR")}>Eliminar</MenuItem>
                            </Menu>
                            </>
                            :
                            <IconButton onClick={handleDeshabilitar} disabled={deshabilitar}>
                                <RemoveCircleIcon/>
                            </IconButton>
                        }
                    
                    </Restricted>
                </Box>
                :
                <Box display='flex' justifyContent="flex-end">
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <IconButton onClick={() => handleEliminarConcepto(item.id)} disabled={deshabilitar}>
                        <DeleteIcon/>
                    </IconButton>
                    
                    </Restricted>
                </Box>
                }
            </Box>
            </>
        )
    }

    const inputsHabilitar = () => {
        return (
            <>
            <Box display="flex" className="dias-iram">
             {/* <Box display="flex" sx={{width:"10vw", minWidth: "100px", maxWidth: "100px", marginLeft:"8px"}}> */}
             <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField variant="outlined" value={item.diasIram} disabled={deshabilitar}></TextField>
                </Restricted>
            </Box>
            <Box display="flex" className="dias-organizacion">
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField variant="outlined" value={item.diasOrganizacion} disabled={deshabilitar}></TextField>
                </Restricted>
            </Box>
            <Box display="flex" className="button-habilitar">
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <Button 
                    fullWidth 
                    variant="outlined" 
                    color="secondary" 
                    disabled={deshabilitar}
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}
                >Definir como ...</Button>
                </Restricted>
                <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleClose}
                MenuListProps={{ 'aria-labelledby': 'basic-button',}}
                >
                    <MenuItem onClick={() => handleMenuOption(TIPO_CONCEPTO.REALIZADA)}>Realizada</MenuItem>
                    <MenuItem onClick={() => handleMenuOption(TIPO_CONCEPTO.HABILITAR)}>Habilitar</MenuItem>
                    <MenuItem onClick={() => handleMenuOption(TIPO_CONCEPTO.A_COTIZAR)}>A cotizar</MenuItem>
                </Menu>
            </Box>
            {/* <Button variant="outlined">Realizada</Button>
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}>
                <Button  variant="outlined" color="secondary" onClick={handleHabilitar} >HABILITAR</Button>
            </Restricted>
            <Button variant="outlined">A cotizar</Button>
            </Box> */}
            {/* <Box display="flex" sx={{width:"20%"}}></Box> */}
            </>
        )
    }

  return (
    <>
    { (auditoria || item.nombre !== "Auditoria de Diagnostico")&&
        <Draggable isDragDisabled={item.nombre === "Auditoria de Diagnostico"} key={item.id+"c"} draggableId={item.id+"c"} index={index} >
        {
        (draggableProvided, snapshot) => (
            <div
            {...draggableProvided.draggableProps}
            ref={draggableProvided.innerRef}
            {...draggableProvided.dragHandleProps}
            >
            <Box display="flex" className={snapshot.isDragging?classes.concepto:classes.conceptoDragging} >
                <TituloConcepto 
                item={item}
                comentario={item.comentario}
                handleComentario={handleComentario}
                deshabilitar={deshabilitar}
                />
                {
                   renderInputs()
                }
            </Box>
            </div>
        )
        }
        </Draggable>
    }
    <ModalComentarioConcepto
        onClose={() => handleModal()}
        isOpened={modalState.opened}
        concepto={item}
        propuesta={propuesta}
      />
    </>
  )
}

export default Concepto