import { Accordion, AccordionDetails, AccordionSummary, Box, Typography, IconButton, Button, TextField, Tooltip } from '@mui/material'
import React, {useContext, useEffect, useState} from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import { ConstructionOutlined, Edit } from '@mui/icons-material';
import { setSeccionesEdit } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { capitalizeFirstLetter } from 'utlis/stringUtils';
import { SolicitudContext } from 'context/solicitud/solicitudContext';

function ParrafoDocumento({seccion, index, desactivado}) {


const {state, dispatch} = useContext(CotizacionContext)
const {secciones, seccionesEdit} = state

const [seccionLocal, setSeccionLocal] = useState(seccion)

const [edicion, setEdicion] = useState(false)

const handleVisibilidad = (e) => {
    e.stopPropagation()
    var seccionAux = {...seccionLocal, visibilidad: !seccionLocal.visibilidad}
    setSeccionLocal(seccionAux)

    var aux = [...seccionesEdit]
    var index = secciones.findIndex((obj => obj.id == seccionLocal.id))
    aux[index] = seccionAux
    dispatch(setSeccionesEdit(aux))
}

const handleModificar = () => {
    if(edicion){
        
        if(seccionLocal.texto === seccion.texto){
         //
        }else{
            var aux = [...seccionesEdit]
            var index = secciones.findIndex((obj => obj.id == seccionLocal.id))
            aux[index] = seccionLocal
            dispatch(setSeccionesEdit(aux))
        }
    }else{
    }
    setEdicion(!edicion)
}

const handleEditarTexto = (e) => {
    
    if(e.target.value === seccion.texto){
        setSeccionLocal({seccion, modificada:false})
    }else{
        setSeccionLocal({...seccionLocal, texto: e.target.value, modificada:true})
    }
}

const handleRestablecer = () => {
    setSeccionLocal(seccion)
    var aux = [...seccionesEdit]
    var index = secciones.findIndex((obj => obj.id == seccionLocal.id))
    aux[index] = seccion
    dispatch(setSeccionesEdit(aux))
}

return (
<>
<Accordion sx={{marginTop:"20px", marginBottom:"20px", padding:"0px"}} >
    <AccordionSummary sx={{backgroundColor:"#c5c5c5"}} expandIcon={<ExpandMoreIcon/>} >
    <Box display="flex" sx={{width:"100%"}} justifyContent="space-between">
        <Box display="flex" gap={1} alignSelf="center">
        <Typography variant={"h6"}  fontWeight="fontWeightBold" 
            // sx={{textDecoration:"line-through"}}
            sx={{textDecoration:seccionLocal.visibilidad?"":"line-through"}}
            >
            {capitalizeFirstLetter(seccion.titulo, true)}
        </Typography>
        {
            seccionLocal.modificada &&
            <Tooltip title="Seccion modificada">
                <EditIcon alignSelf="center"/>
            </Tooltip>
            
        }
        </Box>
        
        <IconButton onClick={handleVisibilidad} sx={{marginRight:"30px"}} disabled={desactivado}>
            {
                seccionLocal.visibilidad?
                <VisibilityIcon/>
                :
                <VisibilityOffIcon/>
            }
            
        </IconButton>
    </Box>   
    
    </AccordionSummary>
    <AccordionDetails>
        {
            edicion?
            <Box display="flex">
                <TextField multiline fullWidth onChange={handleEditarTexto} value={seccionLocal.texto}></TextField>
            </Box>
            :
            <TextField multiline disabled fullWidth value={seccionLocal.texto}></TextField>
        
        }
    
    <Box display="flex" gap={1} sx={{paddingTop:"10px"}}>
        
        <Button variant="contained" onClick={handleModificar} disabled={desactivado}
        startIcon={edicion?<SaveIcon/>:<EditIcon/>}
        >{edicion?"Guardar":"Editar"}</Button>
        {
            seccionLocal.modificada &&
            <Box alignSelf="center">
                <Button variant='outlined' onClick={handleRestablecer}>Restablecer</Button>
            </Box>
        }
    </Box>
    </AccordionDetails>
</Accordion>
</>
)
}

export default ParrafoDocumento