import React from 'react'
import { Box, Typography } from '@mui/material'

const LabelCustom = (props) => {
    return (
        <Box>
            <Typography component='div' fontWeight="fontWeightBold">
                {props.label}
            </Typography>
            <Box>
                {props.value}
            </Box>
        </Box>
    )
}

export default LabelCustom
