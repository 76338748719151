import {getDataAsync, postDataAsync, putDataAsync} from 'services/httpRequest';

export const getCotizacionAlimento = async () =>{
    const response = await getDataAsync(`CotizacionAlimentos`);
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const getCotizacionAlimentoById = async (id) =>{
    const response = await getDataAsync(`CotizacionAlimentos/${id}`);
    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response
    }
}

export const postCotizacionAlimento = async (body) =>{
    const response = await postDataAsync(`CotizacionAlimentos`, body);

    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response.data
    }
}

export const putCotizacionAlimentos = async (body) => {
    const response = await putDataAsync('CotizacionAlimentos', body);

    if(response.status === 200 || response.status === 201){
        return response.data
    }else{
        throw response.data
    }
}