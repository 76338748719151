import LoadingCotizacion from "components/LoadingCotizacion";
import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
import { SolicitudContext } from "context/solicitud/solicitudContext";
import NuevaCotizacionAlimentos from "pages/NuevaCotizacionAlimentos";
import NuevaCotizacionGestion from "pages/NuevaCotizacionGestion";
import NuevaCotizacionProducto from "pages/NuevaCotizacionProducto";
import NuevaCotizacionFormacion from "pages/formacion/NuevaCotizacionFormacion";
import { useContext } from "react";
import { useSelector } from "react-redux";



function NuevaCotizacionBasePage(props) {

    const { stateSolicitud } = useContext(SolicitudContext);
    const tipoSolicitud = stateSolicitud.tipo;

    const globalSlice = useSelector(state => state.global);

    const loadingNacesIafs = globalSlice.listaCodigosIAF.length === 0 && globalSlice.listaCodigosNace.length === 0;

    if(loadingNacesIafs){
        return <LoadingCotizacion />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_GESTION){
        return <NuevaCotizacionGestion {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_ALIMENTOS){
        return <NuevaCotizacionAlimentos {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_PRODUCTO){
        return <NuevaCotizacionProducto {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_FORMACION){
        return <NuevaCotizacionFormacion {...props} />;
    }

    return <LoadingCotizacion />;
}

export default NuevaCotizacionBasePage;