import COTIZACION_VACIA from "context/cotizacion/reducerCotizacion/cotizacionVacia";

export const INITIAL_STATE = {

    //ESTADO DE CARGA
    estadoCarga:{
        guardando:false,
        guardado:false,
        error:false,
    },
    saving: false,
    saved: false,

    //OBSERVACIONES
    observaciones:"",

    //Contexto
    inputDatosCotizacion:{
        nombre:"",
        seguimientos:2,
        codigo:'',
        revision:'D',
        iaf: {
            id: null,
            codigo: null,
            descripcion: '',
        },
        cantidadEstudiosHaccp:0
    },
    estadoCotizacion:{
        estado:"Borrador",
        permiteEdicion:true,
        posiblesTransiciones:[],
        motivoRechazo:''
    },
    turnos: [{
        descripcion: '',
        horarioFin: '',
        horarioInicio: '',
        personal: null,
        selected: false
    }],
    inputNormasMotivos : [{ id:0, 
                            id_local:0, 
                            norma:'', 
                            motivo:'', 
                            nombre:'', 
                            integrada:false, 
                            vencimiento:'', 
                            cambioVersion:false, 
                            transferencia:false, 
                            ampliacion:false, 
                            reduccion:false,
                            numero:'', 
                            cantidadMantenimientos:0,
                            ididEnteTransferencia:0,
                            ente:'',
                            cluster: {
                                id:0,
                            },
                            consumoEnergiaAnual: 0,
                            fuentesDeEnergia: 0,
                            usosDeEnergia: 0
                        }],
    inputReduccion:0.0,
    allAccordionClose:false,
    inputMotivosModificadores: [],
    inputIntegracion:{
        coeficienteOrganizacion: 0,
        coeficienteIRAM:0,
        motivosOrganizacion: [],
        porcentajeReduccion: -1
    },

    listaRangoPersona:[],

    inputCodigosIAFNACE: [{ CodigoIaf:  {id:'', codigo:'' ,descripcion:''}, 
                            CodigoNace: {id:'', codigo:'', descripcion:''}}],
    inputCodigosNACESUBNACES: [
        {
            id: '',
            codigo: '',
            descripcion: '',
            subnaces: [
                {
                    id: '',
                    codigo: '',
                    descripcion: ''
                }
            ]
        }
    ],
    tabSeleccionada:0,
    multisitios:false,
    secciones:[],
    seccionesEdit:[],
    //JSON response
    json: COTIZACION_VACIA,

    //PROPUESTA
    propuestas:[
        {
            id:-1
        }
    ],

    propuesta:{
        id:-1,
        conceptos:[
            {
                id: 1,
                nombre: "",
                diasIram: 0,
                diasOrganizacion: 0,
                bonificacion: 0,
                recargo: 0,
                montoTotal: 0,
                custom: false,
                habilitado: false,
                arancel: null,
                producto: null
            }
        ],
        estado:{
            estado:"",
            permiteEdicion:true,
            posiblesTransiciones:[""]
        }
    },
    productos: null,
    documentoTemp:null,
}