import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

export default function TablaPropuestaResumen({conceptos}) {
  
  const formatNumber = (number) => {
    return new Intl.NumberFormat('es-AR').format(number);
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="resumen propuesta">
        <TableHead>
          <TableRow>
            <TableCell>Concepto</TableCell>
            <TableCell align="right">Días/Cant.</TableCell>
            <TableCell align="right">Total</TableCell>
            <TableCell align="right">Recargo</TableCell>
            <TableCell align="right">Bonificación</TableCell>
            <TableCell align="right">Total Final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conceptos && conceptos.map((concepto) => (
            <TableRow
              key={concepto.id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {concepto.nombre}
              </TableCell>
              <TableCell align="right">{concepto.cantidad.toString().replace(".", ",")}</TableCell>
              <TableCell align="right">{concepto.moneda + " " + formatNumber(concepto.total)}</TableCell>
              <TableCell align="right">{concepto.recargoAplicado.includes("%") ? concepto.recargoAplicado: concepto.moneda + " " + formatNumber(concepto.recargoAplicado)}</TableCell>
              <TableCell align="right">{concepto.bonificacionAplicada.includes("%") ? concepto.bonificacionAplicada: concepto.moneda + " " + formatNumber(concepto.bonificacionAplicada)}</TableCell>
              <TableCell align="right">{concepto.moneda + " " + formatNumber(concepto.totalFinal)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}