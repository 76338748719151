import { Box, Typography } from '@mui/material';

const classes = {
   root: {
      display: 'flex',
      justifyContent: 'space-between',
      backgroundColor: 'background.default',
      borderRadius: 1,
      p: 1
   },
   persons: {
      '& span': {
         fontWeight: 400
      }
   }
}

const AlimentosTurnosSelected = ({ data }) => {
   const { description, hours, persons } = data;

   return (
      <Box sx={classes.root}>
         <Box>
            <Typography variant="subtitle2">
               { description }
            </Typography>
         </Box>
         <Box>
            <Typography variant="subtitle2">
               { hours }
            </Typography>
         </Box>
         <Box>
            <Typography variant="subtitle2" sx={classes.persons}>
               <span>Personas: </span>{ persons }
            </Typography>
         </Box>
      </Box>
   )
}

export default AlimentosTurnosSelected;
