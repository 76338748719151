import React, {createContext, useEffect, useReducer} from 'react';
import {INITIAL_STATE, reducer} from 'context/solicitud/reducerSolicitud/index';

export const SolicitudContext = createContext();

export let dispatchSol;

export const SolicitudProvider = (props) => {
    const [stateSolicitud, dispatchSolicitud] = useReducer(reducer, INITIAL_STATE);
   
    useEffect(() => {
        dispatchSol = dispatchSolicitud;
    }, []);
    

    return (
        <SolicitudContext.Provider value={{stateSolicitud, dispatchSolicitud}}>
            {props.children}
        </SolicitudContext.Provider>
    );
}
