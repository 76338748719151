import { Box, Button } from "@mui/material";
import FormCotizacion from "components/FormCotizacion";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PaperTotalVisitas from "components/paperTotalesVisitas";
import PaperAuditoria from "components/PaperAuditorias";
import { useContext, useEffect, useCallback, useRef } from "react";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import PaperCalculo from "components/paperCalculo";
import AlertaGuardado from "components/AlertaGuardado";
import { useHistory, useParams } from "react-router-dom";
import { SolicitudContext } from "context/solicitud/solicitudContext";
import { getCotizacionById } from "services/Cotizacion";
import { getProductos, obtenerPropuestaByIdSilent, setDatosCotizacion, setEstadoCotizacion, setInputIafNace, setInputIntegracion, setJson, setMultisitios, setNormaMotivo, setTab } from "context/cotizacion/actionsCotizacion/ActionsCotizacion";
import { parseNormas } from "utlis/normasParser";
import UltimaModificacion from "components/ultimaModificacion";
import { useDispatch } from "react-redux";
import { setError } from "redux/slices/globalSlice";

export default function CotizacionGestion() {
    const toggleEditarCotizacion = true;

    const history = useHistory();
    const { idCotizacion, idPropuesta } = useParams();
    // router params 

    const dispatchRedux = useDispatch()
    const { stateSolicitud } = useContext(SolicitudContext);
    const { state: stateCotizacion, dispatch: dispatchCotizacion } = useContext(CotizacionContext);
    const { tabSeleccionada, json } = stateCotizacion;

    const navigateToSolicitud = useCallback(() => {
        history.push(`/solicitud/${stateSolicitud.idSolicitud}`);
    },[stateSolicitud, history]);

    let finalCotizacion = useRef(null);


    const renderPaperTotalVisitas = () => {
        if (json.usaMultisitios) {
            if (tabSeleccionada === 1 || tabSeleccionada === 0) {
                return (<PaperTotalVisitas />)
            }
        }
        return null;
    }

    const renderPaperAuditoriasGeneral = () => {
        if (!json.usaMultisitios) {
            if (tabSeleccionada === 0) {
                return (<PaperAuditoria />)
            }
        }

        return null;
    }

    const buildListaIAFNACE = (naces) => {
        const aux = naces.map(function (item) {
            return {
                CodigoIaf: {
                    id: item.iaf.id,
                    codigo: item.iaf.codigo,
                    descripcion: item.iaf.descripcion
                },
                CodigoNace: {
                    id: item.id,
                    codigo: item.codigo,
                    descripcion: item.descripcion
                }
            }
        })
        return aux;
    }

    const fetchCotizacion = () => {
        getCotizacionById(idCotizacion)
            .then(response => {
                let estadoAux = { ...response.estado }
                estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
                estadoAux.motivoRechazo = response.motivoRechazo
                //zxc
                dispatchCotizacion(setMultisitios(response.usaMultisitios))

                dispatchCotizacion(setEstadoCotizacion(estadoAux))
                const normasMotivos = parseNormas(response.normas)
                dispatchCotizacion(setNormaMotivo(normasMotivos))

                dispatchCotizacion(
                    setInputIafNace(
                        buildListaIAFNACE(response.nacEs)
                    )
                );

                const info = {
                    nombre: response.nombre,
                    seguimientos: response.cantidadMantenimientos,
                    codigo: response.codigo,
                    revision: 'D',
                }

                dispatchCotizacion(setDatosCotizacion(info))

                if (response.infoIntegracion) {
                    dispatchCotizacion(setInputIntegracion(response.infoIntegracion))
                }
                dispatchCotizacion(setJson(response))
                if (idPropuesta) {
                    dispatchCotizacion(setTab(response.usaMultisitios ? 2 : 1))
                    obtenerPropuestaByIdSilent(dispatchCotizacion, { id: idPropuesta, params: true, finalCotizacion });
                    getProductos(dispatchCotizacion);
                }

            })
            .catch(error => {
              
                dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data?.details ? error.data.details : error.data.message }))
                console.error("ERROR GET COTIZACION BY ID", error)
            })
    }


    useEffect(() => {
        fetchCotizacion();
        // route has "propuesta"
        // if (idPropuesta) {
        //     if(json.usaMultisitios){
        //         dispatchCotizacion(setTab(2))
        //     }else{
        //         dispatchCotizacion(setTab(1))
        //     }
        // }

    }, [idPropuesta]);


    return (
        <>
        <Box marginTop={"4vh"} sx={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center"}} alignItems={"flex-end"} >
            <UltimaModificacion />
            <Button
                variant='outlined' 
                onClick={navigateToSolicitud}
                startIcon={<ArrowBackIosNewIcon />} 
            >
                Volver a cotizaciones
            </Button>
        </Box>
            <FormCotizacion/>
            {
                toggleEditarCotizacion &&
                <>
                    <PaperCalculo />
                    {
                        renderPaperTotalVisitas()
                    }
                    {
                        renderPaperAuditoriasGeneral()
                    }

                    <AlertaGuardado />
                </>
            }
        <div id="end" ref={finalCotizacion}></div>
        </>
    )
}