import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Auditoria from "components/Auditoria";
import { TIPO_GRUPO_SITIO } from "constants/constants";
import { CotizacionContext } from "context/cotizacion/cotizacionContext";
import { makeStyles } from '@mui/styles';
const { useContext, useState, useEffect } = require("react");

const useStyles = makeStyles(theme => ({
    Accordion: {
        padding: 0,
    
       
        borderRadius: `5px`
      },
  }));

const PaperAuditoria = () => {
 const classes = useStyles();
    const { state } = useContext(CotizacionContext)
    const { json } = state;

    const loading = json.id ? false : true;

    const getInstancias = () => {
        if (!loading) {
            const grupoGeneral = json.gruposDeSitios.find(g => g.tipo === TIPO_GRUPO_SITIO.GENERAL);
            
            if (grupoGeneral) {
                let instancias = grupoGeneral.sitios[0].instancias;
                return instancias.sort((a, b) => (a.instancia.numero > b.instancia.numero) ? 1 : -1);
            }
        }
        return [];
    }

    let instancias = getInstancias();

    const tipoCotizacion = json.tipo

    const renderAuditoria = (auditoria) => {

            return (
                <>
                {
                auditoria.normas.map(function (norma, index){
                return (
                    <Auditoria.ItemContainer key={"auditoria_container"+index}
                        tieneEtapas={auditoria.instancia.tieneEtapas}
                        nombreNorma={norma.sitioNorma?.cotizacionNorma?.norma?.nombre}
                        integrada={norma.sitioNorma?.integrada}>
                        {
                             auditoria.instancia.tieneEtapas ?
                        (<>
                            
                            <Auditoria.ItemDiasAuditor etapa={1}
                                nombre={norma.nombre}
                                dias={norma.diasAuditorEtapa1} />
                            <Auditoria.ItemDiasAuditor etapa={2}
                                nombre={norma.nombre}
                                dias={norma.diasAuditorEtapa2} />
                        </>
                        ) :
                        (
                            <Auditoria.ItemDiasAuditor width="100%"
                                nombre={norma.nombre}
                                dias={norma.diasAuditor} />
                        )
                        }
                        </Auditoria.ItemContainer>
                    )
                } )}
                
                </>
            )
    }
    return (
        
        
        
                <Accordion className={classes.Accordion}  sx={{marginTop:"40px"}}>
                    <AccordionSummary>
                        <Box display="flex" sx={{paddingBottom:"10px"}}>
                            <Typography variant="h6" fontWeight="fontWeightBold" >Resumen Auditorias</Typography>
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Grid container padding="1%" overflow="scroll" style={{ overflowY: "hidden" }}>
                        <Box display="flex" flexDirection="row">
                            {instancias.map((auditoria, index) =>
                                <Auditoria key={"auditoria"+index}>
                                    <Auditoria.Titulo texto={auditoria.instancia.nombre} />
                                    {
                                        renderAuditoria(auditoria)
                                        
                                    }
                                    {
                                        auditoria.instancia.tieneEtapas &&
                                        <Auditoria.Subtotal etapa1={auditoria.diasAuditorEtapa1} etapa2={auditoria.diasAuditorEtapa2}  />
                                                    
                                    }
                                    <Auditoria.Total tieneEtapas={auditoria.instancia.tieneEtapas} dias={auditoria.diasAuditor} />
                                </Auditoria>
                            )
                            }
                        </Box>
                    </Grid>
                    </AccordionDetails>
                </Accordion>
           
     
    )
}

export default PaperAuditoria;