import { Box, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material"
import { PERMISOS } from "constants/constants"
import { CotizacionContext } from "context/cotizacion/cotizacionContext"
import { setPropuesta } from "context/cotizacion/actionsCotizacion/ActionsCotizacion"
import Restricted, { RESTRICT_METHOD } from "components/Restricted"
import ModalPortal, { ModalPortalAprobaciones } from "components/modalPortal"
import { useContext, useState } from "react"
import { _postConcepto, _putPropuestaEstadoEnAprobacion } from "services/Propuesta"

export default function ModalAprobacionEstado({ onClose, isOpened, propuesta }) {
    const { dispatch } = useContext(CotizacionContext)
    
    const onReject = () => {
        _putPropuestaEstadoEnAprobacion({aprobado: false}, propuesta.id)
            .then( response => {
                dispatch(setPropuesta(response))
                onClose()
            })
            .catch(error => {
                console.error('error', error)
                onClose()
            })
    }

    const onAccept = () => {

        _putPropuestaEstadoEnAprobacion({aprobado: true}, propuesta.id)
            .then( response => {
                dispatch(setPropuesta(response))
                onClose()
            })
            .catch(error => {
                console.error('error', error)
                onClose()
            })
    }
    const estadoEnAprobacion = "Estado pendiente de revisión - " + propuesta?.estadoEnAprobacion?.estado;
    return (
        <ModalPortalAprobaciones
            onReject={onReject}
            onAccept={onAccept}
            onClose={onClose}
            title="Cambio de estado solicitado"
            isOpened={isOpened}

        >
            <Box display="flex" flexDirection={"column"} padding="20px" >
                <Typography>*Al aprobar se permitira al solicitante poder avanzar con el cambio de estado</Typography>
                <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                        labelId='label-estado'
                        label='Estado en aprobación'
                        disabled={true}
                        value={estadoEnAprobacion}
                        input={
                            <OutlinedInput
                                variant="status"
                            />}
                    >

                        <MenuItem
                            value={estadoEnAprobacion}
                        >
                            {estadoEnAprobacion}
                        </MenuItem>

                    </Select>
                </Restricted>
            </Box>
        </ModalPortalAprobaciones>
    )
}
