import { Box } from "@mui/system";
import { TIPO_SOLICITUD_ALIMENTOS, TIPO_SOLICITUD_FORMACION, TIPO_SOLICITUD_GESTION, TIPO_SOLICITUD_PRODUCTO } from "constants/constants";
import NuevaSolicitudAlimentos from "pages/NuevaSolicitudAlimentos";
import NuevaSolicitudGestion from "pages/NuevaSolicitudGestion";
import NuevaSolicitudProducto from "pages/NuevaSolicitudProducto";
import NuevaSolicitudFormacion from "pages/formacion/NuevaSolicitudFormacion";
import { useLocation } from "react-router-dom";



function NuevaSolicitudBasePage(props) {

    const search = useLocation().search;
    const searchParams = new URLSearchParams(search);
    const tipoSolicitud = searchParams.get("tipo");

    if(tipoSolicitud === TIPO_SOLICITUD_GESTION){
        return <NuevaSolicitudGestion {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_ALIMENTOS){
        return <NuevaSolicitudAlimentos {...props} />;
    }

    if(tipoSolicitud === TIPO_SOLICITUD_PRODUCTO){
        return <NuevaSolicitudProducto {...props} />;
        //return <Box display="flex" justifyContent="center">NO DISPONIBLE</Box>
    }

    if(tipoSolicitud === TIPO_SOLICITUD_FORMACION){
        return <NuevaSolicitudFormacion {...props} />;
    }

    return <NuevaSolicitudGestion {...props} />;
}

export default NuevaSolicitudBasePage;