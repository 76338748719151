import { Tooltip, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box } from '@mui/system';

const Auditoria = ({ children }) => {
    return (
        <Box className="auditoria-container">
            {children}
        </Box>
    );
}


const Titulo = ({ texto }) => {
    return <Typography marginBottom="35px" variant="h6">{texto}</Typography>
}

const ItemContainer = ({ children, nombreNorma, tieneEtapas,integrada }) => {
    return (
        <Box className="auditoria-item-container">
            
            
            <Typography minWidth="150px">{nombreNorma}
                {
                    integrada &&
                    <Tooltip title="Norma Integrada">
                        <FiberManualRecordIcon color="integracion" fontSize="small" />
                    </Tooltip>
                }
            </Typography>
           
            <Box className="auditoria-item-wrapper"  width={tieneEtapas ? "320px" : "150px"} >
                {children}
            </Box>
        </Box>
    );
}

const ItemDiasAuditor = ({ nombre, dias, width, borderColor = "#e0e0e0", color = null, etapa = null }) => {

    const renderNombreDias = (nombre, etapa) => 
        etapa !== null && nombre === "Certificacion" ? "Cert. Etapa " + etapa : nombre;
    

    return (
        <>
            {
                dias > 0 ?
                    <Box className="auditoria-item" width={width} borderColor={borderColor}  >
                        <Typography color={color} variant="caption" fontWeight="600">{renderNombreDias(nombre, etapa)}</Typography>
                        <Typography color={color} fontWeight="bold">{dias?.toFixed(2)}</Typography>
                    </Box> :
                    <Box className="auditoria-null-item" />
            }
        </>
    )
}

const Subtotal = ({ etapa1 = null, etapa2 = null }) => {
    return (
        <ItemContainer tieneEtapas={true}>
            <ItemDiasAuditor nombre="Subtotal Etapa 1" dias={etapa1} />
            <ItemDiasAuditor nombre="Subtotal Etapa 2" dias={etapa2} />
        </ItemContainer>
    )
}

const Total = ({ dias, tieneEtapas }) => {
    return (
        <Box className="auditoria-total">
            <ItemContainer tieneEtapas={tieneEtapas}>
                <ItemDiasAuditor width={"100%"} nombre="Total" dias={dias} color={"primary.dark"} borderColor="primary.dark" />
            </ItemContainer>
        </Box>
    )
}

Auditoria.Titulo = Titulo;
Auditoria.ItemContainer = ItemContainer;
Auditoria.ItemDiasAuditor = ItemDiasAuditor;
Auditoria.Subtotal = Subtotal;
Auditoria.Total = Total;

export default Auditoria;
