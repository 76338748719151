import { Box, Typography } from '@mui/material'
import { TIPO_SOLICITUD_PRODUCTO } from 'constants/constants';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import React from 'react'
import { useContext } from 'react';

function FooterPropuesta({ total, conceptos }) {

  const { state: stateCot } = useContext(CotizacionContext)
  const { json } = stateCot;

  let totalMonto = 0;

  for (const concepto of conceptos) {

    if (!concepto.custom) {

      const valorUnitario = concepto.arancel !== null ? concepto.arancel.valor : 0;
      const totalPorDiaAuditor = (Number(concepto.diasOrganizacion)) * Number(valorUnitario);

      const bonificado = concepto.esBonificacionPorcentual ? totalPorDiaAuditor * concepto.bonificacion / 100 : concepto.bonificacion;

      const recargado = concepto.esRecargoPorcentual ? totalPorDiaAuditor * concepto.recargo / 100 : concepto.recargo;

      totalMonto = totalMonto + (totalPorDiaAuditor - bonificado + recargado);
    } else {
      const totalPorProducto = Number(concepto.producto.valor) * Number(concepto.producto.cantidad);

      const bonificado = concepto.esBonificacionPorcentual ? totalPorProducto * concepto.bonificacion / 100 : concepto.bonificacion;

      const recargado = concepto.esRecargoPorcentual ? totalPorProducto * concepto.recargo / 100 : concepto.recargo;

      totalMonto = totalMonto + (totalPorProducto - bonificado + recargado);
    }

  }

  const classNameTitulo = json && json.tipo === TIPO_SOLICITUD_PRODUCTO ? 'titulo-concepto-producto-head' : 'titulo-concepto-unified-head';


  return (
    <>
      {
        total &&
        <Box display="flex" className="headerTable" sx={{ paddingBottom: "10px" }}>
          <Box className={classNameTitulo}>
            <Typography >Totales</Typography>
          </Box>
          {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box className="titulo-dias-iram">
            <Typography >{total.diasIram}</Typography>
          </Box>}
          {json && json.tipo !== TIPO_SOLICITUD_PRODUCTO && <Box className="titulo-dias-organizacion">
            <Typography >{total.diasOrganizacion}</Typography>
          </Box>}
          <Box className="titulo-moneda">
            {/* <Typography>-</Typography> */}
          </Box>
          <Box className="titulo-arancel-new">
            {/* <Typography>-</Typography> */}
          </Box>
          <Box className="titulo-bonificacion">
            {/* <Typography>{total.bonificacion}</Typography> */}
          </Box>
          <Box className="titulo-recargo">
            {/* <Typography >{total.recargo}</Typography> */}
          </Box>
          <Box className="titulo-total">
            <Typography>{totalMonto}</Typography>
          </Box>
        </Box>
      }

    </>
  )
}

export default FooterPropuesta