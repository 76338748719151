import { isDev } from 'config/config';
import axios from 'axios';
import client from 'services/httpRequest';


export const getUsuario = async () => {
    if (isDev()) {
        return await axios.get('https://apirdadevtest.azurewebsites.net/.auth/me', {
            headers: {
                "Content-Type": "application/json"
            },
            withCredentials: true
        });
    } else {
        return await client.get('/.auth/me');
    }
}
