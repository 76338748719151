import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
   palette: {
      primary: {
         light: '#77cbfc',
         main: '#001D96',
         dark: '#478fff',
         contrastText: '#fff',
      },
      secondary: {
         light: '#a7a7a7',
         main: '#7c7c7c',
         dark: '#424242',
         contrastText: '#fff',
      },
      text: {
         primary: 'rgba(0, 0, 0, 0.87)',
         secondary: 'rgba(0, 0, 0, 0.54)',
         disabled: 'rgba(0, 0, 0, 0.38)',
         hint: 'rgba(0, 0, 0, 0.38)',
      },
      background: {
         paper: '#fff',
         default: '#f5f5f5'
      },
      reduccion:{
         light:'#ffb74d',
         main:'#ff9800',
         dark:'#ef6c00',
         contrastText: '#fff',
      },
      factoresSustrativo:{
         light:'#81c784',
         main:'#4caf50',
         dark:'#388e3c',
         contrastText: '#fff',
      },
      factoresAditivo:{
         light:'#ff8a65',
         main:'#ff5722',
         dark:'#e64a19',
         contrastText:'#fff'
      },
      integracion:{
         light:'#4fc3f7',
         main:'#03a9f4',
         dark:'#0277bd',
         contrastText: '#fff',
      },

   },
   shape: {
      borderRadius: 4
   },
   zIndex: {
      appBar: 1200,
      drawer: 1100,
   },
});

// Dark mode specific palette
const darkModePalette = {
   primary: {
      light: '#77cbfc',
      main: '#001D96',
      dark: '#478fff',
      contrastText: '#fff',
   },
   secondary: {
      light: '#a7a7a7',
      main: '#7c7c7c',
      dark: '#424242',
      contrastText: '#fff',
   },
   text: {
      primary: '#fff',
      secondary: 'rgba(255, 255, 255, 0.7)',
      disabled: 'rgba(255, 255, 255, 0.5)',
      hint: 'rgba(255, 255, 255, 0.5)',
   },
   background: {
      paper: '#333',
      default: '#222'
   },
   reduccion:{
      light:'#ffb74d',
      main:'#ff9800',
      dark:'#ef6c00',
      contrastText: '#fff',
   },
   factoresSustrativo:{
      light:'#81c784',
      main:'#4caf50',
      dark:'#388e3c',
      contrastText: '#fff',
   },
   factoresAditivo:{
      light:'#ff8a65',
      main:'#ff5722',
      dark:'#e64a19',
      contrastText:'#fff'
   },
   integracion:{
      light:'#4fc3f7',
      main:'#03a9f4',
      dark:'#0277bd',
      contrastText: '#fff',
   },
};

const theme = createTheme({
   typography: {
      useNextVariants: true,
   },
   components:{
      MuiButton:{
         styleOverrides:{
            root:{
               borderRadius:'20px'
            }
         }
      },
      MuiIconButton: {
         variants: [
            {
               props: { variant: 'edit' },
               style: {
                  color: defaultTheme.palette.primary.main,
                  border: `1px solid ${defaultTheme.palette.primary.main}`,
                  backgroundColor: defaultTheme.palette.background.default,
                  boxShadow: defaultTheme.shadows[4]
               },
            },
            {
               props: { variant: 'delete' },
               style: {
                  color: '#fff',
                  border: `1px solid ${defaultTheme.palette.text.primary}`,
                  backgroundColor: defaultTheme.palette.text.primary,
                  boxShadow: defaultTheme.shadows[4],
                  '&:hover': {
                     backgroundColor: defaultTheme.palette.text.primary,
                  }
               },
            }
         ]
      },
      MuiOutlinedInput: {
         variants: [
            {
               props: { variant: 'status' },
               style: {
                  borderRadius: 40,
                  paddingLeft: 28,
                  '&:before': {
                     content: "''",
                     position: 'absolute',
                     left: 14,
                     top: '50%',
                     marginTop: -7,
                     width: 14,
                     height: 14,
                     display: 'block',
                     backgroundColor: defaultTheme.palette.grey[400],
                     borderRadius: '50%'
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.grey[400]
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.grey[400],
                     borderWidth: 1
                  }
               }
            },
            {
               props: { variant: 'status', color: 'success' },
               style: {
                  color: defaultTheme.palette.success.main,
                  '&:before': {
                     backgroundColor: defaultTheme.palette.success.main,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.success.main
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.success.main,
                  }
               }
            },
            {
               props: { variant: 'status', color: 'warning' },
               style: {
                  color: defaultTheme.palette.warning.main,
                  '&:before': {
                     backgroundColor: defaultTheme.palette.warning.main,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.warning.main
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.warning.main,
                  }
               }
            },
            {
               props: { variant: 'status', color: 'error' },
               style: {
                  color: defaultTheme.palette.error.main,
                  '&:before': {
                     backgroundColor: defaultTheme.palette.error.main,
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.error.main
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                     borderColor: defaultTheme.palette.error.main,
                  }
               }
            }
         ]
      },
       MuiPaper:{
         styleOverrides:{
            root:{
               paddingLeft: 24,
               paddingRight: 24,
            }
         }
      },
      MuiGrid:{
         styleOverrides:{
            root:{
               padding: 10
            },
         }
      },
   },
   palette: defaultTheme.palette,
   shape: defaultTheme.shape,
   zIndex: defaultTheme.zIndex
});


// Extend the theme with dark mode configuration
const darkTheme = createTheme({
   ...theme,
   palette: darkModePalette,
});

export { theme, darkTheme };
