import {  Box, Checkbox, Chip, FormControl, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import ModalPortal from "components/modalPortal";
import { useEffect, useState } from "react";
import Autocomplete from '@mui/material/Autocomplete';
import { toast } from 'react-toastify';
import { getDetalleOrganizacion, getEstadoCliente, getLocalidades, getPaises, getProvincias, getRubros, updateOrganizacion } from "services/Organizacion/organizacionService";

export const ModalEditarOrganizacion = ({ onClose, isOpened, id}) => {
    
    const [cuit, setCuit] = useState("");
    const [cuitError, setCuitError] = useState({ hasError: false, text: "" });
    const [esCuitMultiple, setEsCuitMultiple] = useState(false);
    const [esExtranjero, setEsExtranjero] = useState(false);
    const [estadoCliente, setEstadoCliente] = useState(null);
    const [rubros, setRubros] = useState([]);
    const [paises, setPaises] = useState([]);
    const [provincias, setProvincias] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [razonSocial, setRazonSocial] = useState("");
    const [razonSocialAFIP, setRazonSocialAFIP] = useState("");
    const [email, setEmail] = useState("");
    
    const [selectedRubro, setSelectedRubro] = useState(null);
    const [selectedPais, setSelectedPais] = useState({id: 12, label: "Argentina"});
    const [selectedProvincia, setSelectedProvincia] = useState(null);
    const [selectedLocalidad, setSelectedLocalidad] = useState(null);
    const [domicilo, setDomicilio] = useState("");
    const [codigoPostal, setCodigoPostal] = useState("");
    const [telefono, setTelefono] = useState("");
    const [idDireccion ,setIdDireccion] = useState(null);
    const [direccionLegacy, setDireccionLegacy] = useState("");

    useEffect(() => {
        obtenerRubros();
    }, []);

    useEffect(() => {
        if(isOpened){
            obtenerEmpresa(id);
        }
    }, [isOpened, id]);

    useEffect(() => {
        validarEstadoCliente(cuit);
    }, [cuit])

    function obtenerEmpresa(id) {

        getDetalleOrganizacion(id)
            .then(empresa => {
                setCuit(empresa.cuit);
                setRazonSocial(empresa.razonSocial);
                setRazonSocialAFIP(empresa.razonSocialAFIP);
                setEmail(empresa.email);
                obtenerRubros(empresa.rubroId);

                const direccionFiscal = empresa.direcciones?.find(e => e.esDireccionFiscal);
                
                if(direccionFiscal){
                    obtenerPaises(direccionFiscal.pais?.id);
                    if(!direccionFiscal.pais){
                        setSelectedPais(null);
                    }
                    
                    if(!direccionFiscal.provincia){
                        setSelectedProvincia(null);
                    }

                    obtenerProvincias(direccionFiscal.provincia?.id);
                    obtenerLocalidades(direccionFiscal.provincia?.id,direccionFiscal.localidad?.id);
                    
                    setDomicilio(direccionFiscal.domicilio);
                    setCodigoPostal(direccionFiscal.codigoPostal);
                    setTelefono(direccionFiscal.telefono);
                    setIdDireccion(direccionFiscal.id);
                    if(!direccionFiscal.pais || !direccionFiscal.provincia || !direccionFiscal.localidad){
                        setDireccionLegacy(direccionFiscal.direccionLegacy);
                    }else{
                        setDireccionLegacy("");
                    }
                }else{
                    obtenerPaises();
                    obtenerProvincias();
                }

            })
            .catch(error => {
                console.error("Error al obtener empresa, ", error);
                setCuitError({ hasError: false, text: ""})
            });
    }

    async function validarEstadoCliente(cuit){

        getEstadoCliente(cuit).then(estado => {
            setEstadoCliente(estado)
        }).catch(error => {
            console.error("Error al obtener estado cliente, ", error);
            setEstadoCliente(null)
        });
    }

    function obtenerRubros(id){
        getRubros().then(listaRubros => {
            setRubros(listaRubros);

            if(id){
                const rubro = listaRubros.find(r => r.id === id);
                setSelectedRubro({id: rubro.id, label: rubro.nombre});
            }
        }).catch(error => {
            console.error("Error al obtener rubros, ", error);
            setRubros([]);
        });
    }
    
    function obtenerPaises(id){
        getPaises().then(listaPaises => {
            setPaises(listaPaises);

            if(id){
                const pais = listaPaises.find(p => p.id === id);
                setSelectedPais({id: pais.id, label: pais.descripcion});
            }
        })
        .catch(error => {
            console.error("Error al obtener paises, ", error);
            setPaises([]);
        });
    }

    function obtenerProvincias(id){
        getProvincias(id)
            .then(listaProvincias => {
                setProvincias(listaProvincias);

                if(id){
                    const provincia = listaProvincias.find(p => p.id === id);
                    setSelectedProvincia({id: provincia.id, label: provincia.descripcion});
                }
            })
            .catch(error => {
                console.error("Error al obtener provincias, ", error);
                setProvincias([]);
            });
    }

    function obtenerLocalidades(provinciaId, idLocalidad){
        getLocalidades(provinciaId)
            .then(listaLocalidades => {
                setLocalidades(listaLocalidades);

                if(idLocalidad){
                    const localidad = listaLocalidades.find(l => l.id === idLocalidad);
                    if(localidad){
                        setSelectedLocalidad({id: localidad.id, label: localidad.nombre});
                    }else{
                        setSelectedLocalidad(null);
                    }
                }

            })
            .catch(error => {
                console.error("Error al obtener localidades, ", error);
                setLocalidades([]);
            });
    }

    function handleCuitChange(e) {
        setCuit(e.target.value);
        validarEstadoCliente(e.target.value);
    }

    function handleRubroChange(e, value) {
        setSelectedRubro(value); 
    }

    function handlePaisChange(e, value) {
        setSelectedPais(value); 
    }

    function handleProvinciaChange(e, value) {
        if(value){
            obtenerLocalidades(value.id);
            setSelectedProvincia(value); 
        }else{
            setSelectedProvincia(null); 
            setLocalidades([]);
            setSelectedLocalidad(null);
        }
    }

    function handleLocalidadChange(e, value) {
        setSelectedLocalidad(value); 
    }

    function onSave(e){

        e.preventDefault();

        const editEmpresaBody = {
            id: id,
            identificador: cuit,
            username: "nico",
            razonSocial: razonSocial,
            razonSocialAFIP: razonSocialAFIP,
            email: email,
            idRubro: selectedRubro.id,
            esCuitMultiple: esCuitMultiple,
            direccion: {
                id: idDireccion,
                pais: selectedPais,
                provincia: selectedProvincia,
                localidad: selectedLocalidad,
                domicilio: domicilo,
                codigoPostal: codigoPostal,
                telefono: telefono,
                tipo: {id: 0},
                esDireccionFiscal: true
            }
        }

        updateOrganizacion(editEmpresaBody)
            .then(() => {
                toast("Empresa modificada con éxito");
            })
            .catch(error => {
                console.error("Error al editar la empresa, ", error);
                toast("Error al editar la empresa");
            });
    }

    const rubrosOrdenadosPorNombre = rubros.sort((a, b) => a.nombre.localeCompare(b.nombre));
    const nombreIdentificador = esExtranjero ? "IDENTIFICADOR" :  "CUIT/CUIL";

    const esArgentina = selectedPais && selectedPais.id === 12;

    return (
        <ModalPortal
            onCancel={onClose}
            onSave={onSave}
            title=""
            isOpened={isOpened}
        >
            <div style={{ maxWidth: "900px", display: "flex" }}>

                <Grid container spacing={2}>
                    <Grid display={"flex"} direction={"row"} justifyContent={"space-between"} item xs={12} sm={12}>
                        <Box display={"flex"} flexDirection={"row"}>
                            <Typography variant="h6" >Editar organización</Typography>
                            {estadoCliente &&<Chip label={estadoCliente.descripcion} sx={{marginLeft: "10px", backgroundColor: estadoCliente.color}} />}
                        </Box>
                        <FormControl>
                            <Box sx={{marginLeft:"10px"}} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                            <FormControlLabel control={<Checkbox checked={esExtranjero} onChange={(_, check) => setEsExtranjero(check)} />} label="Es extranjero" />
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid display={"flex"} alignItems={"center"} direction={"row"} item xs={12} sm={6}>
                        <FormControl>
                            <TextField
                                fullWidth
                                required
                                value={cuit}
                                onChange={handleCuitChange}
                                label={nombreIdentificador}
                                error={cuitError.hasError && !esCuitMultiple}
                                helperText={cuitError.hasError && !esCuitMultiple ? cuitError.text : ""}
                            />
                        </FormControl>
                        <FormControl>
                            <Box sx={{marginLeft:"10px"}} display={"flex"} flexDirection={"row"} alignItems={"center"} >
                            <FormControlLabel control={<Checkbox checked={esCuitMultiple} onChange={(_, check) => setEsCuitMultiple(check)} />} label="Es multiple" />
                            </Box>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setRazonSocial(e.target.value)}
                                value={razonSocial}
                                label="Nombre o Razón Social de fantasía"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={(e) => setRazonSocialAFIP(e.target.value)}
                                value={razonSocialAFIP}
                                label="Nombre o Razón Social(AFIP)"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={e => setEmail(e.target.value)}
                                value={email}
                                label="Dirección e-mail"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedRubro}
                        onChange={handleRubroChange}
                        options={rubros.map(rubro => ({ id: rubro.id, label: rubro.nombre }))}
                        renderInput={(params) => <TextField required {...params} 
                        label="Rubro" />}
                        />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <Typography variant="h6" >Dirección Fiscal</Typography>
                        <Typography variant="body2" >{direccionLegacy}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedPais}
                        onChange={handlePaisChange}
                        options={paises.map(pais => ({ id: pais.id, label: pais.descripcion }))}
                        renderInput={(params) => <TextField required {...params} 
                        label="País" />}
                        />
                        </FormControl>
                    </Grid>
                    {esArgentina && <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedProvincia}
                        onChange={handleProvinciaChange}
                        options={provincias.map(provincia => ({ id: provincia.id, label: provincia.descripcion }))}
                        renderInput={(params) => <TextField required {...params} 
                        label="Provincia" />}
                        />
                    </FormControl>
                        
                    </Grid>}
                    {esArgentina && <Grid item xs={12} sm={6}>
                    <FormControl fullWidth >
                        <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={selectedLocalidad}
                        onChange={handleLocalidadChange}
                        options={localidades.map(localidad => ({ id: localidad.id, label: localidad.nombre }))}
                        renderInput={(params) => <TextField required {...params} 
                        label="Localidad" />}
                        />
                    </FormControl>
                    </Grid>}
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={e => setDomicilio(e.target.value)}
                                value={domicilo}
                                label="Domicilio"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={e => setCodigoPostal(e.target.value)}
                                value={codigoPostal}
                                label="Código Postal"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth >
                            <TextField
                                fullWidth
                                required
                                onChange={e => setTelefono(e.target.value)}
                                value={telefono}
                                label="Teléfono"
                            />
                        </FormControl>
                    </Grid>
                </Grid>

            </div>
        </ModalPortal>
    );
}