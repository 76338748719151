import React, { useState, useContext } from 'react'
import ModalPortal from 'components/modalPortal'
import { Box } from '@mui/system'
import { parseISO } from 'date-fns'
import { TextField } from '@mui/material'
import { TIPO_ESTADO } from 'constants/constants';
import { updateEstadoCotizacion } from 'services/Cotizacion'
import { CotizacionContext } from 'context/cotizacion/cotizacionContext'
import { setEstadoCotizacion, updatePropuestaEstado } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion'
 
import { useDispatch } from 'react-redux'

function ModalFechaDescartada({onClose, isOpened, propuesta}) {
    
    const dispatchRedux = useDispatch()
    const {dispatch} = useContext(CotizacionContext)

    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }
    const formatDate = (date) => {
        return [
            date.getFullYear(),
            padTo2Digits(date.getMonth() + 1),
            padTo2Digits(date.getDate()),
          ].join('-');
    }
    const [fecha, setFecha] = useState(formatDate(new Date()))

    const onSave = () => {
        var data = {
            idPropuesta: propuesta.id,
            estado: TIPO_ESTADO.DESCARTADA,
            posiblesTransiciones:null,
            fechaDescarte:parseISO(fecha)
        }
        updatePropuestaEstado(dispatch, data, dispatchRedux)
        onClose()
    }

    const onCloseButton = () => {
        onClose()
    }

    const handleFecha = (e) => {
        setFecha(e.target.value)
    }

    const getFechaMinima = () => {
        
        if(propuesta){
            if(propuesta.fechaEnviada){
                return propuesta.fechaEnviada?.split('T')[0]
            }else{
                return propuesta.fechaCreacion?.split('T')[0]
            }
        }
        else{
            return "2022-01-01"
        }
    }

    return (
        <ModalPortal
        onCancel={onCloseButton}
        onSave={onSave}
        title="Fecha de descarte"
        isOpened={isOpened}

        >
            <Box display="flex">
            <TextField  
                label="Fecha"
                type={"date"}
                fullWidth
                value={fecha} 
                InputLabelProps={{ shrink: true }}  
                InputProps={{inputProps: {min:getFechaMinima(), max:"2900-05-04"}}}
                required
                onChange={handleFecha}/>
            </Box>
        </ModalPortal>
    )
}

export default ModalFechaDescartada