import { CotizacionContext } from "context/cotizacion/cotizacionContext"
import { useContext } from "react"

export const usePermitirEdicion = (modoEdicion) => {

    const {state} = useContext(CotizacionContext)
    const {json} = state
    const guardado = json.id !== ''

    if(guardado){
        return !modoEdicion
      }else{
        return false;
    }
}