export const SET_LISTA_SOLICITUDES = "setListaSolicitudes";
export const SET_LISTA_COTIZACIONES = "setListaCotizaciones"
export const RESET_STATE = "resetState";

export const SET_NUMERO = "setNumero";
export const SET_ANIO = "setAnio";
export const SET_ORGANIZACION = "setOrganizacion";
export const SET_PERSONAL = "setPersonal";
export const SET_ELABORADO = "setElaborado";
export const SET_DIRECCION = "setDireccion";
export const SET_CANTIDAD_ESTUDIOS_HACCP = "setCantidadEstudiosHaccp";
export const SET_TURNOS = "setTurnos";


export const SET_ID_SOLICITUD = "setIdSolicitud";
export const SET_SOLICITUD = 'setSolicitud'

export const SET_FILTROS = "setFiltros"
export const SET_PAGINAS = "setPaginas"
export const SET_NUMERO_PAGINA = "setNumeroPagina"
export const SET_CANTIDAD_POR_PAGINAS = "setCantidadPorPaginas"
export const SET_CARGANDO_PAGINAS = "setCargandoPaginas"
export const RESET_FILTROS = "resetFiltros"
export const SET_ESTADO_SOLICITUD = "setEstadoSolicitud"

export const SET_CEPS = "setCeps"
export const SET_SELECTED_CEP = "setSelectedCep"
export const SET_CEP = "setCEP"
export const SET_RESUMEN_PROPUESTAS = "setResumenPropuestas"
export const RESET_RESUMEN_PROPUESTAS = "resetResumenPropuestas"


