import { Box, Grid, Typography } from '@mui/material';

const classes = {
   data: {
      '& span': {
         fontWeight: 400
      }   
   },
   item: {
      flex: '0 0 auto',
      width: '50%',
      maxWidth: '100%',
      pr: '10px',
      pl: '10px',
      pt: '10px',
      '&:first-child': {
         pt: '0'
      },
      '&:nth-child(2)': {
         pt: '0'
      }
   },
   subcategorias: {
      display: 'flex',
      flexWrap: 'wrap',
      mr: '-10px',
      ml: '-10px',
   },
   root: {
      py: 0,
      mb: '20px',
      borderBottomWidth: 1,
      borderBottomColor: 'divider',
      borderBottomStyle: 'solid'
   }
}

const AlimentosNacesCategoria = ({ data }) => {
   const { descripcion, subcategorias } = data;

   return (
      <Grid container sx={classes.root}>
         <Grid item xs={6}>
            <Typography variant="subtitle2" sx={classes.data}>
               Categoría: <span>{  descripcion}</span>
            </Typography>
         </Grid>
         <Grid item xs={6}>
            { subcategorias && subcategorias.length > 0 &&
               <Box sx={classes.subcategorias}>
                  { subcategorias.map((subcategoria, index) => 
                        <Grid item key={'subcategoria'+index} xs={3} sx={classes.item}>
                           <Typography variant="subtitle2" sx={classes.data}>
                              Subcategoría: <span>{ subcategoria.subCategoria }</span>
                           </Typography>
                        </Grid>
                     )
                  }
               </Box> 
            }
         </Grid>
      </Grid>
   )
}

export default AlimentosNacesCategoria;
