import { Accordion, AccordionDetails, AccordionSummary, CircularProgress, Divider, Drawer, IconButton, Tooltip, Typography } from '@mui/material'
import React, { useState, useEffect, useContext } from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';
import ChatIcon from '@mui/icons-material/Chat';
import CalculateIcon from '@mui/icons-material/Calculate';
import AddCircleIcon from '@mui/icons-material/AddCircle';

//style
import 'scss/components/accordionSitioVisita.scss'
import { makeStyles } from '@mui/styles';
import SitioConcreto from 'components/SitioConcreto';
import { ScrollSyncPane } from 'react-scroll-sync';
import AccordionCalculoDiasAuditor from 'components/accordionCalculoDiasAuditor';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { capitalizeFirstLetter } from 'utlis/stringUtils';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { PERMISOS } from 'constants/constants';

const useStyles = makeStyles(theme => ({
  Accordion: {
    padding: 0,
    boxShadow: `0px 0px 0px 0px`,
    backgroundColor:`#e4e4e4`,
  
  },
  AccordionExpanded:{
      padding: 0,
      boxShadow: `0px 0px 0px 0px`,
  },
  ButtonAdd:{
      backgroundColor: `#e4e4e4`
  },
 
}));

function getRandomNumber(){
  return Math.floor(Math.random() * 100000)
}

function AccordionVisitaSitio({sitio, grupo}) {
  const classes = useStyles();
  const {state} = useContext(CotizacionContext)
  const {json} = state

  const [listaSitiosMuestra, setListaSitios] = useState([])

  const [botonAgregar, setBotonAgregar] = useState(false)


  useEffect(() => {
    setListaSitios(sitio.sitiosConcretos)
  }, [sitio])

  useEffect(() => {
    if(sitio.cantidadDeSitios > 1){
      if(listaSitiosMuestra.length < sitio.cantidadDeSitios){
        setBotonAgregar(true)
      }else{
        setBotonAgregar(false)
      }
    }else{
      setBotonAgregar(false)
    }
  }, [sitio.sitiosConcretos, listaSitiosMuestra])
  
  const renderTooltip = () => {
    return(
      <Box className="sitio-visita-resumen-tooltip">
        <Box className="sitio-visita-resumen-tooltip-sitios">
          <span>SITIOS</span>
          <span>{sitio.cantidadDeSitios}</span>
        </Box>
        <Box className="sitio-visita-resumen-tooltip-dias" gap={1}>
          <Box>DIAS</Box>
          <Box style={{paddingTop:"12px"}}>
          {
            sitio.diasCertificacion &&
            <Box display="flex" justifyContent={"space-between"}>
              <span>Certificacion</span>
              <span style={{paddingLeft:"10px"}}>{sitio.diasCertificacion}</span>
            </Box>
          }
          {
            sitio.diasRecertificacion &&
            <Box display="flex" justifyContent={"space-between"}>
              <span>Recertificacion</span>
              <span style={{paddingLeft:"10px"}}>{sitio.diasRecertificacion}</span>
            </Box>
          }
          {
            sitio.diasMantenimiento &&
            <Box display="flex" justifyContent={"space-between"}>
              <span>Mantenimiento</span>
              <span style={{paddingLeft:"10px"}}>{sitio.diasMantenimiento}</span>
            </Box>
          }
            
        </Box>
      </Box>
    </Box>
    )
  }

  //ACCORDION EXPANDED
  const [expanded, setIsExpanded] = useState(false)
  const handleChangeExpanded = () => (event, isExpanded) => {
      setIsExpanded(isExpanded)
    };

  //DRAWER
  const [openDrawer, setOpenDrawer] = useState(false)
  const closeDrawer = () => {
      setOpenDrawer(false)
  }

  const handleAgregarSitio = () => {
    const aux = listaSitiosMuestra.concat({nombre:''})
    setListaSitios(aux)
  }


  return (
    <div className='sitio-visita-accordion'>
      <Accordion  onChange={handleChangeExpanded()} className={expanded ? classes.AccordionExpanded: classes.Accordion} >
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}   aria-controls="panel1bh-content">
          <Box className="sitio-visita-resumen-nombre" gap={1}>
          <Typography fontWeight="fontWeightBold" >{capitalizeFirstLetter(sitio.nombre)}</Typography>
            <Box alignSelf="center" style={{color:"GrayText"}}> ({sitio.cantidadDeSitios})</Box> 
          </Box>
          <Box className="sitio-visita-resumen-icon">
            <Tooltip title={renderTooltip()}>
              <ChatIcon/>
            </Tooltip>
          </Box>
          <ScrollSyncPane>
            <Box className="sitio-visita-resumen-instancias" gap={1}>
            {
              sitio.instancias.map((instancia, index) => 
              <Box className="sitio-visita-resumen-box" key={'instanciasvisita'+index}>
                <Box className="sitio-visita-resumen-instancia">
                <Typography variant="body2">Sitios planificados</Typography>
                <Typography variant="h6">{instancia.sitiosConcretoInstancia.filter(sci => sci.requiereVisita === true).length}</Typography>
                </Box>
              </Box>
              )
            }
            </Box>
          </ScrollSyncPane>
        </AccordionSummary>
        <AccordionDetails>
          <Box className="sitio-visita-detail">
            <Box className="sitio-visita-detail-calculadora">
              <Tooltip title="modificaciones">
                {/* <Restricted  to={PERMISOS.LECTURA} method={RESTRICT_METHOD.HIDE}> */}
                <IconButton variant="edit" onClick={setOpenDrawer} >
                    <CalculateIcon/>
                  </IconButton>
                {/* </Restricted> */}
              </Tooltip>
            </Box>
            <Box className="sitio-visita-detail-sitioConcreto">
              {
                listaSitiosMuestra &&
                <>
                {
                  listaSitiosMuestra.map((sitioConcreto, index) => 
                  <SitioConcreto 
                    key={getRandomNumber() + "SR"}
                    sitio={{...sitio, idCotizacion: grupo.idCotizacion, idGrupo:grupo.id, instanciasGrupo: grupo.grupoInstancias}}
                    setListaSitios={setListaSitios}
                    index={index}
                    sitioConcreto={sitioConcreto}
                    listaSitiosMuestraSize={listaSitiosMuestra.length}
                  />)
                }
                </>
              }
            </Box>
          </Box>
          {
            botonAgregar &&
            <Box className="sitio-visita-detail-add">
            <Tooltip title={"Cantidad de sitios restante: " + (sitio.cantidadDeSitios-listaSitiosMuestra.length)}>
                <IconButton onClick={handleAgregarSitio}>
                <AddCircleIcon/>
                </IconButton>
            </Tooltip>
            </Box>
          }
          
          {/* {
            listaSitiosMuestra &&
            <>
              <Box className='sitio-visita-detail'>
                <Box className="sitio-visita-detail-calculadora">
                  <Tooltip title="modificaciones">
                  <IconButton variant="edit" onClick={setOpenDrawer} >
                      <CalculateIcon/>
                    </IconButton>
                  </Tooltip>
                </Box>
                {
                listaSitiosMuestra.map((sitioConcreto, index) => 
                <SitioMuestra sitio={{...sitio, idCotizacion: grupo.idCotizacion, idGrupo:grupo.id}}
                setListaSitios={setListaSitios}
                index={index}
                sitioConcreto={sitioConcreto}
                />)}
              </Box>
            
            <Box className="sitio-visita-detail-buttons">
              { botonAgregar &&
              
              }
            </Box>
            </> */}
          
        </AccordionDetails>
      </Accordion>
      <React.Fragment >
            <Drawer
            anchor='right'
            open={openDrawer}
            onClose={closeDrawer}
            >
            <div style={{width:"800px", paddingTop:"100px"}}>
                {
                    sitio.normas.map((norma, index) =>
                    <div style={{paddingTop:"20px"}}>
                        <AccordionCalculoDiasAuditor
                        key={"tabdiasauditorsitio"+ index} 
                        className="accordion" 
                        idCotizacion={json.id}
                        idGrupo={grupo.id}
                        idSitio={sitio.id}
                        norma={{...norma, grupoTipo: grupo.tipo}}
                        />
                    </div>
                    )
                }
            </div>
          </Drawer>
      </React.Fragment>
    </div>
  )
}

export default AccordionVisitaSitio