import React, { useContext, useEffect, useState } from 'react'
import {Grid, FormControl, InputLabel, Select, MenuItem, TextField, Tooltip} from '@mui/material';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { updateNorma } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import InfoIcon from '@mui/icons-material/Info';
import { Box } from '@mui/system';
import { PERMISOS, TIPO_ESTADO } from 'constants/constants';
import Restricted, { RESTRICT_METHOD } from 'components/Restricted';
import { useDispatch, useSelector } from 'react-redux';

const ModificadoresNorma = ({norma, idGrupo, idSitio}) => {

    const dispatchRedux = useDispatch()
    const globalSlice = useSelector(state => state.global)
    const {listaReduccion} = globalSlice

    const {state, dispatch} = useContext(CotizacionContext)
    const {json, estadoCotizacion, estadoCarga} = state

    const tieneComplejidad = norma.cotizacionNorma.norma.usaComplejidad
    const sitioAux = json.gruposDeSitios.find(g => g.id === idGrupo).sitios.find(s => s.id === idSitio)
    const personalSitio = sitioAux.personal ? sitioAux.personal : sitioAux.rangoDePersonal.hasta
    
    //estado local
    const [personalAfectado, setPersonalAfectado] = useState(norma.personalAfectado)
    const [errorPersonal, setErrorPersonalAfectado] = useState(false)

    const handlePersonalAfectado = (e) =>{
        setPersonalAfectado(parseInt(e.target.value))
    }

    
    // useEffect(() => {
        
    //     if(personalAfectado <= personalSitio){
    //         setErrorPersonalAfectado(false)
    //     }
    //     else{
    //         setErrorPersonalAfectado(true)
    //     }
      
    // }, [personalAfectado])

     useEffect(() => {
        if(personalAfectado <= personalSitio){
            setErrorPersonalAfectado(false)
        }
        else{
            
            setErrorPersonalAfectado(true)
        }

        const timeout = setTimeout(
            actualizarPersonalAfectado
            ,1500)
            return () =>{
                clearTimeout(timeout)
            }
    }, [personalAfectado])
    

    const actualizarPersonalAfectado = () =>{
        // const personalTotal = json.gruposDeSitios[0].sitios[0].personal
        const personalTotal = personalSitio
        const personalNorma = norma.personalAfectado
        const personalInput = personalAfectado

        if(personalInput === personalNorma){
        }else{
            if(personalInput <= personalTotal){
                updateNorma(dispatch, json.id, idGrupo, idSitio, {...norma, personalAfectado: personalAfectado})
            }else{
            }
        }
    }

    const handleChangeReduccion = (event) => {
        const porcentaje = event.target.value
        const normaAux = {...norma, reduccionPlanificacionDocumentacion: porcentaje}
        updateNorma(dispatch, json.id, idGrupo, idSitio, normaAux)
    }
    const handleChangeComplejidad = (event) => {
        const complejidad = event.target.value
        const normaAux = {...norma, complejidad: complejidad}
        
        updateNorma(dispatch, json.id, idGrupo, idSitio, normaAux)
    }
   
    const complejidadEditable = () => {
        if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR){
            if(norma.complejidadesPosibles){
                if(norma.complejidadesPosibles.length === 1){
                    return true;
                }
                else{
                    return estadoCarga.guardando;
                }
            }
        }else{
            return true
        }
    }
    
    const inputEditable = () => {
        if(estadoCotizacion.estado === TIPO_ESTADO.BORRADOR){
            return estadoCarga.guardando
        }else{
            return true
        }
    }
    
    return (
        <>
          {
            tieneComplejidad &&
            <Grid item xs={4}>
                <Box display="flex" gap={1}>
                {
                    norma.complejidadesPosibles.length > 1 &&
                    <Box alignSelf={'center'}>
                        <Tooltip title="Hay varias complejidades posibles para seleccionar">
                            <InfoIcon color="warning"/>
                        </Tooltip>
                    </Box>
                    
                }
                
                    <FormControl fullWidth>
                    <InputLabel id="labelComplejidad">Complejidad</InputLabel>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                    labelId="labelComplejidad"
                    label="Complejidad"
                    onChange={handleChangeComplejidad}
                    value={norma.complejidad}
                    disabled={complejidadEditable()}
                    >
                        {
                            norma.complejidadesPosibles.map((item, index) =>
                            <MenuItem key={'complejidad'+index} value={item}>{item}</MenuItem>)
                        }
                    </Select>
                    </Restricted>
                    </FormControl>
                </Box>
            </Grid>
            }
            <Grid item xs={tieneComplejidad?4:6}>
            
                <FormControl fullWidth>
                    <InputLabel id="labelReduccion">Reduccion de planificacion y documentacion</InputLabel>
                    <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                    <Select
                    labelId="labelReduccion"
                    label="Reduccion de planificacion y documentacion"
                    onChange={even =>handleChangeReduccion(even)}
                    value={norma.reduccionPlanificacionDocumentacion}
                    disabled={inputEditable()}>           
                        {
                            listaReduccion.map((item, index) =>
                            <MenuItem key={'red'+index} value={item.value}>{item.detalle}</MenuItem>)
                        }
                    </Select>
                </Restricted>
                </FormControl>
                
            </Grid>
            <Grid item xs={tieneComplejidad?4:6}>
            <Restricted to={PERMISOS.LECTURA} method={RESTRICT_METHOD.DISABLE}>
                <TextField 
                    fullWidth
                    label="Personal Afectado"
                    type="number"
                    error={errorPersonal}
                    value={personalAfectado}
                    disabled={inputEditable()}
                    onChange={handlePersonalAfectado}
                    />
            </Restricted>
            </Grid>  
        </>
    )
}

export default ModificadoresNorma
