import { useContext, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Box, Button, Drawer, Fab, Tooltip } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { makeStyles } from "@mui/styles";

 
import { SolicitudContext } from 'context/solicitud/solicitudContext';
import PaperTotalVisitas from 'components/paperTotalesVisitas';
import PaperAuditoria from 'components/PaperAuditorias';
import AlimentosFormCotizacion from 'components/alimentos/formCotizacion';
import PaperCalculoAlimentos from 'components/paperCalculoAlimentos';
import AlertaGuardado from 'components/AlertaGuardado';
import { CotizacionContext } from 'context/cotizacion/cotizacionContext';
import { setTurnos } from 'context/solicitud/actionsSolicitud/ActionsSolicitud';
import { getCotizacionAlimentoById } from 'services/CotizacionAlimentos';
import { getProductos, obtenerPropuestaByIdSilent, setDatosCotizacion, setEstadoCotizacion, setInputIafNace, setInputIntegracion, setJson, setMultisitios, setNACEs, setNormaMotivo, setTab } from 'context/cotizacion/actionsCotizacion/ActionsCotizacion';
import { parseNormas } from 'utlis/normasParser';
import { useCallback } from 'react';
import ObersvacionesTextEditor from 'components/ObservacionesTextEditor';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import UltimaModificacion from 'components/ultimaModificacion';
import { setError } from 'redux/slices/globalSlice';
import { useDispatch, useSelector } from 'react-redux';

const CotizacionAlimentos = () => {
  const toggleEditarCotizacion = true

  //context global
  const dispatchRedux = useDispatch()
  const globalSlice = useSelector(state => state.global)
  const history = useHistory();

  const { stateSolicitud } = useContext(SolicitudContext);


  const { state: stateCotizacion, dispatch: dispatchCotizacion } = useContext(CotizacionContext);
  const { json, inputCodigosNACESUBNACES, inputCodigosIAFNACE } = stateCotizacion
  const { idCotizacion, idPropuesta } = useParams();

  const useStyles = makeStyles((theme) => ({
    paper: {
      padding: theme.spacing(3),
      margin: theme.spacing(3),
    },
    fixedButton: {
      margin: 0,
      top: "auto",
      left: 50,
      bottom: 20,
      right: "auto",
      position: "fixed"
    }

  }));
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false)
  const closeDrawer = () => {
    setOpenDrawer(false)
  }

  const navigateToSolicitud = useCallback(() => {
    history.push(`/solicitud/${stateSolicitud.idSolicitud}`);
  }, [stateSolicitud, history]);

  const buildListaIAFNACE = (naces) => {

    const aux = naces.map(function (item) {
      return {
        CodigoIaf: {
          id: item.iaf.id,
          codigo: item.iaf.codigo,
          descripcion: item.iaf.descripcion
        },
        CodigoNace: {
          id: item.id,
          codigo: item.codigo,
          descripcion: item.descripcion
        }
      }
    })
    return aux;
  }

  const buildInputNaceSubNace = (naces) => {
    const aux = naces.map(function (item) {
      let naceFull = globalSlice.listaCodigosIAF.find(lciaf => lciaf.id === 3).nacEs.find(nace => nace.id === item.id)
      let subNacesFix = [{ id: "", codigo: "", descripcion: "" }]

      if (naceFull.subNaces.length === 1) {
        subNacesFix = naceFull.subNaces
      } else {
        subNacesFix = [{ id: "", codigo: "", descripcion: "" }]
      }
      return {
        codigo: item.codigo,
        descripcion: item.descripcion,
        id: item.id,
        subnaces: subNacesFix
      }
    })
    return aux;
  }

  useEffect(() => {
    getCotizacionAlimentoById(idCotizacion)
      .then(response => {

        let estadoAux = { ...response.estado }
        estadoAux.posiblesTransiciones = estadoAux.posiblesTransiciones.concat(estadoAux.estado)
        estadoAux.motivoRechazo = response.motivoRechazo
        //zxc
        dispatchCotizacion(setMultisitios(response.usaMultisitios))

        dispatchCotizacion(setEstadoCotizacion(estadoAux))
        const normasMotivos = parseNormas(response.normas)
        dispatchCotizacion(setNormaMotivo(normasMotivos))


        dispatchCotizacion(
          setInputIafNace(
            buildListaIAFNACE(response.nacEs)
          )
        );

        let subNaces = buildInputNaceSubNace(response.nacEs)

        dispatchCotizacion(setNACEs(subNaces))

        const info = {
          nombre: response.nombre,
          seguimientos: response.cantidadMantenimientos,
          codigo: response.codigo,
          revision: 'D',
          cantidadEstudiosHaccp: response.cantidadEstudiosHACCP
        }

        dispatchCotizacion(setDatosCotizacion(info))

        if (response.infoIntegracion) {
          dispatchCotizacion(setInputIntegracion(response.infoIntegracion))
        }
        dispatchCotizacion(setTurnos(response.turnos))
        dispatchCotizacion(setJson(response))
        if (idPropuesta) {
          dispatchCotizacion(setTab(response.usaMultisitios ? 2 : 1))
          obtenerPropuestaByIdSilent(dispatchCotizacion, { id: idPropuesta, params: true });
          getProductos(dispatchCotizacion);
        }

      })
      .catch(error => {
        dispatchRedux(setError({ estado: true, tipo: error.status, descripcion: error.data?.details ? error.data.details : error.data.message }))
        console.error("ERROR GET COTIZACION BY ID", error)
      })
  }, []);

  return (
    <>
      <Box marginTop={"4vh"} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }} alignItems={"flex-end"} >
        <UltimaModificacion />
        <Button
          variant='outlined'
          onClick={navigateToSolicitud}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Volver a cotizaciones
        </Button>
      </Box>
      <AlimentosFormCotizacion />
      {
        toggleEditarCotizacion &&
        <>
          <PaperCalculoAlimentos />
          {
            json.usaMultisitios ?
              <PaperTotalVisitas></PaperTotalVisitas>
              :
              <PaperAuditoria></PaperAuditoria>
          }
          <AlertaGuardado />
        </>
      }
      <Fab
        color="primary"
        className={classes.fixedButton}
        aria-label="add"
        onClick={() => setOpenDrawer(true)}
      >
        <Tooltip title="Observaciones">
          <NoteAltIcon />
        </Tooltip>
      </Fab>
      <Drawer
        anchor='left'
        open={openDrawer}
        onClose={closeDrawer}
      >
        <ObersvacionesTextEditor />
      </Drawer>
    </>

  )
}

export default CotizacionAlimentos;